import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Moment from "react-moment";

import InspectionActionButton from "../../../../components/ActionButtons/InspectionActionButton";
import FilterComponent from "../../../../components/FilterComponent/Index";
import InspectionMethods from "../../../../asyncMethods/InspectionMethods";
import CVInspectionActionButton from "../../../../components/ActionButtons/CVactionButton";
import CVMethods from "../../../../asyncMethods/CVMethods";
import ClientMethods from "../../../../asyncMethods/ClientMethods";


let columns = [
  {
    name: "Client Vehicle Master ID",
    selector: (row) => row.clientVehicleId,
    sortable: true,
  },
  {
    name: "Company Name",
    selector: (row) => row?.clientCompanyName,
  },
  {
    name: "Client Name",
    selector: (row) => row.clientName,
  },
    
  // {
  //   name: "Client id",
  //   selector: (row) => row.clientId,
  // },
  // {
  //   name: "locs id",
  //   selector: (row) => row.clientServiceLocationId,
  // },

  {
    name: "Location",
    selector: (row) => row.clientServiceLocationAddress,
  },
  //   {
  //     name: "Client Information",
  //     selector: (row) => row?.clientInformation,
  //     center: true,
  //   },
  //   {
  //     name: "Date and Time",
  //     selector: (row) => (
  //       <Moment format="MM/DD/YYYY">{row?.inspectionDateTime}</Moment>
  //     ),
  //     center: true,
  //   },
  {
    name: "Vehicle Reg No",
    selector: (row) => row?.vehicleRegNumber,
    center: true,
  },
  {
    name: "Create Date",
    selector: (row) => <Moment format="DD/MM/YYYY" utc>{row?.createdDate}</Moment>,
    center: true,
  },
  {
    name: "Vehicle Manufacturer",
    selector: (row) => row?.vehicleManufacturerLabel,
    center: true,
  },
  {
    name: "Vehicle Model",
    selector: (row) => row?.vehicleModelLabel,
    center: true,
  },
  {
    name: "Vehicle Type",
    selector: (row) => row?.vehicleTypeLabel,
    center: true,
  },
  
  {
    name: "Vehicle Configuration Key",
    selector: (row) => row?.configuredVehicle,
    center: true,
  },
  //   {
  //     name: "Odometer Reading",
  //     selector: (row) => row?.vehicleOdometerReading,
  //     center: true,
  //   },

  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true,
  },
];

const ClientVehicleMaster = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const inspectionMethods = InspectionMethods();
  const cVMethods = CVMethods();
  const clientMethods = ClientMethods();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  // Fetch All ObservationMaster
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;
  const roleType = user?.roleType;
  const client = user?.client;
  const clientId = user?.userID;

  let clientList = useSelector((state) => state.client.clientList);

  if (Array.isArray(clientList) && roleType == 2 && clientList.length > 0) {
    clientList = clientList.filter((inspection) => inspection.userId == userId);
  }

  if (Array.isArray(clientList) && client && clientList.length > 0) {
    clientList = clientList.filter(
      (inspection) => inspection.clientId == clientId
    );
  }

  console.log("client list", clientList);

  let clientIds;
  if (Array.isArray(clientList) && clientList.length > 0) {
    clientIds = clientList.map((client) => client.clientId);
  }
  
  console.log("client clientIds", clientIds);


  let cvList = useSelector(
    (state) => state.cvinspections.CVinspectionList
  );
  

  if (
    Array.isArray(cvList) &&
    roleType == 2 &&
    cvList.length > 0 &&
    Array.isArray(clientIds) && clientIds.length > 0
  ) {
    cvList = cvList.filter((inspection) =>
      clientIds.includes(inspection.clientId)
    );
  }

  if (
    Array.isArray(cvList) &&
    client &&
    cvList.length > 0 &&
    Array.isArray(clientIds) &&
    clientIds.length > 0
  ) {
    cvList = cvList.filter((inspection) =>
      clientIds.includes(inspection.clientId)
    );
  }
  
  console.log("cv List", cvList);


  useEffect(() => {
    // if (client) {
    //   inspectionMethods.fetchClientIspection(clientId);
    //   //cVMethods.fetchAllCVInspections(clientId);
    // } else if (role === "1" || role === 1) {
    //   //inspectionMethods.fetchAllInspections();
    //         cVMethods.fetchAllCVInspections();

    // } else if (role === "2" || role === 2) {
    //   inspectionMethods.fetchUserIspection(userId);
    //         //cVMethods.fetchAllCVInspections(userId);
    // }
    cVMethods.fetchAllCVInspections();
    clientMethods.fetchAllClients();


  }, []);


  // if (roleType == 2) {
  //   if (Array.isArray(clientIds) && clientIds.length > 0) {
  //     columns = columns.filter((column) =>
  //       clientIds.includes(column.clientId)
  //     );
  //   }
  // }

  if (client == true) {
    columns = columns.filter((column) => column.name !== "Action");
    
    // if (
    //   client &&
    //   Array.isArray(clientIds) &&
    //   clientIds.length > 0
    // ) {
    //   columns = columns.filter((column) => clientIds.includes(column.clientId));
    // }
  }


  const statusUpdate = (inspectionId, status) => {
    const data = {
      id: inspectionId,
      status: status == 1 ? 0 : 1,
    };
    // inspectionMethods.inspectionStatusUpdate(data);
    cVMethods.cvinspectionStatusUpdate(data);
  };

  let data = [];
    
  const inspectionList = useSelector(
    (state) => state.cvinspections.CVinspectionList
  );
  console.log("all", inspectionList);

  // if (inspectionList) {
  //   data = inspectionList.map((inspection) => ({
  //     ...inspection,
  //     actionButtons: (
  //         <CVInspectionActionButton
  //           onStatusUpdate={statusUpdate}
  //           inspectionDetails={inspection}
  //           />
      
  //     ),
  //   }));
  //     // .sort((b, a) => a.inspectionId - b.inspectionId);
  // }

  if (inspectionList) {
    data = inspectionList.map((inspection) => ({
      ...inspection,
      actionButtons: (
        <CVInspectionActionButton
          onStatusUpdate={statusUpdate}
          inspectionDetails={inspection}
        />
      ),
    }));
  }

  // let data = Array.from(
  //   useSelector((state) => state?.inspection?.inspectionList)
  // )
  //   .map((inspection) => ({
  //     ...inspection,
  //     actionButtons: (
  //       <InspectionActionButton
  //         onStatusUpdate={statusUpdate}
  //         inspectionDetails={inspection}
  //       />
  //     ),
  //   }))
  //   .sort((b, a) => a.inspectionId - b.inspectionId);
  console.log("daaa", data);


  let filteredItems = data.filter(
    (item) =>
      // (item.clientVehicleId || "")
      //   .toString()
      //   .toLowerCase()
      //   .includes(filterText.toString().toLowerCase()) ||
      (item.clientName || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
        (item.clientCompanyName || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.vehicleRegNumber || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.vehicleManufacturerLabel || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.vehicleModelLabel || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.vehicleTypeLabel || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.clientServiceLocationAddress || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase())
  );
    
  // console.log("fil", JSON.stringify(filteredItems, null, 2));
  console.log("fil", filteredItems);
  // console.dir(filteredItems);


  if (roleType == 2 || roleType == "2") {
    if (Array.isArray(clientIds) && clientIds.length > 0) {
      filteredItems = filteredItems.filter((column) =>
        clientIds.includes(column.clientId)
      );
    }
  }

  if (client == true) {
    if (Array.isArray(clientIds) && clientIds.length > 0) {
      filteredItems = filteredItems.filter((column) => clientIds.includes(column.clientId));
    }
  }


  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        {client ? (
          <></>
        ) : (
          <Button
            onClick={() => navigate("/client-vehicle-master/create")}
            variant="primary"
          >
            <i className="fa-solid fa-plus"></i> New Client Vehicle Master
          </Button>
        )}
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Client Vehicle Master"
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ClientVehicleMaster;
