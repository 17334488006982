import {
  FETCH_ALL_INSPECTION_TYPE,
  SET_EDIT_INSPECTION_TYPE,
  SET_INSPECTION_DETAILS,
  SET_INSPECTION_DETAILS2,
  FETCH_CLIENT_INSPECTION,
  FETCH_USER_INSPECTION,
  FETCH_LAST_INSPECTION,
} from "../constants/InspectionTypes";

const initialState = {
    inspectionList: [],
    editInspection: {},
  inspectionDetails: [],
  inspectionDetails2: [],
    last:[]
}

export const InspectionReducer = (state = initialState, { type, payload }) => {
    if(type === SET_INSPECTION_DETAILS) {
        console.log("🚀 ~ file: InspectionReducer.js:10 ~ InspectionReducer ~ payload:", payload)
        
    }
    switch (type) {
      case FETCH_ALL_INSPECTION_TYPE:
        return {
          ...state,
          inspectionList: payload?.content,
        };
      case SET_EDIT_INSPECTION_TYPE:
        return {
          ...state,
          editInspection: payload,
        };
      case SET_INSPECTION_DETAILS:
        return {
          ...state,
          inspectionDetails: payload,
        };
        case SET_INSPECTION_DETAILS2:
          return {
            ...state,
            inspectionDetails2: payload,
          };
      case FETCH_CLIENT_INSPECTION:
        return {
          ...state,
          inspectionList: payload,
        };
      case FETCH_USER_INSPECTION:
        return {
          ...state,
          inspectionList: payload,
        };
      case FETCH_LAST_INSPECTION:
        return {
          ...state,
          last: payload,
        };
      default:
        return state;
    }
}