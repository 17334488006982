import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import Select from "../../../components/DropdownField/Select";
import MasterDataMethod from "../../../asyncMethods/MasterDataMethod";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const schema = Yup
  .object({
    dropDownId: Yup.string().required("Name is required !"),
    dataKey: Yup.string().required("dataKey is required !"),
    dataLabel: Yup.string().required("dataKey is required !"),
    dataValue: Yup.number().required("dataKey is required !"),
    activeStatus: Yup.number("Should be number").required('Status Required'),
  })
  .required();

const CreateMasterData = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const masterDataMethod = MasterDataMethod();
  const allDropDown = useSelector(state => state?.masterData?.masterDropDown)
  const editMasterData = useSelector(state => state?.masterData?.editMasterData);

  const [initialValues, setInitialValues] = useState({
    dataKey: "",
    dataValue: "",
    dataLabel: "",
    dropDownId: "",
    activeStatus: ""
  });
  // Fetch All Master Data
  useEffect(() => {
    masterDataMethod.fetchAllMasterData();
    masterDataMethod.fetchAllDropDownData();
  }, []);

  const newSetList = {
    dropDownId: editMasterData?.dropDownId?.dropDownId,
    dataKey: editMasterData?.dataKey,
    dataLabel: editMasterData?.dataLabel,
    dataValue: editMasterData?.dataValue,
    activeStatus: editMasterData?.activeStatus,
  }

  useEffect(() => {
    if (parameters.dataId) {
      masterDataMethod.fetchMasterData(parameters.dataId);
    }
  }, []);

  useEffect(() => {
    if (editMasterData.dataId == parameters.dataId) {
      setInitialValues(newSetList)
    }
  }, [editMasterData]);

  // Form handleSubmit
  const onSubmit = (data) => {
    let { dataKey, dataLabel, dataValue, dropDownId } = data
    if (parameters.dataId) {
      masterDataMethod.updateMasterData(parameters.dataId, data)
    }
    else {
      masterDataMethod.checkMasterListDuplicate(dataKey, dataLabel, dataValue, dropDownId, data);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values) => {

          let dropData = allDropDown?.filter((item) =>
            item?.dropDownId == values?.dropDownId
          )
          const newData = {
            dataKey: values?.dataKey,
            dataValue: values?.dataValue,
            dataLabel: values?.dataLabel,
            dropDownId: values?.dropDownId,
            dataType: dropData[0]?.dropDownKey,
            parentId: dropData[0]?.parentDropDownId  ,
            activeStatus: values?.activeStatus
          }
          onSubmit(newData)
        }}
      >
        {(formik) => {
          const { errors, touched } = formik;
          return (
            <>
              <div className="d-flex justify-content-between m-3">
                <h5>{""}</h5>
                <Button
                  onClick={() => navigate("/master-data")}
                  variant="primary"
                >
                  <i className="fa-solid fa-arrow-left"></i> Back
                </Button>
              </div>
              <Card>
                <Card.Body>
                  <Form>
                    <div className="m-3">
                      <div className="form-group form-row">
                        <Select
                          label={"Dropdown Name "}
                          name={"dropDownId"}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                        >
                          <option value={""}>select</option>
                          {
                            allDropDown?.map((dropData, index) => (
                              <option key={index} value={dropData?.dropDownId}>{dropData?.dropDownName}</option>
                            ))
                          }
                        </Select>
                      </div>
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Data Key"}
                          name={"dataKey"}
                          classProps={"col"}
                          required={true}
                        />

                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Data Label"}
                          name={"dataLabel"}
                          classProps={"col"}
                          required={true}
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Data Value"}
                          name={"dataValue"}
                          classProps={"col"}
                          required={true}
                        />
                      </div>
                      <div className="form-group form-row">
                        <Select
                          label={"Status"}
                          name={"activeStatus"}
                          required={true}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                        >
                          <option value={""}>select</option>
                          <option value={1}>
                            Active
                          </option>
                          <option value={0}>
                            inActive
                          </option>
                        </Select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center m-3">
                      <button
                        type="submit"
                        className="btn-fill btn btn-info my-3"
                      >
                        {
                          parameters?.dataId ? "Update" : "Add"
                        } Data
                      </button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateMasterData;
