import React, { useMemo, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import UserMethods from "../../../asyncMethods/UserMethods";
import api from "../../../api";
import { toast } from "react-toastify";
import InputField from "../../../components/InputField";
import PasswordField from "../../../components/PasswordField";
import axios from "axios";

const schema = yup
  .object({
    password: yup.string()
              .required("Password is required")
              .min(8, "Password is too short - should be 8 chars min"),
    confirmPassword: yup.string()
                    .oneOf([yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm Password is required'),
    otp: yup.string().required("Otp is required"),
  })
  .required();

const ForgotPasswordSubmit = () => {
  const navigate = useNavigate();
  const userMethods = UserMethods();

  const userEmailId = localStorage.getItem('userEmailId');

  const [initialValues, setInitialValues] = useState({
    otp: "",
    password:"",
    confirmPassword:"",
  });
  const [showPassword, setShowPassword] = useState(false);

  // Shos/hide password function

  const handlePassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  const onSubmit = async (data) => {
    navigate('/forgot-password-submit')
    const datas = data;
    datas.emailId = userEmailId;
    console.log(datas);

    return api
    .put("/loginservice/forgetpassword", data)
    .then((res) => {
      const result = res.data.result;
      console.log("res", result);
      if(res.data.statusCode != 200){
        toast.error(res.data.statusDescription)
        return;
      }
      toast.success(res.data.statusDescription)
      localStorage.removeItem("userEmailId");
      navigate('/')
    })
    .catch(() => {
      toast.error("Request Failed.");
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { values, errors, touched, isValid, dirty } = formik;
        return (
          <>
            <div className="d-flex justify-content-between ml-3">
              <h4>{"Forgot Password"}</h4>
            </div>
            <Form>
              <div className="m-3">
                <div className="form-group">
                  <TextInputField
                    errors={errors}
                    touched={touched}
                    type={"text"}
                    label={"OTP"}
                    name={"otp"}
                    required={true}
                  />

<TextInputField
                    errors={errors}
                    touched={touched}
                    type={"text"}
                    label={"New Password"}
                    name={"password"}
                    required={true}
                  />

<TextInputField
                    errors={errors}
                    touched={touched}
                    type={"text"}
                    label={"Confirm Password"}
                    name={"confirmPassword"}
                    required={true}
                  />
                  
                </div>
                <div className="form-group">
                  <p className="small text-right">
                    <Link to="/">Login</Link>
                  </p>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn-fill btn btn-info">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordSubmit;
