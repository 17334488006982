import { FETCH_ALL_MASTER_DATA, FETCH_ALL_MASTER_DROP_DOWN_DATA, SET_EDIT_MASTER_DATA } from "../constants/MasterDataType"

const initialState = {
    masterDataList: [],
    editMasterData: {},
    masterDropDown:[]
}

export const MasterDataReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_MASTER_DATA:
            return {
                ...state,
                masterDataList: payload.content
            }
        case SET_EDIT_MASTER_DATA:
            return {
                ...state,
                editMasterData: payload
            } 
            case FETCH_ALL_MASTER_DROP_DOWN_DATA : 
            return {
                ...state ,
                masterDropDown:payload.content
            }
        default:
            return state
    }
}