import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { SET_EDIT_VEHICLE_MODEL, FETCH_ALL_VEHICLE_MODEL } from "../../redux/constants/mst_type/VehicleModelTypes";

const VehicleModelDataMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllVehicleModelData = () => {
        return api.get("/masterservice/getVehicleModel?page =0&size=10000&sortBy=vehicleModelId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_VEHICLE_MODEL,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeVehicleModelData = (data) => {
        return api.post("/masterservice/savevehiclemodelmaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Vehicle Model Created Successful.");
                navigate('/mst-vehicle-model');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchVehicleModelData = (vehicleModelId = 1) => {
        return api.get(`/masterservice/getvehiclemodelmasterdatabyid?vehicleModelId=` + vehicleModelId)
            .then(res => {
                console.log("res ----:", res)
                dispatch({
                    type: SET_EDIT_VEHICLE_MODEL,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }


    const updateVehicleModelData = (vehicleModelId, data) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updatevehiclemodelmaster?vehicleModelTypeId=` + vehicleModelId, data, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Vehicle Model Data updated Successful.");
                    navigate('/mst-vehicle-model');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const vehicleModelStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisablevehiclemodelmaster?vehicleModelId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllVehicleModelData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllVehicleModelData,
        storeVehicleModelData,
        updateVehicleModelData,
        vehicleModelStatusUpdate,
        fetchVehicleModelData
    };
};

export default VehicleModelDataMethod;
