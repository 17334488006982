import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import { useSelector } from "react-redux";
import PasswordField from "../../../components/PasswordField";
import UserMethods from "../../../asyncMethods/UserMethods";
import Select from "../../../components/DropdownField/Select";

const schema = Yup.object({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string().email().required("Email is required"),
  mobileNumber: Yup.number().min(10).required("Mobile Number is required !"),
  roleType: Yup.number("Should be number"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 4 chars min"),
});

const editSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string().email().required("Email is required"),
  mobileNumber: Yup.number().min(10).required("Mobile Number Required"),
  roleType: Yup.number().required("Required Field !"),
  newPassword: Yup.string()
  // .required("New Password is required")
  .min(8, "New Password is too short - should be 8 chars min"),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  // .required('Confirm Password is required'),

});

const CreateUser = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const userMethods = UserMethods();
  const [passwordFieldVisibility, setPasswordFieldVisibility] = useState(
    "form-group form-row"
  );
  const roleOptions = Array.from(
    useSelector((state) => state.authUser.userRoleOptions)
  );
  const statusOptions = Array.from(
    useSelector((state) => state.authUser.userStatusOptions)
  );

  const [initialValues, setInitialValues] = useState({
    name: "",
    emailId: "",
    mobileNumber: "",
    password: "",
    roleType: "",
    activeStatus: "",
    roleType: "",
  });

  useEffect(() => {
    if (parameters.userId) {
      userMethods.fetchUser(parameters.userId);
      // setPasswordFieldVisibility("form-group form-row d-none");
    }
  }, []);

  const editUser = useSelector((state) => state.authUser.editUser);

  useEffect(() => {
    if (parameters.userId && editUser.userId == parameters.userId) {
      setInitialValues(editUser);
    }
  }, [editUser]);

  useEffect(() => {
    userMethods.fetchUserOptions("roles");
    userMethods.fetchUserOptions("userstatus");
  }, []);

  const onSubmit = (data) => {
    if (parameters.userId) {
      console.log("data", data);
      userMethods.updateUser(parameters.userId, data);
    } else {
      userMethods.storeUser(data);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={!parameters.userId ? schema : editSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { errors, touched, setFieldValue } = formik;

        return (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5>{""}</h5>
              <Button onClick={() => navigate("/users")} variant="primary">
                <i className="fa-solid fa-arrow-left"></i> Back
              </Button>
            </div>
            <Card>
              <Card.Body>
                <Form>
                  <div className="m-3">
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Name"}
                        name={"name"}
                        required={true}
                        classProps={"col"}
                        readOnly={parameters.userId}
                      />
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"email"}
                        label={"Email "}
                        name={"emailId"}
                        required={true}
                        classProps={"col"}
                        readOnly={parameters.userId}
                        autoComplete="new-password"
                      />
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Mobile Number "}
                        name={"mobileNumber"}
                        required={true}
                        classProps={"col"}
                        readOnly={parameters.userId}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className={passwordFieldVisibility}>
                      <PasswordField
                        errors={errors}
                        touched={touched}
                        label={"Password "}
                        name={"password"}
                        required={true}
                        classProps={"col"}
                        readOnly={parameters.userId}
                        autoComplete="new-password"
                      />
                    </div>

                    {parameters.userId &&
                    <>
                    <div className="form-group form-row">
                    <PasswordField
                        errors={errors}
                        touched={touched}
                        label={"New Password"}
                        name={"newPassword"}
                        // required={true}
                        classProps={"col"}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="form-group form-row">
                    <PasswordField
                        errors={errors}
                        touched={touched}
                        label={"Confirm Password"}
                        name={"confirmPassword"}
                        // required={true}
                        classProps={"col"}
                        autoComplete="new-password"
                      />
                    </div>
                    </>
                  }

                    <div className="form-group form-row">
                      {/* <Select
                        label={"Role"}
                        name={"roleType"}
                        required={true}
                        classProps={"col"}
                        errors={errors}
                        touched={touched}
                      >
                        <option value={""}>select</option>
                        {roleOptions.map((data, i) => (
                          <option key={i} value={data.dataValue}>
                            {data.dataLabel}
                          </option>
                        ))}
                      </Select> */}
                      <Select
                        label={"Role"}
                        name={"roleType"}
                        required={true}
                        classProps={"col"}
                        errors={errors}
                        touched={touched}
                      >
                        <option value={""}>select</option>
                        <option value="1">Admin</option>
                        <option value="2">User</option>
                      </Select>
                      <Select
                        label={"Status "}
                        name={"activeStatus"}
                        required={true}
                        classProps={"col"}
                        errors={errors}
                        touched={touched}
                      >
                        {/* <option value={""}>select</option>
                        {statusOptions.map((data, i) => (
                          <option key={i} value={data.dataValue}>
                            {data.dataLabel}
                          </option>
                        ))} */}

                        <option value={""}>select</option>
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </Select>
                    </div>
                   
                  </div>
                  <div className="d-flex justify-content-center m-3">
                    <button
                      type="submit"
                      className="btn-fill btn btn-info my-3"
                    >
                      {parameters.userId ? "Update" : "Add"} User
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateUser;
