import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ObservationCategoryMasterButton = ({ observationCategoryMasterDetails, onStatusUpdate }) => {
    const navigate = useNavigate();
    const redirectToEditPage = observationCategoryId => {
        navigate("/mst-observation-category/edit/" + observationCategoryMasterDetails?.observationCategoryId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(observationCategoryMasterDetails?.observationCategoryId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>
                <Form.Check
                    inline
                    className={'mx-3 text-danger'}
                    type="switch"
                    value={observationCategoryMasterDetails?.activeStatus === 1 ? 1 : 0}
                    checked={observationCategoryMasterDetails?.activeStatus === 1 ? true : false}
                    id={'toggle-button' + observationCategoryMasterDetails?.observationCategoryId}
                    onChange={(e) => { onStatusUpdate(observationCategoryMasterDetails?.observationCategoryId, e.target.value) }}
                />
            </div>
        </>
    );
}

export default ObservationCategoryMasterButton;