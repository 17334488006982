import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TextInputField from "../../../../components/InputField/TextInputField";
import Select from "../../../../components/DropdownField/Select";
import { useSelector } from "react-redux";
import VehicleTypeDataMethod from "../../../../asyncMethods/mst_action/VehicleTypeDataMethod";
import VehicleSubTypeDataMethod from "../../../../asyncMethods/mst_action/VehicleSubTypeDataMethod";

const schema = Yup.object({
  vehicleSubTypeName: Yup.string().required("Required Field !"),
  vehicleTypeId: Yup.string().required("Required Field !"),
  noOfTires: Yup.number().required("Required Field!"),
  activeStatus: Yup.number().required("Required Field !"),
});

const CreateVehicleSubType = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const editSubTypeData = useSelector(
    (state) => state?.vehicleSubType?.editVehicleSubType
  );
  const vehicleTypeDataMethod = VehicleTypeDataMethod();
  const vehicleSubTypeDataMethod = VehicleSubTypeDataMethod();

  const [initialValues, setInitialValues] = useState({
    vehicleSubTypeName: "",
    vehicleTypeId: "",
    noOfTires: "",
    activeStatus: "",
  });

  useEffect(() => {
    vehicleTypeDataMethod.fetchAllVehicleTypeData();
    if (parameters.subTypeId) {
      vehicleSubTypeDataMethod.fetchVehicleSubTypeData(parameters.subTypeId);
    }
  }, []);

  useEffect(() => {
    if (
      parameters.subTypeId &&
      editSubTypeData.vehicleSubTypeId == parameters.subTypeId
    ) {
      editSubTypeData.vehicleTypeId =
        editSubTypeData?.vehicleTypeId?.vehicleTypeId;
      setInitialValues(editSubTypeData);
    }
  }, [editSubTypeData]);

  const vehicleTypeOptions = Array.from(
    useSelector((state) => state?.vehicleType?.vehicleTypeList)
  ).filter((s) => s.activeStatus == 1);

  const onSubmit = (data) => {
    console.log("tilakData", data);
    if (parameters.subTypeId) {
      vehicleSubTypeDataMethod.updateVehicleSubTypeData(
        parameters.subTypeId,
        data
      );
    } else {
      vehicleSubTypeDataMethod.storeVehicleSubTypeData(data);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        console.log("vehicleSubTypeName value:", values);
        console.log("noOfTires value:", values);
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { errors, touched } = formik;
        return (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5>{""}</h5>
              <Button
                onClick={() => navigate("/mst-vehicle-sub-type")}
                variant="primary"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </Button>
            </div>
            <Card>
              <Card.Body>
                <Form>
                  <div className="m-2">
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        required={true}
                        type={"text"}
                        label={"Vehicle Sub Type"}
                        name={"vehicleSubTypeName"}
                        classProps={"col-md-12"}
                      />
                    </div>
                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Vehicle Type"
                        name="vehicleTypeId"
                        classProps="col-md-12"
                        required={true}
                      >
                        <option value="">select</option>
                        {vehicleTypeOptions?.map((data, index) => (
                          <option
                            key={index}
                            value={data.vehicleTypeId}
                            data-dataid={data.vehicleTypeId}
                          >
                            {data.vehicleTypeName}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="form-group form-row">
                      <Select
                        label={"No. of Tyres"}
                        name={"noOfTires"}
                        required={true}
                        classProps={"col-md-12"}
                        errors={errors}
                        touched={touched}
                      >
                        <option value={""}>Select</option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                      </Select>
                    </div>

                    <div className="form-group form-row m-2">
                      <Select
                        label={"Status"}
                        name={"activeStatus"}
                        required={true}
                        classProps={"col"}
                        errors={errors}
                        touched={touched}
                      >
                        <option value={""}>select</option>
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </Select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center m-3">
                    <button
                      type="submit"
                      className="btn-fill btn btn-info my-3"
                    >
                      {parameters?.subTypeId
                        ? "Update Vehicle Sub Type"
                        : "Add Vehicle Sub Type"}
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateVehicleSubType;
