import { ErrorMessage, Field } from "formik";
import { useCallback, useState } from "react";

const PasswordField = ({
  errors,
  touched,
  readOnly,
  label,
  name,
  required,
  classProps,
  autoComplete,
}) => {
  const [type, setType] = useState("password");

  const onPasswordEyeClick = useCallback(() => {
    setType(type === "password" ? "text" : "password");
  }, [type]);

  return (
    <>
      <div className={classProps}>
        <label htmlFor={name}>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
        <div className="input-group">
          <Field
            type={type}
            name={name}
            id={name}
            className={"form-control"}
            readOnly={readOnly}
            autoComplete={autoComplete}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="password-show-hide"
              onClick={onPasswordEyeClick}
            >
              {type === "password" ? (
                <i className="fa-solid fa-eye"></i>
              ) : (
                <i className="fa-solid fa-eye-slash"></i>
              )}
            </button>
          </div>
        </div>
        <ErrorMessage
          name={name}
          component="span"
          className="error text-danger"
        />
      </div>
    </>
  );
};

export default PasswordField;
