import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { FETCH_ALL_VEHICLE_TYPE, SET_EDIT_VEHICLE_TYPE } from "../../redux/constants/mst_type/VehicleTypeTypes";

const VehicleTypeDataMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllVehicleTypeData = () => {
        return api.get("/masterservice/getallvehicletype?page =0&size=10000&sortBy=vehicleTypeId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_VEHICLE_TYPE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeVehicleTypeData = (data) => {
        return api.post("/masterservice/savevehicletype", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Vehicle Type Created Successful.");
                navigate('/mst-vehicle-type');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchVehicleTypeData = (vehicleTypeId = 1) => {
        return api.get(`/masterservice/getallvehicletypeid?vehicleTypeId=` + vehicleTypeId)
            .then(res => {
                console.log("res ----:", res)
                dispatch({
                    type: SET_EDIT_VEHICLE_TYPE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateVehicleTypeData = (vehicleTypeId, data) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updatevehicletype?vehicleTypeId=` + vehicleTypeId, data, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Vehicle Type Data updated Successful.");
                    navigate('/mst-vehicle-type');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const vehicleTypeStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisablevehicletypemaster?vehicleTypeId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllVehicleTypeData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllVehicleTypeData,
        storeVehicleTypeData,
        fetchVehicleTypeData,
        updateVehicleTypeData,
        vehicleTypeStatusUpdate
    };
};

export default VehicleTypeDataMethod;
