import { FETCH_ALL_VEHICLE_TIRE_MAKE, SET_EDIT_VEHICLE_TIRE_MAKE } from "../../constants/mst_type/VehicleTireMakeTypes";

const initialState = {
    vehicleTireMakeList: {},
    editVehicleTireMake: {},
};

export const VehicleTireMakeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_VEHICLE_TIRE_MAKE:
            return {
                ...state,
                vehicleTireMakeList: payload?.content,
            };
        case SET_EDIT_VEHICLE_TIRE_MAKE:
            return {
                ...state,
                editVehicleTireMake: payload,
            };

        default:
            return state;
    }
}