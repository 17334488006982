import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { FETCH_ALL_VEHICLE_MANUFACTURE, SET_EDIT_VEHICLE_MANUFACTURE } from "../../redux/constants/mst_type/VehicleManufactureTypes";
import { FETCH_FILTER_VEHICLE_MODEL } from "../../redux/constants/mst_type/VehicleModelTypes"

const VehicleManufactureDataMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllVehicleManufactureData = () => {
        return api.get("/masterservice/getallvehiclemanufacturer?page =0&size=10000&sortBy=vehicleManufacturerId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_VEHICLE_MANUFACTURE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeVehicleManufactureData = (data) => {
        return api.post("/masterservice/savevehiclemanufacturermaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Vehicle Manufacture Created Successful.");
                navigate('/mst-vehicle-manufacture');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchVehicleManufactureData = (vehicleManufacturerId = 1) => {
        return api.get(`/masterservice/getvehiclemanufacturer?vehicleManufacturerId=` + vehicleManufacturerId)
            .then(res => {
                console.log("res ----:", res)
                dispatch({
                    type: SET_EDIT_VEHICLE_MANUFACTURE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateVehicleManufactureData = (vehicleManufacturerId, data) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updatevehiclemanufacturermaster?vehicleManufacturerId=` + vehicleManufacturerId, data, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Vehicle Manufacture Data updated Successful.");
                    navigate('/mst-vehicle-manufacture');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const vehicleManufactureStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisablevehiclemanufacturermaster?vehicleManufacturerId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllVehicleManufactureData();
            }).catch(err => {
                console.log(err);
            })
    }

    const getVehicleByFilter = (vehicleManufacturer = 1 ) => {
        return api.get(`/masterservice/getvehiclebymultiplefilter?vehicleManufacturer=${vehicleManufacturer}`)
          .then(res => {
            dispatch({
                type: FETCH_FILTER_VEHICLE_MODEL,
                payload: (res.data.result) ? res.data.result : []
            });
          }).catch(err => {
            console.log(err);
          })
      }

    return {
        fetchAllVehicleManufactureData,
        fetchVehicleManufactureData,
        storeVehicleManufactureData,
        updateVehicleManufactureData,
        vehicleManufactureStatusUpdate,
        getVehicleByFilter
    };
};

export default VehicleManufactureDataMethod;
