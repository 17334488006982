import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const VehicleActionButton = ({ vehicleDetails, onStatusUpdate }) => {

  const navigate = useNavigate();

  const redirectToEditPage = vehicleId => {
    navigate("/vehicle/edit/" + vehicleDetails.vehicleId);
  }

return (
    <>
      <div className='d-flex align-items-center'>
        <Button variant="primary" size="sm" onClick={() => redirectToEditPage(vehicleDetails.vehicleId)} >
          <i className="fa-solid fa-user-pen"></i>
        </Button>
        <Form.Check
          inline
          className={'mx-3 text-danger'}
          type="switch"
          value={vehicleDetails?.activeStatus === 1 ? 1 : 0}
          checked={vehicleDetails?.activeStatus === 1 ? true : false}
          id={'toggle-button' + vehicleDetails?.vehicleId}
          onChange={(e) => { onStatusUpdate(vehicleDetails?.vehicleId, e.target.value) }}
        />
      </div>
    </>
  );
}

export default VehicleActionButton;