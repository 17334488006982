import { useEffect, useMemo, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import UserMethods from "../../../asyncMethods/UserMethods";
import ClientMethods from "../../../asyncMethods/ClientMethods";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import VehicleMethods from "../../../asyncMethods/VehicleMethods";
import TireConfigurationMasterMethod from "../../../asyncMethods/TireConfigurationMasterMethod";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";
import api from "../../../api";
import CVMethods from "../../../asyncMethods/CVMethods";
import moment from "moment";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import { Link, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';

const DateRangeSelector = ({ onDateRangeChange }) => {
  const dateFormat = "DD-MM-YYYY"; 
  const handleDateRangeChange = (dates) => {
    if (dates) {
      onDateRangeChange(dates);
    }
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };

  return (
    <div>
      <DatePicker.RangePicker
        allowClear={false}
        format={dateFormat}
        placeholder={["Start Date", "End Date"]}
        onChange={handleDateRangeChange}
        disabledDate={disabledDate}
      />
    </div>
  );
};

const Dashboard = () => {
  const userMethods = UserMethods();
  const clientMethods = ClientMethods();
  const vehicleMethods = VehicleMethods();
  const inspectionMethods = InspectionMethods();
  const cVMethods = CVMethods();
  const history = useNavigate();
  const tireConfigurationMasterMethod = TireConfigurationMasterMethod();
  const [clientName, setClientName] = useState(null);
  const [count, setCount] = useState(0);
  const [clientId, setClientId] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate9, setStartDate9] = useState(null);
  const [endDate9, setEndDate9] = useState(null);
  const [startDatex, setStartDatex] = useState(null);
  const [endDatex, setEndDatex] = useState(null);
  const [filtData, setFiltData] = useState([]);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState([]);
  const [selectedServiceLocationId, setSelectedServiceLocationId] = useState();
  const [selectedVehicleRegNumber, setSelectedVehicleRegNumber] = useState("");
  const [issuesData, setIssuesData] = useState([]);
  const [selectedVehicle, SetSlectedVeh] = useState([null]);
  const [tireData, setTireData] = useState([]);
  const [uniqueTireMakes, setUniqueTireMakes] = useState([]);
  const [tireMakes, setTireMakes] = useState(null);
  const [uniqueTireSizes, setUniqueTireSizes] = useState([]);
  const [tireSizes, setTireSizes] = useState(null);
  const [selectedTier, setSelectedTire] = useState("");
  const [api2Data2, setApi2Data2] = useState([]);
  const [top5, setTop5] = useState([]);
  const [apidatas1, setApiDatas1] = useState([]);
  const [apidatas2, setApiDatas2] = useState([]);
  const [apidatas3, setApiDatas3] = useState([]);
  const [total1, setTotal1] = useState(0);
  const [total2, setTotal2] = useState(0);
  const [total3, setTotal3] = useState(0);
  const [total4, setTotal4] = useState(0);
  const [total5, setTotal5] = useState(0);
  const [total6, setTotal6] = useState(0);
  const [total7, setTotal7] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [draftData, setDraftData] = useState(null);
  const [typedValue, setTypedValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [vehicleRegNumbers, setVehicleRegNumbers] = useState([]);
  const [tabData1, setTabData1] = useState(null);
  const [tabData2, setTabData2] = useState(null);
  const [tabData3, setTabData3] = useState(null);
  const [tabData4, setTabData4] = useState(null);
  const [tabData5, setTabData5] = useState(null);
  const [formattedStartDate1, setformattedStartDate1] = useState("");
  const [formattedStartDate2, setformattedStartDate2] = useState("");
  const [mycal, setMycal] = useState(false);
  const [formattedStartDate3, setformattedStartDate3] = useState("");
  const [formattedStartDate4, setformattedStartDate4] = useState("");
  const [filterIssueText, setFilterIssueText] = useState("");
  const [filterdIssue, setFilterdIssue] = useState(null);
  const [filterObsText, setFilterObsText] = useState("");
  const [filterdObs, setFilterdObs] = useState(null);

  const divRef = useRef(null);

  // const [dates, setDates] = useState([]);

  //   const handleDateChange = (selectedDates) => {
  //     setDates(selectedDates);
  //   };


  const user = JSON.parse(localStorage.getItem("user"));
  const roleType = user?.roleType;
  const userId = user?.userID;
  // console.log("role", roleType);
  const client = user?.client;

  let clientIdLocal = localStorage.getItem("tabClient");
  const clientLocationIdLocal = localStorage.getItem("tabClientLoc");
  const tabStartDate = localStorage.getItem("tabStartDate");
  const tabEndDate = localStorage.getItem("tabEndDate");

  if(client){
    clientIdLocal = userId
  }

  let tabStartDate2 = new Date(tabStartDate);
  console.log("tabStartDate2" , tabStartDate2);
  let tabEndDate2 = new Date(tabEndDate);

  const meeee = new Date(tabStartDate);
  console.log("main tabss", meeee);

  
  // console.log("client", client);

  useEffect(() => {
    userMethods.fetchAllUsers();
    clientMethods.fetchAllClients();
    inspectionMethods.fetchAllInspections();
    cVMethods.fetchAllCVInspections();
    //tireConfigurationMasterMethod.fetchAllTireConfigurationMasterData();
    //vehicleMethods.fetchAllVehicleData();

    api.get("/masterservice/getcount").then((res) => {
      const data = res.data.result;
      setCount(data);
      // console.log("api", res.data.result);
    });

    if (client == null) {
      userMethods.fetchUser(userId);
    } else {
      clientMethods.fetchClient(userId);
    }

    if (clientIdLocal) {
      // Set the stored client ID as the selected client
      setClientId(clientIdLocal);
    }

    if(clientLocationIdLocal){
      setSelectedServiceLocationId(clientLocationIdLocal);
    }

    if (tabStartDate && tabEndDate) {
      // Set the stored dates as the selected dates
      const startDater = new Date(tabStartDate);

      const day = startDater.getDate();
      const month = startDater.getMonth() + 1;
      const year = startDater.getFullYear();
    
      const formattedMonth = (month < 10 ? '0' : '') + month;
      const formattedDay = (day < 10 ? '0' : '') + day;
    
      // Construct the desired format
      const formattedStartDate = `${year}-${formattedMonth}-${formattedDay}`;
      if(formattedStartDate != "NaN-NaN-NaN"){
        setformattedStartDate3(formattedStartDate);
        const for1 = moment(formattedStartDate).format("DD-MM-YYYY");
        setformattedStartDate1(for1);
      }

      const startDatery = new Date(tabEndDate);
    
      const dayy = startDatery.getDate();
      const monthy = startDatery.getMonth() + 1;
      const yeary = startDatery.getFullYear();
    
      const formattedMonthy = (monthy < 10 ? '0' : '') + monthy;
      const formattedDayy = (dayy < 10 ? '0' : '') + dayy;
    
      // Construct the desired format
      const formattedStartDatey = `${yeary}-${formattedMonthy}-${formattedDayy}`;
      if(formattedStartDatey != "NaN-NaN-NaN"){
        setformattedStartDate4(formattedStartDatey);

        const for2 = moment(formattedStartDatey).format("DD-MM-YYYY");
      setformattedStartDate2(for2);
      }
    }

    // localStorage.setItem("tabClient", 27);
    // localStorage.setItem("tabClientLoc", 41);

    // localStorage.setItem("tabStartDate", startDate);
    // localStorage.setItem("tabEndDate", endDate);
    localStorage.removeItem("pageRefreshedT1");
    localStorage.removeItem("pageRefreshedT2");
    localStorage.removeItem("obs");
  }, []);

  let all = useSelector((state) => state);
  console.log("all", all);

  console.log("69", filteredOptions);

  console.log("dateee s62", startDate);


  // let users = useSelector((state) => state.authUser.userList.length);
  // let clients = useSelector((state) => state.client.clientList.length);
  // let vehicles = useSelector((state) => state.vehicle.vehicleList.length);
  // let tires = useSelector((state) => state.tireConfigurationMaster.tireConfigurationMasterList.length);

  let cvList2 = useSelector((state) => state.cvinspections.CVinspectionList);

  const filteredCv22 = cvList2.filter((item) => item.clientId == userId);
  const filteredCv2 = filteredCv22.filter((item) => item.activeStatus == 1);

  // console.log("cvvv", filteredCv2);

  const uniqueData2 = Array.from(
    new Map(
      filteredCv2.map((item) => [item["vehicleRegNumber"], item])
    ).values()
  );

  // console.log(uniqueData2);

  let clientList = useSelector((state) => state.client.clientList);
  let clientListRole2 = [];

  if (Array.isArray(clientList) && roleType == 2 && clientList.length > 0) {
    clientList = clientList.filter((inspection) => inspection.userId == userId);
    clientListRole2 = clientList.filter((inspection) => inspection.userId == userId);
  }

  console.log("clientLissst", clientList);

  let inspectionList = useSelector((state) => state.inspection.inspectionList);
  let cvList = useSelector((state) => state.cvinspections.CVinspectionList);
  // console.log("cv list", cvList);

  let clientName2;
  if (Array.isArray(clientList) && clientList.length > 0) {
    clientName2 = clientList.find((item) => item.clientId == userId);
  }

  console.log("client obj", clientName2);  

  let clientName3;
  let clientIdC, clientLocationsC;
  if (clientName2) {
    clientName3 = clientName2?.clientCompanyName;
    clientIdC = clientName2?.clientId;
    clientLocationsC = clientName2?.serviceLocations;
  }

  console.log("clientLocationsCv",clientLocationsC);
  useEffect(() => {
    if(client){
    setClientId(clientIdC);
    setClientName(clientName3);
    setSelectedServiceLocation(clientLocationsC);  
    }
  }, [clientName2]);
  console.log("loccasc",selectedServiceLocation);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedUpdateData = debounce(() => {
    api.get("/masterservice/getcount").then((res) => {
      const data = res.data.result;
      setCount(data);
      // console.log("api", res.data.result);
    });
  }, 800); // Adjust the delay as per your needs

  const handleSelectChange = (event) => {
    const selectedClientId = event.target.value;
    setClientId(selectedClientId);
    setSelectedServiceLocationId(null);
    setSelectedServiceLocation([]);
    setIssuesData([]);
    setSelectedVehicleRegNumber("");
    setTypedValue("");
    setFilteredOptions([]);
    setSelectedTire(null);
    setTireData([]);
    setTireSizes(null);
    setTireMakes(null);
    setTop5([]);
    setDraftData([]);
    setTabData1([]);
    setTabData2([]);
    setTabData3([]);
    setFilterObsText("");
    setFilterdObs(null);
    setTabData4([]);
    setTabData5([]);

    
    // setStartDate(null); // Reset start date
    // setEndDate(null); // Reset end date

    // Find the corresponding clientName in the clientList
    localStorage.setItem("tabClient", selectedClientId);
  
    let selectedClient = clientList.find(
      (client) => client.clientId === parseInt(selectedClientId)
    );

    if (client) {
      selectedClient = clientName2;
    }

    console.log("selecmmm", selectedClient);

    if (selectedClient) {
      setClientName(selectedClient?.clientCompanyName);
      setSelectedServiceLocation(selectedClient?.serviceLocations);
    } else {
      setClientName(null); // Reset clientName if client is not found
    }
    if (client) {
      setClientName(selectedClient?.clientCompanyName);
      setSelectedServiceLocation(selectedClient?.serviceLocations);
    }
  };

  useEffect(() => {
    if (tireData === undefined) {
      console.log("Tire data is undefined");
      return;
    }

    console.log("Tire data in useEffect:", tireData);

    if (tireData && tireData.length > 0) {
      const uniqueInspectionTirePairs = new Set();

      tireData.forEach((tire) => {
        const pair = `${tire.inspectionId}_${tire.tireMakeLabel}`;
        uniqueInspectionTirePairs.add(pair);
      });

      const tireMakesCount = Array.from(uniqueInspectionTirePairs).reduce(
        (acc, pair) => {
          const [inspectionId, tireMakeLabel] = pair.split("_");
          if (acc[tireMakeLabel]) {
            acc[tireMakeLabel] += 1;
          } else {
            acc[tireMakeLabel] = 1;
          }
          return acc;
        },
        {}
      );

      setTireMakes(tireMakesCount);
      console.log(
        "Tire Make Counts for unique Inspection IDs:",
        tireMakesCount
      );

      // Calculate unique tire sizes and counts
      const uniqueSizes = [...new Set(tireData.map((tire) => tire.tireSize))];
      setUniqueTireSizes(uniqueSizes);
      // console.log("Unique Tire Sizes:", uniqueSizes);

      const uniqueInspectionTireSizePairs = new Set();

      tireData.forEach((tire) => {
        const pair = `${tire.inspectionId}_${tire.tireSize}`;
        uniqueInspectionTireSizePairs.add(pair);
      });

      const tireSizeCount = Array.from(uniqueInspectionTireSizePairs).reduce(
        (acc, pair) => {
          const [inspectionId, tireSize] = pair.split("_");
          if (acc[tireSize]) {
            acc[tireSize] += 1;
          } else {
            acc[tireSize] = 1;
          }
          return acc;
        },
        {}
      );

      setTireSizes(tireSizeCount);
      console.log("Tire Size Counts for unique Inspection IDs:", tireSizeCount);
    }
  }, [tireData]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Clicked outside the div, hide the options
        setFilteredOptions([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup: remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setTypedValue(inputValue);

    // Create a Set to keep track of unique vehicle registration numbers
    const uniqueVehicleRegNumbers = new Set();

    // Filter options based on the input value and add to the Set
    const filteredOptions = vehicleRegNumbers
      .filter((loc) =>
        loc["vehicleregnumber,"]
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      )
      .filter((loc) => {
        if (!uniqueVehicleRegNumbers.has(loc["vehicleregnumber,"])) {
          uniqueVehicleRegNumbers.add(loc["vehicleregnumber,"]);
          return true;
        }
        return false;
      });

    setFilteredOptions(filteredOptions);
  };

  const handleInputFocus = () => {
    // Show all options when the input field is focused
    setFilteredOptions(vehicleRegNumbers);
  };

  const handleSelectChange2 = (selectedVal) => {
    setTypedValue(selectedVal);
    const selectedValue = selectedVal;
    setIssuesData([]);
    setFilteredOptions([]);

    // If there is an existing cancel token, cancel the previous request
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new selection.");
    }

    // Create a new cancel token source
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    // Update the state with the selected value and reset other state variables
    SetSlectedVeh(selectedValue);
    // Call the API with the selected value and associate it with the cancel token
    if (selectedValue) {
      api
        .get(
          `vehicleinspection/gettoptenissueclientidandvehicleid?clientId=${clientId}&vehicleRegNumber=${selectedValue}`,
          {
            cancelToken: newCancelTokenSource.token
          }
        )
        .then((res) => {
          const newData = res.data.result;
          // const startDates = new Date(startDate);
          // const endDates = new Date(endDate);

          // // Convert to yyyy-mm-dd format
          // const startDateFormatted = moment(startDates).format('DD/MM/YYYY');
          // const endDateFormatted = moment(endDates).format('DD/MM/YYYY');

          // // Format Moment objects
          // console.log("Start date 628:", startDateFormatted);
          // console.log("End date 628:", endDateFormatted);

          const filteredInspectionsByDate = newData.filter((inspection) => {
            const inspectionDate = moment(inspection.Modifieddate);
            inspectionDate.startOf("day");
            const createdDateLocal = new Date(
              inspectionDate.toLocaleString("en-US", {
                timeZone: "Asia/Kolkata"
              })
            );
            // const formattedInspectionDate = inspectionDate.format('DD/MM/YYYY');

            console.log("created date 629:", createdDateLocal);
            // const inspectionDateObject = moment(formattedInspectionDate, 'DD/MM/YYYY').toDate();
            // console.log("Start date 630:", inspectionDateObject);
            if (startDate9) {
              startDate9.$d.setHours(0, 0, 0, 0);
              console.log("$dd", startDate9.$d);
            }
            if (endDate9) {
              endDate9.$d.setHours(23, 59, 0, 0);
              console.log("$dd", endDate9.$d);
            }

            
          let startDateFormatted;
          let endDateFormatted;
          if(tabStartDate2 != null && tabEndDate2 != null && startDate == null && endDate == null){
            startDateFormatted = tabStartDate2;
            endDateFormatted = tabEndDate2;
          }
          else{
            startDateFormatted = startDate;
            endDateFormatted = endDate;
          }

          console.log("satr date 629f:", startDateFormatted);
          console.log("end date 629f:", endDateFormatted);

            return createdDateLocal >= startDateFormatted && createdDateLocal <= endDateFormatted;
          });

          console.log("gilll",filteredInspectionsByDate);
          setIssuesData(filteredInspectionsByDate);
          // setIssuesData(newData);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // Request was canceled
            console.log("Request canceled:", error.message);
          } else {
            // Handle other errors
            console.error("Error fetching data:", error);
          }
        });
    }
  };

  console.log("issss", issuesData);

  useEffect(() => {
    // Check if clientList is an array
    if (Array.isArray(clientList) && clientList.length > 0 && roleType == 1) {
      const sortedClientList = clientList
        .slice()
        .sort((a, b) => a?.clientCompanyName.localeCompare(b?.clientCompanyName));

        if(clientIdLocal && clientIdLocal != 0){
          clientName2 = clientList.find((item) => item.clientId == clientIdLocal);
        }
        
      if(!clientIdLocal && clientIdLocal == 0){
        setClientId(sortedClientList[0].clientId); // Set the first option as the default
        setClientName(sortedClientList[0]?.clientCompanyName);
        setSelectedServiceLocation(sortedClientList[0]?.serviceLocations?.filter(item => item.activeStatus == 1)); // Set the location data  
      }
      else{
        setClientId(clientIdLocal);
        setClientName(clientName2?.clientCompanyName);
        setSelectedServiceLocation(clientName2?.serviceLocations?.filter(item => item.activeStatus == 1)); // Set the location data  
      }
    }
  }, [clientList]);


  useEffect(() => {
    // Check if clientList is an array
    if (Array.isArray(clientListRole2) && clientListRole2.length > 0 && roleType == 2) {
      if(clientIdLocal && clientIdLocal != 0){
        clientName2 = clientList.find((item) => item.clientId == clientIdLocal);
        setSelectedServiceLocation(clientName2?.serviceLocations?.filter(item => item.activeStatus == 1)); // Set the location data  
      }
        
    }
  }, [clientListRole2]);


  if (Array.isArray(clientList) && clientList.length > 0) {
    clientList = clientList.filter((item) => item.clientActiveStatus == 1);
    console.log("clientList-----: ", clientList);
  }


  console.log("myyclient",clientId);

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      console.log("mainn start", start);
      setStartDate(start);
      setEndDate(end);
      setStartDatex(start);
      setEndDatex(end);
      setStartDate9(start);
      setEndDate9(end);


      localStorage.setItem("tabStartDate", start);
      localStorage.setItem("tabEndDate", end);  
    } else {
      // When the "close" button is clicked, set the state to null
      setStartDate(null);
      setEndDate(null);
      setStartDatex(null);
      setEndDatex(null);
    }
  };

  

  const handleLocationChange = (event) => {
    setSelectedVehicleRegNumber("");
    setTypedValue("");
    setFilteredOptions([]);
    setIssuesData([]);
    const selectedLocationId = event.target.value;
    localStorage.setItem("tabClientLoc", clientLocationIdLocal);
    setSelectedServiceLocationId(selectedLocationId);
    console.log("loc   idddd", selectedLocationId);
  };

  const selectedClientId = clientId;
  console.log("clie", selectedClientId);

  const filteredInspections = inspectionList.filter(
    (inspection) => inspection.clientId == selectedClientId
  );

  console.log("fillll", filteredInspections);

  let endDateNoTime = new Date(endDate);
let startDateNoTime = new Date(startDate);

  // Adding one day to endDate
  // endDateNoTime.setDate(endDateNoTime.getDate() + 1);
  console.log("end ddate", endDateNoTime, startDateNoTime);

  if(tabStartDate2 != null && tabEndDate2 != null && startDate == null && endDate == null){
    startDateNoTime = tabStartDate2;
    endDateNoTime = tabEndDate2;
  }
  endDateNoTime.setDate(endDateNoTime.getDate() + 1);
  console.log("end ddate", endDateNoTime, startDateNoTime);

  const filteredInspectionsByDate = filteredInspections.filter((inspection) => {
    const inspectionDate = new Date(inspection.inspectionDateTime);

    return inspectionDate >= startDateNoTime && inspectionDate <= endDateNoTime;
  });

  console.log("Filtered Inspections by Date Range:", filteredInspectionsByDate);

  const selectedLocationId = selectedServiceLocationId;

  const filteredInspectionsByLocation = filteredInspectionsByDate.filter(
    (inspection) => {
      return inspection.clientServiceLocationId == selectedLocationId;
    }
  );
  console.log(
    "Filtered Inspections by Date and Location:",
    filteredInspectionsByLocation
  );

  const filteredCv3 = cvList.filter(
    (item) => item.clientId == selectedClientId
  );
  console.log("cvvv3333", filteredCv3);

  const filteredCv = filteredCv3.filter((item) => item.activeStatus == 1);

  console.log("cvvv", filteredCv);

  const getNumberOfVehicles = (location) => {
    const vehicleRegNumbers = filteredCv.filter(
      (item) => item.clientServiceLocationId == location
    );

    return vehicleRegNumbers.length;
  };

  const vehicleRegNumbers2 = filteredCv.filter(
    (item) => item.clientServiceLocationId == selectedLocationId
  );

  console.log("vehicel Regg", vehicleRegNumbers2);

  console.log("start 626", startDate);

  console.log("end 626", endDate);

  const filteredTotalInp22 = filteredInspections.filter(
    (item) => item.inspectionStatus == 1
  );
  console.log("totallll22222", filteredTotalInp22);

  const filteredTotalInp2 = filteredTotalInp22.filter(
    (item) => item.activeStatus == 1
  );

  console.log("totallll", filteredTotalInp2);

  const filteredTotalInp3 = filteredTotalInp2.filter(
    (item) => item.clientServiceLocationId == selectedLocationId
  );

  console.log("olllnewww", filteredTotalInp3);

  const filteredData = filteredTotalInp2.filter(
    (item, index, self) =>
      item.inspectionStatus == 1 &&
      index ===
        self.findIndex(
          (t) =>
            t.vehicleRegNumber == item.vehicleRegNumber &&
            t.inspectionStatus == 1
        )
  );
  console.log("ffffffffffff", filteredData);

  const filteredDataWithCv = filteredData.filter((dataItem) =>
    filteredCv.some(
      (cvItem) => cvItem.vehicleRegNumber === dataItem.vehicleRegNumber
    )
  );

  console.log("Filtered Data with filteredCv:", filteredDataWithCv);

  const getNumberOfInspections = (location) => {
    const filteredTotalInp33 = filteredTotalInp2.filter(
      (item) => item.clientServiceLocationId == location
    );

    const filteredData = filteredTotalInp33.filter(
      (item, index, self) =>
        item.inspectionStatus === 1 &&
        index ===
          self.findIndex(
            (t) =>
              t.vehicleRegNumber === item.vehicleRegNumber &&
              t.inspectionStatus === 1
          )
    );

    const filteredDataWithCv = filteredData.filter((dataItem) =>
      filteredCv.some(
        (cvItem) => cvItem.vehicleRegNumber === dataItem.vehicleRegNumber
      )
    );
    // console.log("filteredDataWithCv222", filteredDataWithCv);
    return filteredDataWithCv.length;
  };

  const filteredTotalInp = filteredTotalInp2.filter((inspection) => {
    // Parse the inspectionDateTime string into a Date object
    const inspectionDate = new Date(inspection.inspectionDateTime);

    // Check if the inspectionDate is within the date range

    return inspectionDate >= startDateNoTime && inspectionDate <= endDateNoTime;
  });

  console.log("newww:", filteredTotalInp);

  const getInspectedVehicles = (location) => {
    const filteredTotalInp33 = filteredTotalInp.filter(
      (item) => item.clientServiceLocationId == location
    );

    const filteredDataWithCv = filteredTotalInp33.filter((dataItem) =>
      filteredCv.some(
        (cvItem) => cvItem.vehicleRegNumber === dataItem.vehicleRegNumber
      )
    );

    const filteredData = filteredDataWithCv.filter((item, index, self) => {
      const withinDateRange =
        new Date(item.inspectionDateTime) >= startDateNoTime &&
        new Date(item.inspectionDateTime) <= endDateNoTime;

      const isInspectionStatusOne = item.inspectionStatus === 1;

      const isUnique =
        index ===
        self.findIndex(
          (t) =>
            t.vehicleRegNumber === item.vehicleRegNumber &&
            t.inspectionStatus === 1
        );

      return withinDateRange && isInspectionStatusOne && isUnique;
    });

    return filteredData.length;
  };

  const uniqueMap = new Map();
  const uniqueData = filteredTotalInp.filter((item) => {
    if (!uniqueMap.has(item.vehicleRegNumber)) {
      uniqueMap.set(item.vehicleRegNumber, true);
      return true;
    }
    return false;
  });
  // console.log("uni", uniqueData);

  const vehicleRegNumberCounts = {};

  // Count the occurrences of each 'vehicleRegNumber'
  filteredTotalInp.forEach((item) => {
    const vehicleRegNumber = item.vehicleRegNumber;

    if (vehicleRegNumberCounts[vehicleRegNumber]) {
      vehicleRegNumberCounts[vehicleRegNumber]++;
    } else {
      vehicleRegNumberCounts[vehicleRegNumber] = 1;
    }
  });

  // Filter the counts to include only those repeated more than once
  const repeatedVehicleRegNumbers = {};
  for (const vehicleRegNumber in vehicleRegNumberCounts) {
    if (vehicleRegNumberCounts[vehicleRegNumber] > 1) {
      repeatedVehicleRegNumbers[vehicleRegNumber] =
        vehicleRegNumberCounts[vehicleRegNumber];
    }
  }
  // console.log("reppp", repeatedVehicleRegNumbers);
  const numberOfUniqueObjects = Object.keys(repeatedVehicleRegNumbers).length;

  const getCompletionPercentage = (location) => {
    const numberOfVehicles = getNumberOfVehicles(location);
    const inspectedVehicles = getInspectedVehicles(location);
    const percentage = (
      numberOfVehicles !== 0 ? (inspectedVehicles / numberOfVehicles) * 100 : 0
    ).toFixed(2);

    return percentage;
  };

  const getTotalNumberOfInspections1 = () => {
    let totalInspections1 = 0;

    if (selectedServiceLocation && selectedServiceLocation.length > 0) {
      selectedServiceLocation.forEach((loc) => {
        totalInspections1 += getNumberOfVehicles(loc.clientServiceLocationId);
      });
    }
    return totalInspections1;
  };

  const getTotalNumberOfInspections = () => {
    let totalInspections = 0;

    if (selectedServiceLocation && selectedServiceLocation.length > 0) {
      selectedServiceLocation.forEach((loc) => {
        totalInspections += getNumberOfInspections(loc.clientServiceLocationId);
      });
    }
    return totalInspections;
  };

  const getTotalNumberOfInspections2 = () => {
    let totalInspections2 = 0;

    if (selectedServiceLocation && selectedServiceLocation.length > 0) {
      selectedServiceLocation.forEach((loc) => {
        totalInspections2 += getInspectedVehicles(loc.clientServiceLocationId);
      });
    }
    return totalInspections2;
  };

  const getTotalNumberOfInspections3 = () => {
    let totalCompletionPercentage = 0;
    let totalLocations = 0;

    if (selectedServiceLocation && selectedServiceLocation.length > 0) {
      totalLocations = selectedServiceLocation.length;
      selectedServiceLocation.forEach((loc) => {
        const percentage = parseFloat(
          getCompletionPercentage(loc.clientServiceLocationId)
        );
        totalCompletionPercentage += isNaN(percentage) ? 0 : percentage;
      });
    }

    if (totalLocations == 0) {
      return 0;
    }
    var result = (totalCompletionPercentage / totalLocations).toFixed(2);

    return result;
  };

  const percentageInp =
    filteredInspectionsByLocation.length > 0 && filteredTotalInp.length > 0
      ? (
          (100 * filteredTotalInp.length) /
          filteredInspectionsByLocation.length
        ).toFixed(2)
      : 0;

  const lastInspectionDateTime =
    filteredTotalInp.length > 0 &&
    filteredTotalInp[filteredTotalInp.length - 1].inspectionDateTime;

  const momentDate = moment(lastInspectionDateTime);

  // console.log("lastt", momentDate);

  // Format the date as "DD-MM-YYYY"
  const formattedDate = momentDate.format("DD-MM-YYYY");

  // console.log("formatDateee" ,formattedDate);

  // console.log("datttttttt" , startDate);

  console.log("selectedServiceLocationsss", selectedServiceLocation);

  useEffect(() => {
    const vehicleReadingsMap = {};
    setApi2Data2([]);
    setApiDatas1([]);
    setApiDatas2([]);
    setApiDatas3([]);
    setTotal1(0);
    setTotal2(0);
    setTotal3(0);
    setTotal4(0);
    setTotal5(0);
    setTotal6(0);
    setTotal7(0);
    setDraftData([]);
    setIssuesData([]);
    setSelectedVehicleRegNumber("");
    setTypedValue("");
    setFilteredOptions([]);
    setStartDate9(startDate);
    setEndDate9(endDate);
    setTabData1([]);
    setTabData2([]);
    setTabData3([]);
    setFilterObsText("");
    setFilterdObs(null);
    setTabData4([]);
    setTabData5([]);


    localStorage.setItem("tabClient", clientId);
    localStorage.setItem("tabClientLoc", selectedServiceLocationId);


    filteredInspectionsByLocation.forEach((inspection) => {
      const { vehicleRegNumber, vehicleOdometerReading, inspectionDateTime } =
        inspection;
      if (!vehicleReadingsMap[vehicleRegNumber]) {
        vehicleReadingsMap[vehicleRegNumber] = {
          vehicleOdometerReading,
          inspectionDateTime
        };
      } else {
        if (
          new Date(inspectionDateTime) >
          new Date(vehicleReadingsMap[vehicleRegNumber].inspectionDateTime)
        ) {
          vehicleReadingsMap[vehicleRegNumber] = {
            vehicleOdometerReading,
            inspectionDateTime
          };
        }
      }
    });

    const updatedFiltData = filteredInspectionsByLocation.map((inspection) => {
      return {
        ...inspection,
        vehicleOdometerReading:
          vehicleReadingsMap[inspection.vehicleRegNumber].vehicleOdometerReading
      };
    });

    setFiltData(updatedFiltData);
    console.log("useee upp", updatedFiltData);

    const fetchData = async () => {
      try {
        if (clientId != null && selectedServiceLocationId != null) {
          const response = await api.get(
            `/vehicleinspection/getinspectiondonebyclientidandlocationid?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}`
          );

          const newData = response.data.result;
          let endDateNoTime = new Date(endDate);

          // Adding one day to endDate
          endDateNoTime.setDate(endDateNoTime.getDate() + 1);

          const filteredData = newData.filter((inspection) => {
            const inspectionDate = new Date(inspection.inspectionDateTime);
            return (
              inspectionDate >= new Date(startDate) &&
              inspectionDate <= new Date(endDateNoTime)
            );
          });

          // setApi2Data2(filtData);
          setApi2Data2(newData);
          console.log("Filtered Data: ", filteredData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchData1 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
          const response = await api.get(
            `/vehicleinspection/getnumberofobservationcategoryissue?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const newData = response.data.result;

          let endDateNoTime = new Date(endDate);

          // Adding one day to endDate
          endDateNoTime.setDate(endDateNoTime.getDate() + 1);

          setApiDatas1(newData);

          const total7 = newData.reduce(
            (acc, item) => acc + item.NumberOfObservationCategoryIssue,
            0
          );
          setTotal7(total7);
          // setApiDatas1(filteredData);
          // console.log("newDatas Data11: ", filteredData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas2 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
          const response = await api.get(
            `/vehicleinspection/gettiremakedetailsbyclientidandlocationid?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const response1 = await api.get(
            `vehicleinspection/getnumberoftirepressureissue?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const response2 = await api.get(
            `vehicleinspection/getnumberoftirertdissue?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          let endDateNoTime = new Date(endDate);

          // Adding one day to endDate
          endDateNoTime.setDate(endDateNoTime.getDate() + 1);

          const filteredData1 = response.data.result;

          const filteredData2 = response1.data.result;

          const filteredData3 = response2.data.result;

          const groupAndSum = (data) => {
            if (
              !data ||
              !Array.isArray(data) ||
              data.length === 0 ||
              data[0].result === null
            ) {
              return [];
            }

            const groupedData = {};

            data.forEach((item) => {
              const key = item.tireMake;
              if (!groupedData[key]) {
                groupedData[key] = {
                  tireMake: item.tireMake,
                  sum: 0
                };
              }
              groupedData[key].sum +=
                item["numbers of tires"] ||
                (item.NumberOfTirePressureIssue != null
                  ? item.NumberOfTirePressureIssue
                  : 0) ||
                (item.NumberOfRTdIssue !== null ? item.NumberOfRTdIssue : 0);
            });

            return Object.values(groupedData);
          };

          // Group and sum filtered data for each response
          const groupedData1 = groupAndSum(filteredData1);
          const groupedData2 = groupAndSum(filteredData2);
          const groupedData3 = groupAndSum(filteredData3);

          const filteredCombinedData = groupedData1.map((item1) => {
            const matchingItem2 = groupedData2.find(
              (item2) => item2.tireMake === item1.tireMake
            );
            const matchingItem3 = groupedData3.find(
              (item3) => item3 && item3.tireMake === item1.tireMake
            );

            return {
              tireMake: item1.tireMake,
              sum: item1.sum,
              NumberOfTirePressureIssue: matchingItem2 ? matchingItem2.sum : 0,
              NumberOfRTdIssue: matchingItem3 ? matchingItem3.sum : 0
            };
          });

          console.log("combined", filteredCombinedData);
          setApiDatas2(filteredCombinedData);

          const total1 = filteredCombinedData.reduce(
            (acc, item) => acc + item.sum,
            0
          );

          const total2 = filteredCombinedData.reduce(
            (acc, item) => acc + item.NumberOfTirePressureIssue,
            0
          );

          const total3 = filteredCombinedData.reduce(
            (acc, item) => acc + item.NumberOfRTdIssue,
            0
          );

          setTotal1(total1);
          setTotal2(total2);
          setTotal3(total3);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas3 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
          const response = await api.get(
            `/vehicleinspection/gettiresizedetailsbyclientidandlocationid?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const response1 = await api.get(
            `vehicleinspection/getnumberoftirepressureissueontiresize?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const response2 = await api.get(
            `vehicleinspection/getnumberoftirertdissueontiresize?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          let endDateNoTime = new Date(endDate);

          // Adding one day to endDate
          endDateNoTime.setDate(endDateNoTime.getDate() + 1);

          const filteredData1 = response.data.result;

          const filteredData2 = response1.data.result;

          const filteredData3 = response2.data.result;

          const groupAndSum = (data) => {
            if (
              !data ||
              !Array.isArray(data) ||
              data.length === 0 ||
              data[0].result === null
            ) {
              return [];
            }

            const groupedData = {};

            data.forEach((item) => {
              const key = item.tireSize;
              if (!groupedData[key]) {
                groupedData[key] = {
                  tireSize: item.tireSize,
                  sum: 0
                };
              }
              groupedData[key].sum +=
                item["numbers of tires"] ||
                (item.NumberOfTirePressureIssue != null
                  ? item.NumberOfTirePressureIssue
                  : 0) ||
                (item.NumberOfRTdIssue !== null ? item.NumberOfRTdIssue : 0);
            });

            return Object.values(groupedData);
          };

          // Group and sum filtered data for each response
          const groupedData1 = groupAndSum(filteredData1);
          const groupedData2 = groupAndSum(filteredData2);
          const groupedData3 = groupAndSum(filteredData3);

          const filteredCombinedData = groupedData1.map((item1) => {
            const matchingItem2 = groupedData2.find(
              (item2) => item2.tireSize === item1.tireSize
            );
            const matchingItem3 = groupedData3.find(
              (item3) => item3 && item3.tireSize === item1.tireSize
            );

            return {
              tireSize: item1.tireSize,
              sum: item1.sum,
              NumberOfTirePressureIssue: matchingItem2 ? matchingItem2.sum : 0,
              NumberOfRTdIssue: matchingItem3 ? matchingItem3.sum : 0
            };
          });

          console.log("combined", filteredCombinedData);
          setApiDatas3(filteredCombinedData);

          const total4 = filteredCombinedData.reduce(
            (acc, item) => acc + item.sum,
            0
          );

          const total5 = filteredCombinedData.reduce(
            (acc, item) => acc + item.NumberOfTirePressureIssue,
            0
          );

          const total6 = filteredCombinedData.reduce(
            (acc, item) => acc + item.NumberOfRTdIssue,
            0
          );

          setTotal4(total4);
          setTotal5(total5);
          setTotal6(total6);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas4 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
                    const response = await api.get(
            `/vehicleinspection/getvehicleregnoinspectiondaterange?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setVehicleRegNumbers(filteredData1);
          // setFilteredOptions(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas5 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
          const response = await api.get(
            `/vehicleinspection/getinspectiondraftbyclientidlocationid?clientId=${clientId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}&clientServiceLocationId=${selectedServiceLocationId}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setDraftData(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas6 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          startDate != null &&
          endDate != null
        ) {
          const startDates = new Date(startDate);
          const endDates = new Date(endDate);

          // Convert to yyyy-mm-dd format
          const startDateFormatted = startDates.toISOString().split("T")[0];
          const endDateFormatted = endDates.toISOString().split("T")[0];

          const response = await api.get(
            `/vehicleinspection/gettireinspectionforpressureissuebytiremakeandpattern?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setTabData1(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas7 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
          const response = await api.get(
            `/vehicleinspection/gettireinspectionforrtd?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setTabData2(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas8 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {
          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }
          const response = await api.get(
            `/vehicleinspection/getreportobservationindaterange?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setTabData3(filteredData1);
          setFilterdObs(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas9 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          ((startDate != null || formattedStartDate3 != null) &&
          (endDate != null || formattedStartDate4 != null))
        ) {

          let startDateFormatted;
          let endDateFormatted;
          if(formattedStartDate3 != null && formattedStartDate4 != null && startDate == null && endDate == null){
            startDateFormatted = formattedStartDate3;
            endDateFormatted = formattedStartDate4;
          }
          else{
            const startDates = new Date(startDate);
            const endDates = new Date(endDate);
  
            // Convert to yyyy-mm-dd format
             startDateFormatted = startDates.toISOString().split("T")[0];
             endDateFormatted = endDates.toISOString().split("T")[0];  
          }

          const response = await api.get(
            `/vehicleinspection/getreportvehicletireactiontakenindaterange?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setTabData4(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDatas10 = async () => {
      try {
        if (
          clientId != null &&
          selectedServiceLocationId != null &&
          startDate != null &&
          endDate != null
        ) {
          const startDates = new Date(startDate);
          const endDates = new Date(endDate);

          // Convert to yyyy-mm-dd format
          const startDateFormatted = startDates.toISOString().split("T")[0];
          const endDateFormatted = endDates.toISOString().split("T")[0];

          const response = await api.get(
            `/vehicleinspection/getreportdetailedtyrepictorialindaterange?clientId=${clientId}&clientServiceLocationId=${selectedServiceLocationId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );

          const filteredData1 = await response.data.result;
          console.log(filteredData1);
          setTabData5(filteredData1);
        }
      } catch (error) {
        console.error(error);
      }
    };


    // fetchData();
    fetchDatas2();
    fetchData1();
    fetchDatas3();
    fetchDatas4();
    fetchDatas5();
    // fetchDatas6();
    // fetchDatas7();
    fetchDatas8();
    fetchDatas9();
    // fetchDatas10();
    console.log("sadadsad",startDate);
  }, [clientId, selectedServiceLocationId, startDate, endDate, formattedStartDate3, formattedStartDate4]);

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const renderDateRangePicker = useMemo(() => {
    if (formattedStartDate1 && formattedStartDate2 && !mycal) {
      return (
        <div style={{ position: "relative" }}>
          <RangePicker
            defaultValue={[
              dayjs(formattedStartDate1, 'DD-MM-YYYY') ,
              dayjs(formattedStartDate2, 'DD-MM-YYYY')
            ]}
            allowClear={false}
            disabledDate={disabledDate}
            format={"DD-MM-YYYY"}
            onChange={handleDateRangeChange}
          />
        </div>
      );
    } else {
      return (
        <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
      );
    }
  }, [formattedStartDate1, formattedStartDate2, mycal]);

  const callObsFilter = e => {
    const filt = e.target.value;
    setFilterObsText(e.target.value);

    console.log("filt obs",filt);
  };

  useEffect(() => {
    if (tabData3 !== null && tabData3.length > 0) {
      if (filterObsText === "") {
        setFilterdObs(tabData3);
      } else {
        const filteredData = tabData3.filter(item => {
          // Check if any field starts with the filter text
          return (
            Object.values(item).some(value =>
              typeof value === "string" && value.toLowerCase().includes(filterObsText.toLowerCase())
            ) 
          );
        });
        console.log("filt obsss", filteredData);
        setFilterdObs(null);
        setFilterdObs(filteredData);
      }
    }
  }, [filterObsText, tabData3]);

  console.log("filt obsdata: " , filterdObs);
  console.log("filt tabdata: " , tabData3);

  return (
    <div className="container-fluid">
      <h4 style={{marginTop:"0", paddingTop:"0"}}>Dashboard</h4>
      {!client && roleType == "1" && (
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <Card className="card-stats">
              <Card.Body>
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big icon-warning">
                      {/* <i className="nc-icon nc-chart text-warning"></i> */}
                      <i class="fas fa-users ml-2"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="numbers">
                      <p className="card-category">Users</p>
                      <Card.Title as="h4">{count.userCount}</Card.Title>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div onClick={debouncedUpdateData} className="stats pointer">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <Card className="card-stats">
              <Card.Body>
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big icon-warning">
                      {/* <i className="nc-icon nc-light-3 text-success"></i> */}
                      <i class="fad fa-user-friends ml-2"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="numbers">
                      <p className="card-category">Clients</p>
                      <Card.Title as="h4">{count.clientCount}</Card.Title>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div onClick={debouncedUpdateData} className="stats pointer">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <Card className="card-stats">
              <Card.Body>
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big  icon-warning">
                      {/* <i className="nc-icon nc-vector text-danger"></i> */}
                      <i class="fad fa-truck-container ml-2"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="numbers">
                      <p className="card-category">Vehicles</p>
                      <Card.Title as="h4">{count.vehicleCount}</Card.Title>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div onClick={debouncedUpdateData} className="stats pointer">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <Card className="card-stats">
              <Card.Body>
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big  icon-warning">
                      {/* <i className="nc-icon nc-vector text-danger"></i> */}
                      <i class="far fa-tire ml-2"></i>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="numbers">
                      <p className="card-category">Tires</p>
                      <Card.Title as="h4">{count.tireCount}</Card.Title>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div onClick={debouncedUpdateData} className="stats pointer">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </div>
        </div>
      )}

      {roleType == 1 && (
        <>
          <div className="d-flex align-items-center mob-seles">
            <div>
              <label className="dash-label">Company Name:</label>
              <select
                className="ml-2"
                onChange={handleSelectChange}
                value={clientId}
              >
                {clientList.length > 0 &&
                  clientList
                    .sort((a, b) =>
                      a?.clientCompanyName.localeCompare(b?.clientCompanyName)
                    )
                    .map((client) => (
                      <option key={client.clientId} value={client.clientId}>
                        {client?.clientCompanyName}
                      </option>
                    ))}
              </select>
             
            </div>

            <div className="lg-ml-4 sm-mt-5 mob-seles">
              <label className="dash-label">Location:</label>
              <select
                style={{ width: "12rem" }}
                className="ml-2"
                onChange={handleLocationChange} // Add this line to handle changes in the location dropdown
                value={selectedServiceLocationId} // Use the selectedServiceLocationId, not the array
              >
                <option value={""}>Select a Location</option>
                {selectedServiceLocation &&
                  selectedServiceLocation.filter(item => item.activeStatus === 1).map((loc) => (
                    <option
                      key={loc.clientServiceLocationId}
                      value={loc.clientServiceLocationId} // Use the clientServiceLocationId here
                    >
                      {loc.clientServiceAddress}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex ml-4 mob-seles">
              <label className="dash-label">Date Range:</label>
              {/* <p>{formattedStartDate1}</p> */}
              {renderDateRangePicker}

            </div>
          </div>

          <br></br>
          {/* <p>sddd{startDatex ? startDatex : "no"}</p> */}

          <div>
            <div className="observation-table">
              <div className="observation-summary text-center">
                Vehicle Inspection
                {/* <br></br>
                Company Name: {clientName} */}
              </div>

              <table className="dash-table">
                <thead>
                  <tr className="dash-header">
                    <th>Location</th>
                    <th>Total Vehicles</th>
                    <th>Total Inspected Vehicle</th>
                    <th>Vehicle Inspected in date range</th>
                    {/* <th>Vehicles Inspected More Than Once</th> */}
                    <th>%Completion (Avg.)</th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr className="dash-row">
                    <td>
                      {selectedServiceLocation
                        ? selectedServiceLocation.length
                        : 0}
                    </td>
                    <td>{vehicleRegNumbers ? vehicleRegNumbers.length : 0}</td>
                    <td>{filteredTotalInp ? filteredTotalInp.length : 0}</td>
                    <td>{uniqueData ? uniqueData.length : ""}</td>
                    <td>{numberOfUniqueObjects ? numberOfUniqueObjects : 0}</td>
                    <td>{percentageInp}</td>
                   
                  </tr>
                </tbody> */}
                <tbody>
                  {selectedServiceLocation &&
                    selectedServiceLocation.map((loc) => (
                      <tr
                        className="dash-row"
                        key={loc.clientServiceLocationId}
                      >
                        <td>{loc.clientServiceAddress}</td>
                        {/* You'd need to replace the below placeholders with actual data */}
                        <td>
                          {getNumberOfVehicles(loc.clientServiceLocationId)}
                        </td>
                        <td>
                          {getNumberOfInspections(loc.clientServiceLocationId)}
                        </td>
                        <td>
                          {getInspectedVehicles(loc.clientServiceLocationId)}
                        </td>
                        {/* <td>{getVehiclesInspectedMoreThanOnce(loc.clientServiceLocationId)}</td> */}
                        <td>
                          {getCompletionPercentage(loc.clientServiceLocationId)}
                        </td>
                      </tr>
                    ))}
                  <tr className="dash-row">
                   <td style={{ fontWeight: "bold" }}>Total:</td>
                    <td style={{ fontWeight: "bold" }}>
                      <Link style={{color:"black"}} to='/totalvehicle'>
                      {getTotalNumberOfInspections1()}
                      </Link>
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicleinspected'>
                      {getTotalNumberOfInspections()}
                      </Link>
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicleinspectedindate'>
                      {getTotalNumberOfInspections2()}
                      </Link>
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {getTotalNumberOfInspections3()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {roleType == 2 && (
        <>
          <div className="d-flex align-items-center">
            <div>
              <label className="dash-label">Company Name:</label>
              <select
                className="ml-2"
                onChange={handleSelectChange}
                value={clientId}
              >
                <option value={""}>select a Client</option>
                {clientList.length > 0 &&
                  clientList.map((client) => (
                    <option key={client.clientId} value={client.clientId}>
                      {client?.clientCompanyName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="ml-4">
              <label className="dash-label">Location:</label>
              <select
                style={{ width: "12rem" }}
                className="ml-2"
                onChange={handleLocationChange} // Add this line to handle changes in the location dropdown
                value={selectedServiceLocationId} // Use the selectedServiceLocationId, not the array
              >
                <option value={""}>Select a Location</option>
                {selectedServiceLocation &&
                  selectedServiceLocation.filter(item => item.activeStatus === 1).map((loc) => (
                    <option
                      key={loc.clientServiceLocationId}
                      value={loc.clientServiceLocationId} // Use the clientServiceLocationId here
                    >
                      {loc.clientServiceAddress}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex ml-4">
              <label className="dash-label">Date Range:</label>
              {/* <DateRangeSelector onDateRangeChange={handleDateRangeChange} /> */}
              {renderDateRangePicker}
            </div>
          </div>

          <br></br>

          <div>
            <div className="observation-table">
              <div className="observation-summary text-center">
                Vehicle Inspection
                {/* <br></br>
                Company Name: {clientName} */}
              </div>

              <table className="dash-table">
                <thead>
                  <tr className="dash-header">
                    <th>Location</th>
                    <th>Total Vehicles</th>
                    <th>Total Inspected Vehicle</th>
                    <th>Vehicle Inspected in date range</th>
                    {/* <th>Vehicles Inspected More Than Once</th> */}
                    <th>%Completion (Avg.)</th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr className="dash-row">
                    <td>
                      {selectedServiceLocation
                        ? selectedServiceLocation.length
                        : 0}
                    </td>
                    <td>{vehicleRegNumbers ? vehicleRegNumbers.length : 0}</td>
                    <td>{filteredTotalInp ? filteredTotalInp.length : 0}</td>
                    <td>{uniqueData ? uniqueData.length : ""}</td>
                    <td>{numberOfUniqueObjects ? numberOfUniqueObjects : 0}</td>
                    <td>{percentageInp}</td>
                   
                  </tr>
                </tbody> */}
                <tbody>
                  {selectedServiceLocation &&
                    selectedServiceLocation.map((loc) => (
                      <tr
                        className="dash-row"
                        key={loc.clientServiceLocationId}
                      >
                        <td>{loc.clientServiceAddress}</td>
                        {/* You'd need to replace the below placeholders with actual data */}
                        <td>
                          {getNumberOfVehicles(loc.clientServiceLocationId)}
                        </td>
                        <td>
                          {getNumberOfInspections(loc.clientServiceLocationId)}
                        </td>
                        <td>
                          {getInspectedVehicles(loc.clientServiceLocationId)}
                        </td>
                        {/* <td>{getVehiclesInspectedMoreThanOnce(loc.clientServiceLocationId)}</td> */}
                        <td>
                          {getCompletionPercentage(loc.clientServiceLocationId)}
                        </td>
                      </tr>
                    ))}
                  <tr className="dash-row">
                    <td style={{ fontWeight: "bold" }}>Total:</td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicle'>
                      {getTotalNumberOfInspections1()}
                      </Link>
                      {/* {getTotalNumberOfInspections1()} */}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicleinspected'>
                      {getTotalNumberOfInspections()}
                      </Link>                    </td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicleinspectedindate'>
                      {getTotalNumberOfInspections2()}
                      </Link>
                      {/* {getTotalNumberOfInspections2()} */}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {getTotalNumberOfInspections3()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {client && (
        <>
          <div className="d-flex align-items-center">
            <div className="ml-4">
              <label className="dash-label">Location:</label>
              <select
                style={{ width: "12rem" }}
                className="ml-2"
                onChange={handleLocationChange} // Add this line to handle changes in the location dropdown
                value={selectedServiceLocationId} // Use the selectedServiceLocationId, not the array
              >
                <option value={""}>Select a Location</option>
                {selectedServiceLocation &&
                  selectedServiceLocation.map((loc) => (
                    <option
                      key={loc.clientServiceLocationId}
                      value={loc.clientServiceLocationId} // Use the clientServiceLocationId here
                    >
                      {loc.clientServiceAddress}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex ml-4">
              <label className="dash-label">Date Range:</label>
              {/* <DateRangeSelector onDateRangeChange={handleDateRangeChange} /> */}
              {renderDateRangePicker}
            </div>
          </div>

          <br></br>

          <div>
            <div className="observation-table">
              <div className="observation-summary text-center">
                Vehicle Inspection
                {/* <br></br>
                Company Name: {clientName3} */}
              </div>

              <table className="dash-table">
                <thead>
                  <tr className="dash-header">
                    <th>Location</th>
                    <th>Total Vehicles</th>
                    <th>Total Inspected Vehicle</th>
                    <th>Vehicle Inspected in date range</th>
                    {/* <th>Vehicles Inspected More Than Once</th> */}
                    <th>%Completion (Avg.)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedServiceLocation &&
                    selectedServiceLocation.map((loc) => (
                      <tr
                        className="dash-row"
                        key={loc.clientServiceLocationId}
                      >
                        <td>{loc.clientServiceAddress}</td>
                        {/* You'd need to replace the below placeholders with actual data */}
                        <td>
                          {getNumberOfVehicles(loc.clientServiceLocationId)}
                        </td>
                        <td>
                          {getNumberOfInspections(loc.clientServiceLocationId)}
                        </td>
                        <td>
                          {getInspectedVehicles(loc.clientServiceLocationId)}
                        </td>
                        {/* <td>{getVehiclesInspectedMoreThanOnce(loc.clientServiceLocationId)}</td> */}
                        <td>
                          {getCompletionPercentage(loc.clientServiceLocationId)}
                        </td>
                      </tr>
                    ))}
                  <tr className="dash-row">
                    <td style={{ fontWeight: "bold" }}>Total:</td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicle'>
                      {getTotalNumberOfInspections1()}
                      </Link>
                      {/* {getTotalNumberOfInspections1()} */}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicleinspected'>
                      {getTotalNumberOfInspections()}
                      </Link>                    </td>
                    <td style={{ fontWeight: "bold" }}>
                    <Link style={{color:"black"}} to='/totalvehicleinspectedindate'>
                      {getTotalNumberOfInspections2()}
                      </Link>
                      {/* {getTotalNumberOfInspections2()} */}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {getTotalNumberOfInspections3()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {/* <div className="mt-5 d-flex"> */}
      <br/>

     {(roleType == 1 || roleType == 2) &&<> 

    
     {/* <div>
        <p className="dash-label">
          Report for Tire Inspection for Pressure issues in Date Range (Tire
          Make and Pattern)
        </p>
        <div className="observation-table">
          <div className="observation-summary text-center">
          </div>

          <table className="dash-table">
            <thead>
              <tr className="dash-header">
                <th>Location</th>
                <th>Inspection ID</th>
                <th>Vehicle Reg No</th>
                <th>Tire Make</th>
                <th>Tire Pattern</th>
                <th>Tire Size</th>
                <th>Tire Sr. No</th>
                <th>Tire Position</th>
                <th>Recommended Pressure</th>
                <th>Observed Pressure</th>
              </tr>
            </thead>

            <tbody>
                {tabData1 &&
                  tabData1.length > 0 &&
                  tabData1.map((inspection) => (
                    <tr className="dash-row" key={inspection.inspectionId}>
                      <td>{inspection.clientServiceLocationAddress}</td>
                      <td>{inspection.inspectionId}</td>
                      <td>{inspection.vehicleRegNumber}</td>
                      <td>{inspection.tireMake}</td>
                      <td>{inspection.tirePattern}</td>
                      <td>{inspection.tireSize}</td>
                      <td>{inspection.tireSerialNo}</td>
                      <td>{inspection.tirePosition}</td>
                      <td>{inspection.recoPressure}</td>
                      <td>{inspection.obsPressure}</td>

                     
                    </tr>
                  ))}
              </tbody>
          </table>
        </div>
      </div> */}

      {/* <br></br> */}


      {/* <div>
        <p className="dash-label">
        Report for Tire Inspection for RTD in Date Range
        </p>
        <div className="observation-table">
          <div className="observation-summary text-center">
          </div>

          <table className="dash-table">
            <thead>
              <tr className="dash-header">
                <th>Location</th>
                <th>Inspection ID</th>
                <th>Vehicle Reg No</th>
                <th>Tire Make</th>
                <th>Tire Pattern</th>
                <th>Tire Size</th>
                <th>Tire Sr. No</th>
                <th>Tire Position</th>
                <th>RTD</th>
              </tr>
            </thead>

            <tbody>
                {tabData2 &&
                  tabData2.length > 0 &&
                  tabData2.map((inspection) => (
                    <tr className="dash-row" key={inspection.inspectionId}>
                      <td>{inspection.clientServiceLocationAddress}</td>
                      <td>{inspection.inspectionId}</td>
                      <td>{inspection.vehicleRegNumber}</td>
                      <td>{inspection.tireMake}</td>
                      <td>{inspection.tirePattern}</td>
                      <td>{inspection.tireSize}</td>
                      <td>{inspection.tireSerialNo}</td>
                      <td>{inspection.tirePosition}</td>
                      <td>{inspection.rtd}</td>

                    </tr>
                  ))}
              </tbody>
          </table>
        </div>
      </div>  */}
      </>}
            
      

      <div className="observation-table mt-4">
        <div className="observation-summary text-center">
          Tire Observation
          {/* <br></br>
          Company Name: {clientName} */}
        </div>
        <table className="dash-table">
          <thead>
            <tr className="dash-header">
              {/* <th>Tire Make</th> */}
              <th>Observation Category</th>
              <th>Number Of Observation Category Issue</th>
            </tr>
          </thead>
          <tbody>
            {apidatas1 &&
              apidatas1.length > 0 &&
              apidatas1.map((item, index) => (
                <>
                <tr
                  className="dash-row"
                  key={
                    item.NumberOfObservationCategoryIssue + index
                  }
                >
                  {/* <td>{item.tireMakeLabel}</td> */}
                  <td>{item.observationCategoryLabel}</td>
                  <td> <Link style={{color:"black"}} onClick={() => {localStorage.setItem('obs', item.observationCategoryLabel)}} to={`/pictorialtable?obs=${item.observationCategoryLabel}`}>
                  {item.NumberOfObservationCategoryIssue}
                  </Link></td>
                </tr>
                
                </>
              ))}
              <tr className="dash-row">
              <td style={{ fontWeight: "bold" }}>Total: </td>
              <td style={{ fontWeight: "bold" }}>{total7}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="observation-table mt-4">
        <div className="observation-summary text-center">
          Tire Inspection (Tire make wise)
          <br></br>
          {/* Company Name: {clientName} */}
        </div>
        <table className="dash-table">
          <thead>
            <tr className="dash-header">
              <th>Tire Make</th>
              <th>Numbers of tires</th>
              <th>Number Of Tire Pressure Issue</th>
              <th>Number Of RTD Issue</th>
            </tr>
          </thead>
          <tbody>
            {apidatas2 &&
              apidatas2.length > 0 &&
              apidatas2.map((item) => (
                <tr className="dash-row" key={item.tireMake}>
                  <td>{item.tireMake}</td>
                  <td>{item.sum}</td>
                  <td>{item.NumberOfTirePressureIssue}</td>
                  <td>{item.NumberOfRTdIssue}</td>
                </tr>
              ))}
            <tr className="dash-row">
              <td style={{ fontWeight: "bold" }}>Total: </td>
              <td style={{ fontWeight: "bold" }}>{total1}</td>
              <td style={{ fontWeight: "bold" }}>{total2}</td>
              <td style={{ fontWeight: "bold" }}>{total3}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="observation-table mt-4">
        <div className="observation-summary text-center">
          Tire Inspection (Tire size wise)
          {/* <br></br>
          Company Name: {clientName} */}
        </div>
        <table className="dash-table">
          <thead>
            <tr className="dash-header">
              <th>Tire Size</th>
              <th>Numbers of tires</th>
              <th>Number Of Tire Pressure Issue</th>
              <th>Number Of RTD Issue</th>
            </tr>
          </thead>
          <tbody>
            {apidatas3 &&
              apidatas3.length > 0 &&
              apidatas3.map((item) => (
                <tr className="dash-row" key={item.tireMake}>
                  <td>{item.tireSize}</td>
                  <td>{item.sum}</td>
                  <td>{item.NumberOfTirePressureIssue}</td>
                  <td>{item.NumberOfRTdIssue}</td>
                </tr>
              ))}
            <tr className="dash-row">
              <td style={{ fontWeight: "bold" }}>Total: </td>
              <td style={{ fontWeight: "bold" }}>
              <Link style={{color:"black"}} to='/tireinspection'>
                {total4}
              </Link>
              </td>
              <td style={{ fontWeight: "bold" }}>
              <Link style={{color:"black"}} to='/pressuretable'>
                    {total5}
              </Link>
                </td>
              <td style={{ fontWeight: "bold" }}>
                <Link style={{color:"black"}} to='/rtdtable'>
                    {total6}
              </Link>
                </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-5">
        <p className="dash-label">Top issues in vehicle</p>
        <div>
          <label className="dash-label">Vehicle Reg No.:</label>
          
          <br></br>

          <div ref={divRef}>
            <div className="d-flex">
            <input
              type="text"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              value={typedValue}
              className="ml-2"
              placeholder="Type to search vehicles"
            />
          {/* <input
          id="search-issue"
          type="text"
          placeholder="Search..."
          class="sc-jItqcz fcCtlt ml-3"
          value={filterIssueText}
          onChange={e => callIssueFilter(e)}
        /> */}
            </div>

            {filteredOptions && filteredOptions.length > 0 && (
              <div className="search-div">
                {filteredOptions.map((loc) => (
                  <p
                    className="search-text"
                    key={loc["vehicleregnumber,"]} // Use the correct key here
                    onClick={() =>
                      handleSelectChange2(loc["vehicleregnumber,"])
                    }
                  >
                    {loc["vehicleregnumber,"]}
                  </p>
                ))}
              </div>
            )}
          </div>

          {/* <select
            onChange={handleSelectChange2}
            value={selectedVehicleRegNumber}
            className="ml-2"
          >
            <option value={""}>Select a Vehicle</option>

            {vehicleRegNumbers.length > 0 &&
              vehicleRegNumbers.map((loc) => (
                <option
                  key={loc.vehicleRegNumber}
                  value={loc.vehicleRegNumber} // Use the clientServiceLocationId here
                >
                  {loc.vehicleRegNumber}
                </option>
              ))}
          </select> */}
        </div>
        <div className="mt-3">
          <div className="observation-table">
            {/* <div className="client-info">
              <div>Company Name: {clientName}</div>
            </div> */}
            <table className="dash-table">
              <thead>
                <tr className="dash-header">
                  <th>Inspection ID</th>
                  <th>Tire Position</th>
                  <th>Tire Make</th>
                  <th>Tire Pattern</th>
                  <th>Observation</th>
                  <th>Recommendation</th>
                  <th>Pressure</th>
                  <th>Least Tire Thickness Allowed</th>
                  {/* <th>Wear Analysis</th> */}
                </tr>
              </thead>
              <tbody>
                {issuesData &&
                  issuesData.length > 0 &&
                  issuesData.map((inspection) => (
                    <tr className="dash-row" key={inspection.inspectionId}>
                      <td>{inspection["inspection id"]}</td>
                      <td>{inspection["tire position"]}</td>
                      <td>{inspection["tire make label"]}</td>
                      <td>{inspection["tire pattern"]}</td>
                      <td>{inspection["observation label"]}</td>
                      <td>{inspection["recommendation label"]}</td>
                      <td>{inspection["pressure analysis"]}</td>
                      <td>
                        {inspection["least tire thickness allowed analysis"]}
                      </td>
                      {/* <td>{inspection.wearAnalysis}</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <p className="dash-label">Draft Inspections</p>

        <div className="mt-3">
          <div className="observation-table">
            {/* <div className="client-info">
              <div>Company Name: {clientName}</div>
            </div> */}
            <table className="dash-table">
              <thead>
                <tr className="dash-header">
                  <th>Inspection ID</th>
                  {/* <th>Inspection status</th> */}
                  <th>Date</th>

                  {/* <th>Wear Analysis</th> */}
                </tr>
              </thead>
              <tbody>
                {draftData &&
                  draftData.length > 0 &&
                  draftData.map((inspection) => (
                    <tr className="dash-row" key={inspection.inspectionId}>
                      <td>{inspection.inspectionId}</td>
                      <td>
                        {moment(inspection.createdDate).format("DD/MM/YYYY")}
                      </td>

                      {/* <td>{inspection.wearAnalysis}</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br></br>

    {(roleType == 1 || roleType == 2 || client) && <>
 <br></br>
      <div>
        <p className="dash-label">
        Report for Tire Inspection for Observation in Date Range
        </p>
        <input
          id="search-obs"
          type="text"
          placeholder="Search..."
          class="sc-jItqcz fcCtlt ml-3"
          value={filterObsText}
          onChange={e => callObsFilter(e)}
        />
        <div className="observation-table">
          <div className="observation-summary text-center">
            </div>

            <table className="dash-table">
              <thead>
                <tr className="dash-header">
                  <th>Location</th>
                  <th>Inspection Id</th>
                  <th>Vehicle Reg No</th>
                  <th>Tire Make</th>
                  <th>Tire Pattern</th>
                  <th>New/ Retread</th>
                  <th>Tire Size</th>
                  <th>Tire Position</th>
                  <th>Tire Sr. No</th>
                  <th>Observation Category</th>
                  <th>Observation</th>
                  {/* <th>Wear</th>
                  <th>Sidewall</th>
                  <th>Shoulder</th>
                  <th>Tread & Crown</th>
                  <th>Others</th> */}
                </tr>
              </thead>
  
              <tbody>
                {filterdObs &&
                  filterdObs.length > 0 &&
                  filterdObs.map((inspection, index) => (
                    <tr className="dash-row" key={inspection.inspectionid + index}>
                      <td>{inspection.clientServiceLocationAddress}</td>
                      <td>{inspection.inspectionid}</td>
                      <td>{inspection.vehicleRegNumber}</td>
                      <td>{inspection.tireMake}</td>
                      <td>{inspection.tirePattern}</td>
                      <td>{inspection.tiretypelabel}</td>
                      <td>{inspection.tiresize}</td>
                      <td>{inspection.tireposition}</td>
                      <td>{inspection.tireSerialNo}</td>
                      <td>{inspection.observationcategorylabel}</td>
                      <td>{inspection.observationlabel}</td>
                      {/* <td>{inspection.Wears}</td>
                      <td>{inspection.Sidewall}</td>
                      <td>{inspection.Shoulder}</td>
                      <td>{inspection.TreadorCrown}</td>
                      <td>{inspection.Other}</td> */}
                      
                    </tr>
                  ))}
              </tbody>
             
            </table>
          </div>
        </div>
  
  
        <br></br>
        {/* <div>
          <p className="dash-label">
          Vehicle Tire Actions Taken Report
          </p>
          <div className="observation-table">
            <div className="observation-summary text-center">
            </div>
  
            <table className="dash-table">
              <thead>
                <tr className="dash-header">
                  <th>Location</th>
                  <th>Inspection Id</th>                  
                  <th>Vehicle Reg No</th>
                  <th>Tire Make</th>
                  <th>Tire Pattern</th>
                  <th>New/ Retread</th>
                  <th>Tire Size</th>
                  <th>Tire Sr. No</th>
                  <th>Tire Position</th>
                  <th>Rot</th>
                  <th>ALMNT</th>
                  <th>TWNG</th>
                  <th>RFR</th>
                  <th>NWMNT</th>
                  <th>Scrap</th>
                </tr>
              </thead>
  
              <tbody>
                {tabData4 &&
                  tabData4.length > 0 &&
                  tabData4.map((inspection) => (
                    <tr className="dash-row" key={inspection.inspectionId}>
                      <td>{inspection.clientServiceLocationAddress}</td>
                      <td>{inspection.inspectionId}</td>
                      <td>{inspection.vehicleRegNumber}</td>
                      <td>{inspection.tireMake}</td>
                      <td>{inspection.tirePattern}</td>
                      <td>{inspection?.tyreType}</td>
                      <td>{inspection.tireSize}</td>
                      <td>{inspection.tireSerialNo}</td>
                      <td>{inspection?.tirePosition ? inspection?.tirePosition : inspection.tireposition}</td>
                      <td>{inspection.ROT}</td>
                      <td>{inspection.ALMNT}</td>
                      <td>{inspection.TWNG}</td>
                      <td>{inspection.RFR}</td>
                      <td>{inspection.NWMNT}</td>
                      <td>{inspection.SCRAP}</td>
                    
                    </tr>
                  ))}
              </tbody>
              
            </table>
          </div>
        </div>         */}
      
      
    </>}
    </div>
  );
};

export default Dashboard;
