import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputField from "../../../components/InputField";
import { useSelector } from "react-redux";
import ClientMethods from "../../../asyncMethods/ClientMethods";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";

const ClientLogin = () => {
  const [adminLogin, setAdminLogin] = useState(true);
  const [roleType, setRoleType] = useState(1);
  const [usernameLabel, setUsernameLabel] = useState("Email or Mobile");
  const [forgotPassTextClasses, setForgotPassTextClasses] = useState("small");
  const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


  // Shos/hide password function

  const handlePassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  const authMethod = ClientMethods();

  const onSetAdminLogin = (value) => {
    //setAdminLogin(value == 0 ? true : false);
    setUsernameLabel(value == 0 ? "Email or Mobile" : "Email / Mobile");
    setForgotPassTextClasses(value == 0 ? "small" : "d-none");
  //  setRoleType(value == 0 ? 1 : 2);
  };

  // useEffect(() => {
  //   setRoleType(adminLogin ? 1 : 2);
  //   console.log("use", roleType);
  // }, [adminLogin]);

  // const onSubmit = async (data) => {
  //   console.log("log", data);
  //   await authMethod.login(data);
  // };

    const onSubmit = async (data) => {
      const modifiedData = {
        ...data,
      };

      // Make the API request for client login
      try {
        await authMethod.login(modifiedData);

        // If login is successful, redirect to /dashboard
        navigate("/dashboard");
      } catch (error) {
        // Handle login failure
        console.error("Login failed:", error);
      }
    };


  const schema = yup
    .object({
      username: yup
        .mixed()
        .test(
          "username",
          `Please enter a valid ${adminLogin ? "email or mobile" : "email / mobile"} `,
          function (value, context) {
            if (value === "") return false;
            if (
              adminLogin &&
              (yup.string().length(10).matches(/^\d+$/).isValidSync(value) ||
                yup.string().email().isValidSync(value))
            ) {
              return true;
            }
            if (
              !adminLogin &&
              (yup.string().length(10).matches(/^\d+$/).isValidSync(value) ||
                yup.string().email().isValidSync(value))
            ) {
              return true;
            }
            return false;
          }
        )
        .required(),
      // clientMobileNo: yup.string().required("Enter valid Mobile No."),
      clientPassword: yup.string().min(8).max(30).required(),
      // roleType: yup.number(),
    })
    .required();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      // clientMobileNo: "",
      clientPassword: "",
      //roleType: roleType,
    },
  });

  return (
    <>
      <h4>Client Login</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label={usernameLabel}
          placeholder={usernameLabel}
          name="username"
          // label={"Mobile No."}
          // placeholder={"Mobile No."}
          // name="clientMobileNo"
          control={control}
        />
        <InputField
          label="Password"
          placeholder="Password"
          name="clientPassword"
          control={control}
          type={showPassword ? "text" : "password"}
          showPassword={showPassword}
          passField={true}
          handlePassword={handlePassword}
        />

        {/* <div className="form-group my-3 d-flex justify-content-between">
          <Form.Check
            inline
            name="roleType"
            type="switch"
            id={"toggle-button"}
            label={"Admin"}
            value={adminLogin ? 1 : 0}
            checked={adminLogin ? true : false}
            onChange={(e) => {
              onSetAdminLogin(e.target.value); // Pass 0 or 1 to onSetAdminLogin
              // setRoleType(e.target.value === "1" ? 0 : 1);
            }}
          />
          <p className={forgotPassTextClasses}>
            <Link to="/forgot-password">Forgot password?</Link>
          </p>
        </div> */}
        <div className="form-group login-btn">
          <div>
            <button type="submit" className="btn-fill btn btn-info">
              Login
            </button>
          </div>
          {/* <div>
            <a href="https://milancrm.q10analytics.com/ReportForm/">
              <i
                class="fa fa-file"
                aria-hidden="true"
                style={{
                  fontSize: "30px",
                }}
              ></i>
            </a>
          </div> */}
        </div>
      </form>
    </>
  );
};

export default ClientLogin;

// import { useForm } from "react-hook-form";
// import { Link, useNavigate } from "react-router-dom";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";

// import InputField from "../../../components/InputField";
// import { useSelector } from "react-redux";
// import ClientMethods from "../../../asyncMethods/ClientMethods";
// import { Form } from "react-bootstrap";
// import { useState } from "react";
// import { useEffect } from "react";
// import Logo from "../../../assets/images/logo.png";


// const ClientLogin = () => {
//   const [adminLogin, setAdminLogin] = useState(true);
//   const [roleType, setRoleType] = useState(1);
//   const [usernameLabel, setUsernameLabel] = useState("Email or Mobile");
//   const [forgotPassTextClasses, setForgotPassTextClasses] = useState("small");
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   // Shos/hide password function

//   const handlePassword = () => {
//     setShowPassword(showPassword ? false : true);
//   };

//   const authMethod = ClientMethods();

//   const onSetAdminLogin = (value) => {
//     //setAdminLogin(value == 0 ? true : false);
//     setUsernameLabel(value == 0 ? "Email or Mobile" : "Email / Mobile");
//     setForgotPassTextClasses(value == 0 ? "small" : "d-none");
//     //  setRoleType(value == 0 ? 1 : 2);
//   };

//   // useEffect(() => {
//   //   setRoleType(adminLogin ? 1 : 2);
//   //   console.log("use", roleType);
//   // }, [adminLogin]);

//   // const onSubmit = async (data) => {
//   //   console.log("log", data);
//   //   await authMethod.login(data);
//   // };

//   const onSubmit = async (data) => {
//     const modifiedData = {
//       ...data,
//     };

//     // Make the API request for client login
//     try {
//       await authMethod.login(modifiedData);

//       // If login is successful, redirect to /dashboard
//       navigate("/dashboard");
//     } catch (error) {
//       // Handle login failure
//       console.error("Login failed:", error);
//     }
//   };

//   const schema = yup
//     .object({
//       username: yup
//         .mixed()
//         .test(
//           "username",
//           `Please enter a valid ${
//             adminLogin ? "email or mobile" : "email / mobile"
//           } `,
//           function (value, context) {
//             if (value === "") return false;
//             if (
//               adminLogin &&
//               (yup.string().length(10).matches(/^\d+$/).isValidSync(value) ||
//                 yup.string().email().isValidSync(value))
//             ) {
//               return true;
//             }
//             if (
//               !adminLogin &&
//               (yup.string().length(10).matches(/^\d+$/).isValidSync(value) ||
//                 yup.string().email().isValidSync(value))
//             ) {
//               return true;
//             }
//             return false;
//           }
//         )
//         .required(),
//       // clientMobileNo: yup.string().required("Enter valid Mobile No."),
//       clientPassword: yup.string().min(8).max(30).required(),
//       // roleType: yup.number(),
//     })
//     .required();

//   const { control, handleSubmit } = useForm({
//     resolver: yupResolver(schema),
//     defaultValues: {
//       username: "",
//       // clientMobileNo: "",
//       clientPassword: "",
//       //roleType: roleType,
//     },
//   });

//   return (
//     <>
//       <img
//         style={{
//           position: "absolute",
//           width: "8rem",
//           height: "3rem",
//           left: "8px",
//           top: "12px",
//         }}
//         src={Logo}
//       />
//       <div className="login-page">
//         <div className="background"></div>
//         <div className="login-form">
//           <h4 className="log-text">Client Login</h4>
//           <form onSubmit={handleSubmit(onSubmit)}>
//             <InputField
//               label={usernameLabel}
//               placeholder={usernameLabel}
//               name="username"
//               // label={"Mobile No."}
//               // placeholder={"Mobile No."}
//               // name="clientMobileNo"
//               control={control}
//             />
//             <InputField
//               label="Password"
//               placeholder="Password"
//               name="clientPassword"
//               control={control}
//               type={showPassword ? "text" : "password"}
//               showPassword={showPassword}
//               passField={true}
//               handlePassword={handlePassword}
//             />

//             {/* <div className="form-group my-3 d-flex justify-content-between">
//           <Form.Check
//             inline
//             name="roleType"
//             type="switch"
//             id={"toggle-button"}
//             label={"Admin"}
//             value={adminLogin ? 1 : 0}
//             checked={adminLogin ? true : false}
//             onChange={(e) => {
//               onSetAdminLogin(e.target.value); // Pass 0 or 1 to onSetAdminLogin
//               // setRoleType(e.target.value === "1" ? 0 : 1);
//             }}
//           />
//           <p className={forgotPassTextClasses}>
//             <Link to="/forgot-password">Forgot password?</Link>
//           </p>
//         </div> */}
//             <div className="form-group login-btn">
//               <div>
//                 <button type="submit" className="btn-fill btn btn-info">
//                   Login
//                 </button>
//               </div>
//               {/* <div>
//             <a href="https://milancrm.q10analytics.com/ReportForm/">
//               <i
//                 class="fa fa-file"
//                 aria-hidden="true"
//                 style={{
//                   fontSize: "30px",
//                 }}
//               ></i>
//             </a>
//           </div> */}
//             </div>
//           </form>{" "}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ClientLogin;

