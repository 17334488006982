import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextInputField from "../../../components/InputField/TextInputField";
import OperationalMasterMethod from "../../../asyncMethods/OperationalMasterMethod";
import Select from "../../../components/DropdownField/Select";
import ObservationCategoryMaster from "../../../asyncMethods/ObservationCategoryMaster";


const schema = Yup.object({
  observationName: Yup.string().required("Required Field !"),
  observationRecommendation: Yup.string().required("Required Field !"),
  observationCategoryId: Yup.string().required("Rewuired Field !")
});

const CreateOperationalMaster = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const operationalMasteraMethod = OperationalMasterMethod();
  const observationCategoryMaster = ObservationCategoryMaster()
  const [initialValues, setInitialValues] = useState({
    observationName: "",
    observationRecommendation: "",
    observationCategoryId: "",
    activeStatus: 1
  });
  const observationCategoryTypeOption = Array.from(useSelector((state) => state?.observationCategoryMaster?.observationCategoryMasterList)).filter((s) => s.activeStatus == 1)
  useEffect(() => {
    if (parameters.observationId) {
      operationalMasteraMethod.fetchOperationalMasterData(parameters.observationId);
    }
    observationCategoryMaster.fetchAllObservationCategoryMasterData()
  }, []);

  const editOperationalMaster = useSelector(state => state?.operational?.editOperationalMasterList);

  // Edit Master Type Data
  useEffect(() => {
    if (parameters.observationId && editOperationalMaster.observationId == parameters.observationId) {
      setInitialValues(editOperationalMaster)
    }
  }, [editOperationalMaster]);

  // Form handleSubmit
  const onSubmit = (data) => {
    if (parameters.observationId) {
      operationalMasteraMethod.updateOperationalMasterData(parameters.observationId, data)
    }
    else {
      operationalMasteraMethod.storeOperationalMasterData(data);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {

        let category = observationCategoryTypeOption?.find((item) => {
          return values?.observationCategoryId == item?.observationCategoryId
        })

        values.observationCategoryType = category.observationCategoryType;

        console.log("saveOperationalData:", values)
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { errors, touched } = formik;
        return (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5>{""}</h5>
              <Button
                onClick={() => navigate("/observation-master")}
                variant="primary"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </Button>
            </div>
            <Card>
              <Card.Body>
                <Form>
                  <div className="m-3">
                  <div className="form-group form-row">
                      <Select
                        label={"Observation Category Type"}
                        name={"observationCategoryId"}
                        required={true}
                        classProps={"col"}
                        errors={errors}
                        touched={touched}
                      >
                        <option value={""}>select</option>
                        {
                          observationCategoryTypeOption?.map((data, index) => (
                            <option key={index} value={data.observationCategoryId} data-dataid={data.observationCategoryId} >
                              {data.observationCategoryType}
                            </option>
                          ))
                        }
                      </Select>
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Name"}
                        name={"observationName"}
                        required={true}
                        classProps={"col"}
                      />
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Recommendation"}
                        name={"observationRecommendation"}
                        required={true}
                        classProps={"col"}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center m-3">
                    <button
                      type="submit"
                      className="btn-fill btn btn-info my-3"
                    >
                      {
                        parameters?.observationId ? "Update" : "Add"
                      } Data
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateOperationalMaster;
