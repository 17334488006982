import { FETCH_ALL_CLIENT, SET_EDIT_CLIENT, SET_GST_REGISTRATION_OPTIONS } from "../constants/ClientActionTypes"

const initialState = {
    clientList : {},
    editClient : {},
    gstRegistrationTypes : {}
}

export const ClientReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_CLIENT: 
            return {
                ...state,
                clientList : payload?.content
            }
        case SET_EDIT_CLIENT :
            return {
                ...state,
                editClient : payload
            }
        case SET_GST_REGISTRATION_OPTIONS : 
            return {
                ...state,
                gstRegistrationTypes : payload?.content
            }
        default:
            return state
    }
}