import { SET_EDIT_VEHICLE_MODEL, FETCH_ALL_VEHICLE_MODEL, FETCH_FILTER_VEHICLE_MODEL } from "../../constants/mst_type/VehicleModelTypes"

const initialState = {
    vehicleModelList: {},
    editVehicleModel: {},
    filterVehicleModelList: []
};

export const VehicleModelReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_VEHICLE_MODEL:
            return {
                ...state,
                vehicleModelList: payload?.content,
            };
        case SET_EDIT_VEHICLE_MODEL:
            return {
                ...state,
                editVehicleModel: payload,
            };
        case FETCH_FILTER_VEHICLE_MODEL:
            return {
                ...state,
                filterVehicleModelList: payload,
            };

        default:
            return state;
    }
}