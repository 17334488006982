import { useDispatch } from "react-redux";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FETCH_ALL_TIRE_CONFIGURATION_MASTER_TYPE, SET_EDIT_TIRE_CONFIGURATION_MASTER_TYPE } from "../redux/constants/TireConfigurationTypes";

const TireConfigurationMasterMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllTireConfigurationMasterData = () => {
        return api.get("/masterservice/getalltireconfiguration?page=0&size=10000&sortBy=tireConfigurationId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_TIRE_CONFIGURATION_MASTER_TYPE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeTireConfigurationMasterData = (data) => {
        return api.post("/masterservice/savetireconfigurationmaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Tire Configuration Master Created Successful.");
                navigate('/mst-tire-configuration');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchTireConfigurationMasterData = (tireConfigurationId = 1) => {
        return api.get(`/masterservice/gettireconfigurationbyid?tireConfigurationId=` + tireConfigurationId)
            .then(res => {
                dispatch({
                    type: SET_EDIT_TIRE_CONFIGURATION_MASTER_TYPE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateTireConfigurationMasterData = (tireConfigurationId, tireConfigurationData) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updatetireconfigurationmaster?tireConfigurationId=` + tireConfigurationId, tireConfigurationData, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Tire Configuration Master Data Updated Successful.");
                    navigate('/mst-tire-configuration');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const tireConfigurationMasterStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisabletireconfigurationmaster?tireConfigurationId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllTireConfigurationMasterData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllTireConfigurationMasterData,
        fetchTireConfigurationMasterData,
        storeTireConfigurationMasterData,
        updateTireConfigurationMasterData,
        tireConfigurationMasterStatusUpdate
    };
};

export default TireConfigurationMasterMethod;
