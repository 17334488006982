import { useField } from "formik";

const Select = ({ label, classProps, required, ...props }) => {
  const [field, meta] = useField(props.name);
  return (
    <div className={classProps}>
      {label &&
        <label htmlFor={props.id || props.name}>
          {label} {required && <span className="text-danger">*</span>}
        </label>
      }

      <select className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Select;