import axios from "axios";

// const api = axios.create({
//   baseURL: "https://www.milantires.com/dev",
// });

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default api;