import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ClientActionButtons = ({ clientDetails, onStatusUpdate }) => {

    const navigate = useNavigate();

    const redirectToEditPage = clientId => {
      navigate("/clients/edit/"+clientDetails.clientId);
    }

    return (
      <>
        <div className='d-flex align-items-center'>
          <Button variant="primary" size="sm" onClick={() => redirectToEditPage(clientDetails.clientId)} >
            <i className="fa-solid fa-user-pen"></i>
          </Button>{" "}
          <Form.Check
            inline
            className={'mx-3 text-danger'}
            type="switch"
            value = { clientDetails.clientActiveStatus === 1 ? 1 : 0}
            checked = { clientDetails.clientActiveStatus === 1 ? true : false}
            id={'toggle-button'+clientDetails.clientId}
            onChange = {(e) => {onStatusUpdate(clientDetails.clientId,e.target.value)}}
          />
        </div>
      </>
    );
}

export default ClientActionButtons;