import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TextInputField from "../../../../components/InputField/TextInputField";
import Select from "../../../../components/DropdownField/Select";
import { useEffect } from "react";
import VehicleManufactureDataMethod from "../../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
import { useSelector } from "react-redux";

const schema = Yup.object({
    vehicleManufacturerName: Yup.string().required("Required Field !"),
    activeStatus: Yup.number().required("Required Field !")
});

const CreateVehicleManufacture = () => {
    const navigate = useNavigate();
    const parameters = useParams();
    const editManufactureData = useSelector((state) => state?.vehicleManufacture?.editVehicleManufacture);
    const vehicleManufactureMethod = VehicleManufactureDataMethod();

    const [initialValues, setInitialValues] = useState({
        vehicleManufacturerName: "",
        activeStatus: ""
    });

    useEffect(() => {
        if (parameters.vehicleManufacturerId) {
            vehicleManufactureMethod.fetchVehicleManufactureData(parameters.vehicleManufacturerId);
        }
    }, []);

    useEffect(() => {
        if (parameters.vehicleManufacturerId && editManufactureData.vehicleManufacturerId == parameters.vehicleManufacturerId) {
            setInitialValues(editManufactureData)
        }
    }, [editManufactureData]);



    const onSubmit = (data) => {
        if (parameters.vehicleManufacturerId) {
            vehicleManufactureMethod.updateVehicleManufactureData(parameters.vehicleManufacturerId, data)
        } else {
            vehicleManufactureMethod.storeVehicleManufactureData(data);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {(formik) => {
                const { errors, touched } = formik;
                return (
                    <>
                        <div className="d-flex justify-content-between m-3">
                            <h5>{""}</h5>
                            <Button
                                onClick={() => navigate("/mst-vehicle-manufacture")}
                                variant="primary"
                            >
                                <i className="fa-solid fa-arrow-left"></i> Back
                            </Button>
                        </div>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <div className="m-2">
                                        <div className="form-group form-row">
                                            <TextInputField
                                                errors={errors}
                                                touched={touched}
                                                required={true}
                                                type={"text"}
                                                label={"Vehicle Manufacture"}
                                                name={"vehicleManufacturerName"}
                                                classProps={"col-md-12"}
                                            />
                                        </div>

                                        <div className="form-group form-row m-1">
                                            <Select
                                                label={"Status"}
                                                name={"activeStatus"}
                                                required={true}
                                                classProps={"col"}
                                                errors={errors}
                                                touched={touched}
                                            >
                                                <option value={""}>select</option>
                                                <option value="1">
                                                    Active
                                                </option>
                                                <option value="0">
                                                    InActive
                                                </option>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-center m-3">
                                        <button
                                            type="submit"
                                            className="btn-fill btn btn-info my-3"
                                        >
                                            {
                                                parameters?.vehicleManufacturerId ? "Update Vehicle Manufacture" : "Add Vehicle Manufacture"
                                            }
                                            
                                        </button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </>
                );
            }}
        </Formik>
    );
};

export default CreateVehicleManufacture;
