import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import UserMethods from "../../../asyncMethods/UserMethods";
import api from "../../../api";
import { toast } from "react-toastify";

const schema = yup
  .object({
    // username: yup
    //   .mixed()
    //   .test(
    //     "username",
    //     "Please enter a valid email or mobile",
    //     function (value, context) {
    //       if (value === "") return false;
    //       if (
    //         yup.string().length(10).matches(/^\d+$/).isValidSync(value) ||
    //         yup.string().email().isValidSync(value)
    //       ) {
    //         return true;
    //       }
    //       return false;
    //     }
    //   )
    //   .required(),
    emailId: yup.string().email().required(),
  })
  .required();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const userMethods = UserMethods();

  const [initialValues, setInitialValues] = useState({
    emailId: ""
  });

  const onSubmit = async (data) => {
    console.log(data);

    // const payload = { };
  
    //   if (/^\d{10}$/.test(data.username)) {
    //     payload.mobileNumber = data.username;
    //   } else {
    //     payload.emailId = data.username;
    //   }
  
      return api
        .post("/loginservice/sendotp", data)
        .then((res) => {
          const result = res.data.result;
          console.log("res", result);
          toast.success(res.data.statusDescription)
          localStorage.setItem("userEmailId", data.emailId);
          navigate('/forgot-password-submit')
        })
        .catch(() => {
          toast.error("Request Failed.");
        });

  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { values, errors, touched, isValid, dirty } = formik;
        return (
          <>
            <div className="d-flex justify-content-between ml-3">
              <h4>{"Forgot Password"}</h4>
            </div>
            <Form>
              <div className="m-3">
                <div className="form-group">
                  <TextInputField
                    errors={errors}
                    touched={touched}
                    type={"text"}
                    label={"Email"}
                    name={"emailId"}
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <p className="small text-right">
                    <Link to="/">Login</Link>
                  </p>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn-fill btn btn-info">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ForgotPassword;
