import { useEffect } from "react";
import { useCallback } from "react";
import { Button, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserMethods from "../../../asyncMethods/UserMethods";


const AdminNavbar = () => {
  const navigate = useNavigate();
  const userMethods = UserMethods();

  const user = JSON.parse(localStorage.getItem("user"));
  
  const roleType = user?.roleType;
  const userId = user?.userID;
  console.log("role", roleType);
  const client = user?.client;
  console.log("client", client);
  
  const mobileSidebarToggle = useCallback((e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("pageRefreshedA")) {
      // Set the flag to indicate that the page has been refreshed
      localStorage.setItem("pageRefreshedA", "true");
      // Refresh the page
      window.location.reload();
    }
  },[])

  let userList = useSelector((state) => state.authUser.userList) || "";

  let editUser;
   if (Array.isArray(userList) && userList.length > 0) {
     editUser = userList.filter((inspection) => inspection.userId == userId);
   }
  
  if (Array.isArray(userList) && userList.length > 0) {
    editUser = userList.filter((inspection) => inspection.userId == userId);
    if (editUser !== undefined && editUser !== null) {
      editUser = editUser[0]?.name;
    }
  }

  console.log("name", editUser);
  let editClient = useSelector((state) => state.client.editClient) || "";
  editClient = editClient?.clientFirstName;
  console.log("client", editClient);

  if (client) {
    editUser = editClient;
  }
  
//   return (
//     <Navbar bg="light" expand="lg">
//       <Container fluid>
//         <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
//           <Button
//             variant="dark"
//             className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
//             onClick={mobileSidebarToggle}
//           >
//             <i className="fas fa-ellipsis-v"></i>
//           </Button>
//         </div>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
//           <span className="navbar-toggler-bar burger-lines"></span>
//           <span className="navbar-toggler-bar burger-lines"></span>
//           <span className="navbar-toggler-bar burger-lines"></span>
//         </Navbar.Toggle>
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="ml-auto" navbar>
//             <Dropdown as={Nav.Item} alignRight>
//               <Dropdown.Toggle
//                 aria-expanded={false}
//                 aria-haspopup={true}
//                 as={Nav.Link}
//                 data-toggle="dropdown"
//                 id="navbarDropdownMenuLink"
//                 variant="default"
//                 className="m-0"
//               >
//                 <div className="d-flex justify-content-center align-items-center">
//                   <div>
//                   <i
//                     className="nc-icon nc-circle-09"
//                     style={{ fontSize: "30px" }}
//                     ></i>
//                     </div>
//                   <p className="mt-2 ml-2">{editUser}</p>
//                 </div>
//               </Dropdown.Toggle>
//               <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
//                 <Dropdown.Item>Profile</Dropdown.Item>
//                 <Dropdown.Item
//                   onClick={() => navigate("/dashboard/change-password")}
//                 >
//                   Change Password
//                 </Dropdown.Item>
//                 <div className="divider"></div>
//                 <Dropdown.Item onClick={() => userMethods.userLogout()}>
//                   Logout
//                 </Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

return (
  <Navbar bg="light" expand="lg">
    <Container fluid>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
        <span className="navbar-toggler-bar burger-lines"></span>
        <span className="navbar-toggler-bar burger-lines"></span>
        <span className="navbar-toggler-bar burger-lines"></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto" navbar>
          <Dropdown as={Nav.Item} alignRight>
            <Dropdown.Toggle
              aria-expanded={false}
              aria-haspopup={true}
              as={Nav.Link}
              data-toggle="dropdown"
              id="navbarDropdownMenuLink"
              variant="default"
              className="m-0"
            >
              <div className="d-flex justify-content-center align-items-center">
                <div>
                <i
                  className="nc-icon nc-circle-09"
                  style={{ fontSize: "30px" }}
                  ></i>
                  </div>
                <p className="mt-2 ml-2">{editUser}</p>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
              <Dropdown.Item>Profile</Dropdown.Item>
              {/* <Dropdown.Item
                onClick={() => navigate("/dashboard/change-password")}
              >
                Change Password
              </Dropdown.Item> */}
              <div className="divider"></div>
              <Dropdown.Item onClick={() => userMethods.userLogout()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
      <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
        <Button
          variant="dark"
          className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
          onClick={mobileSidebarToggle}
        >
          <i className="fas fa-ellipsis-v"></i>
        </Button>
      </div>
    </Container>
    
  </Navbar>
);
}

export default AdminNavbar;
