import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Moment from 'react-moment';

import InspectionActionButton from "../../../components/ActionButtons/InspectionActionButton";
import FilterComponent from "../../../components/FilterComponent/Index";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";

let columns = [
  {
    name: "Inspection ID",
    selector: (row) => row.inspectionId,
    sortable: true,
  },
  {
    name: "Company Name",
    selector: (row) => row?.clientInformation.split(',')[0].trim(),
    center: true,
  },
  {
    name: "Client Name",
    selector: (row) => row.clientName,
  },
  
  {
    name: "Date and Time",
    selector: (row) => (
      <Moment format="DD/MM/YYYY" utc>{row?.inspectionDateTime}</Moment>
    ),
    center: true,
  },
  {
    name: "Vehicle Reg No",
    selector: (row) => row?.vehicleRegNumber,
    center: true,
  },
  {
    name: "Vehicle Configuration Key",
    selector: (row) => row?.configuredVehicle,
    center: true,
  },
  {
    name: "Odometer Reading",
    selector: (row) => row?.vehicleOdometerReading,
    center: true,
  },
  {
    name: "Driver Name",
    selector: (row) => row?.driverName,
    center: true,
  },
  {
    name: "Driver Mobile No.",
    selector: (row) => row?.driverMobileNo,
    center: true,
  },
  {
    name: "Maintanace Manager Name",
    selector: (row) => row?.maintanaceManagerName,
    center: true,
  },
  {
    name: "Maintanace Manager Mobile No.",
    selector: (row) => row?.maintanaceManagerMobileNo,
    center: true,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true,
  },
];

const Inspection = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const inspectionMethods = InspectionMethods();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Fetch All ObservationMaster
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;
  const role = user?.roleType;
  const client = user?.client;
  const clientId = user?.userID;

  useEffect(() => {
    if (client) {
      inspectionMethods.fetchClientIspection(clientId);
    } else if (role === "1" || role === 1) {
      inspectionMethods.fetchAllInspections();
    } else if (role === "2" || role === 2) {
      inspectionMethods.fetchUserIspection(userId);
    }
  }, []);

  if (client == true) {
    columns = columns.filter((column) => column.name !== "Action");
  }

    const statusUpdate = (inspectionId, status) => {
      const data = {
        id: inspectionId,
        status: status == 1 ? 0 : 1,
      };
      inspectionMethods.inspectionStatusUpdate(data);
    };

  let data = [];

  const inspectionList = useSelector(
    (state) => state?.inspection?.inspectionList
  );

    const inspectionList2 = useSelector(
      (state) => state
    );
    console.log("all", inspectionList2);

  if (inspectionList) {
    data = Array.from(inspectionList)
      .map((inspection) => ({
        ...inspection,
        actionButtons: (
          <InspectionActionButton
            onStatusUpdate={statusUpdate}
            inspectionDetails={inspection}
          />
        ),
      }))
      .sort((a, b) => new Date(b.inspectionDateTime) - new Date(a.inspectionDateTime));
  }


  // let data = Array.from(
  //   useSelector((state) => state?.inspection?.inspectionList)
  // )
  //   .map((inspection) => ({
  //     ...inspection,
  //     actionButtons: (
  //       <InspectionActionButton
  //         onStatusUpdate={statusUpdate}
  //         inspectionDetails={inspection}
  //       />
  //     ),
  //   }))
  //   .sort((b, a) => a.inspectionId - b.inspectionId);

  const filteredItems = data.filter(
    (item) =>
      (item.inspectionId || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.clientName || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.vehicleRegNumber || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.clientInformation.split(',')[0].trim() || "").toString().toLowerCase().includes(filterText.toString().toLowerCase())

  );

  console.log("daaa", data);
      console.log("fil", filteredItems);


  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const conditionalRowStyles = [
    {
      when: (row) => row.inspectionStatus == 2,
      style: {
        backgroundColor: '#FFF88F',
      },
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        {client ?(<></>) :(
          <Button
            onClick={() => navigate("/client-inspection/create")}
            variant="primary"
          >
            <i className="fa-solid fa-plus"></i> New Inspection
          </Button>
        ) }
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Inspection"
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            conditionalRowStyles={conditionalRowStyles}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Inspection;
