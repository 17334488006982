import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OperationalMasterButton = ({ operationalMasterDetails, onStatusUpdate }) => {
    const navigate = useNavigate();
    const redirectToEditPage = observationId => {
        navigate("/observation-master/edit/" + operationalMasterDetails?.observationId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(operationalMasterDetails?.observationId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>
                <Form.Check
                    inline
                    className={'mx-3 text-danger'}
                    type="switch"
                    value={operationalMasterDetails?.activeStatus === 1 ? 1 : 0}
                    checked={operationalMasterDetails?.activeStatus === 1 ? true : false}
                    id={'toggle-button' + operationalMasterDetails?.observationId}
                    onChange={(e) => { onStatusUpdate(operationalMasterDetails?.observationId, e.target.value) }}
                />
            </div>
        </>
    );
}

export default OperationalMasterButton;