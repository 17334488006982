import AdminLayout from "../layouts/AdminLayout";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import ChangePassword from "../pages/Private/ChangePassword";
import Clients from "../pages/Private/Clients";
import CreateClient from "../pages/Private/Clients/CreateClient";
import Dashboard from "../pages/Private/Dashboard";
import MasterData from "../pages/Private/Master_data";
import CreateMasterData from "../pages/Private/Master_data/CreateMasterData";
import MasterType from "../pages/Private/Master_type";
import CreateMasterType from "../pages/Private/Master_type/CreateMasterType";
import CreateObserVationMaster from "../pages/Private/Observation_Master/CreateObservationMaster";
import Users from "../pages/Private/Users";
import CreateUser from "../pages/Private/Users/CreateUser";
import Vehicles from "../pages/Private/vehicle";
import CreateVehicles from "../pages/Private/vehicle/CreateVehicles";
import CreateTireMake from "../pages/Private/vehicle/Mst_Vehicle/Tire_Make/CreateTireMake";
import ForgotPassword from "../pages/Public/forgot_password";
import Login from "../pages/Public/Login";
import TireMakeData from "../pages/Private/vehicle/Mst_Vehicle/Tire_Make/index";
import TireConfiguration from "../pages/Private/Tire_Configuration_Master/index";
import CreateTireConfiguration from "../pages/Private/Tire_Configuration_Master/CreateTireConfigurationMaster";
import VehicleSubTypeData from "../pages/Private/vehicle/Mst_Subtype_Vehicle";
import CreateVehicleSubType from "../pages/Private/vehicle/Mst_Subtype_Vehicle/CreateVehicleSubType";
import VehicleTypeData from "../pages/Private/vehicle/Mst_Type_Vehicle";
import CreateVehicleType from "../pages/Private/vehicle/Mst_Type_Vehicle/CreateVehicleType";
import CreateVehicleManufacture from "../pages/Private/vehicle/Mst_Vehicle_Manufacture/CreateVehicleManufacture";
import VehicleMManufactureData from "../pages/Private/vehicle/Mst_Vehicle_Manufacture";
import CreateVehicleUsage from "../pages/Private/vehicle/Mst_Usage_Vehicle/CreateVehicleUsage";
import VehicleUsageData from "../pages/Private/vehicle/Mst_Usage_Vehicle";
import OperationMasterData from "../pages/Private/Observation_Master/Index";
import VehicleModelData from "../pages/Private/vehicle/Mst_Model_Vehicle";
import CreateVehicleModel from "../pages/Private/vehicle/Mst_Model_Vehicle/CreateVehicleModel";
import OperationCategoryMasterData from "../pages/Private/Observation_Category_Master/Index";
import CreateObservationCategoryMaster from "../pages/Private/Observation_Category_Master/CreateObservationCategoryMaster";
import Report from "../pages/Private/Report/Index";
import Inspection from "../pages/Private/Inspection/Index";
import CreateInspection from "../pages/Private/Inspection/CreateInspection";
import VisiterInspection from "../pages/Private/Inspection/VisiterInspection";
// import Analytical from "../pages/Private/Report/Analytical";
import DetailsReport from "../pages/Private/Report/Details";
import DetailAnalytical from "../pages/Private/Report/Details/DetailAnalytical";
import ClientLogin from "../pages/Public/ClientLogin";
import { Navigate } from "react-router-dom";
import CommonLogin from "../pages/Public/ClientLogin";
import ClientVehicleMaster from "../pages/Private/Inspection/ClientVehicleMaster";
import CVCreateInspection from "../pages/Private/Inspection/ClientVehicleMaster/CVcreateInspection";
import Analytical2 from "../pages/Private/Report/Analytical2";
import Table1 from "../pages/Private/Dashboard/Tables1TotalVehicle";
import Table2 from "../pages/Private/Dashboard/Table2TotlaVehicleInspectedInDate";
import Table31 from "../pages/Private/Dashboard/Table3PressureTable";
import Table41 from "../pages/Private/Dashboard/Table4RtdTable";
import Table51 from "../pages/Private/Dashboard/Table5PictorialTable";
import Table6 from "../pages/Private/Dashboard/Table6RtdTable2";
import Table7 from "../pages/Private/Dashboard/Table7TotlaVehicleInspected";
import ForgotPasswordSubmit from "../pages/Public/forgot_password/ForgetPasswordSubmit";

const user = JSON.parse(localStorage.getItem("user"));
const roleType = user?.roleType;
console.log("role", roleType);

const ProtectedRoute = ({ roleType, children }) => {
  const allowedRole = 1 || 2;

  if (roleType === allowedRole) {
    return children;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

const routes = [
  {
    path: "/",
    element: <AuthenticationLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/forgot-password-submit",
        element: <ForgotPasswordSubmit />,
      },
      {
        path: "/clientlogin",
        element: <ClientLogin />,
      },
    ],
  },

  // {
  //   path: "/",
  //   element: <AuthenticationLayout />,
  //   children: [
  //     {
  //       index: true, // This makes /login the default route
  //       element: <Login />,
  //     },
  //     {
  //       path: "/login",
  //       element: <Login />,
  //     },
  //     {
  //       path: "/clientlogin",
  //       element: <ClientLogin />,
  //     },
  //     {
  //       path: "/dashboard", // This route is common for both user and client login
  //       element: <Dashboard />,
  //     },
  //   ],
  // },
  {
    path: "/dashboard",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/totalvehicle",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table1 />,
      },
    ],
  },
  {
    path: "/totalvehicleinspectedindate",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table2 />,
      },
    ],
  },
  {
    path: "/pressuretable",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table31 />,
      },
    ],
  },
  {
    path: "/rtdtable",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table41 />,
      },
    ],
  },
  {
    path: "/tireinspection",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table6 />,
      },
    ],
  },
  {
    path: "/totalvehicleinspected",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table7 />,
      },
    ],
  },
  {
    path: "/pictorialtable",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Table51 />,
      },
    ],
  },
  {
    path: "/users",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Users />,
      },
      {
        path: "create",
        element: <CreateUser />,
      },
      {
        path: "edit/:userId",
        element: <CreateUser />,
      },
    ],
  },
  {
    path: "/clients",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Clients />,
      },
      {
        path: "create",
        element: <CreateClient />,
      },
      {
        path: "edit/:clientId",
        element: <CreateClient />,
      },
    ],
  },
  {
    path: "/vehicle",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Vehicles />,
      },
      {
        path: "create",
        element: <CreateVehicles />,
      },
      {
        path: "edit/:vehicleId",
        element: <CreateVehicles />,
      },
    ],
  },
  {
    path: "/master-type",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <MasterType />,
      },
      {
        path: "create",
        element: <CreateMasterType />,
      },
      {
        path: "edit/:dropDownId",
        element: <CreateMasterType />,
      },
    ],
  },
  {
    path: "/master-data",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <MasterData />,
      },
      {
        path: "create",
        element: <CreateMasterData />,
      },
      {
        path: "edit/:dataId",
        element: <CreateMasterData />,
      },
    ],
  },
  {
    path: "/observation-master",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <OperationMasterData />,
      },
      {
        path: "create",
        element: <CreateObserVationMaster />,
      },
      {
        path: "edit/:observationId",
        element: <CreateObserVationMaster />,
      },
    ],
  },
  {
    path: "/mst-observation-category",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <OperationCategoryMasterData />,
      },
      {
        path: "create",
        element: <CreateObservationCategoryMaster />,
      },
      {
        path: "edit/:observationCategoryId",
        element: <CreateObservationCategoryMaster />,
      },
    ],
  },
  {
    path: "/mst-tire-make",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <TireMakeData />,
      },
      {
        path: "create",
        element: <CreateTireMake />,
      },
      {
        path: "edit/:tireMakeId",
        element: <CreateTireMake />,
      },
    ],
  },
  {
    path: "/mst-tire-configuration",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <TireConfiguration />,
      },
      {
        path: "create",
        element: <CreateTireConfiguration />,
      },
      {
        path: "edit/:tireConfigurationId",
        element: <CreateTireConfiguration />,
      },
    ],
  },
  {
    path: "/mst-vehicle-sub-type",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <VehicleSubTypeData />,
      },
      {
        path: "create",
        element: <CreateVehicleSubType />,
      },
      {
        path: "edit/:subTypeId",
        element: <CreateVehicleSubType />,
      },
    ],
  },
  {
    path: "/mst-vehicle-type",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <VehicleTypeData />,
      },
      {
        path: "create",
        element: <CreateVehicleType />,
      },
      {
        path: "edit/:typeId",
        element: <CreateVehicleType />,
      },
    ],
  },
  {
    path: "/mst-vehicle-usage",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <VehicleUsageData />,
      },
      {
        path: "create",
        element: <CreateVehicleUsage />,
      },
      {
        path: "edit/:vehicleUsageId",
        element: <CreateVehicleUsage />,
      },
    ],
  },
  {
    path: "/mst-vehicle-manufacture",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <VehicleMManufactureData />,
      },
      {
        path: "create",
        element: <CreateVehicleManufacture />,
      },
      {
        path: "edit/:vehicleManufacturerId",
        element: <CreateVehicleManufacture />,
      },
    ],
  },
  {
    path: "/mst-vehicle-model",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <VehicleModelData />,
      },
      {
        path: "create",
        element: <CreateVehicleModel />,
      },
      {
        path: "edit/:vehicleModelId",
        element: <CreateVehicleModel />,
      },
    ],
  },
  {
    path: "/report",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Report />,
      },
      {
        path: "analytical/:inspectionId",
        element: <Analytical2 />,
      },
      // {
      //   path: "analytical2/:inspectionId",
      //   element: <Analytical />,
      // },
      {
        path: "detail/:inspectionId",
        element: <DetailsReport />,
      },
      {
        path: "detail/:inspectionId/analytical/:inspectionDetailIndex",
        element: <DetailAnalytical />,
      },
    ],
  },

  {
    path: "/client-inspection",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Inspection />,
      },
      {
        path: "create",
        element: <CreateInspection />,
      },
      {
        path: "edit/:inspectionId",
        element: <CreateInspection />,
      },
    ],
  },
  {
    path: "/visiter-inspection",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <VisiterInspection />,
      },
    ],
  },
  {
    path: "/client-vehicle-master",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <ClientVehicleMaster />,
      },
      {
        path: "create",
        element: <CVCreateInspection />,
      },
      {
        path: "edit/:inspectionId",
        element: <CVCreateInspection />,
      },
      // {
      //   path: "edit/:inspectionId",
      //   element: <CVCreateInspection />,
      // },
    ],
  },
];

export default routes;
