import { FETCH_ALL_VEHICLE_SUB_TYPE, SET_EDIT_VEHICLE_SUB_TYPE } from "../../constants/mst_type/VehicleSubType";

const initialState = {
    vehicleSubTypeList: [],
    editVehicleSubType: {},
};

export const VehicleSubTypeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_VEHICLE_SUB_TYPE:
            return {
                ...state,
                vehicleSubTypeList: payload?.content,
            };
        case SET_EDIT_VEHICLE_SUB_TYPE:
            return {
                ...state,
                editVehicleSubType: payload,
            };

        default:
            return state;
    }
}