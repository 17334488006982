import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const AuthenticationLayout = () => {
  const navigate = useNavigate();
  const authUser = JSON.parse(localStorage.getItem("user"));
  const cliLog = JSON.parse(localStorage.getItem("cliLog"));

  console.log("auuuuutthh", authUser);

  useEffect(() => {
    if (authUser && authUser != null && authUser != undefined)
      navigate("/dashboard");
    else {
      if (cliLog) {
        navigate("/clientLogin");
      }
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row align-items-center ">
        <div
          className="col-12 p-0 col-lg-8 mob-login"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "center"
            // backgroundImage: "url(/assets/images/banner.jpeg)",
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100vh", backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <img src="/assets/images/logo.png" alt="MTSS" width="200" />
          </div>
        </div>
        <div className="mob-log2">
          <img src="/assets/images/logo.png" alt="MTSS" width="200" />
        </div>
        <div className="col-12 col-lg-4">
          <div className="px-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
