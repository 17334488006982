import React, { useMemo, useState, useEffect } from "react";

import { Navbar, Nav, Accordion, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import ClientIns from "../../../assets/images/Client-Inspection.png";
import VisitIns from "../../../assets/images/Visitor-Inspection.png";


const MenuItem = ({ path, icon,imgSrc, title }) => {
  const location = useLocation();

  const activeRoute = useMemo(() => {
    return location.pathname.indexOf(path) > -1 ? "active" : "";
  }, [location, path]);

  return (
    // <li className={activeRoute}>
    //   <NavLink className="nav-link" to={path}>
    //     <i className={icon} />
    //     <p>{title}</p>
    //   </NavLink>
    // </li>

    <li className={activeRoute}>
      <NavLink className="nav-link" to={path}>
        {imgSrc && (
          <div>
            <img
              style={{ height: "1.4rem", width: "1.25rem" }}
              src={imgSrc}
              alt={title}
              className="menu-image"
            />
            <p className="ml-3">{title}</p>
          </div>
        )}
        {icon && (
          <div>
            {" "}
            <i className={icon} />
            <p>{title}</p>
          </div>
        )}
        {/* <p>{title}</p> */}
      </NavLink>
    </li>
  );
};

const Sidebar = () => {
  const [vehicleCollapsed, setVehicleCollapsed] = useState(true);
  const [tireCollapsed, setTireCollapsed] = useState(true);
  const [observationCollapsed, setObservationCollapsed] = useState(true);
  const [inspCollapsed, setInspCollapsed] = useState(true);


  const user = JSON.parse(localStorage.getItem("user"));
  const roleType = user?.roleType;
  console.log("role", roleType);
  const isDisabled = roleType === 2; // Check if roleType is 1 (disabled)
  const client = user?.client;
  console.log("client", client);

  return (
    <div className="sidebar">
      <div className="sidebar-background" />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-center">
          <Link to="/" className="simple-text logo-mini mx-1">
            <div className="logo-img">
              <img src="/assets/images/logo.png" alt="MTSS" />
            </div>
          </Link>
        </div>

        <Nav>
          <MenuItem
            path="/dashboard"
            icon="fa-solid fa-gauge-high"
            title="Dashboard"
          />

          {client == null ? (
            <>
              {roleType == 1 ? (
                <MenuItem path="/users" icon="fa-solid fa-user" title="Users" />
              ) : (
                <div className="disabled-menu-item">
                  <MenuItem icon="fa-solid fa-user" title="Users" />
                </div>
              )}

              {roleType == 1 ? (
                <MenuItem
                  path="/clients"
                  // icon="fa-solid fa-list"
                  icon="fad fa-user-friends mt-1"
                  title="Clients"
                />
              ) : (
                <div className="disabled-menu-item">
                  <MenuItem icon="fad fa-user-friends mt-1" title="Clients" />
                </div>
              )}

              {roleType == 1 ? (
                <li>
                  <Nav.Item className="open">
                    <Accordion>
                      <Accordion.Toggle
                        as={Nav.Link}
                        variant="link"
                        eventKey="0"
                        onClick={() => setVehicleCollapsed(!vehicleCollapsed)}
                      >
                        <i className="fa-sharp fa-solid fa-truck"></i> Vehicle
                        Master{" "}
                        <i
                          className={
                            "fa-solid  float-right  " +
                            (vehicleCollapsed ? "fa-caret-down" : "fa-caret-up")
                          }
                        ></i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <nav aria-disabled="true" className="nav flex-column">
                          <MenuItem
                            path="/vehicle"
                            icon="fa-sharp fa-solid fa-truck"
                            title="Vehicle Configuration Master"
                          />
                          <MenuItem
                            path="/mst-vehicle-type"
                            icon="fa-sharp fa fa-truck"
                            title="Vehicle Type Master"
                          />
                          <MenuItem
                            path="/mst-vehicle-sub-type"
                            icon="fa-sharp fa fa-truck"
                            title="Vehicle Sub Type Master"
                          />
                          <MenuItem
                            path="/mst-vehicle-usage"
                            icon="fa-sharp fa fa-truck"
                            title=" Vehicle Usage Master"
                          />
                          <MenuItem
                            path="/mst-vehicle-manufacture"
                            icon="fa-sharp fa fa-truck"
                            title="Vehicle Manufacture Master"
                          />
                          <MenuItem
                            path="/mst-vehicle-model"
                            icon="fa-sharp fa-solid fa-truck"
                            title="Vehicle Model Master"
                          />
                        </nav>
                      </Accordion.Collapse>
                    </Accordion>
                  </Nav.Item>
                </li>
              ) : (
                <li>
                  <Nav.Item className="open">
                    <Accordion>
                      <Accordion.Toggle
                        as={Nav.Link}
                        variant="link"
                        eventKey="0"
                        onClick={() => setVehicleCollapsed(!vehicleCollapsed)}
                      >
                        <i className="fa-sharp fa-solid fa-truck"></i> Vehicle
                        Master{" "}
                        <i
                          className={
                            "fa-solid  float-right  " +
                            (vehicleCollapsed ? "fa-caret-down" : "fa-caret-up")
                          }
                        ></i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <nav aria-disabled="true" className="nav flex-column">
                          <MenuItem
                            path="/vehicle"
                            icon="fa-sharp fa-solid fa-truck"
                            title="Vehicle Configuration Master"
                          />
                        </nav>
                      </Accordion.Collapse>
                    </Accordion>
                  </Nav.Item>
                </li>
              )}

              {roleType == 1 ? (
                <li>
                  <Nav.Item className="open">
                    <Accordion>
                      <Accordion.Toggle
                        as={Nav.Link}
                        variant="link"
                        eventKey="0"
                        onClick={() => setTireCollapsed(!tireCollapsed)}
                      >
                        {/* <i className="fa-sharp fa-solid fa-truck"></i> Tire
                        Master{" "}
                        <i
                          className={
                            "fa-solid  float-right  " +
                            (tireCollapsed ? "fa-caret-down" : "fa-caret-up")
                          }
                        ></i> */}
                        <i class="far fa-tire mt-1"></i>Tire Master
                        <i
                          className={
                            "fa-solid  float-right  " +
                            (tireCollapsed ? "fa-caret-down" : "fa-caret-up")
                          }
                        ></i>{" "}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <nav className="nav flex-column">
                          <MenuItem
                            path="/mst-tire-configuration"
                            icon="far fa-tire mt-1"
                            title="Tire Configuration Master"
                          />
                          <MenuItem
                            path="/mst-tire-make"
                            icon="far fa-tire mt-1"
                            title=" Tire Make Master"
                          />
                        </nav>
                      </Accordion.Collapse>
                    </Accordion>
                  </Nav.Item>
                </li>
              ) : (
                <>
                  <li className="disabled-menu-item">
                    <Accordion.Toggle as={Nav.Link} variant="link" eventKey="0">
                      <i className="far fa-tire mt-1"></i> Tire Master{" "}
                      <i
                        className={
                          "fa-solid  float-right  " +
                          (vehicleCollapsed ? "fa-caret-down" : "fa-caret-up")
                        }
                      ></i>
                    </Accordion.Toggle>
                  </li>
                </>
              )}
              {roleType == 1 ? (
                <li>
                  <Accordion>
                    <Accordion.Toggle
                      as={Nav.Link}
                      variant="link"
                      eventKey="0"
                      onClick={() =>
                        setObservationCollapsed(!observationCollapsed)
                      }
                    >
                      <i className="fa-sharp fa fa-gear"></i> Observation Master{" "}
                      <i
                        className={
                          "fa-solid  float-right  " +
                          (observationCollapsed
                            ? "fa-caret-down"
                            : "fa-caret-up")
                        }
                      ></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <nav className="nav flex-column">
                        <MenuItem
                          path="/observation-master"
                          icon="fa-sharp fa fa-gear"
                          title="Observation Master"
                        />
                        <MenuItem
                          path="/mst-observation-category"
                          icon="fa-sharp fa fa-gear"
                          title="Observation Category Master"
                        />
                      </nav>
                    </Accordion.Collapse>
                  </Accordion>
                </li>
              ) : (
                <>
                  <li className="disabled-menu-item">
                    <Accordion.Toggle as={Nav.Link} variant="link" eventKey="0">
                      <i className="fa-sharp fa fa-gear"></i> Observation Master{" "}
                      <i
                        className={
                          "fa-solid  float-right  " +
                          (observationCollapsed
                            ? "fa-caret-down"
                            : "fa-caret-up")
                        }
                      ></i>
                    </Accordion.Toggle>
                  </li>
                </>
              )}
              <li>
                <Accordion>
                  <Accordion.Toggle
                    as={Nav.Link}
                    variant="link"
                    eventKey="0"
                    onClick={() => setInspCollapsed(!inspCollapsed)}
                  >
                    <i className="fa-solid fa-file-pen"></i> Inspection{" "}
                    <i
                      className={
                        "fa-solid  float-right  " +
                        (inspCollapsed ? "fa-caret-down" : "fa-caret-up")
                      }
                    ></i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <nav className="nav flex-column">
                      <MenuItem
                        path="/visiter-inspection"
                        // icon="fa-solid fa-file-pen"
                        imgSrc={VisitIns}
                        title="Visitor Inspection"
                      />
                      <MenuItem
                        path="/client-vehicle-master"
                        // icon="fa-solid fa-file-pen"
                        imgSrc={ClientIns}
                        title="Client Vehicle Master"
                      />
                      <MenuItem
                        path="/client-inspection"
                        // icon="fa-solid fa-file-pen"
                        imgSrc={ClientIns}
                        title="Client Inspection"
                      />
                    </nav>
                  </Accordion.Collapse>
                </Accordion>
              </li>
              <MenuItem
                path="/report"
                icon="fa-solid fa-file-lines"
                title="Reports"
              />
            </>
          ) : (
            <>
              <li>
                <Accordion>
                  <Accordion.Toggle
                    as={Nav.Link}
                    variant="link"
                    eventKey="0"
                    onClick={() =>
                      setObservationCollapsed(!observationCollapsed)
                    }
                  >
                    <i className="fa-solid fa-file-pen"></i> Inspection{" "}
                    <i
                      className={
                        "fa-solid  float-right  " +
                        (observationCollapsed ? "fa-caret-down" : "fa-caret-up")
                      }
                    ></i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <nav className="nav flex-column">
                      {/* <MenuItem
                        path="/visiter-inspection"
                        icon="fa-solid fa-file-pen"
                        title="Visitor Inspection"
                      /> */}
                      <MenuItem
                        path="/client-vehicle-master"
                        // icon="fa-solid fa-file-pen"
                        imgSrc={ClientIns}
                        title="Client Vehicle Master"
                      />

                      <MenuItem
                        path="/client-inspection"
                        // icon="fa-solid fa-file-pen"
                        imgSrc={ClientIns}
                        title="Client Inspection"
                      />
                    </nav>
                  </Accordion.Collapse>
                </Accordion>
              </li>
              <MenuItem
                path="/report"
                icon="fa-solid fa-file-lines"
                title="Reports"
              />
            </>
          )}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
