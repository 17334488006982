import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TextInputField from "../../../../../components/InputField/TextInputField";
import Select from "../../../../../components/DropdownField/Select";
import { useSelector } from "react-redux";
import VehicleTireMakeDataMethod from "../../../../../asyncMethods/mst_action/VehicleTireMakeDataMethod";
import { useEffect } from "react";

const schema = Yup.object({
    tireMakeName: Yup.string().required("Required Field !"),
    activeStatus: Yup.number().required("Required Field !")
});

const CreateTireMake = () => {
    const navigate = useNavigate();
    const parameters = useParams();
    const editTireMakeData = useSelector((state) => state?.vehicleTireMake?.editVehicleTireMake);
    const vehicleTireMakeMethod = VehicleTireMakeDataMethod();

    const [initialValues, setInitialValues] = useState({
        tireMakeName: "",
        activeStatus: ""
    });

    useEffect(() => {
        if (parameters.tireMakeId) {
            vehicleTireMakeMethod.fetchVehicleTireMakeData(parameters.tireMakeId);
        }
    }, []);

    useEffect(() => {
        if (parameters.tireMakeId && editTireMakeData.tireMakeId == parameters.tireMakeId) {
            setInitialValues(editTireMakeData)
        }
    }, [editTireMakeData]);

    const onSubmit = (data) => {
         if (parameters.tireMakeId) {
            vehicleTireMakeMethod.updateVehicleTireMakeData(parameters.tireMakeId, data)
        } else {
            vehicleTireMakeMethod.storeVehicleTireMakeData(data);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {(formik) => {
                const { errors, touched } = formik;
                return (
                    <>
                        <div className="d-flex justify-content-between m-3">
                            <h5>{""}</h5>
                            <Button
                                onClick={() => navigate("/mst-tire-make")}
                                variant="primary"
                            >
                                <i className="fa-solid fa-arrow-left"></i> Back
                            </Button>
                        </div>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <div className="m-2">
                                        <div className="form-group form-row">
                                            <TextInputField
                                                errors={errors}
                                                touched={touched}
                                                required={true}
                                                type={"text"}
                                                label={"Tire Make"}
                                                name={"tireMakeName"}
                                                classProps={"col-md-12"}
                                            />
                                        </div>

                                        <div className="form-group form-row m-2">
                                            <Select
                                                label={"Status"}
                                                name={"activeStatus"}
                                                required={true}
                                                classProps={"col"}
                                                errors={errors}
                                                touched={touched}
                                            >
                                                <option value={""}>select</option>
                                                <option value="1">
                                                    Active
                                                </option>
                                                <option value="0">
                                                    InActive
                                                </option>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-center m-3">
                                        <button
                                            type="submit"
                                            className="btn-fill btn btn-info my-3"
                                        >
                                            {
                                                parameters?.tireMakeId ? "Update " : "Add "
                                            } Tire Make
                                        </button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </>
                );
            }}
        </Formik>
    );
};

export default CreateTireMake;
