import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const MasterDataActionButtons = ({ masterDataDetails, onStatusUpdate }) => {

  const navigate = useNavigate();

  const redirectToEditPage = dataId => {
    navigate("/master-data/edit/" + masterDataDetails.dataId);
  }

  return (
    <>
      <div className='d-flex align-items-center'>
        <Button variant="primary" size="sm" onClick={() => redirectToEditPage(masterDataDetails.dataId)} >
          <i className="fa-solid fa-user-pen"></i>
        </Button>{" "}
        <Form.Check
          inline
          className={'mx-3 text-danger'}
          type="switch"
          value={masterDataDetails?.activeStatus === 1 ? 1 : 0}
          checked={masterDataDetails?.activeStatus === 1 ? true : false}
          id={'toggle-button' + masterDataDetails?.dataId}
          onChange={(e) => { onStatusUpdate(masterDataDetails?.dataId, e.target.value) }}
        />
      </div>
    </>
  );
}

export default MasterDataActionButtons;