import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import InspectionMethods from "../../../../../asyncMethods/InspectionMethods";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from 'react-moment';
import moment from "moment";
import { useReactToPrint } from "react-to-print";


const DetailAnalytical = () => {

  const [odo, setOdo] = useState("");
  const parameters = useParams();
  const inspectionMethods = InspectionMethods();

  const componentRef = useRef(null);

  useEffect(() => {
    if (parameters.inspectionId && parameters.inspectionDetailIndex) {
      inspectionMethods.getVehicleInspectionDetails(parameters.inspectionId);
      inspectionMethods.fetchInspection(parameters.inspectionId);
    }
  }, []);

  const data = Array.from(useSelector((state) => state?.inspection.inspectionDetails))
    .find((d) => d.inspectionDetailIndex === Number(parameters.inspectionDetailIndex))
  
  const editInspectionData = useSelector(
    (state) => state.inspection.editInspection
  );
  console.log("editInspection", editInspectionData);

  useEffect(() => {
    setOdo(editInspectionData);
    console.log("u ed", editInspectionData);
    console.log("u s", odo);
  }, [editInspectionData])
  
   const printContent = useReactToPrint({
     content: () => componentRef.current,
   });

  return (
    <>
      <Card>
        <Card.Body>
          {data && (
            <div className="row" id="printable-content2" ref={componentRef}>
              <div className="col-sm-5">
                <h3 className="mb-8 ml-2" style={{ marginTop: "0" }}>
                  Photo Observation
                </h3>

                <div className="d-flex justify-content-center h-100 align-items-center">
                  <img
                    className="ml-2"
                    src={`${process.env.REACT_APP_API_URL}${data?.tireImageUrl}`}
                    height={700}
                  />
                </div>
              </div>
              <div className="col-sm-7">
                <div>
                  <table
                    cellPadding="5"
                    className="ReportDetailsTable d-flex justify-content-center mt-4"
                  >
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>InspectionId</td>
                        <td>{data?.inspectionId}</td>
                      </tr>
                      <tr>
                        <td>Inspection Date</td>
                        <td>
                          {moment(data?.inspectionDateTime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Registration No.</td>
                        <td>{odo?.vehicleRegNumber}</td>
                      </tr>
                      <tr>
                        <td>Tire Position</td>
                        <td>{data?.tirePosition}</td>
                      </tr>
                      <tr>
                        <td>TireType Label</td>
                        <td>{data?.tireTypeLabel}</td>
                      </tr>
                      <tr>
                        <td>Tire Make</td>
                        <td>{data?.tireMakeLabel}</td>
                      </tr>
                      <tr>
                        <td>Tire Size</td>
                        <td>{data?.tireSize}</td>
                      </tr>
                      <tr>
                        <td>Tire Pattern</td>
                        <td>{data?.tirePattern}</td>
                      </tr>
                      <tr>
                        <td>Tire Serial No.</td>
                        <td>{data?.tireSerialNo}</td>
                      </tr>
                      <tr>
                        <td>OTD mm</td>
                        <td>{data?.otdMm}</td>
                      </tr>
                      <tr>
                        <td>RST mm</td>
                        <td>{data?.rstMm}</td>
                      </tr>
                      <tr>
                        <td>CT mm</td>
                        <td>{data?.ctMm}</td>
                      </tr>
                      <tr>
                        <td>LST mm</td>
                        <td>{data?.lstMm}</td>
                      </tr>
                      <tr>
                        <td>RTD</td>
                        <td>{data?.rtd}</td>
                      </tr>
                      <tr>
                        <td>Reco. Pressure</td>
                        <td>{data?.recoPressure}</td>
                      </tr>
                      <tr>
                        <td>Obs. Pressure</td>
                        <td>{data?.obsPressure}</td>
                      </tr>
                      <tr>
                        <td>Observation_Category</td>
                        <td>{data?.observationCategoryLabel}</td>
                      </tr>
                      <tr>
                        <td>Observation</td>
                        <td>{data?.observationLabel}</td>
                      </tr>
                      <tr>
                        <td>Recommendation</td>
                        <td>{data?.recommendationLabel}</td>
                      </tr>
                      <tr>
                        <td>Original Fitment Date</td>
                        <td>
                          {data?.tireOriginalFitmentDate == null ? (
                            <></>
                          ) : (
                            <Moment format="DD/MM/YYYY">
                              {data?.tireOriginalFitmentDate}
                            </Moment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Odometer Reading when fitted</td>
                        <td>{data?.odometerReadingWhenFitted}</td>
                      </tr>
                      <tr>
                        <td>Current Odometer Reading</td>
                        <td>{odo?.vehicleOdometerReading}</td>
                      </tr>
                      <tr>
                        <td>Current Tire Life</td>
                        <td>{data?.currentTireLife}</td>
                      </tr>
                      <tr>
                        <td>Mileage Per mm</td>
                        <td>{data?.mileagePerMm}</td>
                      </tr>
                      <tr>
                        <td>Projected Life</td>
                        <td>{data?.projectedMileage}</td>
                      </tr>
                      <tr>
                        <td>Least Tire Thickness Allowed</td>
                        <td>{data?.leastTireThicknessAllowed}</td>
                      </tr>
                      <tr>
                        <td>Tire Removal Date</td>
                        <td>
                          {data?.tireRemovalDate == null ? (
                            <></>
                          ) : (
                            <Moment format="DD/MM/YYYY">
                              {data?.tireRemovalDate}
                            </Moment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Odometer Reading when removed</td>
                        <td>{data?.odometerReadingWhenRemoved}</td>
                      </tr>

                      <tr>
                        <td>Final Tire Life</td>
                        <td>{data?.finalTireLife}</td>
                      </tr>
                      <tr>
                        <td>Remark / Reason for Removal</td>
                        <td>{data?.remark}</td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={() => printContent()}>
              Print
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailAnalytical;
