// import { DatePicker } from "antd";
import moment from "moment";
import api from "../../../api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClientMethods from "../../../asyncMethods/ClientMethods";
import { tab } from "@testing-library/user-event/dist/tab";
import TextInputField from "../../../components/InputField/TextInputField";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Space } from 'antd';
import axios from "axios";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf('week').format(weekFormat)} ~ ${dayjs(value)
    .endOf('week')
    .format(weekFormat)}`;
  
function Table2() {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate9, setStartDate9] = useState(null);
  const [endDate9, setEndDate9] = useState(null);
  const [clientId, setClientId] = useState(0);
  // const [clientLocId, setClientLocId] = useState(0);
  const [clientName, setClientName] = useState(null);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState([]);
  const [selectedServiceLocationId, setSelectedServiceLocationId] = useState();
  const [tabData1, setTabData1] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [filterd, setFilterd] = useState(null);

  const clientMethods = ClientMethods();

  const user = JSON.parse(localStorage.getItem("user"));
  const tabClient = (localStorage.getItem("tabClient"));
  const tabClientLoc = (localStorage.getItem("tabClientLoc"));
  const tabStartDate = (localStorage.getItem("tabStartDate"));
  const tabEndDate = (localStorage.getItem("tabEndDate"));
  const clientLog = user?.client;


  const startDater = new Date(tabStartDate);

  const day = startDater.getDate();
  const month = startDater.getMonth() + 1;
  const year = startDater.getFullYear();

  const formattedMonth = (month < 10 ? '0' : '') + month;
  const formattedDay = (day < 10 ? '0' : '') + day;

  // Construct the desired format
  const formattedStartDate = `${year}-${formattedMonth}-${formattedDay}`;

  const startDatery = new Date(tabEndDate);

  const dayy = startDatery.getDate();
  const monthy = startDatery.getMonth() + 1;
  const yeary = startDatery.getFullYear();

  const formattedMonthy = (monthy < 10 ? '0' : '') + monthy;
  const formattedDayy = (dayy < 10 ? '0' : '') + dayy;

  // Construct the desired format
  const formattedStartDatey = `${yeary}-${formattedMonthy}-${formattedDayy}`;

  let clientList = useSelector((state) => state.client.clientList);


  useEffect(() => {
    // userMethods.fetchAllUsers();
    // if (!localStorage.getItem("pageRefreshedT2")) {
    //   // Set the flag to indicate that the page has been refreshed
    //   localStorage.setItem("pageRefreshedT2", "true");
    //   // Refresh the page
    //   window.location.reload();
    // }
    clientMethods.fetchAllClients();

    if(formattedStartDate !== "NaN-NaN-NaN" && formattedStartDatey !== "NaN-NaN-NaN"){
      setStartDate(formattedStartDate);
      setEndDate(formattedStartDatey);
    }

        setClientId(tabClient);
        // if(tabClientLoc != null || tabClientLoc!= undefined){ 
        // // setClientLocId(tabClientLoc);
        // setSelectedServiceLocationId(tabClientLoc);
        // }
        // setStartDate(tabStartDate);
        // setEndDate(tabEndDate);  
  }, []);


  const roleType = user?.roleType;
  const userId = user?.userID;


  if (Array.isArray(clientList) && roleType == 2 && clientList.length > 0) {
    clientList = clientList.filter((inspection) => inspection.userId == userId);
  }

  // const handleSelectChange = (event) => {
  //   const selectedClientId = event.target.value;
  //   setClientId(selectedClientId);
  // };

  const handleSelectChange = (event) => {
    const selectedClientId = event.target.value;
    setClientId(selectedClientId);
    // setSelectedServiceLocationId(null);
    setSelectedServiceLocation([]);

    // setStartDate(null); // Reset start date
    // setEndDate(null); // Reset end date

    // Find the corresponding clientName in the clientList
    let selectedClient = clientList.find(
      (client) => client.clientId === parseInt(selectedClientId)
    );

    // if (client) {
    //   selectedClient = clientName2;
    // }

    console.log("selecmmm", selectedClient);

    if (selectedClient) {
      setClientName(selectedClient.clientCompanyName);
      setSelectedServiceLocation(selectedClient.serviceLocations);
    } else {
      setClientName(null); // Reset clientName if client is not found
    }
    // if (client) {
    //   setClientName(selectedClient.clientCompanyName);
    //   setSelectedServiceLocation(selectedClient.serviceLocations);
    // }
  };

  const handleLocationChange = (event) => {
    const selectedLocationId = event.target.value;
    // setSelectedServiceLocationId(selectedLocationId);
    console.log("loc   idddd", selectedLocationId);

    // You can use selectedLocationId to further process the selected location
  };


  useEffect(() => {
    // Check if clientList is an array
    if (Array.isArray(clientList) && clientList.length > 0 && roleType == 1) {
      // Sort the clientList by clientFirstName in ascending order
      const sortedClientList = clientList
        .slice()
        .sort((a, b) => a.clientCompanyName.localeCompare(b.clientCompanyName));

    //   setClientId(sortedClientList[0].clientId); // Set the first option as the default
      setClientName(sortedClientList[0].clientCompanyName);
      setSelectedServiceLocation(sortedClientList[0].serviceLocations); // Set the location data
    }

    if(clientList.length > 0){
    let selectedClient = clientList.find(
      (client) => client.clientId === parseInt(tabClient)
    );

    if (selectedClient) {
      setClientName(selectedClient.clientCompanyName);
      setSelectedServiceLocation(selectedClient.serviceLocations);

    } else {
      setClientName(null); // Reset clientName if client is not found
    }
  }
  }, [clientList]);


const disabledDate = (current) => {
  // Disable dates after today
  return current && (current > moment().endOf("day") || current < moment().endOf("day"));
};

const handleDateChange = (dates) => {
  if (dates && dates.length === 2) {
    setStartDate9(dates[0]);
    setEndDate9(dates[1]);
    setStartDate(null);
    setEndDate(null);
  }
};

// console.log("client list", clientList);
console.log("client id" , tabClient);
console.log("70" , formattedStartDate);
console.log("start Dtae" , startDate);
console.log("srrr", selectedServiceLocation);


useEffect(() => {
  // setTabData1([]);
  let source = axios.CancelToken.source(); // Create a cancel token source

  const fetchDatas1 = async () => {
    try {
      setTabData1([]);
      setFilterText("");
      setFilterd(null);

      if (
        clientId != null && selectedServiceLocation.length > 0 
      ) {

        let endDateFormatted;
        let startDateFormatted;

        if(startDate != null && endDate != null){
          startDateFormatted = startDate;
          endDateFormatted = endDate;
        }

        if(startDate == null && endDate == null) {
          if (startDate9) {
            startDate9.$d.setHours(0, 0, 0, 0);
            console.log("$dd", startDate9.$d);
          }
          if (endDate9) {
            endDate9.$d.setHours(23, 59, 0, 0);
            console.log("$dd", endDate9.$d);
          }
          
          if(startDate9 && endDate9){
          const startDates = new Date(startDate9);
          const endDates = new Date(endDate9);
          
          startDates.setDate(startDates.getDate() + 1)
          
          // Convert to yyyy-mm-dd format
           startDateFormatted = startDates.toISOString().split("T")[0];
           endDateFormatted = endDates.toISOString().split("T")[0];
          
          console.log("startDateFormatted", startDateFormatted);
          console.log("endDateFormatted", endDateFormatted);
          }
 
        }
        if(startDateFormatted != undefined && endDateFormatted != undefined){
          const response = await api.get(
            `/vehicleinspection/getvehiclesinspectedindaterange?clientId=${clientId}&formDate=${startDateFormatted}&toDate=${endDateFormatted}`
          );
          const filteredData1 = response.data.result;
          setTabData1(filteredData1);
          setFilterd(filteredData1);
      }
    }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error(error);
      }
    }
  };

  fetchDatas1();

  return () => {
    source.cancel('Component unmounted'); // Cancel the request when component unmounts or dependencies change
  };
},[startDate, endDate, selectedServiceLocation, clientId, startDate9, endDate9])

const callFilter = (e) => {
  const filt = e.target.value;
  setFilterText(e.target.value)

  console.log(filt);
}

// useEffect(() => {
//   if(tabData1 !== null && tabData1.length > 0){
//     if(filterText == ""){
//       setFilterd(tabData1);
//     }
//     else{
//       const filt2 = tabData1.filter((item) => (item.vehicleRegNumber.toLowerCase().includes(filterText.toLowerCase())) ||
//       (item.inspectionId.toLowerCase().includes(filterText.toLowerCase())) ||
//       (item.vehicleManufacturer.toLowerCase().includes(filterText.toLowerCase())) ||
//       (item.vehicleModel.toLowerCase().includes(filterText.toLowerCase())) 
//       );
//       setFilterd(filt2);
//     }
//   }
// },[filterText])

useEffect(() => {
  if (tabData1 !== null && tabData1.length > 0) {
    if (filterText === "") {
      setFilterd(tabData1);
    } else {
      const filteredData = tabData1.filter(item => {
        // Check if any field contains the filter text
        // return (
        //   Object.values(item).some(value =>
        //     typeof value === "string" && value.toLowerCase().includes(filterText.toLowerCase())
        //   )
        // );
        return (
          Object.values(item).some(value =>
            typeof value === "string" && value.toLowerCase().startsWith(filterText.toLowerCase())
          ) ||
          typeof item.lastVehicleOdometerReading === "number" && item.lastVehicleOdometerReading.toString().startsWith(filterText.toLowerCase())||
          typeof item.vehicleOdometerReading === "number" && item.vehicleOdometerReading.toString().startsWith(filterText.toLowerCase())
        );
      });
      setFilterd(filteredData);
    }
  }
}, [filterText]);



  return (
    <>
      <div className="ml-3">
        <div className="d-flex mb-2">
          <select
            className="ml-2 mr-4"
            onChange={handleSelectChange}
            value={clientId}
            disabled={clientLog}

          >
            {clientList.length > 0 &&
              clientList
                .sort((a, b) =>
                  a.clientCompanyName.localeCompare(b.clientCompanyName)
                )
                .map((client) => (
                  <option key={client.clientId} value={client.clientId}>
                    {client.clientCompanyName}
                  </option>
                ))}
          </select>
       
    {formattedStartDate == "NaN-NaN-NaN" || formattedStartDatey == "NaN-NaN-NaN" ? 
      <RangePicker         
      allowClear={false}
      format={"DD-MM-YYYY"} disabledDate={disabledDate} onChange={handleDateChange}
      />
    :
    <RangePicker
    allowClear={false}
      defaultValue={[dayjs(formattedStartDate, dateFormat), dayjs(formattedStartDatey, dateFormat)]}
      format={"DD-MM-YYYY"}
      disabledDate={disabledDate}
      onChange={handleDateChange}
    />
        }

<input id="search" type="text" placeholder="Search..." class="sc-jItqcz fcCtlt ml-3" value={filterText} onChange={(e) => callFilter(e)}/>

        </div>
        <p className="dash-label">Report for Total Vehicles Inspected in Date Range</p>
        <div className="observation-table">
          <div className="observation-summary text-center">
            Total Vehicle Inspected
            <br></br>
            {/* Company Name: {clientName} */}
          </div>

          <table className="dash-table">
            <thead>
              <tr className="dash-header">
                <th>Location</th>
                <th>Inspection ID</th>
                <th>Vehicle Inspection date</th>
                <th>Vehicle Reg No</th>
                <th>Vehicle Odometer reading</th>
                <th>Vehicle Manufacturer</th>
                <th>Vehicle Model</th>
                <th>Vehicle type</th>
                {/* <th>Status</th> */}
                <th>Last Inspection date</th>
                <th>Last Inspection Km</th>
              </tr>
            </thead>

            <tbody>
                {filterd &&
                  filterd.length > 0 &&
                  filterd.map((inspection) => (
                    <tr className="dash-row" key={inspection.inspectionId}>
                      <td>{inspection?.clientServiceLocationAddress}</td>
                      <td>{inspection?.inspectionId}</td>
                      <td>{(inspection?.inspectionDate !== null ? moment(inspection?.inspectionDate).format("DD/MM/YYYY") : '')}</td>
                      <td>{inspection?.vehicleRegNumber}</td>
                      <td>{inspection?.vehicleOdometerReading}</td>
                      <td>{inspection?.vehicleManufacturer}</td>
                      <td>{inspection?.vehicleModel}</td>
                      <td>{inspection?.vehicleType}</td>
                      {/* <td>{inspection.activeStatus}</td> */}
                      <td>{(inspection?.lastInspDate !== null ? moment(inspection?.lastInspDate).format("DD/MM/YYYY") : '')}</td>
                      <td>{inspection?.lastVehicleOdometerReading}</td>

                      {/* <td>
                        {moment(inspection.createdDate).format("DD/MM/YYYY")}
                      </td> */}
                    </tr>
                  ))}
              </tbody>
          </table>
        </div>
      </div>{" "}
    </>
  );
}

export default Table2;
