import { useDispatch } from "react-redux";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FETCH_ALL_OPERATIONAL_MASTER, SET_EDIT_OPERATIONAL_MASTER } from "../redux/constants/OperationalMasterTypes";

const OperationalMasterMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllOperationalMasterData = () => {
        return api.get("/masterservice/getallobservation?page=0&size=10000&sortBy=observationId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_OPERATIONAL_MASTER,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeOperationalMasterData = (data) => {
        return api.post("/masterservice/saveobservationmaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Observation Master Created Successful.");
                navigate('/observation-master');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchOperationalMasterData = (observationId = 1) => {
        return api.get(`/masterservice/getobservation?observationId=` + observationId)
            .then(res => {
                dispatch({
                    type: SET_EDIT_OPERATIONAL_MASTER,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateOperationalMasterData = (observationId, operationalData) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updateobservationmaster?observationId=` + observationId, operationalData, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Observation Master Data Updated Successful.");
                    navigate('/observation-master');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const operationalMasterStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisableobservationmaster?observationId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllOperationalMasterData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllOperationalMasterData,
        fetchOperationalMasterData,
        storeOperationalMasterData,
        updateOperationalMasterData,
        operationalMasterStatusUpdate
    };
};

export default OperationalMasterMethod;
