import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import {
  FETCH_ALL_VEHICLE_SUB_TYPE,
  SET_EDIT_VEHICLE_SUB_TYPE,
} from "../../redux/constants/mst_type/VehicleSubType";

const VehicleSubTypeDataMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllVehicleSubTypeData = (vehicleTypeId) => {
    console.log("res ----:", vehicleTypeId);
    return api
      .get(
        `masterservice/getvehiclesubtypemasterdata?vehicleTypeId=` +
          vehicleTypeId
      )
      .then((res) => {
        dispatch({
          type: FETCH_ALL_VEHICLE_SUB_TYPE,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllVehicleSubTypeList = () => {
    return api
      .get(
        "masterservice/getvehiclesubtypemasterdata?page=0&size=10000&sortBy=vehicleSubTypeId"
      )
      .then((res) => {
        dispatch({
          type: FETCH_ALL_VEHICLE_SUB_TYPE,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const storeVehicleSubTypeData = (data) => {
    return api
      .post("/masterservice/savevehiclesubtypemaster", data)
      .then((res) => {
        if (res.data.statusCode == 201) {
          toast.success("Vehicle Sub Type Created Successful.");
          navigate("/mst-vehicle-sub-type");
          return;
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchVehicleSubTypeData = (vehicleSubTypeId = 1) => {
    return api
      .get(
        `/masterservice/getvehiclesubtypemasterdatabyid?vehicleSubTypeId=` +
          vehicleSubTypeId
      )
      .then((res) => {
        console.log("res ----:", res);
        dispatch({
          type: SET_EDIT_VEHICLE_SUB_TYPE,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateVehicleSubTypeData = (vehicleSubTypeId, data) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return api
      .put(
        `/masterservice/updatevehiclesubtypemaster?vehicleSubTypeId=` +
          vehicleSubTypeId,
        data,
        { headers }
      )
      .then((res) => {
        if (res.data.statusCode == 200) {
          toast.success("Vehicle Sub Type Data updated Successful.");
          navigate("/mst-vehicle-sub-type");
          return;
        }
        toast.error(res.data.statusDescription);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Fetch Failed.");
      });
  };

  const vehicleSubTypeStatusUpdate = ({ id, status }) => {
    const data = {
      activeStatus: status,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return api
      .put(
        `/masterservice/enabledisablevehiclesubtypemaster?vehicleSubTypeId=` +
          id,
        data,
        { headers }
      )
      .then((res) => {
        toast.success("Status Updated Successful.");
        fetchAllVehicleSubTypeData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    fetchAllVehicleSubTypeData,
    fetchAllVehicleSubTypeList,
    storeVehicleSubTypeData,
    fetchVehicleSubTypeData,
    updateVehicleSubTypeData,
    vehicleSubTypeStatusUpdate,
  };
};

export default VehicleSubTypeDataMethod;
