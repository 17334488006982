import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import VehicleActionButton from '../../../components/ActionButtons/VehicleActionButton';
import VehicleMethods from '../../../asyncMethods/VehicleMethods';
import FilterComponent from '../../../components/FilterComponent/Index';

const columns = [
  {
    name: "Model",
    selector: (row) => row.vehicleModelLabel,
    center: true,
    sortable: true,
  },
  {
    name: "Manufacturer",
    selector: (row) => row.vehicleManufacturerLabel,
    sortable: true,
  },
  {
    name: "Vehicle Type",
    selector: (row) => row.vehicleTypeLabel,
    sortable: true,
  },
  {
    name: "Vehicle SubType",
    selector: (row) => row.vehicleSubTypeLabel,
    sortable: true,
  },

  {
    name: "Vehicle Usage",
    selector: (row) => row.vehicleUsageLabel,
    sortable: true,
  },
  {
    name: "No Of Tire",
    selector: (row) => row.noOfTires,
    sortable: true,
  },
  {
    name: "No Of Axle",
    selector: (row) => row.noOfAxle,
    sortable: true,
  },
  {
    name: "No Of Stepneys ",
    selector: (row) => row.noOfStepneys,
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true,
  },
];

const Vehicles = () => {
    const navigate = useNavigate();
    const vehicleMethods = VehicleMethods();
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Fetch All Vehicle
    useEffect(() => {
        vehicleMethods.fetchAllVehicleData();
    }, []);


    const statusUpdate = (vehicleId, status) => {
        const data = {
            id: vehicleId,
            status: status == 1 ? 0 : 1
        }
        vehicleMethods.vehicleStatusUpdate(data);
    }

    const data = Array.from(useSelector((state) => state?.vehicle?.vehicleList))
        .map((vehicle) => ({
            ...vehicle,
            actionButtons: (
                <VehicleActionButton
                    onStatusUpdate={statusUpdate}
                    vehicleDetails={vehicle}
                />
            ),
        }))
        .sort((b, a) => a.vehicleId - b.vehicleId);

    console.log("sle", data);

    const filteredItems = data.filter(
        (item) =>
            ((item.vehicleModelLabel || "").toString().toLowerCase().includes(filterText.toString().toLowerCase())) ||
            (item.vehicleTypeLabel || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.vehicleSubTypeLabel || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.noOfTires || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.noOfAxle || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.noOfStepneys || "").toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <div className="d-flex justify-content-between m-3">
                <h5></h5>
                <Button
                    onClick={() => navigate("/vehicle/create")}
                    variant="primary"
                >
                    <i className="fa-solid fa-plus"></i> New Vehicle
                </Button>
            </div>
            <Card>
                <Card.Body>
                    <DataTable
                        title="Vehicle List"
                        columns={columns}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        data={filteredItems}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default Vehicles
