import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import TireConfigurationMasterMethod from "../../../asyncMethods/TireConfigurationMasterMethod"
import TireActionButton from '../../../components/ActionButtons/TireActionButton';
import FilterComponent from '../../../components/FilterComponent/Index';

const columns = [
  {
    name: "Tire Make",
    selector: (row) => row.tireMakeId.tireMakeName,
    sortable: true,
  },
  {
    name: "Tire Type",
    selector: (row) => row.tireType,
    center: true,
    sortable: true,
  },
  {
    name: "Tire Size",
    selector: (row) => row.tireSize,
    center: true,
    sortable: true,
  },
  {
    name: "Tire Pattern",
    selector: (row) => row.tirePattern,
    sortable: true,
  },
  {
    name: "Tire Thickness",
    selector: (row) => row.tireThickness,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row?.activeStatus,
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true
  },
];

const TireConfigurationMaster = () => {
  const navigate = useNavigate();
  const tireConfigurationMasterMethod = TireConfigurationMasterMethod();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Fetch All Tire
  useEffect(() => {
    tireConfigurationMasterMethod.fetchAllTireConfigurationMasterData();
  }, []);

  // Status Update
  const statusUpdate = (tireConfigurationId, status) => {
    const data = {
      id: tireConfigurationId,
      status: status == 1 ? 0 : 1
    }
    tireConfigurationMasterMethod.tireConfigurationMasterStatusUpdate(data);
  }


  const data = Array.from(useSelector((state) => state?.tireConfigurationMaster?.tireConfigurationMasterList))
    .map((tire) => ({
      ...tire,
      actionButtons: (
        <TireActionButton
          onStatusUpdate={statusUpdate}
          tireDetails={tire}
        />
      ),
    }))
    .sort((b, a) => a.tyreId - b.tyreId);

  const filteredItems = data.filter(
    (item) =>
      (item.tireSize || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.tirePattern || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.tireThickness || "").toString().toLowerCase().includes(filterText.toLowerCase()) || (item.activeStatus || "").toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/mst-tire-configuration/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Tire
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Tire List"
            columns={columns}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            data={filteredItems}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default TireConfigurationMaster
  ;