import React from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CVInspectionActionButton = ({ inspectionDetails, onStatusUpdate }) => {
  const navigate = useNavigate();
  const redirectToEditPage = () => {
     // navigate("/client-vehicle-master/edit/" + inspectionDetails?.inspectionId);
        navigate(
          "/client-vehicle-master/edit/" + inspectionDetails?.clientVehicleId
        );


  };

  const user = JSON.parse(localStorage.getItem("user"));
  const roleType = user?.roleType;
  const userId = user?.userID;
  const client = user?.client;
  const clientId = user?.userID;
  // console.log("role", roleType);

  return (
    <>
      <div className="d-flex align-items-center">
        <Button
          variant="primary"
          size="sm"
          onClick={() => redirectToEditPage(inspectionDetails?.clientVehicleId)}
        >
          <i className="fa-solid fa-user-pen"></i>
        </Button>
        <Form.Check
          inline
          className={"mx-3 text-danger"}
          type="switch"
          value={inspectionDetails.activeStatus === 1 ? 1 : 0}
          checked={inspectionDetails.activeStatus === 1 ? true : false}
          id={"toggle-button" + inspectionDetails.clientVehicleId}
          onChange={(e) => {
            onStatusUpdate(inspectionDetails.clientVehicleId, e.target.value);
          }}
        />
      </div>
    </>
  );
};

export default CVInspectionActionButton;
