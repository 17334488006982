import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import TextInputField from "../../../components/InputField/TextInputField";
import Select from "../../../components/DropdownField/Select";
import MasterTypeMethod from "../../../asyncMethods/MasterTypeMethod";

const schema = Yup.object({
  dropDownName: Yup.string().required("Name is required !"),
  activeStatus: Yup.number("Should be number").required('Status Required'),
});

const CreateMasterType = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const masterTypeMethod = MasterTypeMethod();
  const allDropDown = useSelector(state => state?.masterType?.masterTypeList)

  const formRef = useRef() ;
  
  const [initialValues, setInitialValues] = useState({
    dropDownName: "",
    dropDownKey: "",
    isChild: true,
    parentDropDownId: "",
    activeStatus: ""
  });

  useEffect(() => {
    if (parameters.dropDownId) {
      masterTypeMethod.fetchMasterType(parameters.dropDownId);
    }
  }, []);

  const editMasterType = useSelector(state => state.masterType.editMasterType);

  // Edit Master Type Data
  useEffect(() => {
    if (parameters.dropDownId && editMasterType.dropDownId == parameters.dropDownId) {
      setInitialValues(editMasterType)
    }
  }, [editMasterType]);

  // Form handleSubmit
  const onSubmit = (data) => {
    if (parameters.dropDownId) {
      masterTypeMethod.updateMasterType(parameters.dropDownId, data)
    }
    else {
      masterTypeMethod.checkDroDownDuplicate(data?.dropDownName, data?.dropDownKey, data);
    }
  };

  const handleOnChange = (e) => {
    if (e.target.name === "dropDownName") {
      let keyValue;
      keyValue = e.target.value.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      })
      let finalKey = keyValue.join("_")
      formRef.current.setFieldValue("dropDownKey",finalKey.toLowerCase())
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={(values) => {
          const newData = values;
          if (!newData.isChild) {
            newData.parentDropDownId = "0"
          }
          onSubmit(newData)
        }}
      >
        {(formik) => {
          const { errors, touched } = formik;
          return (
            <>
              <div className="d-flex justify-content-between m-3">
                <h5>{""}</h5>
                <Button
                  onClick={() => navigate("/master-type")}
                  variant="primary"
                >
                  <i className="fa-solid fa-arrow-left"></i> Back
                </Button>
              </div>
              <Card>
                <Card.Body>
                  <Form onChange={handleOnChange}>
                    <div className="m-3">
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Dropdown Name"}
                          name={"dropDownName"}
                          classProps={"col"}
                          required={true}
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          readOnly
                          label={"Dropdown Key"}
                          name={"dropDownKey"}
                          classProps={"col"}

                        />
                      </div>
                      <div className="form-group form-row">
                        <Select
                          label={"Status"}
                          name={"activeStatus"}
                          required={true}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                        >
                          <option value={""}>select</option>
                          <option value={1}>
                            Active
                          </option>
                          <option value={0}>
                            inActive
                          </option>
                        </Select>
                      </div>
                      <div className="form-group form-row">
                        <div className="col">
                          <label>
                            <Field
                              type={"checkbox"}
                              name={"isChild"}
                            />
                            {" Parent Dropdown"}
                          </label>
                        </div>
                      </div>

                      {formik.values.isChild &&
                        <div className="form-group form-row">
                          <Select
                            label={"Dropdown"}
                            name={"parentDropDownId"}
                            classProps={"col"}
                            errors={errors}
                            touched={touched}
                          >
                            <option value={""}>select</option>
                            {
                              allDropDown?.length !== 0 && allDropDown?.map((dropVal, index) => (
                                <option key={index} value={dropVal?.dropDownId}>
                                  {dropVal?.dropDownName}
                                </option>
                              ))
                            }

                          </Select>
                        </div>}
                    </div>
                    <div className="d-flex justify-content-center m-3">
                      <button
                        type="submit"
                        className="btn-fill btn btn-info my-3"
                      >
                        {
                          parameters?.dropDownId ? "Update" : "Add"
                        }
                        Type
                      </button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateMasterType;
