import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import { toast } from "react-toastify";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];


const TextField = styled.input`
  height: 40px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 40px;
  width: 72px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  filterStartDate,
  filterEndDate,
  isDateFilter,
  onStartDateFilter,
  onEndDateFilter,
}) => (
  <>
   {isDateFilter && (
      <div>
        <TextField
          id="search"
          type="date"
          aria-label="Search Input"
          value={filterStartDate}
          onChange={onStartDateFilter}
          format={"MM/DD/YYYY"}
        />
        <TextField
          id="search"
          type="date"
          placeholder="Search..."
          aria-label="Search Input"
          value={filterEndDate}
          onChange={onEndDateFilter}
          
        />
      </div>
    )}
    {/* {isDateFilter && (
      <div className='flexx'>
       <DatePicker
                            format={dateFormatList}
                            style={{ width: "200px", Height: "50px", marginRight:"10px" }}                            
                            placeholder="DD/MM/YYYY"
                          />
                          <DatePicker
                            format={dateFormatList}
                            style={{ width: "200px", Height: "40px",marginRight:"10px" }}                            
                            placeholder="DD/MM/YYYY"

                          />
        <TextField
              style={{marginTop:"1rem"}}

          id="search"
          type="date"
          placeholder="Search..."
          aria-label="Search Input"
          value={filterEndDate}
          onChange={onEndDateFilter}
          
        />
      </div>
    )} */}

    <TextField
      // style={{marginTop:"1rem"}}
      id="search"
      type="text"
      placeholder="Search..."
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton       
 type="button" onClick={onClear}>
      Reset
    </ClearButton>
  </>
);


export default FilterComponent;