import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import OperationalMasterButton from "../../../components/ActionButtons/OperationalMasterButton";
import FilterComponent from "../../../components/FilterComponent/Index";
import OperationalMasterMethod from "../../../asyncMethods/OperationalMasterMethod";

const columns = [
  {
    name: "Name",
    selector: (row) => row.observationName,
    sortable: true
  },
  {
    name: "Recommendation",
    selector: (row) => row.observationRecommendation,
    sortable: true
  },
  {
    name: "Observation Category",
    selector: (row) => row.observationCategoryType,
    sortable: true
  },
  {
    name: "Status",
    selector: (row) => row.activeStatus
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true
  },
]

const OperationMasterData = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const operationalMasterMethod = OperationalMasterMethod();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Fetch All ObservationMaster
  useEffect(() => {
    operationalMasterMethod.fetchAllOperationalMasterData();
  }, []);

  // Status Update
  const statusUpdate = (observationId, status) => {
    const data = {
      id: observationId,
      status: status == 1 ? 0 : 1
    }
    operationalMasterMethod.operationalMasterStatusUpdate(data);
  }

  const data = Array.from(useSelector((state) => state?.operational?.operationalMasterList))
    .map((operationalMaster) => ({
      ...operationalMaster,
      actionButtons: (
        <OperationalMasterButton
          onStatusUpdate={statusUpdate}
          operationalMasterDetails={operationalMaster}
        />
      ),
    }))
    .sort((b, a) => a.observationId - b.observationId);


  const filteredItems = data.filter(
    (item) =>
      (item.observationName.toLowerCase().includes(filterText.toLowerCase())) ||
      item.observationRecommendation.toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/observation-master/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Observation Data
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Observation Master"
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default OperationMasterData;
