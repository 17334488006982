import { FETCH_ALL_USERS, FETCH_ROLE_OPTIONS, FETCH_STATUS_OPTIONS, SET_EDIT_USER, USER_LOGIN, USER_LOGOUT } from "../constants/AuthActionTypes"

export const userLogin = data => {
    return {
        type: USER_LOGIN,
        payload : data
    }
}

export const userLogout = () => {
    return {
        type: USER_LOGOUT
    }
}

export const fetchAllUsers = (users) => {
    return {
        type: FETCH_ALL_USERS,
        payload : users
    }
}

export const setEditUser = (users) => {
    return {
        type: SET_EDIT_USER,
        payload : users
    }
}

export const fetchRoleOptions = (roles) => {
    return {
        type: FETCH_ROLE_OPTIONS,
        payload : roles
    }
}

export const fetchStatusOptions = (roles) => {
    return {
        type: FETCH_STATUS_OPTIONS,
        payload : roles
    }
}