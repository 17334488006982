import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Moment from 'react-moment';

import ReportActionButtons from "../../../components/ActionButtons/ReportActionButtons";
import FilterComponent from "../../../components/FilterComponent/Index";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";



const columns = [
  {
    name: "Inspection ID",
    selector: (row) => row.inspectionId,
    sortable: true,
  },
  {
    name: "Company Name",
    selector: (row) => row?.clientInformation.split(",")[0].trim(),
  },
  {
    name: "Client Name",
    selector: (row) => row?.clientName,
    center: true,
  },
  // {
  //   name: "Client id",
  //   selector: (row) => row?.clientId,
  //   center: true,
  // },
  // {
  //   name: "Active ststus",
  //   selector: (row) => row?.inspectionStatus,
  //   center: true,
  // },

  // {
  //   name: "Date and Time",
  //   selector: (row) => (
  //     // <Moment format="DD/MM/YYYY">{row?.inspectionDateTime}</Moment>
  //     row?.inspectionDateTime.slice(0, 10)
  //   ),
  //   center: true,
  // },
  {
    name: "Date and Time",
    selector: (row) => (
      <Moment format="DD/MM/YYYY" utc>{row?.inspectionDateTime}</Moment>
      ),
    center: true,
  },
  {
    name: "Vehicle Reg No",
    selector: (row) => row?.vehicleRegNumber,
    center: true,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true,
  },
];

const Report = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const inspectionMethods = InspectionMethods();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.userID;
    const role = user?.roleType;
    const client = user?.client;
    const clientId = user?.userID;

  useEffect(() => {
    if (client) {
       inspectionMethods.fetchClientIspection(clientId);
     }
    else if (role === "1" || role === 1) {
      inspectionMethods.fetchAllInspections();
    }
    else if (role === "2" || role === 2) {
      inspectionMethods.fetchUserIspection(userId);
    }
   
  }, []);

  let data = [];

  const inspectionList = useSelector(
    (state) => state?.inspection?.inspectionList
  );

  if (inspectionList) {
    data = Array.from(inspectionList)
      .map((inspection) => ({
        ...inspection,
        actionButtons: <ReportActionButtons reportDetails={inspection} />,
      }))
      .sort((a, b) => new Date(b.inspectionDateTime) - new Date(a.inspectionDateTime));
  }



  // const data = Array.from(useSelector((state) => state?.inspection?.inspectionList))
  //   .map((inspection) => ({
      // ...inspection,
      // actionButtons: (
      //   <ReportActionButtons
      //   reportDetails={inspection}
      //   />
  //     ),
  //   }))
  //   .sort((b, a) => a.inspectionId - b.inspectionId);

  let filteredItems = data.filter(
    (item) =>
      ((item.inspectionId || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
      (item.clientName || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
      (item.clientInformation.split(',')[0].trim() || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
      (item.vehicleRegNumber || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()))
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    if(filterStartDate && filterEndDate) {
        filteredItems = filteredItems.filter(d => (new Date(d.inspectionDateTime) - new Date(filterStartDate) > 0) && new Date(d.inspectionDateTime) - new Date(filterEndDate) < 0);
    } else if(filterStartDate) {
        filteredItems = filteredItems.filter(d => (new Date(d.inspectionDateTime) - new Date(filterStartDate) > 0));
    } else if(filterEndDate) {
        filteredItems = filteredItems.filter(d => new Date(d.inspectionDateTime) - new Date(filterEndDate) < 0);
    }

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onStartDateFilter={(e) => setFilterStartDate(e.target.value)}
        onEndDateFilter={(e) => setFilterEndDate(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        isDateFilter={true}
      />
    );
  }, [filterText, resetPaginationToggle, filterStartDate, filterEndDate]);


  return (
    <>
      <Card >
        <Card.Body>
          <DataTable
            title="Report"
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Report;
