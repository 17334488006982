import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FETCH_ALL_MASTER_TYPE, SET_EDIT_MASTER_TYPE } from "../redux/constants/MasterTypeTypes";
import { useNavigate } from "react-router-dom";
import api from "../api";

const MasterTypeMethod = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllMasterType = () => {
    return api.get("/masterservice/getalldropdownmasterdata?page=0&size=0")
      .then(res => {
        dispatch({
          type: FETCH_ALL_MASTER_TYPE,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const storeMasterType = (data) => {
    return api.post("/masterservice/savedropdownmaster", data).then(res => {
      if (res.data.statusCode == 201) {
        toast.success("Master-type created Successful.");
        navigate('/master-type');
        return;
      }
    }).catch(err => console.log(err));
  }

  const checkDroDownDuplicate = (dropName, dropKey, data) => {
    return api.get(`masterservice/checkdropdownmasterduplicate?dropDownName=${dropName}&dropDownKey=${dropKey}`)
      .then(res => {
        if (res.data.statusCode == 200) {
          storeMasterType(data);
          return;
        } else if (res.data.statusCode == 500) {
          toast.warn(res.data.statusDescription)
        }
      }).catch(err => {
        console.log(err);
      })
  }

  const fetchMasterType = (masterTypeId = 1) => {
    return api.get(`/masterservice/getdropdownmasterdata?dropDownId=` + masterTypeId)
      .then(res => {
        dispatch({
          type: SET_EDIT_MASTER_TYPE,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const updateMasterType = (userId, masterType) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/updatedropdownmaster?dropDownId=` + userId, masterType, { headers })
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("Master-Type updated Successful.");
          navigate('/master-type');
          return;
        }
        toast.error(res.data.statusDescription);
      }).catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      })
  }

  const masterTypeStatusUpdate = ({ id, status }) => {
    const data = {
      "activeStatus": status
    }
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/enabledisabledropdownmaster?dropDownId=` + id, data, { headers })
      .then(res => {
        toast.success("Status Updated Successful.");
        fetchAllMasterType();
      }).catch(err => {
        console.log(err);
      })
  }

  return {
    fetchAllMasterType,
    storeMasterType,
    fetchMasterType,
    updateMasterType,
    masterTypeStatusUpdate,
    checkDroDownDuplicate
  };
}

export default MasterTypeMethod