import { useDispatch } from "react-redux";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FETCH_ALL_VEHICLE, SET_EDIT_VEHICLE, SET_VEHICLE_MANUFACTURE, SET_VEHICLE_SUB_TYPE_OPTIONS, SET_VEHICLE_TYPE_OPTIONS, SET_VEHICLE_USAGE_OPTIONS } from "../redux/constants/VehicleActionTypes";

const VehicleMethods = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();;

  const fetchVehicleOptions = (datatype, parentId = 0) => {
    const actionTypes = {
      vehicle_type: SET_VEHICLE_TYPE_OPTIONS,
      vehicle_sub_type: SET_VEHICLE_SUB_TYPE_OPTIONS,
      vehicle_manufacturer: SET_VEHICLE_MANUFACTURE,
      vehicle_usage: SET_VEHICLE_USAGE_OPTIONS
    };

    return api
      .get(
        "/masterservice/masterdatalist?datatype=" +
        datatype +
        "&parentId=" +
        parentId +
        "&activeStatus=1"
      )
      .then((res) => {
        dispatch({
          type: actionTypes[datatype],
          payload: res.data.result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllVehicleData = () => {
    return api.get("/masterservice/getallvehicledata?page=0&size=10000")
      .then(res => {
        dispatch({
          type: FETCH_ALL_VEHICLE,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const storeVehicleData = (data) => {
    return api.post("/masterservice/savevehicle", data).then(res => {
      if (res.data.statusCode == 201) {
        toast.success("Vehicle created Successful.");
        navigate('/vehicle');
        return;
      }
    }).catch(err => console.log(err));
  }

  const fetchVehicleData = (vehicleId = 1) => {
    return api.get(`/masterservice/getvehicledata?vehicleId=` + vehicleId)
      .then(res => {
        dispatch({
          type: SET_EDIT_VEHICLE,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const updateVehicleData = (vehicleId, vehicleData) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/updatevehicle?vehicleId=` + vehicleId, vehicleData, { headers })
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("Vehicle-Data updated Successful.");
          navigate('/vehicle');
          return;
        }
        toast.error(res.data.statusDescription);
      }).catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      })
  }

  const vehicleStatusUpdate = ({ id, status }) => {
    const data = {
        "activeStatus": status
    }
    const headers = {
        "Content-Type": "application/json"
    };
    return api.put(`/masterservice/enabledisablevehicle?vehicleId=` + id, data, { headers })
        .then(res => {
            toast.success("Status Updated Successful.");
            fetchAllVehicleData();
        }).catch(err => {
            console.log(err);
        })
}

  return {
    fetchVehicleOptions,
    fetchAllVehicleData,
    fetchVehicleData,
    storeVehicleData,
    updateVehicleData,
    vehicleStatusUpdate
  };
};

export default VehicleMethods;
