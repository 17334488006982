import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import Moment from 'react-moment';


import ReportDetailsActionButtons from "../../../../components/ActionButtons/ReportDetailsActionButtons";
import FilterComponent from "../../../../components/FilterComponent/Index";
import InspectionMethods from "../../../../asyncMethods/InspectionMethods";

const DetailsReport = () => {
    const [odo, setOdo] = useState("");

  const navigate = useNavigate();
  const parameters = useParams();
  const [filterText, setFilterText] = useState("");
  const inspectionMethods = InspectionMethods();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  const columns = [
    {
      name: "Inspection ID",
      selector: (row) => row.inspectionId,
      sortable: true,
    },
    {
      name: "Tire Position",
      selector: (row) => row.tirePosition,
    },
    {
      name: "New / Retread",
      selector: (row) => row?.tireTypeLabel,
      center: true,
    },
    {
      name: "Tire Make",
      selector: (row) => row?.tireMakeLabel,
      center: true,
    },
    {
      name: "Tire Size",
      selector: (row) => row?.tireSize,
      center: true,
    },
    {
      name: "Tire Pattern",
      selector: (row) => row?.tirePattern,
      center: true,
    },
    {
      name: "Tire Sr. No",
      selector: (row) => row?.tireSerialNo,
      center: true,
    },
    {
      name: "OTD mm",
      selector: (row) => row?.otdMm,
      center: true,
    },
    {
      name: "RST mm",
      selector: (row) => row?.rstMm,
      center: true,
    },
    {
      name: "CT mm",
      selector: (row) => row?.ctMm,
      center: true,
    },
    {
      name: "LST mm",
      selector: (row) => row?.lstMm,
      center: true,
    },
    {
      name: "RTD",
      selector: (row) => row?.rtd,
      center: true,
    },
    {
      name: "Reco. Pressure",
      selector: (row) => row?.recoPressure,
      center: true,
    },
    {
      name: "Obs. Pressure",
      selector: (row) => row?.obsPressure,
      center: true,
    },
    {
      name: "Observation Category",
      selector: (row) => row?.observationCategoryLabel,
      center: true,
    },
    {
      name: "Observation",
      selector: (row) => row?.observationLabel,
      center: true,
    },
    {
      name: "Recommendation",
      selector: (row) => row?.recommendationLabel,
      center: true,
    },
    // {
    //   name: "Original Fitment Date",
    //   selector: (row) => row?.tireOriginalFitmentDate,
    //   // <Moment format="MM/DD/YYYY">{row?.tireOriginalFitmentDate}</Moment>
    //   center: true,
    // },
    {
      name: "Original Fitment Date",
      selector: (row) => {
        const dateValue = row?.tireOriginalFitmentDate;

        // Check if the dateValue is null or undefined
        if (dateValue === null || dateValue === undefined) {
          return ""; // Display "N/A" or any other message you prefer
        }

        // If the dateValue is not null or undefined, format it as a date
        const formattedDate = new Date(dateValue).toLocaleDateString(); // You can adjust the formatting as needed

        return formattedDate;
      },
      center: true,
    },
    {
      name: "Odometer Reading when fitted",
      selector: (row) => row?.odometerReadingWhenFitted,
      center: true,
    },
    {
      name: "Current Odometer Reading",
      selector: (row) => odo?.vehicleOdometerReading,
      center: true,
    },
    {
      name: "Current Tire Life",
      selector: (row) => row?.currentTireLife,
      center: true,
    },
    {
      name: "Mileage per mm",
      selector: (row) => row?.mileagePerMm,
      center: true,
    },
    {
      name: "Projected Life",
      selector: (row) => row?.projectedMileage,
      center: true,
    },
    {
      name: "Least Tire Thickness Allowed mm",
      selector: (row) => row?.leastTireThicknessAllowed,
      center: true,
    },
    // {
    //   name: "Tire Removal Date",
    //   selector: (row) => row?.tireRemovalDate,
    //   center: true,
    // },
    {
      name: "Tire Removal Date",
      selector: (row) => {
        const dateValue = row?.tireRemovalDate;

        // Check if the dateValue is null or undefined
        if (dateValue === null || dateValue === undefined) {
          return ""; // Display "N/A" or any other message you prefer
        }

        // If the dateValue is not null or undefined, format it as a date
        const formattedDate = new Date(dateValue).toLocaleDateString(); // You can adjust the formatting as needed

        return formattedDate;
      },
      center: true,
    },
    {
      name: "Odometer Reading when removed",
      selector: (row) => row?.odometerReadingWhenRemoved,
      center: true,
    },

    {
      name: "Final Tire Life",
      selector: (row) => row?.finalTireLife,
      center: true,
    },
    {
      name: "Remark / Reason for Removal",
      selector: (row) => row?.remark,
      center: true,
    },

    {
      name: "Tire Image Url",
      selector: (row) => row?.tireImageUrl,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row?.actionButtons,
      center: true,
    },
  ];

  useEffect(() => {
    if (parameters.inspectionId) {
      inspectionMethods.getVehicleInspectionDetails(parameters.inspectionId);
      inspectionMethods.fetchInspection(parameters.inspectionId);
    }
  }, []);


  const data = Array.from(useSelector((state) => state?.inspection.inspectionDetails))
    .map((inspection) => ({
      ...inspection,
      actionButtons: (
        <ReportDetailsActionButtons
            reportDetails={inspection}
        />
      ),
    }))
    .sort((b, a) => a.inspectionId - b.inspectionId);
  
  //  const data2 = Array.from(
  //    useSelector((state) => state?.inspection.inspectionDetails)
  //  ).find(
  //    (d) => d.inspectionDetailIndex === Number(parameters.inspectionDetailIndex)
  //  );

   const editInspectionData = useSelector(
     (state) => state.inspection.editInspection
   );
  console.log("editInspection", editInspectionData);
  
   useEffect(() => {
     setOdo(editInspectionData);
     console.log("u ed", editInspectionData);
     console.log("u s", odo);
   }, [editInspectionData]);
 
  

//   let filteredItems = data.filter(
//     (item) =>
//       ((item.inspectionId || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
//       (item.clientName || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()) ||
//       (item.vehicleRegNumber || "").toString().toLowerCase().includes(filterText.toString().toLowerCase()))
//   );
  
  let filteredItems = data.filter(
    (item) =>
      (item.tirePosition || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.tireSize || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.tireMakeLabel || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.tirePattern || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase()) ||
      (item.tireSerialNo || "")
        .toString()
        .toLowerCase()
        .includes(filterText.toString().toLowerCase())
  );

  console.log("filterd", filteredItems);

  const newArray = filteredItems.map((item) => {
    const newItem = { ...item };

    // Remove the fields "mileagePerMm" and "tireType" from the copy
    delete newItem.rowId;
    delete newItem.tireType;
    delete newItem.tireMake;
    delete newItem.actionButtons;
    delete newItem.inspectionDetailIndex;

    return newItem;

  });

  console.log("neew",newArray);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function convertArrayOfObjectsToCSV(array) {
let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(data[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
  }
  
  const handleDownloadExcel = (dataSource, sheetName, fileName) => {
    const ws = utils.json_to_sheet(dataSource);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, `${fileName}.xlsx`);
  };

      const downloadExcel = () => {
        handleDownloadExcel(newArray, "Detail Report ", "CSV");
      };


// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
// function downloadCSV(array) {
// 	const link = document.createElement('a');
// 	let csv = convertArrayOfObjectsToCSV(array);
// 	if (csv == null) return;

// 	const filename = 'export.csv';

// 	if (!csv.match(/^data:text\/csv/i)) {
// 		csv = `data:text/csv;charset=utf-8,${csv}`;
// 	}

// 	link.setAttribute('href', encodeURI(csv));
// link.setAttribute('download', filename);
// link.click();
// }


// const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export to CSV</Button>;

//   const actionsMemo = React.useMemo(
//     () => <Export onExport={() => downloadCSV(data)} />,
//     []
//   );


  return (
    <>
      <Card>
        <Card.Body>
          <DataTable
            title="Details Report"
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            // actions={actionsMemo}
          />
          <Button
            type={"button"}
            style={{ position:"relative", left:"48%" }}
            className="btn-sm float-end mx-2 p-2"
            text="Excel"
            onClick={downloadExcel}
          >
            Export to CSV
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailsReport;
