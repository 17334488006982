import React ,{useEffect}from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ReportDetailsActionButtons = ({ reportDetails }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className='d-flex align-items-center'>
                {reportDetails.recommendationLabel && <Button 
                    variant="primary" size="sm" onClick={() => navigate(`/report/detail/${reportDetails.inspectionId}/analytical/${reportDetails.inspectionDetailIndex}`)} >
                    Analytical
                </Button>}
            </div>
        </>
    );
}

export default ReportDetailsActionButtons;