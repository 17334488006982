import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MasterDataMethod from "../../../asyncMethods/MasterDataMethod";
import MasterDataActionButtons from "../../../components/ActionButtons/MasterDataActionButtons";
import FilterComponent from "../../../components/FilterComponent/Index";

const columns = [
  {
    name: "Dropdown Name",
    selector: (row) => row?.dropDownId?.dropDownName,
    sortable: true
  },
  {
    name: "Data Key",
    selector: (row) => row?.dataKey,
    sortable: true
  },
  {
    name: "Data Label",
    selector: (row) => row?.dataLabel,
    sortable: true,

  },
  {
    name: "Data Value",
    selector: (row) => row?.dataValue,
    sortable: true,

  },
  {
    name: "Status",
    selector: (row) => row?.activeStatus,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true
  },
]

const MasterData = () => {
  const navigate = useNavigate();
  const masterDataMethod = MasterDataMethod();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Fetch All Master Data
  useEffect(() => {
    masterDataMethod.fetchAllMasterData();
  }, []);

  // Status Update

  const statusUpdate = (dataId, status) => {
    const data = {
      id: dataId,
      status: status == 1 ? 0 : 1
    }
    masterDataMethod.masterDataStatusUpdate(data);
  }

  const data = Array.from(useSelector((state) => state?.masterData?.masterDataList))
    .map((masterdata) => ({
      ...masterdata,
      actionButtons: (
        <MasterDataActionButtons
          onStatusUpdate={statusUpdate}
          masterDataDetails={masterdata}
        />
      ),
    }))
    .sort((b, a) => a.dataId - b.dataId);

  const filteredItems = data.filter(
    (item) =>
      (item?.dropDownId?.dropDownName.toLowerCase().includes(filterText.toLowerCase())) ||
      item.dataLabel.toString().toLowerCase().includes(filterText.toLowerCase()) || item.dataKey.toString().toLowerCase().includes(filterText.toLowerCase()) || item.dataValue.toString().toLowerCase().includes(filterText.toLowerCase())
  );


  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/master-data/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Data
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Master Data"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default MasterData;
