import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { FETCH_ALL_MASTER_DATA, FETCH_ALL_MASTER_DROP_DOWN_DATA, SET_EDIT_MASTER_DATA } from "../redux/constants/MasterDataType";

const MasterDataMethod = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const fetchAllMasterData = () => {
        return api.get("/masterservice/masterdatalist?page=0&size=0")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_MASTER_DATA,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const fetchAllDropDownData = () => {
        return api.get("/masterservice/getalldropdownmasterdata?page=0&size=0")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_MASTER_DROP_DOWN_DATA,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeMasterData = (data) => {
        return api.post("/masterservice/savemasterdatalistmaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Master-data created Successful.");
                navigate('/master-data');
                return;
            }
        }).catch(err => console.log(err));
    }

    const checkMasterListDuplicate = (dataKey, dataLabel, dataValue, dropDownId, data) => {
        return api.get(`masterservice/checkmasterdatalistduplicate?dataKey=${dataKey}&dataValue=${dataValue}&dataLabel=${dataLabel}&dropDownId=${dropDownId}`)
            .then(res => {
                if (res.data.statusCode == 200) {
                    storeMasterData(data);
                    return;
                } else if (res.data.statusCode == 500) {
                    toast.warn(res.data.statusDescription)
                }
            }).catch(err => {
                console.log(err);
            })
    }

    const fetchMasterData = (masterDataId = 1) => {
        return api.get(`/masterservice/getmasterdata?dataId=` + masterDataId)
            .then(res => {
                dispatch({
                    type: SET_EDIT_MASTER_DATA,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateMasterData = (userId, masterData) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updatemasterdatalistmaster?dataId=` + userId, masterData, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Master-Data updated Successful.");
                    navigate('/master-data');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const masterDataStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisablemasterdatalistmaster?dataId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllDropDownData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllMasterData,
        storeMasterData,
        fetchMasterData,
        updateMasterData,
        fetchAllDropDownData,
        checkMasterListDuplicate,
        masterDataStatusUpdate,
    };
}

export default MasterDataMethod