import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  FETCH_ALL_CVINSPECTION_TYPE,
  SET_EDIT_CVINSPECTION_TYPE
  //   SET_INSPECTION_DETAILS,
  //   FETCH_CLIENT_INSPECTION,
  //   FETCH_USER_INSPECTION,
  //   FETCH_LAST_INSPECTION,
} from "../redux/constants/CVTypes";
import { useNavigate } from "react-router-dom";
import api from "../api";

const CVMethods = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllCVInspections = () => {
    ///masterservice/getallclientvehicle?page=0&size=5000&sortBy=clientVehicleId
    return api
      .get(
        "/masterservice/getallclientvehicle?page=0&size=5000&sortBy=clientVehicleId"
      )
      .then(res => {
        dispatch({
          type: FETCH_ALL_CVINSPECTION_TYPE,
          payload: res.data.result
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const storeCVInspection = data => {
    return api
      .post("/masterservice/saveclientVehicle", data)
      .then(res => {
        if (res.data.statusCode == 201) {
          toast.success("Inspection created Successful.");
          navigate(`/client-vehicle-master`);
          return;
        } else {
          toast.error(res.data.statusDescription);
          return;
        }
      })
      .catch(err => console.log(err));
  };

  const fetchCVInspection = (inspectionId = 1) => {
    return api
      .get(
        `/masterservice/getclientvehiclebyid?clientVehicleId=` + inspectionId
      )
      .then(res => {
        dispatch({
          type: SET_EDIT_CVINSPECTION_TYPE,
          payload: res.data.result
        });
        return res.data.result;
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getVehicleByFilter = (
    vehicleManufacturer = 1,
    vehicleModel = 1,
    vehicleType = 1
  ) => {
    return api
      .get(
        `/masterservice/getvehiclebymultiplefilter?vehicleManufacturer=${vehicleManufacturer}&vehicleModel=${vehicleModel}&vehicleType=${vehicleType}`
      )
      .then(res => {
        return res.data.result;
      })
      .catch(err => {
        console.log(err);
      });
  };

  const updateCVInspection = (inspectionId, masterData) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return api
      .put(
        `/masterservice/updateClientvehicle?clientVehicleId=` + inspectionId,
        masterData,
        { headers }
      )
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("Inspection Data updated Successful.");
          navigate("/client-vehicle-master");
          return;
        }
        toast.error(res.data.statusDescription);
      })
      .catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      });
  };

  const cvinspectionStatusUpdate = ({ id, status }) => {
    const data = {
      activeStatus: status
    };
    const headers = {
      "Content-Type": "application/json"
    };
    return api
      .put(
        `/masterservice/enabledisableclientvehiclemaster?clientVehicleId=` + id,
        data,
        {
          headers
        }
      )
      .then(res => {
        toast.success("Status Updated Successful.");
        fetchAllCVInspections();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return {
    storeCVInspection,
    fetchCVInspection,
    fetchAllCVInspections,
    getVehicleByFilter,
    updateCVInspection,
    cvinspectionStatusUpdate
  };
};

export default CVMethods;
