import React, { useState, useEffect, useRef, useMemo } from "react";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_INSPECTION_DETAILS, SET_INSPECTION_DETAILS2 } from "../../../redux/constants/InspectionTypes";

import VehicleTireMakeDataMethod from "../../../asyncMethods/mst_action/VehicleTireMakeDataMethod";
import ObservationCategoryMaster from "../../../asyncMethods/ObservationCategoryMaster";
import OperationalMasterMethod from "../../../asyncMethods/OperationalMasterMethod";
import TireConfigurationMasterMethod from "../../../asyncMethods/TireConfigurationMasterMethod";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import { toast } from "react-toastify";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

const TableForm = () => {
  const parameters = useParams();
  const dispatch = useDispatch();
  const [selectedObservationCategory, setSelectedObservationCategory] =
    useState("normal");
  const [inspectionData, setInspectionData] = useState([]);
  const [tireData, setTireData] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [selectedCellIndex, setSelectedCellIndex] = useState(-1);
  const [selectedTireMake, setSelectedTireMake] = useState("");
  const [selectedTireSize, setSelectedTireSize] = useState("");
  const [selectedTirePattern, setSelectedTirePattern] = useState("");
  const [selectedTireThickness, setSelectedTireThickness] = useState(""); // State for tire thickness
  const [tableData, setTableData] = useState([]);
  const [tireThicknessValues, setTireThicknessValues] = useState([]);
  const [selectedAllOkRows, setSelectedAllOkRows] = useState([]);
  const [obb, setObb] = useState(0);
  const [obb2, setObb2] = useState(0);
  const [inspStat, setInspStat] = useState(1);
  const [noTire, setNoTire] = useState(0);
  const [noStep, setNostep] = useState(0);
  const [text1, setText] = useState("");
  const [selectedCell, setSelectedCell] = useState({
    rowIndex: null,
    columnIndex: null,
  });
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const[error1, setError1] = useState("");
  const [isError1, setIsError1] = useState(false);
  const[error2, setError2] = useState("");
  const [isError2, setIsError2] = useState(false);
  const[error3, setError3] = useState("");
  const [isError3, setIsError3] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);


  const tyreTypes = [
    {
      id: "1",
      label: "New",
    },
    {
      id: "2",
      label: "Retread",
    },
    {
      id: "3",
      label: "Transfred-N",
    },
    {
      id: "4",
      label: "Transfred-R",
    }
  ];

  const handleTextChange = (event) => {
    setText(event.target.textContent);
  };

  const inspectionMethods = InspectionMethods();
  const vehicleTireMakeMethod = VehicleTireMakeDataMethod();
  const observationCategoryMaster = ObservationCategoryMaster();
  const operationalMasterMethod = OperationalMasterMethod();
  const tireConfigurationMasterMethod = TireConfigurationMasterMethod();
  let inspectionDetails = Array.from(
    useSelector((state) => state.inspection.inspectionDetails)
  );
  console.log("til", inspectionDetails);

  let inspectionDetails2 = Array.from(
    useSelector((state) => state.inspection.inspectionDetails2)
  );

  console.log("til2222", inspectionDetails2);

  if(inspectionDetails == null || inspectionDetails[0]?.tirePosition == "" || inspectionDetails[0]?.tirePosition == null) {
    if(inspectionDetails2 && inspectionDetails2 != null && inspectionDetails2.length > 0) {
      inspectionDetails2 = inspectionDetails2 && inspectionDetails2.map(item => {
        const { rowId, ...rest } = item;
        return {
          ...rest,
          inspectionId: parameters.inspectionId
        };
      });
      if(inspectionDetails2.length < inspectionDetails.length){
        inspectionDetails = [
          ...inspectionDetails2,
          ...inspectionDetails.slice(inspectionDetails2.length, inspectionDetails.length)
        ]
      }
      else{
        inspectionDetails = inspectionDetails2;
      }
    }
  }
  // else{
  //   inspectionDetails = inspectionDetails;
  // }



  inspectionDetails = inspectionDetails && inspectionDetails.map(item => ({
    ...item,
    inspectionId: parameters.inspectionId
  }));

  console.log("tilffffff", inspectionDetails);


  const tireMake = Array.from(
    useSelector((state) => state?.vehicleTireMake?.vehicleTireMakeList)
  ).filter((s) => s.activeStatus == 1);
  const observationCategoryTypeOption = Array.from(
    useSelector(
      (state) => state?.observationCategoryMaster?.observationCategoryMasterList
    )
  ).filter((s) => s.activeStatus == 1);
  const observations = Array.from(
    useSelector((state) => state?.operational?.operationalMasterList)
  ).filter((s) => s.activeStatus == 1);

  // const tireConfigurationMaster = Array.from(
  //   useSelector(
  //     (state) => state?.tireConfigurationMaster?.tireConfigurationMasterList
  //   )
  // );

  const tireConfigurationMasterList = useSelector((state) => state?.tireConfigurationMaster?.tireConfigurationMasterList);
  
  const tireConfigurationMaster = useMemo(() => Array.from(tireConfigurationMasterList), [tireConfigurationMasterList]);


  const clientId = useSelector(
    (state) => state.inspection.editInspection.clientId
  );
  console.log("clientId", clientId);


  const vehicleReg = useSelector(
    (state) => state.inspection.editInspection.vehicleRegNumber
  );
  console.log("vehicleRegNumber", vehicleReg);

  console.log("tier red", tireConfigurationMaster);

  let lastDate = useSelector((state) => state.inspection.last);

  if (lastDate != null || lastDate != undefined) {
    lastDate = lastDate[0];
  } else {
    lastDate = "";
  }

  console.log("last", lastDate);


  useEffect(() => {
    if (clientId && vehicleReg) {
      inspectionMethods.fetchLastInspection(clientId, vehicleReg, parameters.inspectionId);
    }
  }, [clientId, vehicleReg, parameters.inspectionId]);


  useEffect(() => {
    if(inspectionDetails == null || inspectionDetails.length == 0){
      console.log("valllasld", inspectionDetails?.lastInspectionId);
      inspectionMethods.getVehicleInspectionDetails2(lastDate?.lastInspectionId)
      }
  },[lastDate])

  useEffect(() => {
    initInspectionDetails();
    inspStatus();
    observationCategoryMaster.fetchAllObservationCategoryMasterData();
    vehicleTireMakeMethod.fetchAllVehicleTireMakeData();
    operationalMasterMethod.fetchAllOperationalMasterData();
    tireConfigurationMasterMethod.fetchAllTireConfigurationMasterData();
  }, []);


  useEffect(() => {
    if (tireConfigurationMaster && tireConfigurationMaster.length) {
      const obj = {};

      for (const element of tireConfigurationMaster) {
        element.tireMake = element.tireMakeId.tireMakeId;
        if (!obj[element.tireMake]) {
          obj[element.tireMake] = {
            tireSize: [],
            [element.tireSize]: {
              tirePattern: [],
              tireThickness: [],
            },
          };
        }

        if (obj[element.tireMake].tireSize.indexOf(element.tireSize) === -1) {
          obj[element.tireMake].tireSize.push(element.tireSize);
          obj[element.tireMake][element.tireSize] = {
            tirePattern: [],
            tireThickness: [],
          };
        }

        if (
          obj[element.tireMake][element.tireSize].tirePattern.indexOf(
            element.tirePattern
          ) === -1
        ) {
          obj[element.tireMake][element.tireSize].tirePattern.push(
            element.tirePattern
          );
        }

        if (
          obj[element.tireMake][element.tireSize].tireThickness.indexOf(
            element.tireThickness
          ) === -1
        ) {
          obj[element.tireMake][element.tireSize].tireThickness.push(
            element.tireThickness
          );
        }
      }
      setTireData(obj);
    }
  }, [tireConfigurationMaster]);

  const handleTireMakeChange = (e) => {
    setSelectedTireSize("");
    setSelectedTirePattern("");


    const newSelectedTireMake = e.target.value;
    setSelectedTireMake(newSelectedTireMake);
    console.log("setMake: ", newSelectedTireMake);
    setTireThicknessValues(""); // Reset tire thickness when tire make changes
  };

  // Handler for tire size selection
  const handleTireSizeChange = (e, rowIndex) => {
    setSelectedTirePattern("");

    inspectionDetails[rowIndex].tirePattern = "";
    inspectionDetails[rowIndex].otdMm = "";


    const newSelectedTireSize = e.target.value;
    setSelectedTireSize(newSelectedTireSize);
    console.log("setMake 2: ", newSelectedTireSize);
    setTireThicknessValues(""); // Reset tire thickness when tire size changes
  };

  const handleTirePatternChange = (e, rowIndex) => {
    const newSelectedTirePattern = e.target.value;
    setSelectedTirePattern(newSelectedTirePattern);
  };

  // console.log("obb", observationCategoryTypeOption);
  // console.log("obb2", observations);

  useEffect(() => {
    const obbId = observationCategoryTypeOption.find(
      (o) => o.observationCategoryType == "ALL OK"
    );
    setObb(obbId?.observationCategoryId);
    // console.log(obbId?.observationCategoryId);
  }, [observationCategoryTypeOption]);

  useEffect(() => {
    const obbId = observations.find(
      (o) => o.observationCategoryType == "ALL OK"
    );
    setObb2(obbId?.observationId);
    // console.log(obbId?.observationId);
  }, [observations]);

  const handleAllOkChange = (e, rowIndex) => {
    const updatedDetails = [...inspectionDetails];
    updatedDetails[rowIndex].allOk = "ALL OK";

    if (e.target.checked) {
      // Add the rowIndex to the selectedAllOkRows state
      setSelectedAllOkRows((prevSelectedRows) => [
        ...prevSelectedRows,
        rowIndex,
      ]);

      if (obb && obb2) {
        const allOkObservationCategoryId = obb;
        const allobservationId = obb2;
        updatedDetails[rowIndex].observationCategory =
          allOkObservationCategoryId;
        updatedDetails[rowIndex].observation = allobservationId;
      }

      // Change the values of the select options to "All OK" when the checkbox is selected
      // const allOkObservationCategoryId = 14;
      // const allobservationId = 11;
      
      updatedDetails[rowIndex].recommendationLabel = "All Ok";
      updatedDetails[rowIndex].observationLabel = "All Ok";
      updatedDetails[rowIndex].observationCategoryLabel = "ALL OK";
    } else {
      // Remove the rowIndex from the selectedAllOkRows state
      setSelectedAllOkRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow !== rowIndex)
      );

      // If the checkbox is not checked, remove the 'allOk' property from the object
    }
    delete updatedDetails[rowIndex].allOk;
    
    console.log("ippp", updatedDetails);

    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: updatedDetails,
    });

    dispatch({
      type: SET_INSPECTION_DETAILS2,
      payload: updatedDetails,
    });

  };

  const totalCellsInRow = 24; // Change this to the actual number of cells
  const tableRef = useRef(null);

  

  useEffect(() => {
    const handleTabKey = (e) => {
      if (e.key === "Tab" || (e.key === "Tab" && e.shiftKey)) {
        // Calculate the index for the previous cell based on the shift key
        let nextCellIndex;

        if (e.shiftKey) {
          nextCellIndex = selectedCellIndex - 1;
          if (nextCellIndex < 0) {
            // If we're at the beginning of the row, move to the previous row
            setSelectedRowIndex(selectedRowIndex - 1);
            nextCellIndex = totalCellsInRow - 1;
          }
        } else {
          nextCellIndex = (selectedCellIndex + 1) % totalCellsInRow;

          if (nextCellIndex === 0) {
            // If we're at the end of the current row, move to the next row
            setSelectedRowIndex(selectedRowIndex + 1);
          }
        }

        setSelectedCellIndex(nextCellIndex);
        console.log("rowww", selectedRowIndex);
        console.log("Ceell",selectedCellIndex);

        // Find the selected cell's DOM element
        const selectedCell = tableRef.current.querySelectorAll(
          `tr:nth-child(${selectedRowIndex + 1}) td`
        )[nextCellIndex];

        // Scroll the table to bring the selected cell into view
        if (selectedCell) {
          const tableRect = tableRef.current.getBoundingClientRect();
          const cellRect = selectedCell.getBoundingClientRect();
          const scrollLeft = cellRect.left - tableRect.left;
          tableRef.current.scrollLeft = scrollLeft;
        }
      } else if (e.key === "Enter") {
        console.log("pressed Enter");
        e.preventDefault(); // Prevent the default Enter key behavior
        // Handle Enter key press as needed
      }
    };

    window.addEventListener("keydown", handleTabKey);

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, [selectedCellIndex, selectedRowIndex]);


  const initInspectionDetails = async () => {
    if (parameters.inspectionId) {
      const response = await inspectionMethods.getVehicleInspectionDetails(
        parameters.inspectionId
      );
      console.log("tilakResp" , response);
      prepareData(response);
    }

  };

  const inspStatus = async () => {
    if (parameters.inspectionId) {
      const res = await inspectionMethods.fetchInspection(
        parameters.inspectionId
      );
      console.log("status", res?.inspectionStatus);
      setInspStat(res?.inspectionStatus);
      setNoTire(res?.noOfTires);
      setNostep(res?.noOfTires);
    }
  }

  const handleErr = () => {
    toast.error("Cant save or draft after saved 1 time");
  }

  const validtes = () => {
    toast.error("Not valid Reg No.");
  };

  const validtes2 = () => {
    toast.error("Not valid ctMm, lstMm or RstMm.");
  };



  const disabledDate = (current) => {
    // Get today's date
    const today = moment().endOf("day");

    // Disable dates after today
    return current && current > today;
  };

  const prepareData = async (response) => {
    //console.log("tilakRes" + response);
    const inspectionEditData = await inspectionMethods.fetchInspection(
      parameters.inspectionId
    );

    // const updatedResponse = response.map(item => ({
    //   ...item,
    //   inspectionId: parameters.inspectionId
    // }));
    const rows = [];
    for (
      let index = response.length;
      index <
      Number(inspectionEditData.noOfStepneys) +
        Number(inspectionEditData.noOfTires);
      index++
    ) {
      rows.push({
        inspectionId: parameters.inspectionId,
        inspectionDetailIndex: index + 1,
        tirePosition: "",
        tireType: "",
        tireTypeLabel: "",
        tireMake: "",
        tireMakeLabel: "",
        tireOriginalFitmentDate: "",
        tireSerialNo: "",
        // "otdProjectedMileage": "4",
        leastTireThicknessAllowed: "3",
        odometerReadingWhenFitted: "",
        tireSize: "",
        tirePattern: "",
        tireRemovalDate: "",
        odometerReadingWhenRemoved: "",
        recoPressure: "",
        obsPressure: "",
        otdMm: "",
        lstMm: "",
        ctMm: "",
        rstMm: "",
        observationCategory: "",
        observationCategoryLabel: "",
        observation: "",
        observationLabel: "",
        recommendationLabel: "",
        tireImageUrl: "",
        remark: "",
      });
    }
    const data = [...response, ...rows];
    console.log("tialk" , data);
    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: data,
    });
  };


  const handleChange = (e, date, dateString, i, labelType) => {
    if (labelType === "tireRemovalDate") {
      if (dateString) {
        const parts = dateString.split("/");
        inspectionDetails[
          i
        ].tireRemovalDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      } else {
        // Handle the case where the date is cleared or empty
        inspectionDetails[i].tireRemovalDate = null; // or an appropriate value
      }
    } else if (labelType === "tireOriginalFitmentDate") {
      if (dateString) {
        const parts = dateString.split("/");
        inspectionDetails[
          i
        ].tireOriginalFitmentDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      } else {
        // Handle the case where the date is cleared or empty
        inspectionDetails[i].tireOriginalFitmentDate = null; // or an appropriate value
      }
    } else if (e && e.target.name) {
      if (e.target.name === "odometerReadingWhenFitted") {
        const newValue = e.target.value.trim();

        if (newValue === "" || newValue === "0") {
          inspectionDetails[i][e.target.name] = 0;
        } else {
          inspectionDetails[i][e.target.name] = parseInt(newValue);
        }
      } else if (e.target.name === "tireSerialNo") {
        const inputValue = e.target.value;
        let error = ""; // Initialize error as an empty string

        const capitalizedValue = inputValue.toUpperCase();
        const isValid = /^[A-Z0-9]*$/.test(capitalizedValue); // Check for letters and numbers only

        if (isValid) {
          // Clear any previous error message
          // Update the value in the inspectionDetails
          inspectionDetails[i][e.target.name] = capitalizedValue;
          error = "";
          setError(error);
          setIsError(false);
        } else {
          inspectionDetails[i][e.target.name] = capitalizedValue;
          const error =
            "Tire Sr.No should have no spaces and no special characters";
          setError(error);
          setIsError(true);
        }
      }
      // else if (e.target.name === "otdMm") {
      //   const otdMmValue = e.target.value;
      //   const rstMmValue = inspectionDetails[i].rstMm;
      //   const lstMmValue = inspectionDetails[i].lstMm;
      //   const ctMmValue = inspectionDetails[i].ctMm;

      //   let error1 = "";

      //   if (
      //     otdMmValue < rstMmValue ||
      //     otdMmValue < lstMmValue ||
      //     otdMmValue < ctMmValue
      //   ) {
      //     // Display an error message or handle the validation as needed
      //     inspectionDetails[i][e.target.name] = otdMmValue;
      //     error1 = "";
      //     setError1(error1);
      //     setIsError1(false);
      //   } else {
      //     inspectionDetails[i][e.target.name] = otdMmValue;
      //     const error1 = "Should be less than or equal to otdmm";
      //     setError1(error1);
      //     setIsError1(true);
      //   }
      // }
      else if (e.target.name === "rstMm") {
        const currentField = e.target.name;
        const currentInputValue = e.target.value;
        const otdMmValue = inspectionDetails[i].otdMm;

        let error1 = "";

        if (currentInputValue <= otdMmValue) {
          // Display an error message or handle the validation as needed
          inspectionDetails[i][e.target.name] = currentInputValue;
          error1 = "";
          setError1(error1);
          setIsError1(false);
        } else {
          inspectionDetails[i][e.target.name] = currentInputValue;
          const error1 = "Should be less than or equal to otdmm";
          setError1(error1);
          setIsError1(true);
        }
      } else if (e.target.name === "ctMm") {
        const currentField = e.target.name;
        const currentInputValue = e.target.value;
        const otdMmValue = inspectionDetails[i].otdMm;

        let error2 = "";

        if (currentInputValue <= otdMmValue) {
          inspectionDetails[i][e.target.name] = currentInputValue;

          error2 = "";
          setError2(error2);
          setIsError2(false);
        } else {
          inspectionDetails[i][e.target.name] = currentInputValue;

          const error2 = "Should be less than or equal to otdmm";
          setError2(error2);
          setIsError2(true);
        }
      } else if (e.target.name === "lstMm") {
        const currentField = e.target.name;
        const currentInputValue = e.target.value;
        const otdMmValue = inspectionDetails[i].otdMm;

        let error3 = "";

        if (currentInputValue <= otdMmValue) {
          inspectionDetails[i][e.target.name] = currentInputValue;

          error3 = "";
          setError3(error3);
          setIsError3(false);
        } else {
          inspectionDetails[i][e.target.name] = currentInputValue;

          const error3 = "Should be less than or equal to otdmm";
          setError3(error3);
          setIsError3(true);
        }
      } else {
        inspectionDetails[i][e.target.name] = e.target.value;
      }

     // Remove the 'allOk' property if it exists
     if (inspectionDetails[i].hasOwnProperty("allOk")) {
       delete inspectionDetails[i].allOk;
     }

     switch (labelType) {
       case "tireTypeLabel":
         let selectedType = tyreTypes.find((t) => t.id == e.target.value);
         inspectionDetails[i][labelType] = selectedType.label;
         setSelectedTirePattern("");
         break;
       case "tireMakeLabel":
         inspectionDetails[i].otdMm = "";
         inspectionDetails[i].tireSize = "";
         inspectionDetails[i].tirePattern = "";
         let selectedMake = tireMake.find(
           (t) => t.tireMakeId == e.target.value
         );
         inspectionDetails[i][labelType] = selectedMake.tireMakeName;
         setSelectedTirePattern("");
         break;
       case "observationCategoryLabel":
         let selectedCategory = observationCategoryTypeOption.find(
           (t) => t.observationCategoryId == e.target.value
         );
         inspectionDetails[i][labelType] =
           selectedCategory.observationCategoryType;
         break;
       case "observationLabel":
         let selectedObservation = observations.find(
           (t) => t.observationId == e.target.value
         );
         inspectionDetails[i][labelType] = selectedObservation.observationName;
         inspectionDetails[i]["recommendationLabel"] =
           selectedObservation.observationRecommendation;
         break;
       case "tirePattern":
         const newSelectedTirePattern = e.target.value;
         setSelectedTirePattern(newSelectedTirePattern);

         // Find the corresponding tire configuration based on selected options
         const matchingTireConfig = tireConfigurationMaster.find(
           (config) =>
             config.tireMakeId.tireMakeId === parseInt(selectedTireMake) &&
             config.tireSize === selectedTireSize &&
             config.tirePattern === newSelectedTirePattern
         );

         if (matchingTireConfig) {
           const matchedTireThickness = matchingTireConfig.tireThickness;

           // Update the tire thickness value for the specific row
           const updatedTireThicknessValues = [...tireThicknessValues];
           updatedTireThicknessValues[i] = matchedTireThickness;
           setTireThicknessValues(updatedTireThicknessValues);

           // Update the otdMm value for the specific row
           const updatedInspectionDetails = [...inspectionDetails];
           updatedInspectionDetails[i].otdMm = matchedTireThickness;
           dispatch({
             type: SET_INSPECTION_DETAILS,
             payload: updatedInspectionDetails,
           });
         }
         break;

       default:
         break;
     }
   }

    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: inspectionDetails,
    });

    console.log("dispatch", inspectionDetails);
  };

  onsubmit = (type) => {
    const filterdata = inspectionDetails
      .map((i) => {
        // Check if odometerReadingWhenFitted is null or empty, then set it to 0
        if (
          i.odometerReadingWhenFitted === null ||
          i.odometerReadingWhenFitted === ""
        ) {
          i.odometerReadingWhenFitted = 0;
        }

        if (
          i.odometerReadingWhenFitted === null ||
          i.odometerReadingWhenFitted === ""
        ) {
          i.odometerReadingWhenFitted = 0;
        }

        if (
          i.observationCategory === ""
        ) {
          i.observationCategory = 14;
        }

        if (
          i.observationCategoryLabel === ""
        ) {
          i.observationCategoryLabel = "ALL OK";
        }

        if (
          i.observation === ""
        ) {
          i.observation = 11;
        }

        if (
          i.observationLabel === ""
        ) {
          i.observationLabel = "ALL OK";
        }

        if (
          i.recommendationLabel === ""
        ) {
          i.recommendationLabel = "ALL OK";
        }
        return i;
      })
      .filter(
        (i) =>
          (i.tirePosition &&
            i.tireType &&
            i.tireTypeLabel &&
            i.tireMake &&
            i.tireMakeLabel &&
            i.leastTireThicknessAllowed &&
            i.tireSize &&
            i.tirePattern &&
            i.recoPressure !== null && i.recoPressure !== "" &&
            i.obsPressure !== null && i.obsPressure !== "" &&
            i.otdMm !== null && i.otdMm !== "" &&  // Updated condition
    i.lstMm !== null && i.lstMm !== "" &&  // Updated condition
    i.ctMm !== null && i.ctMm !== "" &&  // Updated condition
    i.rstMm !== null && i.rstMm !== "" &&  // Updated condition
            i.observationCategory &&
            i.observationCategoryLabel &&
            i.observation &&
            i.observationLabel &&
            i.odometerReadingWhenFitted !== null &&
            i.odometerReadingWhenFitted !== "" &&
            i.recommendationLabel) ||
          i.odometerReadingWhenRemoved ||
          i.tireOriginalFitmentDate ||
          i.tireSerialNo ||
          i.tireImageUrl ||
          i.remark
      );

      if(filterdata.length > noTire + noStep){
        toast.error("Payload data is high");
      }
      else{
    inspectionMethods.saveVehicleInspectionDetails(
      filterdata,
      type,
      parameters.inspectionId
    );
    }

    console.log("filterData", filterdata);
  };

  const onFileSelect = async (e, i) => {
    const nextCellIndex = 23; // Or the cell index you want to focus on next
    setSelectedCellIndex(nextCellIndex);
    setSelectedRowIndex(i);
    
    let formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("key", "inspection-tires");
    formData.append("uniqueKey", parameters.inspectionId);

    const response = await inspectionMethods.uploadFile(formData);
    inspectionDetails[i].tireImageUrl = response;
    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: inspectionDetails,
    });

    
    // const nextSelectedCell = tableRef.current.querySelectorAll(
    //   `tr:nth-child(${selectedRowIndex + 1}) td`
    // )[nextCellIndex];
    // nextSelectedCell.focus();
  };


  const removeImage = (i) => {
    inspectionDetails[i].tireImageUrl = "";
    dispatch({
      type: SET_INSPECTION_DETAILS,
      payload: inspectionDetails,
    });
  };

  const handleClick1 = () => {
    if (isButtonClicked) {
      return;
    }

    // Set the state to indicate that the button is clicked
    setIsButtonClicked(true);

    // Your API call logic goes here
    // For the sake of the example, let's assume there's an asynchronous function called makeApiCall
    onsubmit(2)
      .then(() => {
        // Reset the state after 2 seconds
        setTimeout(() => {
          setIsButtonClicked(false);
        }, 4000);
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error('API call failed:', error);
        setIsButtonClicked(false);
      });
  };

  const handleClick2 = () => {
    if (isButtonClicked) {
      return;
    }

    // Set the state to indicate that the button is clicked
    setIsButtonClicked(true);

    // Your API call logic goes here
    // For the sake of the example, let's assume there's an asynchronous function called makeApiCall
    onsubmit(1)
      .then(() => {
        // Reset the state after 2 seconds
        setTimeout(() => {
          setIsButtonClicked(false);
        }, 4000);
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error('API call failed:', error);
        setIsButtonClicked(false);
      });
  };



  // console.log("69", inspectionDetails);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Form>
              <div className="table-responsive form-group">
                <table
                  className="table-bordered inspectionTableForm"
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th>Tire Position</th>
                      <th>New / Retread</th>
                      <th>Tire Make</th>
                      <th>Tire Size</th>
                      <th>Tire Pattern</th>
                      <th>Tire Sr. No</th>
                      <th>OTD mm</th>
                      <th>RST mm</th>
                      <th>CT mm</th>
                      <th>LST mm</th>
                      <th>Reco. Pressure</th>
                      <th>Obs. Pressure</th>
                      <th>All Ok</th>
                      <th>Observation Category</th>
                      <th>Observation</th>
                      <th>Recommendation</th>
                      <th>Original Fitment Date</th>
                      <th>Odometer Reading when fitted</th>
                      <th>Least Tire Thickness Allowed mm</th>
                      <th>Tire Removal Date</th>
                      <th>Odometer Reading when removed</th>
                      <th>Remark / Reason for Removal</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-nowrap">
                    {inspectionDetails && inspectionDetails.map((item, i) => (
                      <tr scope="row" key={i}>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 0
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(0);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="text"
                            className="form-control table-input"
                            // className={
                            //   selectedRowIndex === i && selectedCellIndex === 0
                            //     ? "selected-cell"
                            //     : ""
                            // }
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(0);
                            }}
                            placeholder="Tire Position"
                            value={item.tirePosition}
                            name="tirePosition"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 1
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(1);
                          // }}
                        >
                          <select
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            className="form-control table-input"
                            value={item.tireType}
                            name="tireType"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(1);
                            }}
                            onChange={(e) =>
                              handleChange(e, null, null, i, "tireTypeLabel")
                            }
                          >
                            <option value={""}>select</option>
                            {tyreTypes.map((tire, index) => (
                              <option key={tire.id} value={tire.id}>
                                {tire.label}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 2
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(2);
                          // }}
                        >
                          <select
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            className="form-control table-input"
                            defaultValue="Apollo"
                            value={item.tireMake}
                            name="tireMake"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(2);
                            }}
                            onChange={(e) => {
                              handleTireMakeChange(e, i);
                              handleChange(e, null, null, i, "tireMakeLabel");
                            }}
                          >
                            <option value={""}>select</option>
                            {tireMake.map((tire, index) => (
                              <option
                                key={tire.tireMakeId}
                                value={tire.tireMakeId}
                              >
                                {tire.tireMakeName}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 3
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(3);
                          // }}
                        >
                          <select
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            className="form-control table-input"
                            defaultValue=""
                            value={item.tireSize}
                            name="tireSize"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(3);
                            }}
                            onChange={(e) => {
                              handleTireSizeChange(e, i);
                              handleChange(e, null, null, i);
                            }}
                          >
                            <option value={""}>select</option>
                            {tireData[item.tireMake] &&
                              tireData[item.tireMake].tireSize &&
                              tireData[item.tireMake].tireSize.map(
                                (tire, index) => (
                                  <option key={tire} value={tire}>
                                    {tire}
                                  </option>
                                )
                              )}
                          </select>
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 4
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(4);
                          // }}
                        >
                          <select
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            className="form-control table-input"
                            defaultValue=""
                            value={item.tirePattern}
                            name="tirePattern"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(4);
                            }}
                            onChange={(e) => {
                              handleTirePatternChange(e, i);
                              handleChange(e, null, null, i, "tirePattern");
                            }}
                          >
                            <option value={""}>select</option>
                            {tireData[item.tireMake] &&
                              tireData[item.tireMake][item.tireSize] &&
                              tireData[item.tireMake][item.tireSize]
                                .tirePattern &&
                              tireData[item.tireMake][
                                item.tireSize
                              ].tirePattern.map((tire, index) => (
                                <option key={tire} value={tire}>
                                  {tire}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 5
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(5);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="text"
                            className="form-control table-input"
                            placeholder="Tire Sr.No"
                            value={item.tireSerialNo}
                            name="tireSerialNo"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(5);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                          {selectedRowIndex === i &&
                            selectedCellIndex === 5 &&
                            error && <p style={{ color: "red" }}>{error}</p>}
                        </td>
                        {/* <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 6
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(6);
                          }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="OTD mm"
                            value={item.otdMm}
                            name="otdMm"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td> */}
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 6
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(6);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="OTD mm"
                            value={
                              selectedTirePattern != "" &&
                              tireThicknessValues[i] != undefined
                                ? tireThicknessValues[i]
                                : item.otdMm != undefined && item.otdMm != ""
                                ? item.otdMm
                                : ""
                            }
                            name="otdMm"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(6);
                            }}
                            onChange={(e) => {
                              handleChange(e, null, null, i);
                            }}
                            readOnly
                          />
                        </td>

                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 7
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(7);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="RST mm"
                            value={item.rstMm}
                            name="rstMm"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(7);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                          {selectedRowIndex === i &&
                            selectedCellIndex === 7 &&
                            error1 && <p style={{ color: "red" }}>{error1}</p>}
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 8
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(8);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="CT mm"
                            value={item.ctMm}
                            name="ctMm"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(8);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                          {selectedRowIndex === i &&
                            selectedCellIndex === 8 &&
                            error2 && <p style={{ color: "red" }}>{error2}</p>}
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 9
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(9);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="LST mm"
                            value={item.lstMm}
                            name="lstMm"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(9);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                          {selectedRowIndex === i &&
                            selectedCellIndex === 9 &&
                            error3 && <p style={{ color: "red" }}>{error3}</p>}
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 10
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(10);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="Reco.Pressure"
                            value={item.recoPressure}
                            name="recoPressure"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(10);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 11
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(11);
                          // }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="Obs. Pressure"
                            value={item.obsPressure}
                            name="obsPressure"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(11);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 12
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(12);
                          // }}
                        >
                          <label
                            style={{ marginBottom: "-1rem" }}
                            className="checkbox-container"
                          >
                            <input
                              style={{ marginRight: "1rem" }}
                              type="checkbox"
                              className="form-control table-input"
                              placeholder="All ok"
                              name="allOk"
                              checked={item.allOk}
                              onClick={() => {
                                setSelectedRowIndex(i);
                                setSelectedCellIndex(12);
                              }}
                              onChange={(e) => handleAllOkChange(e, i)}
                            />
                          </label>
                          All OK
                        </td>
                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }} // Set cell style
                          className={
                            selectedRowIndex === i && selectedCellIndex === 13
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(13);
                          // }}
                        >
                          {selectedAllOkRows.includes(i) ? (
                            <select
                              style={{
                                width: "100%",
                                minHeight: "5rem",
                              }}
                              className="form-control table-input"
                              value={"ALL OK"}
                              name="observationCategory"
                              onClick={() => {
                                setSelectedRowIndex(i);
                                setSelectedCellIndex(13);
                              }}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationCategoryLabel"
                                )
                              }
                            >
                              <option value={"ALL OK"}>ALL OK</option>
                            </select>
                          ) : (
                            <select
                              style={{
                                width: "100%",
                                minHeight: "5rem",
                              }}
                              className="form-control table-input"
                              value={item.observationCategory}
                              name="observationCategory"
                              onClick={() => {
                                setSelectedRowIndex(i);
                                setSelectedCellIndex(13);
                              }}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationCategoryLabel"
                                )
                              }
                            >
                              <option value={""}>select</option>
                              {observationCategoryTypeOption?.map(
                                (data, index) => (
                                  <option
                                    key={data.observationCategoryId}
                                    value={data.observationCategoryId}
                                    data-dataid={data.observationCategoryId}
                                  >
                                    {data.observationCategoryType}
                                  </option>
                                )
                              )}
                            </select>
                          )}
                        </td>

                        <td
                          style={{ padding: 3, margin: 0, height: "100%" }}
                          className={
                            selectedRowIndex === i && selectedCellIndex === 14
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(14);
                          // }}
                        >
                          {selectedAllOkRows.includes(i) ? (
                            <select
                              style={{
                                width: "100%",
                                minHeight: "5rem",
                              }}
                              name="observation"
                              className="form-control table-input"
                              value={"All Ok"}
                              onClick={() => {
                                setSelectedRowIndex(i);
                                setSelectedCellIndex(14);
                              }}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationLabel"
                                )
                              }
                            >
                              <option value={"All Ok"}>All Ok</option>
                              {/* {observations
                                ?.filter(
                                  (data) =>
                                    data.observationCategoryType ===
                                    item.observationCategoryLabel // Filter observations based on selected observationCategoryLabel
                                )
                                .map((data, index) => (
                                  <option
                                    key={data.observationId}
                                    value={data.observationId}
                                    data-dataid={data.observationId}
                                  >
                                    {data.observationName}
                                  </option>
                                ))} */}
                            </select>
                          ) : (
                            <select
                              style={{
                                width: "100%",
                                minHeight: "5rem",
                              }}
                              name="observation"
                              className="form-control table-input"
                              value={item.observation}
                              onClick={() => {
                                setSelectedRowIndex(i);
                                setSelectedCellIndex(14);
                              }}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  i,
                                  "observationLabel"
                                )
                              }
                              disabled={
                                item.observationCategoryLabel.toLowerCase() ===
                                "normal"
                              }
                            >
                              <option value={""}>select</option>
                              {observations
                                ?.filter(
                                  (data) =>
                                    data.observationCategoryType ===
                                    item.observationCategoryLabel // Filter observations based on selected observationCategoryLabel
                                )
                                .map((data, index) => (
                                  <option
                                    key={data.observationId}
                                    value={data.observationId}
                                    data-dataid={data.observationId}
                                  >
                                    {data.observationName}
                                  </option>
                                ))}
                            </select>
                          )}
                        </td>

                        <td
                        // className={
                        //   selectedRowIndex === i && selectedCellIndex === 15
                        //     ? "selected-cell"
                        //     : ""
                        // }
                        // onClick={() => {
                        //   setSelectedRowIndex(i);
                        //   setSelectedCellIndex(15);
                        // }}
                        >
                          {selectedAllOkRows.includes(i) ? (
                            <p>All Ok</p>
                          ) : (
                            <p>{item.recommendationLabel}</p>
                          )}
                        </td>

                        {/* <td>
                          <input
                            type="date"
                            className="form-control table-input"
                            placeholder="Original Fitment Date"
                            value={moment(item.tireOriginalFitmentDate).format(
                              "YYYY-MM-DD"
                            )}
                            name="tireOriginalFitmentDate"
                            onChange={(e) => handleChange(e, i)}
                          />
                        </td> */}
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 15
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(15);
                          // }}
                        >
                          {item.tireOriginalFitmentDate
                            ? dayjs(item.tireOriginalFitmentDate).format(
                                "DD/MM/YYYY"
                              )
                            : ""}{" "}
                          <DatePicker
                            format={dateFormatList}
                            style={{ width: "12rem", minHeight: "max-content" }}
                            disabledDate={disabledDate}
                            // value={
                            //   item.tireOriginalFitmentDate
                            //     ? dayjs(item.tireOriginalFitmentDate).format(
                            //         "DD-MM-YYYY"
                            //       )
                            //     : null
                            // }
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(15);
                            }}
                            onChange={(date, dateString) =>
                              handleChange(
                                null,
                                date,
                                dateString,
                                i,
                                "tireOriginalFitmentDate"
                              )
                            }
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 16
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(16);
                          // }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Odometer Reading when fitted"
                            defaultValue={0}
                            value={item.odometerReadingWhenFitted}
                            name="odometerReadingWhenFitted"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(16);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>
                        {/* <td><input type="number" className="form-control table-input" placeholder="OTD Projected Mileage (Kms)" value={item.otdProjectedMileage} name="otdProjectedMileage" onChange={(e) => handleChange(e, i)} /></td>                                                 */}
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 17
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(17);
                          // }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Least Tire Thickness Allowed mm"
                            defaultValue={3}
                            value={3}
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(17);
                            }}
                            name="leastTireThicknessAllowed"
                          />
                        </td>
                        {/* <td key={i}><input type="text" className="form-control table-input" placeholder="Tire Pattern" value={item.tirePattern} name="tirePattern" onChange={(e) => handleChange(e, i)} /></td> */}

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 18
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(18);
                          // }}
                        >
                          {item.tireRemovalDate
                            ? dayjs(item.tireRemovalDate).format("DD/MM/YYYY")
                            : ""}{" "}
                          <DatePicker
                            format={dateFormatList}
                            style={{ width: "12rem", minHeight: "max-content" }}
                            disabledDate={disabledDate}
                            // value={
                            //   item.tireOriginalFitmentDate
                            //     ? dayjs(item.tireOriginalFitmentDate).format(
                            //         "DD-MM-YYYY"
                            //       )
                            //     : null
                            // }
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(18);
                            }}
                            onChange={(date, dateString) =>
                              handleChange(
                                null,
                                date,
                                dateString,
                                i,
                                "tireRemovalDate"
                              )
                            }
                          />
                        </td>
                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 19
                              ? "selected-cell"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(19);
                          // }}
                        >
                          <input
                            type="number"
                            className="form-control table-input"
                            placeholder="Odometer Reading when removed"
                            value={item.odometerReadingWhenRemoved}
                            name="odometerReadingWhenRemoved"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(19);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td>

                        {/* <td
                          style={{ padding: 3, margin: 0, height: "100%" }}
                          className={
                            selectedRowIndex === i && selectedCellIndex === 20
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(20);
                          }}
                        >
                          <input
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            type="number"
                            className="form-control table-input"
                            placeholder="Current Tire Life"
                            value={item.currentTireLife}
                            name="currentTireLife"
                            onChange={(e) => handleChange(e, null, null, i)}
                          />
                        </td> */}

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 20
                              ? "selected-cell2"
                              : ""
                          }
                          // onClick={() => {
                          //   setSelectedRowIndex(i);
                          //   setSelectedCellIndex(20);
                          // }}
                        >
                          <textarea
                            id="items"
                            rows="2"
                            cols="40"
                            style={{
                              whiteSpace: "nowrap",
                              overflowY: "hidden",
                              resize: "horizontal",
                            }}
                            className="form-control table-input"
                            placeholder="Remark / Reason for Removal"
                            value={item.remark}
                            name="remark"
                            onClick={() => {
                              setSelectedRowIndex(i);
                              setSelectedCellIndex(20);
                            }}
                            onChange={(e) => handleChange(e, null, null, i)}
                          >
                            Example text Example text Example text Example text
                            Example text Example text Example text Example text
                          </textarea>
                        </td>

                        {/* <div
                          className={
                            selectedRowIndex === i && selectedCellIndex === 20
                              ? "selected-cell2"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(20);
                          }}
                        >
                          <td
                            style={{
                              width: "500px",
                              overflowX: "scroll",
                              padding: "0",
                              margin: "0",
                              position: "relative",
                            }}
                          >
                            <input
                              style={{
                                minWidth: "100%",
                                padding: "0",
                                margin: "1rem 0 0 0",
                                border: "none", // Remove borders if necessary
                                outline: "none", // Remove outlines if necessary
                                background: "none", // Remove background if necessary
                                wordWrap: "normal",
                                
                                // Prevent text from wrapping
                              }}
                              type="text"
                              className="form-control table-input"
                              placeholder="Remark"
                              value={item.remark}
                              name="remark"
                              onChange={(e) => handleChange(e, null, null, i)}
                            />

                            <p
                              style={{
                                width: "500px",
                                textOverflow: "clip",
                                padding: "0",
                                margin: "0",
                                visibility: "hidden",
                                fontSize: "16px",
                              }}
                              // name="remark"
                              // contentEditable
                              // onInput={handleTextChange}
                            >
                              sssssssssssssssssssssssssfffdfsdfsddsfssssssssssssssssssssssssssssssss
                              + {item.remark}
                            </p>
                          </td>
                        </div> */}

                        <td
                          className={
                            selectedRowIndex === i && selectedCellIndex === 21
                              ? "selected-cell"
                              : ""
                          }
                          onClick={() => {
                            setSelectedRowIndex(i);
                            setSelectedCellIndex(21);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === " " || e.key === "Spacebar") {
                              e.preventDefault(); // Prevent the default behavior
                            }
                          }}
                          tabIndex={0} // Add this to make the <td> element focusable
                        >
                          {!item.tireImageUrl && (
                            <div className="upload-btn-wrapper">
                              <button className="btn-fill btn btn-info pointer">
                                Upload
                              </button>
                              <input
                                type="file"
                                name="myfile"
                                onChange={(e) => onFileSelect(e, i)}
                              />
                            </div>
                          )}
                          {item.tireImageUrl && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onFocus={() => {
                                const nextCellIndex = 23; // Or the cell index you want to focus on next
                                setSelectedCellIndex(nextCellIndex);
                                setSelectedRowIndex(i);
                              }}
                            >
                              {item.tireImageUrl.split("/").pop()}{" "}
                              <i
                                className="fa fa-close"
                                onClick={(e) => removeImage(i)}
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center m-3">
                  <button
                    type="button"
                    className="btn-fill btn btn-info m-3"
                    onClick={() => {
                      inspStat == 2 ? isError ? validtes() : (isError1 || isError2 || isError3) ? validtes2(): handleClick1(2) : handleErr();
                    }}
                    disabled={isButtonClicked}

                  >
                    Draft
                  </button>
                  <button
                    type="button"
                    className="btn-fill btn btn-info m-3"
                    onClick={() => {
                      inspStat == 2
                        ? isError
                          ? validtes()
                          : (isError1 || isError2 || isError3) ?
                            validtes2()
                          : handleClick2(1)
                        : handleErr();
                    }}
                    disabled={isButtonClicked}
                    // onClick={() => {
                    //   onsubmit(1);
                    // }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableForm;

