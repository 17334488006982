import { SET_VEHICLE_MANUFACTURE } from "../constants/VehicleActionTypes";
import { FETCH_ALL_VEHICLE, SET_EDIT_VEHICLE, SET_VEHICLE_SUB_TYPE_OPTIONS, SET_VEHICLE_TYPE_OPTIONS, SET_VEHICLE_USAGE_OPTIONS } from "../constants/VehicleActionTypes"

const initialState = {
  vehicleList: {},
  editVehicle: {},
  vehicleTypeOptions: {},
  vehiclesubTypeOptions: {},
  vehicleManufactureOptions: {},
  vehicleUsageOptions: {},
};

export const VehicleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_ALL_VEHICLE:
        return {
          ...state,
          vehicleList: payload?.content,
        };
      case SET_EDIT_VEHICLE:
        return {
          ...state,
          editVehicle: payload,
        };
      case SET_VEHICLE_TYPE_OPTIONS:
        return {
          ...state,
          vehicleTypeOptions: payload?.content,
        };
      case SET_VEHICLE_SUB_TYPE_OPTIONS:
        return {
          ...state,
          vehiclesubTypeOptions: payload?.content,
        };

      case SET_VEHICLE_MANUFACTURE:
        return {
          ...state,
          vehicleManufactureOptions: payload?.content,
        };
      case SET_VEHICLE_USAGE_OPTIONS:
        return {
          ...state,
          vehicleUsageOptions: payload?.content,
        };

      default:
        return state;
    }
}