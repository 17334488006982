import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MasterTypeMethod from "../../../asyncMethods/MasterTypeMethod";
import MasterTypeActionButtons from "../../../components/ActionButtons/MasterTypeActionButtons";
import FilterComponent from "../../../components/FilterComponent/Index";

const columns = [
  {
    name: "Dropdown Name",
    selector: (row) => row?.dropDownName,
    sortable: true
  },
  {
    name: "Dropdown Key",
    selector: (row) => row?.dropDownKey,
    sortable: true
  },
  {
    name: "Parent DropDown",
    selector: (row) => row?.parentDropDownId,
    sortable: true,

  },
  {
    name: "Status",
    selector: (row) => row?.activeStatus,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true
  },
]

const MasterType = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const masterTypeMethod = MasterTypeMethod();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Fetch All Master Type
  useEffect(() => {
    masterTypeMethod.fetchAllMasterType();
  }, []);

  // Status Update
  const statusUpdate = (dropDownId, status) => {
    const data = {
      id: dropDownId,
      status: status == 1 ? 0 : 1
    }
    masterTypeMethod.masterTypeStatusUpdate(data);
  }

  const data = Array.from(useSelector((state) => state?.masterType?.masterTypeList))
    .map((masterType) => ({
      ...masterType,
      actionButtons: (
        <MasterTypeActionButtons
          onStatusUpdate={statusUpdate}
          masterTypeDetails={masterType}
        />
      ),
    }))
    .sort((b, a) => a.dropDownId - b.dropDownId);

  const filteredItems = data.filter(
    (item) =>
      (item.dropDownName.toLowerCase().includes(filterText.toLowerCase())) ||
      item.dropDownKey.toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/master-type/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Type
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Types"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default MasterType;
