import { FETCH_ALL_TIRE_CONFIGURATION_MASTER_TYPE, SET_EDIT_TIRE_CONFIGURATION_MASTER_TYPE } from "../constants/TireConfigurationTypes"

const initialState = {
    tireConfigurationMasterList: [],
    editTireConfigurationMaster: {},
}

export const TireConfigurationMasterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_TIRE_CONFIGURATION_MASTER_TYPE:
            return {
                ...state,
                tireConfigurationMasterList: payload?.content
            }
        case SET_EDIT_TIRE_CONFIGURATION_MASTER_TYPE:
            return {
                ...state,
                editTireConfigurationMaster: payload
            }
        default:
            return state
    }
}