import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Select from "../../../components/DropdownField/Select";
import TireConfigurationMasterMethod from "../../../asyncMethods/TireConfigurationMasterMethod";
import TextInputField from "../../../components/InputField/TextInputField";
import { useSelector } from "react-redux";
import VehicleTireMakeDataMethod from "../../../asyncMethods/mst_action/VehicleTireMakeDataMethod";

const schema = Yup.object({
  tireMakeId: Yup.number().required("Required Field !"),
  tireType: Yup.string().required("Required Field !"),
  tireSize: Yup.string().required("Required Field !"),
  tirePattern: Yup.string().required("Required Field !"),
  tireThickness: Yup.number().optional(),
  activeStatus: Yup.number().required("Required Field !"),
});

const CreateTireConfigurationMaster = () => {
  const navigate = useNavigate();
  const parameters = useParams();

  const tireConfigurationMasterMethod = TireConfigurationMasterMethod();
  const vehicleTireMakeDataMethod = VehicleTireMakeDataMethod();
  const editTireConfigurationData = useSelector(
    (state) => state.tireConfigurationMaster.editTireConfigurationMaster
  );

  const tireMakerOptions = Array.from(
    useSelector((state) => state?.vehicleTireMake?.vehicleTireMakeList)
  ).filter((s) => s.activeStatus == 1);

  const [initialValues, setInitialValues] = useState({
    tireMakeId: "",
    tireType: "",
    tireSize: "",
    tirePattern: "",
    tireThickness: "",
    activeStatus: 1,
  });

  useEffect(() => {
    if (parameters.tireConfigurationId) {
      tireConfigurationMasterMethod.fetchTireConfigurationMasterData(
        parameters.tireConfigurationId
      );
    }
  }, []);

  useEffect(() => {
    vehicleTireMakeDataMethod.fetchAllVehicleTireMakeData();
  }, []);

  // Edit Tire Data
  useEffect(() => {
    if (
      parameters.tireConfigurationId &&
      editTireConfigurationData.tireConfigurationId ==
        parameters.tireConfigurationId
    ) {
      editTireConfigurationData.tireMakeId =
        editTireConfigurationData.tireMakeId.tireMakeId;
      setInitialValues(editTireConfigurationData);
    }
  }, [editTireConfigurationData]);

  const onSubmit = (data) => {
    if (parameters.tireConfigurationId) {
      tireConfigurationMasterMethod.updateTireConfigurationMasterData(
        parameters.tireConfigurationId,
        data
      );
    } else {
      tireConfigurationMasterMethod.storeTireConfigurationMasterData(data);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        console.log("saveOperationalData:", values);
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { errors, touched } = formik;
        return (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5>{""}</h5>
              <Button
                onClick={() => navigate("/mst-tire-configuration")}
                variant="primary"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </Button>
            </div>
            <Card>
              <Card.Body>
                <Form>
                  <div className="m-3">
                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label={"Tire Make"}
                        name={"tireMakeId"}
                        classProps="col-md-12"
                      >
                        <option value={""}>select</option>
                        {tireMakerOptions?.map((item, index) => (
                          <option key={index} value={item.tireMakeId}>
                            {item.tireMakeName}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label={"Tire Type"}
                        name={"tireType"}
                        classProps="col-md-12"
                      >
                        <option value={""}>select</option>
                        <option value={"1"}>Tube</option>
                        <option value={"2"}>Tubeless</option>
                      </Select>
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Tire Size"}
                        name={"tireSize"}
                        classProps={"col-md-12"}
                      />
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Tire Pattern"}
                        name={"tirePattern"}
                        classProps={"col-md-12"}
                      />
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Tire Thickness"}
                        name={"tireThickness"}
                        classProps={"col-md-12"}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center m-3">
                    <button
                      type="submit"
                      className="btn-fill btn btn-info my-3"
                    >
                      {parameters.tireConfigurationId ? "Update" : "Add"} Tire
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateTireConfigurationMaster;
