import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import VehicleSubTypeActionButtons from "../../../../components/ActionButtons/mst_button/VehicleSubTypeActionButtons";
import VehicleSubTypeDataMethod from "../../../../asyncMethods/mst_action/VehicleSubTypeDataMethod";
import { useSelector } from "react-redux";

const columns = [
  {
    name: "Vehicle Sub Type",
    selector: (row) => row.vehicleSubTypeName,
    sortable: true,
  },
  {
    name: "Vehicle Type",
    selector: (row) => row.vehicleTypeId?.vehicleTypeName,
    sortable: true,
  },
  {
    name: "No. of Tyres",
    selector: (row) => row.noOfTires,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.activeStatus,
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row?.actionButtons,
    center: true,
  },
];

const VehicleSubTypeData = () => {
  const navigate = useNavigate();
  const vehicleSubTypeDataMethod = VehicleSubTypeDataMethod();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    vehicleSubTypeDataMethod.fetchAllVehicleSubTypeList();
  }, []);

  const statusUpdate = (vehicleSubTypeId, status) => {
    const data = {
      id: vehicleSubTypeId,
      status: status == 1 ? 0 : 1,
    };
    vehicleSubTypeDataMethod.vehicleSubTypeStatusUpdate(data);
  };
  useSelector((state) => {
    console.log(
      "🚀 ~ file: index.js:53 ~ useSelector ~ state?.vehicleSubType?.vehicleSubTypeList:",
      state
    );
  });

  const data = Array.from(
    useSelector((state) => state?.vehicleSubType?.vehicleSubTypeList)
  )
    .map((vehicleSubTypeDetails) => ({
      ...vehicleSubTypeDetails,
      actionButtons: (
        <VehicleSubTypeActionButtons
          onStatusUpdate={statusUpdate}
          vehicleSubTypeDetails={vehicleSubTypeDetails}
        />
      ),
    }))
    .sort((b, a) => a.vehicleSubTypeId - b.vehicleSubTypeId);
  console.log("🚀 ~ file: index.js:62 ~ VehicleSubTypeData ~ data:", data);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <h5></h5>
        <Button
          onClick={() => navigate("/mst-vehicle-sub-type/create")}
          variant="primary"
        >
          <i className="fa-solid fa-plus"></i> New Vehicle Sub Type
        </Button>
      </div>
      <Card>
        <Card.Body>
          <DataTable
            title="Vehicle SubType List"
            columns={columns}
            data={data}
            pagination
            subHeader
            persistTableHead
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default VehicleSubTypeData;
