import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { FETCH_ALL_VEHICLE_TIRE_MAKE, SET_EDIT_VEHICLE_TIRE_MAKE } from "../../redux/constants/mst_type/VehicleTireMakeTypes";

const VehicleTireMakeDataMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllVehicleTireMakeData = () => {
        return api.get("/masterservice/tiremaketypemasterdata?page=0&size=10&sortBy=tireMakeId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_VEHICLE_TIRE_MAKE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeVehicleTireMakeData = (data) => {
        return api.post("/masterservice/savetiremakemaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Tire Make Created Successful.");
                navigate('/mst-tire-make');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchVehicleTireMakeData = (tireMakeId = 1) => {
        return api.get(`/masterservice/tiremakebyid?tireMakeId=` + tireMakeId)
            .then(res => {
                dispatch({
                    type: SET_EDIT_VEHICLE_TIRE_MAKE,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateVehicleTireMakeData = (tireMakeId, data) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updatetiremakemaster?tireMakeId=` + tireMakeId, data, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Tire Make Data updated Successful.");
                    navigate('/mst-tire-make');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const vehicleTireMakeStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisabletiremakemaster?tireMakeId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllVehicleTireMakeData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllVehicleTireMakeData,
        fetchVehicleTireMakeData,
        storeVehicleTireMakeData,
        updateVehicleTireMakeData,
        vehicleTireMakeStatusUpdate
    };
};

export default VehicleTireMakeDataMethod;
