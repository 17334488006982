import {
  FETCH_ALL_CVINSPECTION_TYPE,
  SET_EDIT_CVINSPECTION_TYPE,
  SET_CVINSPECTION_DETAILS,
  FETCH_CLIENT_CVINSPECTION,
  FETCH_USER_CVINSPECTION,
  FETCH_LAST_CVINSPECTION,
} from "../constants/CVTypes";

const initialState = {
  CVinspectionList: [],
  editCVInspection: {},
  CVinspectionDetails: [],
};

export const CVInspectionReducer = (state = initialState, { type, payload }) => {
  if (type === SET_CVINSPECTION_DETAILS) {
    console.log(
      "🚀 ~ file: InspectionReducer.js:10 ~ InspectionReducer ~ payload:",
      payload
    );
  }
  switch (type) {
    case FETCH_ALL_CVINSPECTION_TYPE:
      return {
        ...state,
        CVinspectionList: payload?.content,
      };
    case SET_EDIT_CVINSPECTION_TYPE:
      return {
        ...state,
        editCVInspection: payload,
      };
    case SET_CVINSPECTION_DETAILS:
      return {
        ...state,
        CVinspectionDetails: payload,
      };
    case FETCH_CLIENT_CVINSPECTION:
      return {
        ...state,
        CVinspectionList: payload,
      };
    case FETCH_USER_CVINSPECTION:
      return {
        ...state,
        CVinspectionList: payload,
      };
    default:
      return state;
  }
};
