import { useController } from "react-hook-form";

const InputField = ({ control, name, label, type,showPassword,handlePassword,passField, classProps,value, ...props }) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className={classProps}>
      <label htmlFor={field.name}>{label}</label>
      <input
        id={field.name}
        {...props}
        type = {type}
        className="form-control"
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={value}
        name={field.name}
        ref={field.ref}
        autoComplete="off"
      />
       {
           passField ? <span className="eye-icon">
           <i className={showPassword ? "fa-solid fa-eye " : "fa-solid fa-eye-slash "} onClick={handlePassword}></i>
         </span> : ""
        
            }
      {fieldState.error ? (
        <div className="invalid-feedback d-block">
          {fieldState.error?.message}
        </div>
      ) : null}
    </div>
  );
};


const InputField2 = ({ control, name, label, type,showPassword,handlePassword,passField, classProps,value, ...props }) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className={classProps}>
      <label style={{ display:"none" }} htmlFor={field.name}>{label}</label>
      <input
        id={field.name}
        {...props}
        type = {type}
        className="form-control"
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={value}
        name={field.name}
        ref={field.ref}
        autoComplete="off"
      />
       {
           passField ? <span className="eye-icon">
           <i className={showPassword ? "fa-solid fa-eye " : "fa-solid fa-eye-slash "} onClick={handlePassword}></i>
         </span> : ""
        
            }
      {fieldState.error ? (
        <div className="invalid-feedback d-block">
          {fieldState.error?.message}
        </div>
      ) : null}
    </div>
  );
};


export { InputField, InputField2 };
export default InputField; // Default export

