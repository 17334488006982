import { FETCH_ALL_VEHICLE_MANUFACTURE, SET_EDIT_VEHICLE_MANUFACTURE } from "../../constants/mst_type/VehicleManufactureTypes";

const initialState = {
    vehicleManufactureList: {},
    editVehicleManufacture: {},
};

export const VehicleManufactureReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_VEHICLE_MANUFACTURE:
            return {
                ...state,
                vehicleManufactureList: payload?.content,
            };
        case SET_EDIT_VEHICLE_MANUFACTURE:
            return {
                ...state,
                editVehicleManufacture: payload,
            };

        default:
            return state;
    }
}