import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const VehicleUsageActionButton = ({ vehicleUsageDetails, onStatusUpdate }) => {

    const navigate = useNavigate();

    const redirectToEditPage = userId => {
        navigate("/mst-vehicle-usage/edit/" + vehicleUsageDetails.vehicleUsageId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(vehicleUsageDetails.vehicleUsageId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>{" "}
                <Form.Check
                    inline
                    className={'mx-3 text-danger'}
                    type="switch"
                    value={vehicleUsageDetails.activeStatus === 1 ? 1 : 0}
                    checked={vehicleUsageDetails.activeStatus === 1 ? true : false}
                    id={'toggle-button' + vehicleUsageDetails.vehicleUsageId}
                    onChange={(e) => { onStatusUpdate(vehicleUsageDetails.vehicleUsageId, e.target.value) }}
                />
            </div>
        </>
    );
}

export default VehicleUsageActionButton;