import { FETCH_ALL_OBSERVATION_CATEGORY_MASTER, SET_EDIT_OBSERVATION_CATEGORY_MASTER } from "../constants/ObservationCategoryMasterTypes"

const initialState = {
    observationCategoryMasterList: [],
    editObservationCategoryMasterList: {},
}

export const ObservationCategoryMasterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_OBSERVATION_CATEGORY_MASTER:
            return {
                ...state,
                observationCategoryMasterList: payload?.content
            }
        case SET_EDIT_OBSERVATION_CATEGORY_MASTER:
            return {
                ...state,
                editObservationCategoryMasterList: payload
            }
        default:
            return state
    }
}