import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import VehicleTypeActionButtons from '../../../../components/ActionButtons/mst_button/VehicleTypeActionButtons';
import VehicleTypeDataMethod from "../../../../asyncMethods/mst_action/VehicleTypeDataMethod";
import { useSelector } from 'react-redux';

const columns = [
    {
        name: "Vehicle Type Name",
        selector: (row) => row.vehicleTypeName,
        sortable: true
    },

    {
        name: "Status",
        selector: (row) => row.activeStatus,
        sortable: true
    },
    {
        name: "Action",
        selector: (row) => row?.actionButtons,
        center: true
    },
];

const VehicleTypeData = () => {
    const navigate = useNavigate();
    const vehicleTypeDataMethod = VehicleTypeDataMethod();
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        vehicleTypeDataMethod.fetchAllVehicleTypeData();
    }, []);

    const statusUpdate = (vehicleTypeId, status) => {
        const data = {
            id: vehicleTypeId,
            status: status == 1 ? 0 : 1
        }
        vehicleTypeDataMethod.vehicleTypeStatusUpdate(data);
    }

    const data = Array.from(useSelector((state) => state?.vehicleType?.vehicleTypeList))
    .map((vehicleTypeDetails) => ({
        ...vehicleTypeDetails,
        actionButtons: (
            <VehicleTypeActionButtons
                onStatusUpdate={statusUpdate}
                vehicleTypeDetails={vehicleTypeDetails}
            />
        ),
    }))
    .sort((b, a) => a.vehicleTypeId - b.vehicleTypeId);

    return (
        <>
            <div className="d-flex justify-content-between m-3">
                <h5></h5>
                <Button
                    onClick={() => navigate("/mst-vehicle-type/create")}
                    variant="primary"
                >
                    <i className="fa-solid fa-plus"></i> New Vehicle Type
                </Button>
            </div>
            <Card>
                <Card.Body>
                    <DataTable
                        title="Vehicle Type List"
                        columns={columns}
                        data={data}
                        pagination
                        subHeader
                        persistTableHead
                        paginationResetDefaultPage={resetPaginationToggle}
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default VehicleTypeData
