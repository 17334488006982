import { FETCH_ALL_MASTER_TYPE, SET_EDIT_MASTER_TYPE } from "../constants/MasterTypeTypes"

const initialState = {
    masterTypeList: [],
    editMasterType: {},
}

export const MasterTypeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_MASTER_TYPE:
            return {
                ...state,
                masterTypeList: payload?.content
            }
        case SET_EDIT_MASTER_TYPE:
            return {
                ...state,
                editMasterType: payload
            }
        default:
            return state
    }
}