import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import routes from "./routes";
import { Provider } from "react-redux";
import {store} from "./redux/store";

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer/>
      <RouterProvider router={router}/>
    </Provider>
  );
};

export default App;
