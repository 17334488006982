import { useDispatch } from "react-redux";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FETCH_ALL_OBSERVATION_CATEGORY_MASTER, SET_EDIT_OBSERVATION_CATEGORY_MASTER } from "../redux/constants/ObservationCategoryMasterTypes";

const ObservationCategoryMasterMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();;

    const fetchAllObservationCategoryMasterData = () => {
        return api.get("/masterservice/getallcategoryobservtion?page=0&size=10000&sortBy=observationCategoryId")
            .then(res => {
                dispatch({
                    type: FETCH_ALL_OBSERVATION_CATEGORY_MASTER,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const storeObservationCategoryMasterData = (data) => {
        return api.post("/masterservice/saveobservationcategorymaster", data).then(res => {
            if (res.data.statusCode == 201) {
                toast.success("Observation Category Master Created Successful.");
                navigate('/mst-observation-category');
                return;
            }
        }).catch(err => console.log(err));
    }

    const fetchObservationCategoryMasterData = (observationCategoryId = 1) => {
        return api.get(`/masterservice/getobservationcategorydatabyid?observationCategoryId=` + observationCategoryId)
            .then(res => {
                dispatch({
                    type: SET_EDIT_OBSERVATION_CATEGORY_MASTER,
                    payload: res.data.result
                });
            }).catch(err => {
                console.log(err);
            })
    }

    const updateObservationCategoryMasterData = (observationCategoryId, observationCategoryData) => {
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/updateobservationcategorymaster?observationCategoryId=` + observationCategoryId, observationCategoryData, { headers })
            .then(res => {
                if (res.data.statusCode == 200) {
                    toast.success("Observation Category Master Data Updated Successful.");
                    navigate('/mst-observation-category');
                    return;
                }
                toast.error(res.data.statusDescription);
            }).catch(err => {
                console.log(err);
                toast.error("Fetch Failed.");
            })
    }

    const observationCategoryMasterStatusUpdate = ({ id, status }) => {
        const data = {
            "activeStatus": status
        }
        const headers = {
            "Content-Type": "application/json"
        };
        return api.put(`/masterservice/enabledisableobservationcategorymaster?observationCategoryId=` + id, data, { headers })
            .then(res => {
                toast.success("Status Updated Successful.");
                fetchAllObservationCategoryMasterData();
            }).catch(err => {
                console.log(err);
            })
    }

    return {
        fetchAllObservationCategoryMasterData,
        storeObservationCategoryMasterData,
        fetchObservationCategoryMasterData,
        updateObservationCategoryMasterData,
        observationCategoryMasterStatusUpdate
    };
};

export default ObservationCategoryMasterMethod;
