import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FETCH_ALL_USERS,
  FETCH_ROLE_OPTIONS,
  FETCH_STATUS_OPTIONS,
  SET_EDIT_USER
} from "../redux/constants/AuthActionTypes";
import api from "../api";
import { userLogin } from "../redux/actions/UserActions";

const UserMethods = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = data => {
    const payload = {
      password: data.password,
      isAdmin: data.isAdmin
    };

    if (/^\d{10}$/.test(data.username)) {
      payload.mobileNumber = data.username;
    } else {
      payload.email = data.username;
    }

    return api
      .post("/loginservice/login", payload)
      .then(res => {
        const result = res.data.result;
        console.log("res", result);
        if (result == null || result == undefined) {
          toast.error(res.data.statusDescription);
          return;
        } else if (Number(res.data.statusCode) === 401) {
          toast.error(res.data.statusDescription);
          return;
        } else {
          localStorage.setItem("user", JSON.stringify(res.data.result));
          dispatch(userLogin(result));

          navigate("/dashboard");
          fetchAllUsers();
          toast.success("Login Successful.");
        }
      })
      .catch(() => {
        toast.error("Login Failed.");
      });
  };

  const userLogout = () => {
    localStorage.clear();
    toast.success("Logout Successful.");
    navigate("/");
  };

  const storeUser = data => {
    return api
      .post("/registrationservice/registeruser", data)
      .then(res => {
        if (res.data.statusCode == 201) {
          toast.success("User created Successful.");
          navigate("/users");
          return;
        }
        alert(res.data.statusDescription);
      })
      .catch(err => console.log(err));
  };

  const fetchAllUsers = () => {
    return api
      .get("/registrationservice/getallusers?page=1&size=100000")
      .then(res => {
        dispatch({
          type: FETCH_ALL_USERS,
          payload: res.data.result.content
        });
        fetchUserOptions("roles");
        fetchUserOptions("userstatus");
        fetchUser();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchUser = (userId = 1) => {
    return api
      .get(`/registrationservice/getuserdetail?userId=` + userId)
      .then(res => {
        dispatch({
          type: SET_EDIT_USER,
          payload: res.data.result
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const updateUser = (userId, user) => {
    const data = {
      roleType: user.roleType,
      activeStatus: user.activeStatus,
      newPassword: user.newPassword,
      confirmPassword: user.confirmPassword
    };
    const headers = {
      "Content-Type": "application/json"
    };
    return api
      .put(`/registrationservice/updateuser?userId=` + userId, data, {
        headers
      })
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("User Updated Successful.");
          navigate("/users");
          return;
        }
        alert(res.data.statusDescription);
      })
      .catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      });
  };

  const userStatusUpdate = ({ id, status }) => {
    const data = {
      activeStatus: status
    };
    const headers = {
      "Content-Type": "application/json"
    };
    return api
      .put(`/registrationservice/enabledisableuser?userId=` + id, data, {
        headers
      })
      .then(res => {
        toast.success("Status Updated Successful.");
        fetchAllUsers();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchUserOptions = datatype => {
    const actionType =
      datatype === "roles" ? FETCH_ROLE_OPTIONS : FETCH_STATUS_OPTIONS;
    return api
      .get(
        "/masterservice/masterdatalist?datatype=" +
          datatype +
          "&parentId=0&activeStatus=1"
      )
      .then(res => {
        dispatch({
          type: actionType,
          payload: res.data.result
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return {
    login,
    userLogout,
    fetchAllUsers,
    storeUser,
    fetchUser,
    updateUser,
    fetchUserOptions,
    userStatusUpdate
  };
};

export default UserMethods;
