import React from "react";
import { ErrorMessage, Field } from "formik";

const TextInputField = ({
  errors,
  touched,
  type,
  label,
  name,
  required,
  readOnly,
  classProps,
  maxDate,
  handleChange,
  autoComplete,
  defaultValue,
}) => {
  const handleInput = (e) => {
    if (maxDate && e.target.value > maxDate) {
      e.target.value = maxDate;
    }
     if (handleChange) {
       handleChange(e);
     }
  };
  return (
    <>
      <div className={classProps}>
        {label && (
          <label htmlFor={name} className="text-label">
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <Field
          type={type}
          name={name}
          id={name}
          readOnly={readOnly}
          className={
            errors.name && touched.name
              ? "input-error form-control"
              : "form-control"
          }
          max={maxDate}
          defaultValue={defaultValue}
          onInput={handleInput}
          autoComplete={autoComplete}
        />
        <ErrorMessage
          name={name}
          component="span"
          className="error text-danger"
        />
      </div>
    </>
  );
};

export default TextInputField;
