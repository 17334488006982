import React from 'react';
import { Card } from "react-bootstrap";

const VisiterInspection = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h2">  <div className="row">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-6 d-flex align-items-center justify-content-center" >
                                        <div className='font-weight-bold'>MILAN TIRE SERVICE & SOLUTION</div>
                                    </div>
                                    <div className="col-lg-3 d-flex justify-content-end">
                                        <div><img src="/assets/images/logoCvCenter.png" alt="MTSS" /></div>
                                    </div>
                                </div></Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <h4 className='font-weight-normal d-flex justify-content-center'>VEHICLE CHECK SHEET</h4>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-12">
                                        <div className="text-end justify-content-between d-flex">

                                            <div className="visiter-form-details">
                                                <table style={{ width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <th className="text-center" style={{ width: "50px" }}>No.&nbsp;:</th>
                                                            <td><input /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div>
                                                <label><strong>Date:</strong></label> <input type="datetime-local" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-lg-4">
                                        <div className="visiter-form-details">
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <th className="text-center">Name & Address of<br />
                                                        Transport Company</th>
                                                    <td><select data-container="body">
                                                        <option>Balaji Transport Co.</option>
                                                        <option>Buraq Logistics</option>
                                                        <option>Sunil Transport</option>
                                                        <option>H S Roadlines</option>
                                                        <option>Allanasons Pvt Ltd </option>
                                                        <option>Pankaj Transport </option>
                                                        <option>Modern Travels </option>
                                                        <option>Dolphin Travels </option>
                                                        <option>Shree Krishna Transport </option>
                                                        <option>Sri Balaji Roadlines </option>
                                                        <option>Yuva Transport </option>
                                                        <option>Agrawal Roadlines Pvt Ltd </option>
                                                        <option>B.D Dhalla Transport Pvt Ltd </option>
                                                        <option>Kuldeep Transport </option>
                                                        <option>Lalsingh & Sons </option>
                                                        <option>TA Roadlines </option>
                                                        <option>Parmar Transport Co. </option>
                                                        <option>Pawan Roadlines </option>
                                                        <option>Incubate Consultancy Services </option>
                                                        <option>R.N Cabs Pvt Ltd </option>
                                                        <option>Simaran Transport Co. </option>
                                                        <option>Amritpal Roadlines </option>
                                                        <option>Sandhu Transport Co. </option>
                                                        <option>Kalpesh Roadlines Ltd </option>
                                                        <option>Satyabizcon India Pvt Ltd </option>
                                                        <option>New Gurupal Transport </option>
                                                        <option>Geetanjali Transline </option>
                                                        <option>Santkrupa Transport</option>
                                                        <option>MP Roadlines</option>
                                                        <option>Dosti Containers</option>
                                                        <option>JK Tyre & Industries</option>
                                                        <option>GD Travels</option>
                                                        <option>HTC Logistics</option>
                                                        <option>Excellent Transport</option>
                                                        <option>Sai Travels</option>
                                                        <option>Ujjwal Travels</option>
                                                        <option>Sunil Transport Service</option>

                                                    </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Mobile / Tel. No.</th>
                                                    <td><input /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="visiter-form-details">
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <th className="text-center">Registration No.</th>
                                                    <td><input /></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Vehicle Make/Model</th>
                                                    <td><input /></td>
                                                </tr>
                                            </table>
                                            <table style={{ width: "100%", marginTop: "5px" }}>
                                                <tr>
                                                    <th className="text-center">Vehicle Configuration</th>
                                                    <td><input /></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Odometer Reading</th>
                                                    <td><input /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="visiter-form-details observations">
                                            <table style={{ width: "100%" }}>
                                                <tr className="text-center">
                                                    <th colspan="7" height="40px">Observations</th>
                                                </tr>
                                                <tr>
                                                    <td colspan="1" height="40px"></td>
                                                    <td>F</td>
                                                    <td>R1</td>
                                                    <td>R2</td>
                                                    <td>TA1</td>
                                                    <td>TA2</td>
                                                    <td>TA3</td>
                                                </tr>

                                                <tr>
                                                    <td colspan="1" rowspan="1" width="200px" height="80px">Recommended air<br />
                                                        pressure</td>
                                                    <td rowspan="1"><input /></td>
                                                    <td rowspan="1"><input /></td>
                                                    <td><input /></td>
                                                    <td><input /></td>
                                                    <td><input /></td>
                                                    <td><input /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3 mt-5">
                                    <div className="col-lg-9 visiter">
                                        <div className="arrow-img-area"><img alt="" src="/assets/images/Arrow.png" /></div>
                                        <div className="row">
                                            <div className="col-lg-3 mt-5">
                                                <div className="visiter-form-detail mt-2">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <th className="text-center" width="160px" height="35px">Size & Brand</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center" height="35px">Pattern</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center" height="35px">Pressure</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center" height="35px">Tread Depth</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-center" height="35px">Tire Serial No.</th>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>1L</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "80%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>1R</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>Stepney</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>2LO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>2LI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "80%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>2RI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>2RO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>3LO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>3LI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "80%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>3RI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>3RO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>4LO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>4LI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "80%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>4RI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>4RO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>5LO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>5LI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "80%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>5RI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>5RO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>6LO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>6LI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "80%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>6RI</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="head-number text-center">
                                                    <h5>6RO</h5>
                                                </div>
                                                <div className="visiter-form-detail part-input">
                                                    <table style={{ width: "90%" }}>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 visiter mt-2 pl-0">
                                        <div className="visiter-form-detail observations mt-5 visiter-checkmark">
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td width="380px">Visual Checks</td>
                                                        <td width="135px">Ok</td>
                                                        <td width="100px">To<br />
                                                            Check</td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Ball Joint</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Tie Rod</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Steering Rod</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">King Pin</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Drag Link</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Clamp</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Center Bolt</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Bushing</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">U Bolt</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Leaf Condition</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Valve caps & Valve Extension</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Front Type Wear</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Rear Type Wear</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Air Pressure</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                    <tr>

                                                        <td rowspan="1">Cut on Tyre</td>
                                                        <td rowspan="1"><input type="checkbox" /></td>
                                                        <td><input type="checkbox" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 visiter">
                                    <div className="work-headd text-center mb-3">
                                        <h2>Alignment & Tyre Rotation Section</h2>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="visiter-form-details observations sugest">
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr className="text-center">
                                                                <th colspan="3" height="40px">Wheel Alignment</th>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="2" height="80px" rowspan="1" width="50px">Date:</td>
                                                                <td rowspan="1"><input type="datetime-local" /></td>

                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" height="80px" rowspan="1" width="50px">K.M.:</td>
                                                                <td rowspan="1"><input /></td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="visiter-form-details observations sugest">
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr className="text-center">
                                                                <th colspan="3" height="40px">Tyre Rotation</th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" height="80px" rowspan="1" width="50px">Date:</td>
                                                                <td rowspan="1"><input type="datetime-local" /></td>

                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" height="80px" rowspan="1" width="50px">K.M.:</td>
                                                                <td rowspan="1"><input /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="visiter mt-2">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="comment-part">
                                                    <div className="comment-head">
                                                        <h3>Technician Observations:</h3>
                                                    </div><textarea type="text"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="comment-part">
                                                    <div className="comment-head">
                                                        <h3>Technician Recommendations:</h3>
                                                    </div><textarea type="text"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="visiter mt-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="visiter-form-details observations sugest">
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="1" height="80px">Driver Name</td>
                                                                <td><input /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1" height="80px" rowspan="1" width="180px">Mob. No.</td>
                                                                <td rowspan="1"><input /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1" height="80px" rowspan="1" width="200px">Signature</td>
                                                                <td rowspan="1">
                                                                    <div className="signature-pad-container">
                                                                        <a id="clear_button">CLEAR</a>
                                                                        <canvas width="400"></canvas>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="visiter-form-details observations sugest">
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="1" height="80px">Maintenance Manager Name</td>
                                                                <td><input /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1" height="80px" rowspan="1" width="180px">Mob. No.</td>
                                                                <td rowspan="1"><input /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1" height="80px" rowspan="1" width="180px">Signature</td>
                                                                <td rowspan="1">
                                                                    <div className="signature-pad-container">
                                                                        <a id="clear_button">CLEAR</a>
                                                                        <canvas width="400"></canvas>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="visiter-form-details observations sugest">
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr className="text-center">
                                                                <th colspan="1" height="80px">Milan Tire Service & Solution</th>
                                                                <td rowspan="1"><input /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1" height="80px" rowspan="1" width="180px">Mob. No.</td>
                                                                <td rowspan="1"><input /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1" height="80px" rowspan="1" width="200px">Signature</td>
                                                                <td rowspan="1">
                                                                    <div className="signature-pad-container">
                                                                        <a id="clear_button">CLEAR</a>
                                                                        <canvas width="400"></canvas>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="visiter">
                                    <div className="container-fluid">
                                        <div className="d-flex justify-content-between align align-items-center">
                                            <div className="copy-1">
                                                <h4>White copy : For Customer</h4>
                                            </div><button ><a className="p-btn " href="#">Print </a></button>
                                            <div className="copy-1">
                                                <h4>Blue copy : For Service Center</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VisiterInspection;