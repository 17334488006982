import { FETCH_ALL_VEHICLE_USAGE, SET_EDIT_VEHICLE_USAGE } from "../../constants/mst_type/VehicleUsageTypes";

const initialState = {
    vehicleUsageList: {},
    editVehicleUsage: {},
};

export const VehicleUsageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_VEHICLE_USAGE:
            return {
                ...state,
                vehicleUsageList: payload?.content,
            };
        case SET_EDIT_VEHICLE_USAGE:
            return {
                ...state,
                editVehicleUsage: payload,
            };

        default:
            return state;
    }
}