// import React, { useState, useRef, useEffect } from "react";
// import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
// import { useSelector } from "react-redux";
// import { Form, Formik, useFormikContext } from "formik";
// import * as Yup from "yup";
// import { useLocation, useParams } from "react-router-dom";
// import TextInputField from "../../../../components/InputField/TextInputField";
// import Select from "../../../../components/DropdownField/Select";
// import VehicleManufactureDataMethod from "../../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
// import VehicleTypeDataMethod from "../../../../asyncMethods/mst_action/VehicleTypeDataMethod";
// import VehicleModelDataMethod from "../../../../asyncMethods/mst_action/VehicleModelDataMethod";
// import ClientMethods from "../../../../asyncMethods/ClientMethods";
// import InspectionMethods from "../../../../asyncMethods/InspectionMethods";
// import moment from "moment";
// import api from "../../../../api";

// const CVInspectionForm = () => {
//   const [vehicleRegNumber, setVehicleRegNumber] = useState("");
//   const [vehicleData, setVehicleData] = useState(null);
//   const [err, setErr] = useState(false);
//   const [nul, setNull] = useState(false);
//   const [selectedVehicles, setSelectedVehicles] = useState([]);
//   const [selectedVehicleManufacturer, setSelectedVehicleManufacturer] =
//     useState(null);
//   const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
//   const [selectedVehicleType, setSelectedVehicleType] = useState(null);
//   const [selectedVehicleManufacturerId, setSelectedVehicleManufacturerId] =
//     useState(null);
//   const [selectedVehicleModelId, setSelectedVehicleModelId] = useState(null);
//   const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);
//   const [selectedConfig, setSelectedConfig] = useState(null);

//   const parameters = useParams();
//   console.log("par", parameters);
//   const formRef = useRef();
//   const schema = Yup.object({
//     clientId: Yup.string().required("Client Name is required !"),
//     // clientInformation: Yup.string().optional(),
//     clientName: Yup.string().optional(),
//     // clientServiceLocationId: Yup.string().required(
//     //   "Client Location is required."
//     // ),
//     // inspectionDateTime: Yup.string().required("Date And Time is required !"),
//     vehicleRegNumber: Yup.string()
//       .required("Vehicle Reg No is required !")
//       .test(
//         "no-spaces-special-chars",
//         "Letter should be in capital.No spaces or special characters allowed",
//         (value) => {
//           // Test for spaces and special characters (only allowing capital letters and digits)
//           return /^[A-Z0-9]*$/.test(value);
//         }
//       ),
//     vehicleManufacturer: Yup.string().optional(),
//     vehicleManufacturerLabel: Yup.string().optional(),
//     vehicleModel: Yup.string().optional(),
//     vehicleType: Yup.string().optional(),
//     vehicleOdometerReading: Yup.string().required(
//       "Vehicle Odometer Reading is required !"
//     ),
//     vehicleId: Yup.string().required("Vehicle Id is required !"),
//     // noOfTires: Yup.string().optional(),
//     configuredVehicle: Yup.string().required(
//       "vehicleConfigurationKey is required !"
//     ),
//     // noOfStepneys: Yup.string().optional(),
//     // inspectionStatus: Yup.string().optional(),
//     // tireSequence: Yup.string().optional(),
//     // tireSequenceLabel: Yup.string().optional(),
//     // driverName: Yup.string().optional(),
//     // driverMobileNo: Yup.string().optional(),
//     // maintanaceManagerName: Yup.string().optional(),
//     // maintanaceManagerMobileNo: Yup.string().optional(),
//     // milanTireServiceAndSolutionName: Yup.string().optional(),
//     // milanTireServiceAndSolutionMobileNo: Yup.string().optional(),
//     // milanTireServiceAndSolutionName: Yup.string().optional(),
//     // milanTireServiceAndSolutionMobileNo: Yup.string().optional(),
//   });

//   const [initialValues, setInitialValues] = useState({
//     inspectionId: "",
//     clientId: "",
//     clientName: "",
//     // clientInformation: "",
//     // clientServiceLocationId: "",
//     // inspectionDateTime: "",
//     vehicleRegNumber: "",
//     //vehicleOdometerReading: "",
//     vehicleId: "",
//     // noOfTires: "",
//     configuredVehicle: "",
//     // noOfStepneys: "",
//     inspectionStatus: "1",
//     vehicleManufacturer: "",
//     vehicleManufacturerLabel: "",
//     vehicleModel: "",
//     vehicleType: "",
//     // tireSequence: "",
//     // tireSequenceLabel: "",
//     // driverName: "",
//     // driverMobileNo: "",
//     // maintanaceManagerName: "",
//     // maintanaceManagerMobileNo: "",
//     // milanTireServiceAndSolutionName: "",
//     // milanTireServiceAndSolutionMobileNo: "",
//     // mtssName: "",
//     // mtssMobileNo: "",
//   });

//   const [selectedClient, setSelectedClient] = useState([]);
//   const [serviceLocations, setServiceLocations] = useState([]);
//   const [date, setDate] = useState(getCurrentDateTime());

//   const vehicleManufactureDataMethod = VehicleManufactureDataMethod();
//   const vehicleTypeDataMethod = VehicleTypeDataMethod();
//   const vehicleModelDataMethod = VehicleModelDataMethod();
//   const clientMethods = ClientMethods();
//   const inspectionMethods = InspectionMethods();

//   const user = JSON.parse(localStorage.getItem("user"));
//   const userId = user?.userID;
//   const role = user?.roleType;

//   const vehicleManufactureOptions = Array.from(
//     useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList)
//   ).filter((s) => s.activeStatus == 1);
//   const vehicleTypeOptions = Array.from(
//     useSelector((state) => state?.vehicleType?.vehicleTypeList)
//   ).filter((s) => s.activeStatus == 1);
//   const vehicleModelOptions = Array.from(
//     useSelector((state) => state?.vehicleModel?.filterVehicleModelList)
//   ).filter((s) => s.activeStatus == 1);
//   let clients = Array.from(useSelector((state) => state?.client?.clientList));
//   if (role === "2" || role === 2) {
//     clients = clients.filter((client) => client.userId == userId);
//     console.log("client fil", clients);
//   }
//   const clientLocationArr = Array.from(
//     useSelector((state) => state?.client?.clientList)
//   );

//   function getCurrentDateTime() {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");
//     const hours = String(now.getHours()).padStart(2, "0");
//     const minutes = String(now.getMinutes()).padStart(2, "0");

//     return `${year}-${month}-${day}T${hours}:${minutes}`;
//   }

//   // Handle changes in the date input
//   function handleDateChange(e) {
//     const selectedDate = e.target.value;
//     if (selectedDate <= getCurrentDateTime()) {
//       setDate(selectedDate);
//     }
//   }
//   const handleVehicleRegNumberChange = (e) => {
//     // Update the vehicleRegNumber state when the input value changes
//     setVehicleRegNumber(e.target.value);
//     console.log(vehicleRegNumber);
//   };

//   console.log("seeee", selectedVehicles);

//   useEffect(() => {
//     vehicleManufactureDataMethod.fetchAllVehicleManufactureData();
//     vehicleTypeDataMethod.fetchAllVehicleTypeData();
//     vehicleModelDataMethod.fetchAllVehicleModelData();
//     clientMethods.fetchAllClients();

//     if (parameters.inspectionId) {
//       inspectionMethods.fetchInspection(parameters.inspectionId);
//     }
//   }, []);

//   const editInspectionData = useSelector(
//     (state) => state.inspection.editInspection
//   );

//   useEffect(() => {
//     if (
//       parameters.inspectionId &&
//       editInspectionData.inspectionId == parameters.inspectionId &&
//       clients &&
//       clients.length > 0
//     ) {
//       if (editInspectionData?.inspectionDateTime) {
//         editInspectionData.inspectionDateTime =
//           editInspectionData.inspectionDateTime.toString().slice(0, 16);
//       }
//       const selectedClient = clients?.find(
//         (c) => c.clientId == editInspectionData.clientId
//       );
//       if (selectedClient) {
//         setSelectedClient([selectedClient]);
//         setServiceLocations(selectedClient.serviceLocations);
//       }
//       editInspectionData.clientServiceLocationId = (
//         editInspectionData.clientServiceLocationId || ""
//       ).toString();
//       vehicleManufactureDataMethod.getVehicleByFilter(
//         editInspectionData.vehicleManufacturer
//       );
//       setInitialValues(editInspectionData);
//     }
//   }, [editInspectionData]);

//   const onSubmit = (data) => {
//     // if (parameters.inspectionId) {
//     //   inspectionMethods.updateInspection(parameters.inspectionId, data);
//     // } else {
//     //   inspectionMethods.storeInspection(data);
//     // }
//     console.log("this" , data);
//   };

//   const onSelectVehicle = async (data, setFieldValue, type) => {
//     if (data.vehicleManufacturer && data.vehicleModel && data.vehicleType) {
//       const response = await inspectionMethods.getVehicleByFilter(
//         data.vehicleManufacturer,
//         data.vehicleModel,
//         data.vehicleType
//       );
//       setFieldValue("vehicleId", response ? response.vehicleId : "");
//       setFieldValue(
//         "configuredVehicle",
//         response ? response.configuredVehicle : ""
//       );
//       // setFieldValue("noOfTires", response ? response.noOfTires : "");
//       // setFieldValue("noOfStepneys", response ? response.noOfStepneys : "");
//       // setFieldValue("tireSequence", response ? response.tireSequence : "");
//       // setFieldValue(
//       //   "tireSequenceLabel",
//       //   response ? response.vehicleSubTypeLabel : ""
//       // );
//     }

//     if (type === "vehicleManufacturer") {
//       const selectedManufacture = vehicleManufactureOptions.find(
//         (v) => v.vehicleManufacturerId == data.vehicleManufacturer
//       );
//       setFieldValue(
//         "vehicleManufacturerLabel",
//         selectedManufacture.vehicleManufacturerName
//       );
//       vehicleManufactureDataMethod.getVehicleByFilter(data.vehicleManufacturer);
//     }

//     if (type === "vehicleModel") {
//       const selectedModel = vehicleModelOptions.find(
//         (v) => v.vehicleModelId == data.vehicleModel
//       );
//       setFieldValue("vehicleModelLabel", selectedModel.vehicleModelName);
//     }

//     if (type === "vehicleType") {
//       const selectedType = vehicleTypeOptions.find(
//         (v) => v.vehicleTypeId == data.vehicleType
//       );
//       console.log(
//         "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
//         selectedType
//       );
//       setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
//     }

//     if (type === "vehicleType") {
//       const selectedType = vehicleTypeOptions.find(
//         (v) => v.vehicleTypeId == data.vehicleType
//       );
//       console.log(
//         "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
//         selectedType
//       );
//       setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
//     }
//   };

//   return (
//     <>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={schema}
//         enableReinitialize={true}
//         innerRef={formRef}
//         onSubmit={(values) => {
//           console.log("----------", values);
//           onSubmit(values);
//         }}
//       >
//         {(formik) => {
//           const { errors, touched, setFieldValue, setFieldTouched, values } =
//             formik;

//           return (
//             <>
//               <div className="container-fluid">
//                 <div className="row">
//                   <div className="col-12">
//                     <Form>
//                       {parameters?.inspectionId && (
//                         <div className="row mb-4">
//                           <div className="col-6">Inspection ID:</div>
//                           <div className="col-6 text-muted h-40">
//                             {values?.inspectionId ? values?.inspectionId : "-"}
//                           </div>
//                         </div>
//                       )}

//                       <div className="row mb-4">
//                         <div className="col-6">
//                           Client Name : {selectedClient.clientId}
//                         </div>
//                         <div className="col-6 text-muted">
//                           <Typeahead
//                             clearButton
//                             id="basic-typeahead-single"
//                             multiple={false}
//                             disabled={parameters.inspectionId}
//                             onChange={(selected) => {
//                               const value =
//                                 selected.length > 0 ? selected[0].clientId : "";
//                               setFieldValue("clientId", value);
//                               setFieldValue(
//                                 "clientName",
//                                 value ? selected[0].clientFullName : ""
//                               );
//                               setSelectedClient(selected);
//                               if (value) {
//                                 setFieldValue(
//                                   "clientInformation",
//                                   `${selected[0].clientCompanyName},${selected[0].clientMobileNo}`
//                                 );
//                                 setServiceLocations(
//                                   selected[0].serviceLocations
//                                 );
//                               } else {
//                                 setFieldValue("clientInformation", "");
//                                 setServiceLocations([]);
//                               }
//                             }}
//                             onBlur={(e) => setFieldTouched("clientId", true)}
//                             labelKey="clientFullName"
//                             options={clients}
//                             selected={selectedClient}
//                           />
//                           <div className="text-danger">
//                             {touched.clientId && errors.clientId}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="row mb-4">
//                         <div className="col-6">Vehicle Reg No :</div>
//                         <div
//                           style={{ position: "relative" }}
//                           className="col-6 text-muted"
//                         >
//                           <TextInputField
//                             errors={errors}
//                             touched={touched}
//                             type={"text"}
//                             label={false}
//                             name={"vehicleRegNumber"}
//                             handleChange={handleVehicleRegNumberChange} // Update user input state
//                             readOnly={parameters.inspectionId}
//                           />
//                         </div>
//                       </div>

//                       <div className="row mb-4">
//                         <div className="col-6">
//                           Vehicle Manufacturer (Master) :
//                         </div>
//                         <div className="col-6 text-muted">
//                           <Select
//                             errors={errors}
//                             touched={touched}
//                             label={false}
//                             readOnly={parameters.inspectionId}
//                             disabled={
//                               parameters.inspectionId ||
//                               selectedVehicleManufacturer
//                             }
//                             name="vehicleManufacturer"
//                             value={values.vehicleManufacturer}
//                             required={false}
//                             onChange={(e) => {
//                               setFieldValue(
//                                 "vehicleManufacturer",
//                                 e.target.value
//                               );
//                               values.vehicleManufacturer = e.target.value;
//                               onSelectVehicle(
//                                 values,
//                                 setFieldValue,
//                                 "vehicleManufacturer"
//                               );
//                               if (selectedVehicleManufacturer) {
//                                 setFieldValue(
//                                   "vehicleManufacturer",
//                                   selectedVehicleManufacturerId
//                                 );
//                                 values.vehicleManufacturer =
//                                   selectedVehicleManufacturerId; // Update the values object
//                                 values.vehicleManufacturerLabel =
//                                   selectedVehicleManufacturer;
//                                 values.configuredVehicle = selectedConfig;
//                               }
//                             }}
//                           >
//                             {!selectedVehicleManufacturer && (
//                               <option value="">select</option>
//                             )}

//                             {selectedVehicleManufacturer ? (
//                               <option
//                                 key={selectedVehicleManufacturerId}
//                                 value={selectedVehicleManufacturerId}
//                               >
//                                 {selectedVehicleManufacturer}
//                               </option>
//                             ) : (
//                               vehicleManufactureOptions?.map((data, index) => (
//                                 <>
//                                   <option
//                                     key={data.vehicleManufacturerId}
//                                     value={data.vehicleManufacturerId}
//                                   >
//                                     {data.vehicleManufacturerName}
//                                   </option>
//                                 </>
//                               ))
//                             )}
//                           </Select>
//                         </div>
//                       </div>

//                       <div className="row mb-4">
//                         <div className="col-6">Vehicle Model :</div>
//                         <div className="col-6 text-muted">
//                           <Select
//                             errors={errors}
//                             touched={touched}
//                             label={false}
//                             readOnly={parameters.inspectionId}
//                             name="vehicleModel"
//                             required={false}
//                             disabled={
//                               parameters.inspectionId || selectedVehicleModel
//                             }
//                             onChange={(e) => {
//                               setFieldValue("vehicleModel", e.target.value);
//                               if (selectedVehicleModel) {
//                                 setFieldValue(
//                                   "vehicleModel",
//                                   selectedVehicleModelId
//                                 );
//                                 values.vehicleModel = selectedVehicleModelId; // Update the values object
//                                 values.vehicleModelLabel = selectedVehicleModel;
//                               }
//                               values.vehicleModel = e.target.value;
//                               onSelectVehicle(
//                                 values,
//                                 setFieldValue,
//                                 "vehicleModel"
//                               );
//                             }}
//                           >
//                             {!selectedVehicleModel && (
//                               <option value="">select</option>
//                             )}

//                             {selectedVehicleModel ? (
//                               <option
//                                 key={selectedVehicleModelId}
//                                 value={selectedVehicleModel}
//                               >
//                                 {selectedVehicleModel}
//                               </option>
//                             ) : (
//                               vehicleModelOptions?.map((data, index) => (
//                                 <option
//                                   key={data.vehicleModelId}
//                                   value={data.vehicleModelId}
//                                   data-dataid={data.vehicleModelId}
//                                 >
//                                   {data.vehicleModelName}
//                                 </option>
//                               ))
//                             )}
//                           </Select>
//                         </div>
//                       </div>

//                       <div className="row mb-4">
//                         <div className="col-6">Vehicle type :</div>
//                         <div className="col-6 text-muted">
//                           <Select
//                             errors={errors}
//                             touched={touched}
//                             label={false}
//                             readOnly={parameters.inspectionId}
//                             name="vehicleType"
//                             disabled={
//                               parameters.inspectionId || selectedVehicleType
//                             }
//                             required={false}
//                             onChange={(e) => {
//                               setFieldValue("vehicleType", e.target.value);
//                               if (selectedVehicleType) {
//                                 setFieldValue(
//                                   "vehicleType",
//                                   selectedVehicleTypeId
//                                 );
//                                 values.vehicleType = selectedVehicleTypeId; // Update the values object
//                                 values.vehicleTypeLabel = selectedVehicleType;
//                               }
//                               values.vehicleType = e.target.value;
//                               onSelectVehicle(
//                                 values,
//                                 setFieldValue,
//                                 "vehicleType"
//                               );
//                             }}
//                           >
//                             {!selectedVehicleType && (
//                               <option value="">select</option>
//                             )}

//                             {selectedVehicleType ? (
//                               <option
//                                 key={selectedVehicleTypeId}
//                                 value={selectedVehicleType}
//                               >
//                                 {selectedVehicleType}
//                               </option>
//                             ) : (
//                               vehicleTypeOptions?.map((data, index) => (
//                                 <option
//                                   key={data.vehicleTypeId}
//                                   value={data.vehicleTypeId}
//                                   data-dataid={data.vehicleTypeId}
//                                 >
//                                   {data.vehicleTypeName}
//                                 </option>
//                               ))
//                             )}
//                           </Select>
//                         </div>
//                       </div>

//                       <div className="row mb-4">
//                         <div className="col-6">Vehicle Configuration Key :</div>

//                         {!values.configuredVehicle && (
//                           <div className="col-6 text-muted h-40 pl-4">
//                             Auto Fill
//                           </div>
//                         )}
//                         {selectedConfig ? (
//                           <div className="col-6 text-muted h-40">
//                             {selectedConfig}
//                           </div>
//                         ) : (
//                           values.configuredVehicle && (
//                             <div className="col-6 text-muted h-40">
//                               {values.configuredVehicle}
//                             </div>
//                           )
//                         )}
//                       </div>

//                       <div className="d-flex justify-content-center mt-5">
//                         <button
//                           type="submit"
//                           className="btn-fill btn btn-info m-3"
//                         >
//                           {parameters?.inspectionId ? "Update" : "Save"}
//                         </button>
//                       </div>
//                     </Form>
//                   </div>
//                 </div>
//               </div>
//             </>
//           );
//         }}
//       </Formik>
//     </>
//   );
// };

// export default CVInspectionForm;

import React, { useState, useRef, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useLocation, useParams } from "react-router-dom";
import TextInputField from "../../../../components/InputField/TextInputField";
import Select from "../../../../components/DropdownField/Select";
import VehicleManufactureDataMethod from "../../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
import VehicleTypeDataMethod from "../../../../asyncMethods/mst_action/VehicleTypeDataMethod";
import VehicleModelDataMethod from "../../../../asyncMethods/mst_action/VehicleModelDataMethod";
import ClientMethods from "../../../../asyncMethods/ClientMethods";
import InspectionMethods from "../../../../asyncMethods/InspectionMethods";
import moment from "moment";
import api from "../../../../api";
import CVMethods from "../../../../asyncMethods/CVMethods";

const CVInspectionForm = () => {
  const [vehicleRegNumber, setVehicleRegNumber] = useState("");
  const [vehicleData, setVehicleData] = useState(null);
  const [err, setErr] = useState(false);
  const [nul, setNull] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedVehicleManufacturer, setSelectedVehicleManufacturer] =
    useState(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedVehicleManufacturerId, setSelectedVehicleManufacturerId] =
    useState(null);
  const [selectedVehicleModelId, setSelectedVehicleModelId] = useState(null);
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);

  const parameters = useParams();
  console.log("parameters", parameters);
  const formRef = useRef();
  const schema = Yup.object({
    clientId: Yup.string().required("Client Name is required !"),
    clientCompanyName: Yup.string().optional(),
    clientServiceLocationId: Yup.string().required(
      "Client Location is required."
    ),
    clientName: Yup.string().optional(),
    //inspectionDateTime: Yup.string().required("Date And Time is required !"),
    vehicleRegNumber: Yup.string()
      .required("Vehicle Reg No is required !")
      .test(
        "no-spaces-special-chars",
        "Letter should be in capital.No spaces or special characters allowed",
        (value) => {
          // Test for spaces and special characters (only allowing capital letters and digits)
          return /^[A-Z0-9]*$/.test(value);
        }
      ),
    vehicleManufacturer: Yup.string().optional(),
    vehicleManufacturerLabel: Yup.string().optional(),
    vehicleModel: Yup.string().optional(),
    vehicleType: Yup.string().optional(),
    vehicleId: Yup.string().required("Vehicle Id is required !"),
    configuredVehicle: Yup.string().required(
      "vehicleConfigurationKey is required !"
    ),
    inspectionStatus: Yup.string().optional()
  });

  const [initialValues, setInitialValues] = useState({
    clientVehicleId: "",
    clientId: "",
    clientName: "",
    clientServiceLocationId: "",
    vehicleId: "",
    configuredVehicle: "",
    // inspectionStatus: "1",
    vehicleManufacturer: "",
    vehicleManufacturerLabel: "",
    vehicleModel: "",
    vehicleType: "",
    activeStatus: "1"
  });

  const [selectedClient, setSelectedClient] = useState([]);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [date, setDate] = useState(getCurrentDateTime());

  const vehicleManufactureDataMethod = VehicleManufactureDataMethod();
  const vehicleTypeDataMethod = VehicleTypeDataMethod();
  const vehicleModelDataMethod = VehicleModelDataMethod();
  const clientMethods = ClientMethods();
  const inspectionMethods = InspectionMethods();
  const cVMethods = CVMethods();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;
  const role = user?.roleType;

  const vehicleManufactureOptions = Array.from(
    useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleTypeOptions = Array.from(
    useSelector((state) => state?.vehicleType?.vehicleTypeList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleModelOptions = Array.from(
    useSelector((state) => state?.vehicleModel?.filterVehicleModelList)
  ).filter((s) => s.activeStatus == 1);
  let clients = Array.from(useSelector((state) => state?.client?.clientList));
  if (role === "2" || role === 2) {
    clients = clients.filter((client) => client.userId == userId);
    console.log("client fil", clients);
  }
  const clientLocationArr = Array.from(
    useSelector((state) => state?.client?.clientList)
  );

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  // Handle changes in the date input
  function handleDateChange(e) {
    const selectedDate = e.target.value;
    if (selectedDate <= getCurrentDateTime()) {
      setDate(selectedDate);
    }
  }
  const handleVehicleRegNumberChange = (e) => {
    // Update the vehicleRegNumber state when the input value changes
    setVehicleRegNumber(e.target.value);
    console.log(vehicleRegNumber);
  };

  console.log("seeee", selectedVehicles);

  useEffect(() => {
    vehicleManufactureDataMethod.fetchAllVehicleManufactureData();
    vehicleTypeDataMethod.fetchAllVehicleTypeData();
    vehicleModelDataMethod.fetchAllVehicleModelData();
    clientMethods.fetchAllClients();

    if (parameters.inspectionId) {
      //inspectionMethods.fetchInspection(parameters.inspectionId);
      cVMethods.fetchCVInspection(parameters.inspectionId);
    }
  }, []);

  const allD = useSelector((state) => state);

  console.log("all", allD);

  const editInspectionData = useSelector(
    (state) => state.cvinspections.editCVInspection
  );

  console.log("all", editInspectionData);

  useEffect(() => {
    if (
      parameters.inspectionId &&
      editInspectionData.clientVehicleId == parameters.inspectionId
      // clients &&
      // clients.length > 0
    ) {
      if (editInspectionData?.inspectionDateTime) {
        editInspectionData.inspectionDateTime =
          editInspectionData.inspectionDateTime.toString().slice(0, 16);
      }
      const selectedClient = clients?.find(
        (c) => c.clientId == editInspectionData.clientId
      );
      if (selectedClient) {
        setSelectedClient([selectedClient]);
        setServiceLocations(selectedClient.serviceLocations);
      }
      editInspectionData.clientServiceLocationId = (
        editInspectionData.clientServiceLocationId || ""
      ).toString();
      vehicleManufactureDataMethod.getVehicleByFilter(
        editInspectionData.vehicleManufacturer
      );
      setInitialValues(editInspectionData);
    }
  }, [editInspectionData]);

  const onSubmit = (data) => {
    if (parameters.inspectionId) {
      //inspectionMethods.updateInspection(parameters.inspectionId, data);
      cVMethods.updateCVInspection(parameters.inspectionId, data);
    } else {
      //inspectionMethods.storeInspection(data);
      cVMethods.storeCVInspection(data);
    }
    console.log("sub ", data);
  };

  const onSelectVehicle = async (data, setFieldValue, type) => {
    if (data.vehicleManufacturer && data.vehicleModel && data.vehicleType) {
      const response = await inspectionMethods.getVehicleByFilter(
        data.vehicleManufacturer,
        data.vehicleModel,
        data.vehicleType
      );
      setFieldValue("vehicleId", response ? response.vehicleId : "");
      setFieldValue(
        "configuredVehicle",
        response ? response.configuredVehicle : ""
      );
    }

    if (type === "vehicleManufacturer") {
      const selectedManufacture = vehicleManufactureOptions.find(
        (v) => v.vehicleManufacturerId == data.vehicleManufacturer
      );
      setFieldValue(
        "vehicleManufacturerLabel",
        selectedManufacture.vehicleManufacturerName
      );
      vehicleManufactureDataMethod.getVehicleByFilter(data.vehicleManufacturer);
    }

    if (type === "vehicleModel") {
      const selectedModel = vehicleModelOptions.find(
        (v) => v.vehicleModelId == data.vehicleModel
      );
      setFieldValue("vehicleModelLabel", selectedModel.vehicleModelName);
    }

    if (type === "vehicleType") {
      const selectedType = vehicleTypeOptions.find(
        (v) => v.vehicleTypeId == data.vehicleType
      );
      console.log(
        "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
        selectedType
      );
      setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
    }

    if (type === "vehicleType") {
      const selectedType = vehicleTypeOptions.find(
        (v) => v.vehicleTypeId == data.vehicleType
      );
      console.log(
        "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
        selectedType
      );
      setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={(values) => {
          console.log("----------", values);
          onSubmit(values);
        }}
      >
        {(formik) => {
          const { errors, touched, setFieldValue, setFieldTouched, values } =
            formik;

          return (
            <>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Form>
                      {parameters?.inspectionId && (
                        <div className="row mb-4">
                          <div className="col-6">Client Vehicle ID:</div>
                          <div className="col-6 text-muted h-40">
                            {values?.clientVehicleId
                              ? values?.clientVehicleId
                              : "-"}
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <div className="col-6">
                          Client Company Name : {selectedClient.clientId}
                        </div>
                        <div className="col-6 text-muted">
                          <Typeahead
                            clearButton
                            id="basic-typeahead-single"
                            multiple={false}
                            onChange={(selected) => {
                              const value =
                                selected.length > 0
                                  ? selected[0].clientCompanyName
                                  : "";
                              setFieldValue("clientCompanyName", value);
                              setFieldValue(
                                "clientName",
                                value ? selected[0].clientFullName : ""
                              );
                              setSelectedClient(selected);
                              if (value) {
                                setFieldValue(
                                  "clientId",
                                  `${selected[0].clientId}`
                                );
                                setServiceLocations(
                                  selected[0].serviceLocations
                                );
                              } else {
                                setFieldValue("clientId", "");
                                setServiceLocations([]);
                              }
                            }}
                            onBlur={(e) =>
                              setFieldTouched("clientCompanyName", true)
                            }
                            labelKey="clientCompanyName" // Assuming clientCompanyName is the label key in your options
                            options={clients}
                            selected={selectedClient}
                          />
                          <div className="text-danger">
                            {touched.clientCompanyName &&
                              errors.clientCompanyName}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Client Name :</div>
                        {!values.clientName && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {values.clientName && (
                          <div className="col-6 text-muted h-40">
                            {values.clientName.split(",")[0].trim()}
                          </div>
                        )}
                      </div>

                      {(values.clientCompanyName ||
                        parameters.inspectionId) && (
                        <div className="row mb-4">
                          <div className="col-6">Client Location :</div>
                          <div className="col-6 text-muted">
                            <Select
                              errors={errors}
                              touched={touched}
                              label={false}
                              name="clientServiceLocationId"
                              required={false}
                              onChange={(e) => {
                                setFieldValue(
                                  "clientServiceLocationId",
                                  e.target.value
                                );
                                const selectedAddress = serviceLocations.find(
                                  (s) =>
                                    s.clientServiceLocationId ===
                                    Number(e.target.value)
                                );
                                if (selectedAddress) {
                                  setFieldValue(
                                    "clientServiceLocationAddress",
                                    `${selectedAddress.clientServiceAddress}, ${selectedAddress.clientServiceCity}, ${selectedAddress.clientServicePincode}`
                                  );
                                  values.clientServiceLocationAddress = `${selectedAddress.clientServiceAddress}, ${selectedAddress.clientServiceCity}, ${selectedAddress.clientServicePincode}`;
                                }
                                values.clientServiceLocationId = e.target.value;
                              }}
                            >
                              <option value="">select</option>
                              {serviceLocations?.map((data) => (
                                <option
                                  key={data.clientServiceLocationId}
                                  value={data.clientServiceLocationId}
                                >
                                  {`${data.clientServiceAddress}, ${data.clientServiceCity}, ${data.clientServicePincode}`}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Reg No :</div>
                        <div
                          style={{ position: "relative" }}
                          className="col-6 text-muted"
                        >
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={false}
                            name={"vehicleRegNumber"}
                            handleChange={handleVehicleRegNumberChange} // Update user input state
                            //readOnly={parameters.inspectionId}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          Vehicle Manufacturer (Master) :
                        </div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            //readOnly={parameters.inspectionId}
                            // disabled={
                            //   parameters.inspectionId ||
                            //   selectedVehicleManufacturer
                            // }
                            name="vehicleManufacturer"
                            value={values.vehicleManufacturer}
                            required={false}
                            onChange={(e) => {
                              setFieldValue(
                                "vehicleManufacturer",
                                e.target.value
                              );
                              values.vehicleManufacturer = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleManufacturer"
                              );
                              if (selectedVehicleManufacturer) {
                                setFieldValue(
                                  "vehicleManufacturer",
                                  selectedVehicleManufacturerId
                                );
                                values.vehicleManufacturer =
                                  selectedVehicleManufacturerId; // Update the values object
                                values.vehicleManufacturerLabel =
                                  selectedVehicleManufacturer;
                                values.configuredVehicle = selectedConfig;
                              }
                            }}
                          >
                            {!selectedVehicleManufacturer && (
                              <option value="">select</option>
                            )}

                            {selectedVehicleManufacturer ? (
                              <option
                                key={selectedVehicleManufacturerId}
                                value={selectedVehicleManufacturerId}
                              >
                                {selectedVehicleManufacturer}
                              </option>
                            ) : (
                              vehicleManufactureOptions?.map((data, index) => (
                                <>
                                  <option
                                    key={data.vehicleManufacturerId}
                                    value={data.vehicleManufacturerId}
                                  >
                                    {data.vehicleManufacturerName}
                                  </option>
                                </>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Model :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            //readOnly={parameters.inspectionId}
                            name="vehicleModel"
                            required={false}
                            // disabled={
                            //   parameters.inspectionId || selectedVehicleModel
                            // }
                            onChange={(e) => {
                              setFieldValue("vehicleModel", e.target.value);
                              if (selectedVehicleModel) {
                                setFieldValue(
                                  "vehicleModel",
                                  selectedVehicleModelId
                                );
                                values.vehicleModel = selectedVehicleModelId; // Update the values object
                                values.vehicleModelLabel = selectedVehicleModel;
                              }
                              values.vehicleModel = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleModel"
                              );
                            }}
                          >
                            {!selectedVehicleModel && (
                              <option value="">select</option>
                            )}

                            {selectedVehicleModel ? (
                              <option
                                key={selectedVehicleModelId}
                                value={selectedVehicleModel}
                              >
                                {selectedVehicleModel}
                              </option>
                            ) : (
                              vehicleModelOptions?.map((data, index) => (
                                <option
                                  key={data.vehicleModelId}
                                  value={data.vehicleModelId}
                                  data-dataid={data.vehicleModelId}
                                >
                                  {data.vehicleModelName}
                                </option>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle type :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            //readOnly={parameters.inspectionId}
                            name="vehicleType"
                            // disabled={
                            //   parameters.inspectionId || selectedVehicleType
                            // }
                            required={false}
                            onChange={(e) => {
                              setFieldValue("vehicleType", e.target.value);
                              if (selectedVehicleType) {
                                setFieldValue(
                                  "vehicleType",
                                  selectedVehicleTypeId
                                );
                                values.vehicleType = selectedVehicleTypeId; // Update the values object
                                values.vehicleTypeLabel = selectedVehicleType;
                              }
                              values.vehicleType = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleType"
                              );
                            }}
                          >
                            {!selectedVehicleType && (
                              <option value="">select</option>
                            )}

                            {selectedVehicleType ? (
                              <option
                                key={selectedVehicleTypeId}
                                value={selectedVehicleType}
                              >
                                {selectedVehicleType}
                              </option>
                            ) : (
                              vehicleTypeOptions?.map((data, index) => (
                                <option
                                  key={data.vehicleTypeId}
                                  value={data.vehicleTypeId}
                                  data-dataid={data.vehicleTypeId}
                                >
                                  {data.vehicleTypeName}
                                </option>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Configuration Key :</div>

                        {!values.configuredVehicle && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {selectedConfig ? (
                          <div className="col-6 text-muted h-40">
                            {selectedConfig}
                          </div>
                        ) : (
                          values.configuredVehicle && (
                            <div className="col-6 text-muted h-40">
                              {values.configuredVehicle}
                            </div>
                          )
                        )}
                      </div>

                      <div className="d-flex justify-content-center mt-5">
                        <button
                          type="submit"
                          className="btn-fill btn btn-info m-3"
                        >
                          {parameters?.inspectionId ? "Update" : "Save"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CVInspectionForm;
