import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FETCH_ALL_CLIENT, SET_EDIT_CLIENT, SET_GST_REGISTRATION_OPTIONS } from "../redux/constants/ClientActionTypes";
import api from "../api";
import { clientLogin } from "../redux/actions/ClientAction";


const ClientMethods = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const login = (data) => {
      const payload = {
        // clientMobileNo: data.clientMobileNo,
        clientPassword: data.clientPassword
        // roleType: data.roleType,
      };

      if (/^\d{10}$/.test(data.username)) {
        payload.clientMobileNo = data.username;
      } else {
        payload.clientEmailId = data.username;
      }

      return api
        .post("/masterservice/clientLoginUser", payload)
        .then((res) => {
          const result = res.data.result;
          if (Number(res.data.statusCode) === 401) {
            toast.error(res.data.statusDescription);
            localStorage.setItem("cliLog", true);
            return;
            // navigate("/clientLogin");
          }
          else {
            localStorage.setItem("user", JSON.stringify(res.data.result));
            const user = JSON.parse(localStorage.getItem("user"));
            if (user) {
              user.client = true;
              localStorage.setItem("user", JSON.stringify(user));
              console.log("Updated user object:", user);
            }

            dispatch(clientLogin(result));
            navigate("/dashboard");
            fetchAllClients();
            toast.success("Login Successful.");
          }
        })
        .catch(() => {
          toast.error("Login Failed.");
        });
    };

    const ClientLogout = () => {
      localStorage.clear();
      toast.success("Logout Successful.");
      navigate("/");
    };

  const fetchAllClients = () => {
    return api.get("/masterservice/getallclientdata?page=1&size=2000")
      .then(res => {
        dispatch({
          type: FETCH_ALL_CLIENT,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const storeClient = (data) => {
    return api.post("/masterservice/saveclient", data).then(res => {
      if (res.data.statusCode == 201) {
        toast.success("Client created Successful.");
        navigate('/clients');
        return;
      }
      toast.error(res.data.statusDescription);
      console.log(res.data.result);
    }).catch(err => console.log(err));
  }

  const fetchClient = (clientId = 1) => {
    return api.get(`/masterservice/getclientdata?clientId=` + clientId)
      .then(res => {
        dispatch({
          type: SET_EDIT_CLIENT,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  const updateClient = (userId, client) => {
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/updateclient?clientId=` + userId, client, { headers })
      .then(res => {
        if (res.data.statusCode == 200) {
          toast.success("Client updated Successful.");
          navigate("/clients");
          return;
        }
        toast.error(res.data.statusDescription);
      }).catch(err => {
        console.log(err);
        toast.error("Fetch Failed.");
      })
  }

  const clientStatusUpdate = ({ id, status }) => {
    const data = {
      "clientActiveStatus": status
    }
    const headers = {
      "Content-Type": "application/json"
    };
    return api.put(`/masterservice/enabledisableclient?clientId=` + id, data, { headers })
      .then(res => {
        toast.success("Status Updated Successful.");
        fetchAllClients();
      }).catch(err => {
        console.log(err);
      })
  }

  const fetchClientOptions = (datatype = 'gsttype') => {
    return api.get("/masterservice/masterdatalist?datatype="+datatype+"&parentId=0&activeStatus=1")
      .then(res => {
        console.log("res:",res)
        dispatch({
          type: SET_GST_REGISTRATION_OPTIONS,
          payload: res.data.result
        });
      }).catch(err => {
        console.log(err);
      })
  }

  return {
    login,
    ClientLogout,
    fetchAllClients,
    storeClient,
    fetchClient,
    updateClient,
    clientStatusUpdate,
    fetchClientOptions
  };
}

export default ClientMethods