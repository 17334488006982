import React from "react";
import { Card } from "react-bootstrap";
import CVInspectionForm from "./CVinspectionForm";
// import TableForm from "./TableForm";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";

const CVCreateInspection = () => {
  const parameters = useParams();
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Card style={{ width: "100" }}>
              <Card.Body>
                <Tabs defaultActiveKey="inspectionForm" id="fill-tab-example">
                  <Tab eventKey="inspectionForm" title="Client Vehicle Master Form">
                    <br />
                    <br />
                    <CVInspectionForm />
                  </Tab>
                  {/* <Tab
                    eventKey="tableForm"
                    title="Table Form"
                    disabled={!parameters.inspectionId}
                  >
                    <br />
                    <br />
                    <TableForm />
                  </Tab> */}
                </Tabs>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default CVCreateInspection;
