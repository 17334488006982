import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";

import TextInputField from "../../../components/InputField/TextInputField";
import { Button, Card } from "react-bootstrap";
import ClientMethods from "../../../asyncMethods/ClientMethods";
import Select from "../../../components/DropdownField/Select";
import PasswordField from "../../../components/PasswordField";
import UserMethods from "../../../asyncMethods/UserMethods";


const schema = Yup.object({
  clientTitle: Yup.string().required("Title is required !"),
  clientFirstName: Yup.string().max(100).required("First Name is required !"),
  clientEmailId: Yup.string().email().required("Email required !"),
  clientMobileNo: Yup.number().required("Mobile is required !"),
  clientMiddleName: Yup.string().optional("Middle Name is required !"),
  clientLastName: Yup.string().required("Last Name is required !"),
  clientCompanyName: Yup.string().required("Company Name is required !"),
  clientPhoneNo: Yup.string(),
  gstRegistrationType: Yup.number(),
  //.required("GST REGISTRATION TYPE is required !"),
  clientGstNumber: Yup.string().max(15).min(15),
  clientPanNumber: Yup.string()
    .max(10)
    .min(10)
    .required("Pan No is required !"),
  clientBillingAddress: Yup.string().required("Address is required !"),
  clientBillingState: Yup.string().required("State is required !"),
  clientBillingCity: Yup.string().required("City/Town is required !"),
  clientBillingCountry: Yup.string().required("Country is required !"),
  clientBillingPincode: Yup.string().required("Pin is required !"),
  clientPassword: Yup.string()
    .required("Client Password required")
    .min(8, "Password is too short - should be 8 chars min"),
  clientContactOwner: Yup.string(),
  // clientServiceAddress: Yup.string().required("ADDRESS is required !"),
  // clientServiceState: Yup.string().required("STATE is required !"),
  // clientServiceCity: Yup.string().required("CITY/TOWN is required !"),
  // clientServiceCountry: Yup.string().required("COUNTRY is required !"),
  // clientServicePincode: Yup.string().required("PIN is required !"),
  addresses: Yup.array().of(
    Yup.object().shape({
      clientServiceAddress: Yup.string().required("ADDRESS is required !"),
      clientServiceState: Yup.string().required("STATE is required !"),
      clientServiceCity: Yup.string().required("CITY/TOWN is required !"),
      clientServicePincode: Yup.string().required("PIN is required !"),
    })
  ),
});

const editSchema = Yup.object({
  clientTitle: Yup.string().required("Title is required !"),
  clientFirstName: Yup.string().max(100).required("First Name is required !"),
  clientEmailId: Yup.string().email().required("Email required !"),
  clientMobileNo: Yup.number().required("Mobile is required !"),
  clientMiddleName: Yup.string().optional("Middle Name is required !"),
  clientLastName: Yup.string().required("Last Name is required !"),
  clientCompanyName: Yup.string().required("Company Name is required !"),
  clientPhoneNo: Yup.string(),
  gstRegistrationType: Yup.number(),
  //.required("GST REGISTRATION TYPE is required !"),
  clientGstNumber: Yup.string().max(15).min(15),
  clientPanNumber: Yup.string()
    .max(10)
    .min(10)
    .required("Pan No is required !"),
  clientBillingAddress: Yup.string().required("Address is required !"),
  clientBillingState: Yup.string().required("State is required !"),
  clientBillingCity: Yup.string().required("City/Town is required !"),
  clientBillingCountry: Yup.string().required("Country is required !"),
  clientBillingPincode: Yup.string().required("Pin is required !"),
  clientContactOwner: Yup.string(),
  clientNewPassword: Yup.string()
    // .required("New Password is required")
    .min(8, "New Password is too short - should be 8 chars min"),
  clientConfirmPassword: Yup.string()
    .oneOf([Yup.ref('clientNewPassword'), null], 'Passwords must match'),
    // .required('Confirm Password is required'),
  addresses: Yup.array().of(
    Yup.object().shape({
      clientServiceAddress: Yup.string().required("ADDRESS is required !"),
      clientServiceState: Yup.string().required("STATE is required !"),
      clientServiceCity: Yup.string().required("CITY/TOWN is required !"),
      clientServicePincode: Yup.string().required("PIN is required !"),
    })
  ),
});


const CreateClient = () => {
  const userMethods = UserMethods();
  const navigate = useNavigate();
  const [gstNoFielsClasses, setGstNoFielsClasses] = useState("col");
  const [users1, setusers1] = useState([]);
  const [errorz, setErrorz] = useState(false);
    const [passwordFieldVisibility, setPasswordFieldVisibility] = useState(
      "form-group form-row"
    );
  const parameters = useParams();

  const clientMethods = ClientMethods();

  const [initialValues, setInitialValues] = useState({
    clientTitle: "",
    clientFirstName: "",
    clientEmailId: "",
    clientMobileNo: "",
    clientMiddleName: "",
    clientLastName: "",
    clientCompanyName: "",
    clientWebsite: "",
    clientPhoneNo: "",
    gstRegistrationType: "",
    clientGstNumber: "",
    clientPanNumber: "",
    clientBillingAddress: "",
    clientBillingState: "",
    clientBillingCity: "",
    clientBillingCountry: "",
    clientBillingPincode: "",
    clientPassword: "",
    userId: "",
    // clientServiceAddress: "",
    // clientServiceState: "",
    // clientServiceCity: "",
    // clientServiceCountry: "",
    // clientServicePincode: "",
    clientActiveStatus: 1,
    serviceLocations: [],
  });

  const titleOptions = [
    {
      dataLabel: "Mr",
      dataValue: "Mr.",
    },
    {
      dataLabel: "Ms",
      dataValue: "Ms",
    },
    {
      dataLabel: "Mrs",
      dataValue: "Mrs",
    },
  ];

  useEffect(() => {
    userMethods.fetchAllUsers();

        if (!localStorage.getItem("pageRefreshed")) {
          // Set the flag to indicate that the page has been refreshed
          localStorage.setItem("pageRefreshed", "true");
          // Refresh the page
          window.location.reload();
        }


    if (parameters.clientId) {
      clientMethods.fetchClient(parameters.clientId);
      // setPasswordFieldVisibility("form-group form-row d-none");
    }
  }, []);

  const editClient = useSelector((state) => state.client.editClient);
 // const editClient = useSelector((state) => state);
  const users = useSelector((state) => state.authUser.userList);

  useEffect(() => {
        setusers1(users);
        console.log("users", users);
    }, [users]);
  
  useEffect(() => {

    if (editClient.clientId == parameters.clientId) {
      console.log("users", users);
      console.log("edit client", editClient);
      // setInitialValues(editClient);
      const clientData = {
        ...editClient,
        clientMiddleName: editClient.clientMiddleName || "",
        clientPhoneNo: editClient.clientPhoneNo || "",
        clientGstNumber: editClient.clientGstNumber || "",
        clientWebsite: editClient.clientWebsite || "",
        clientPassword: editClient.clientPassword || "",
      };
      setInitialValues(clientData);
    }
  }, [editClient]);

  


  const onSubmit = (data) => {
    const selectedName = data.clientContactOwner; // Get the selected name
    const selectedOption = document.querySelector(
      'select[name="clientContactOwner"] option:checked'
    );
    const selectedUserId = selectedOption.getAttribute("data-userid"); // Get the userId from the selected option

    console.log("Selected Name:", selectedName);
    console.log("Selected UserId:", selectedUserId);
    data.userId = (selectedUserId);
    console.log("data" , data);
    if (parameters.clientId) {      
      clientMethods.updateClient(parameters.clientId, data);
    } else {
      if (data.clientPassword != "" && data.clientPassword != null) {
        setErrorz(false);
        clientMethods.storeClient(data);
      }
      else {
        setErrorz(true);
      }
    }
  };

  useEffect(() => {
    clientMethods.fetchClientOptions("gsttype");
  }, []);

  // const gstRegistrationTypes = Array.from(useSelector(state => state.client.gstRegistrationTypes));
  const gstRegistrationTypes = [
    {
      dataValue: 1,
      dataLabel: 1,
    },
  ];

  const handleOnChange = (event) => {
    if (event.target.name === "gstRegistrationType") {
      event.target.value == 720
        ? setGstNoFielsClasses("col d-none")
        : setGstNoFielsClasses("col");
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={!parameters.clientId ? schema : editSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(formik) => {
          const { values, errors, touched, isValid, dirty, setFieldValue } =
            formik;

          if (!values.serviceLocations) {
            values.serviceLocations = [];
            values.serviceLocations.push({
              clientServiceLocationId: 0,
              clientServiceAddress: "",
              clientServiceCity: "",
              clientServiceState: "",
              clientServiceCountry: "India",
              clientServicePincode: "",
              activeStatus: "1",
            });
            setFieldValue("serviceLocations", values.serviceLocations);
          }

          return (
            <>
              <div className="d-flex justify-content-between m-3">
                <h5>{""}</h5>
                <Button onClick={() => navigate("/clients")} variant="primary">
                  <i className="fa-solid fa-arrow-left"></i> Back
                </Button>
              </div>
              <Card>
                <Card.Body>
                  <Form onChange={handleOnChange}>
                    <div className="m-3">
                      <div className="form-group form-row">
                        <Select
                          label={"Title "}
                          name={"clientTitle"}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                          required={true}
                        >
                          <option value={""}>select</option>
                          {titleOptions.map((data, i) => (
                            <option key={i} value={data.dataValue}>
                              {data.dataLabel}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"First Name "}
                          name={"clientFirstName"}
                          classProps={"col"}
                          required={true}
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Middle Name"}
                          name={"clientMiddleName"}
                          classProps={"col"}
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Last Name "}
                          name={"clientLastName"}
                          classProps={"col"}
                          required={true}
                        />
                      </div>
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Company Name "}
                          name={"clientCompanyName"}
                          classProps={"col"}
                          required={true}
                        />
                      </div>
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"email"}
                          label={"Email "}
                          name={"clientEmailId"}
                          classProps={"col"}
                          required={true}
                          autoComplete="new-password"
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"url"}
                          label={"Website"}
                          name={"clientWebsite"}
                          classProps={"col"}
                        />
                      </div>
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Mobile "}
                          name={"clientMobileNo"}
                          classProps={"col"}
                          required={true}
                          autoComplete="new-password"
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"Phone"}
                          name={"clientPhoneNo"}
                          classProps={"col"}
                        />
                      </div>
                      <div className="form-group form-row">
                        {/* <Select
                          label={"GST Registration Type "}
                          name={"gstRegistrationType"}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                          required={false}
                        >
                          <option value={""}>select</option>
                          {gstRegistrationTypes.map((data, i) => (
                            <option key={i} value={data.dataValue}>
                              {data.dataLabel}
                            </option>
                          ))}
                        </Select> */}
                        <Select
                          label={"GST Registration Type "}
                          name={"gstRegistrationType"}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                          required={false}
                        >
                          <option value={""}>select</option>
                          <option value="0">Regular GST Registration</option>
                          <option value="1">
                            Composition Dealer Registration
                          </option>
                          <option value="2">Voluntary GST Registration</option>
                          <option value="3">
                            Casual Taxable Person Registration
                          </option>
                          <option value="4">
                            Non-Resident Taxable Person Registration
                          </option>
                          <option value="5">
                            ISD (Input Service Distributor) Registration
                          </option>
                          <option value="6">
                            TDS (Tax Deduction at Source) Registration
                          </option>
                          <option value="7">
                            TCS (Tax Collection at Source) Registration
                          </option>
                          <option value="8">
                            GST Practitioner Registration.
                          </option>
                        </Select>
                      </div>
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"PAN No "}
                          name={"clientPanNumber"}
                          classProps={"col"}
                          required={true}
                        />
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          type={"text"}
                          label={"GST No "}
                          name={"clientGstNumber"}
                          classProps={gstNoFielsClasses}
                          required={false}
                        />
                      </div>
                      <div className="form-group form-row">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "2px",
                          }}
                          className={passwordFieldVisibility}
                        >
                          <PasswordField
                            errors={errors}
                            touched={touched}
                            label={"Password "}
                            name={"clientPassword"}
                            required={true}
                            classProps={"col"}
                            autoComplete="new-password"
                            readOnly={parameters.clientId}
                          />
                          {errorz && (
                            <div>
                              <p style={{ color: "red", width: "10rem" }}>
                                Password is Required
                              </p>
                            </div>
                          )}
                        </div>

                        {parameters.clientId && <> 
                          <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "2px",
                          }}
                          className="form-group form-row"
                        >
                          <PasswordField
                            errors={errors}
                            touched={touched}
                            label={"New Password"}
                            name={"clientNewPassword"}
                            required={true}
                            classProps={"col"}
                            autoComplete="new-password"
                          />
                          
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "2px",
                          }}
                          className="form-group form-row"
                        >
                          <PasswordField
                            errors={errors}
                            touched={touched}
                            label={"Confirm Password"}
                            name={"clientConfirmPassword"}
                            required={true}
                            classProps={"col"}
                            autoComplete="new-password"
                          />
                          
                        </div> 
                        </>
                        }

                        <Select
                          label={"User Contact Person"}
                          name={"clientContactOwner"}
                          required={true}
                          classProps={"col"}
                          errors={errors}
                          touched={touched}
                        >
                          <option value={""}>select</option>
                          {users1.length > 0 &&
                            users1
                              ?.filter((items) => items.roleType == 2)
                              ?.map((item) => {
                                return (
                                  <option
                                    key={item.userId}
                                    value={item.name}
                                    data-userid={item.userId}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                        </Select>
                      </div>
                    </div>
                    <Card className="border-0">
                      <Card.Body>
                        <Card.Title className="my-3 font-weight-bold">
                          Billing Address
                        </Card.Title>
                        <div className="form-group form-row">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={"Address "}
                            name={"clientBillingAddress"}
                            required={true}
                            classProps={"col"}
                          />
                        </div>
                        <div className="form-group form-row">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={"City/Town "}
                            name={"clientBillingCity"}
                            required={true}
                            classProps={"col"}
                          />
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={"State "}
                            name={"clientBillingState"}
                            required={true}
                            classProps={"col"}
                          />
                        </div>
                        <div className="form-group form-row">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={"Country "}
                            name={"clientBillingCountry"}
                            required={true}
                            classProps={"col"}
                          />
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={"PIN "}
                            name={"clientBillingPincode"}
                            required={true}
                            classProps={"col"}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className="border-0">
                      <Card.Body>
                        <Card.Title className="my-3 font-weight-bold">
                          Service to Address
                        </Card.Title>
                      </Card.Body>
                    </Card>
                    <div className="m-3">
                      {values.serviceLocations &&
                        values.serviceLocations.map((address, index) => (
                          <Card>
                            <Card.Title
                              className="d-flex justify-content-end"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                values.serviceLocations.splice(index, 1);
                                setFieldValue(
                                  "serviceLocations",
                                  values.serviceLocations
                                );
                              }}
                            >
                              <i
                                class="fa fa-window-close"
                                aria-hidden="true"
                              ></i>
                            </Card.Title>
                            <Card.Body>
                              <div className="form-group form-row">
                                <TextInputField
                                  errors={errors}
                                  touched={touched}
                                  type={"text"}
                                  label={"Address "}
                                  name={`serviceLocations.${index}.clientServiceAddress`}
                                  required={true}
                                  classProps={"col"}
                                />
                                <TextInputField
                                  errors={errors}
                                  touched={touched}
                                  type={"text"}
                                  label={"City/Town "}
                                  name={`serviceLocations.${index}.clientServiceCity`}
                                  required={true}
                                  classProps={"col"}
                                />
                              </div>
                              <div className="form-group form-row">
                                <TextInputField
                                  errors={errors}
                                  touched={touched}
                                  type={"text"}
                                  label={"State "}
                                  name={`serviceLocations.${index}.clientServiceState`}
                                  required={true}
                                  classProps={"col"}
                                />
                                <TextInputField
                                  errors={errors}
                                  touched={touched}
                                  type={"text"}
                                  label={"PIN "}
                                  name={`serviceLocations.${index}.clientServicePincode`}
                                  required={true}
                                  classProps={"col"}
                                />
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>

                    <div className="d-flex justify-content-start m-3">
                      <button
                        type="button"
                        className="btn-fill btn btn-info my-3"
                        onClick={() => {
                          if (!values.serviceLocations) {
                            values.serviceLocations = [];
                          }
                          values.serviceLocations.push({
                            clientServiceLocationId: 0,
                            clientServiceAddress: "",
                            clientServiceCity: "",
                            clientServiceState: "",
                            clientServiceCountry: "India",
                            clientServicePincode: "",
                            activeStatus: "1",
                          });
                          setFieldValue(
                            "serviceLocations",
                            values.serviceLocations
                          );
                        }}
                      >
                        Add More
                      </button>
                    </div>
                    <div className="d-flex justify-content-center m-3">
                      <button
                        type="submit"
                        className="btn-fill btn btn-info my-3"
                      >
                        {parameters.clientId ? "Update" : "Add"} Client
                      </button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateClient;
