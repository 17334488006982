import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import InspectionMethods from "../../../../asyncMethods/InspectionMethods";
import VehicleMethods from "../../../../asyncMethods/VehicleMethods";
import { useSelector } from "react-redux";
import api from "../../../../api";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Content } from "antd/es/layout/layout";
import moment from "moment";

const Analytical2 = () => {
  const parameters = useParams();
  const inspectionMethods = InspectionMethods();
  const [array, setArray] = useState([]);
  const [attention, setAttention] = useState([]);
  const [att, setatt] = useState([]);
  const [att2, setatt2] = useState([]);
  const [dets, setDets] = useState([]);
  const [array2, setArray2] = useState([]);
  const [attention2, setAttention2] = useState([]);
  const [dets2, setDets2] = useState([]);
  const [resLen, setResLen] = useState(0);
  const vehicleMethods = VehicleMethods();

  const componentRef = useRef(null);

  const createStepany = (length) => {
    var mainArray2 = [];
    //   console.log("firstMainarr", mainArray);
    if (!length) {
      return mainArray2;
    }
    //   console.log("firstMain", mainArray);
    var firstSubarray = [0, 1];
    //   console.log("firstSub", firstSubarray);
    mainArray2.push(firstSubarray);
    //   console.log("mainPushSub", mainArray);

    var remainingLength = length - 2;
    //   console.log("remLen", remainingLength);
    var subarrayLength = Math.min(remainingLength, 4);
    //   console.log("sublen", subarrayLength);

    while (remainingLength > 0) {
      var subarray = Array.from(
        { length: subarrayLength },
        (_, index) => index
      );
      mainArray2.push(subarray);
      //   console.log("pushMAin", mainArray);
      //     console.log("Sub", subarray);

      remainingLength -= subarrayLength;
      subarrayLength = Math.min(remainingLength, 4);
    }
    //   console.log("mainLAst", mainArray);

    return mainArray2;
  };

  const createSubArrays = (length, tierSequence) => {
    const mainArray = [];
    let currentIndex = 0;

    for (const tierLength of tierSequence) {
      const subarray = Array.from(
        { length: tierLength },
        (_, index) => index + currentIndex
      );
      mainArray.push(subarray);
      currentIndex += tierLength;
    }

    return mainArray;
  };

  useEffect(() => {
    if (parameters.inspectionId) {
      inspectionMethods.fetchInspection(parameters.inspectionId);
    }
  }, []);

  const editInspectionData = useSelector(
    (state) => state.inspection.editInspection
  );
  console.log("editInspection", editInspectionData);

  const clientId = useSelector(
    (state) => state.inspection.editInspection.clientId
  );
  console.log("clientId", clientId);

  const all = useSelector((state) => state.inspection);
  console.log("all", all);

  const vehicleReg = useSelector(
    (state) => state.inspection.editInspection.vehicleRegNumber
  );
  console.log("vehicleRegNumber", vehicleReg);

  let lastDate = useSelector((state) => state.inspection.last);
  console.log("last", lastDate);

  if (lastDate != null || lastDate != undefined) {
    lastDate = lastDate[0];
  } else {
    lastDate = "";
  }

  const inspDetails = useSelector(
    (state) => state.inspection.inspectionDetails
  );
  console.log("insp lennn", inspDetails?.length);

  const tierSequenceString = useSelector(
    (state) => state.inspection.editInspection.tireSequence
  );
  console.log("edit tier", tierSequenceString);

  let tireSequenceLabel = useSelector(
    (state) => state.inspection.editInspection
  );

  tireSequenceLabel = tireSequenceLabel?.tireSequenceLabel;
  if (tireSequenceLabel != undefined && tireSequenceLabel != null) {
    tireSequenceLabel = tireSequenceLabel.split(",");
  }
  console.log("tireSequenceLabel", tireSequenceLabel);

  useEffect(() => {
    if (clientId && vehicleReg) {
      inspectionMethods.fetchLastInspection(clientId, vehicleReg, parameters.inspectionId);
    }
  }, [clientId, vehicleReg, parameters.inspectionId]);

  useEffect(() => {
    if (editInspectionData && editInspectionData.noOfTires % 2 === 0) {
      initInspectionDetails();
      //initInspectionDetails2();
    }
    if (
      editInspectionData &&
      editInspectionData.noOfStepneys !== undefined &&
      editInspectionData.noOfStepneys !== 0
    ) {
      setResLen(inspDetails.length);
      console.log("use lennnnnnn", inspDetails.length);
      initInspectionDetails2();
    }
  }, [editInspectionData]);

  let tierSequenceArray;
  if (tierSequenceString != undefined) {
    tierSequenceArray = tierSequenceString
      .split(",")
      .map((str) => (str === "null" ? null : Number(str)))
      .filter(Number.isInteger);
  }

  const initInspectionDetails = async () => {
    const attention = [];
    const dets = [];
    const vehicleInspectionDetails =
      await inspectionMethods.getVehicleInspectionDetails(
        parameters.inspectionId
      );
    const response = vehicleInspectionDetails.splice(
      0,
      editInspectionData.noOfTires
    );
    console.log("tilakRes", response);
    let totalTires = await editInspectionData.noOfTires;
    // let totalTires = response.length;
    // console.log(totalTires, response.length);
    // if (editInspectionData.noOfTires > response.length) {
    //   totalTires = response.length;
    // }
    // const array = createSubArrays(totalTires);
    const validTierSequenceArray = await tierSequenceArray.slice(0, totalTires);

    const array = createSubArrays(totalTires, validTierSequenceArray);

    //console.log("tilakafterSub", array);
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      console.log("tilakElement", element);
      console.log("ele len", element.length);
      for (let i = 0; i < element.length; i++) {
        let tireNumber;
        const ele = element[i];
        console.log("tilakEle", ele);
        // const colorIndex =
        //   ele + (index === 1 || index === 0 ? index * 2 : index - 3);
        const colorIndex = ele;

        console.log("tilakColor", colorIndex);

        if (response[colorIndex]) {
          console.log("resp colo", response[colorIndex].recommendationLabel);
          tireNumber = response[colorIndex].tirePosition;
          //   console.log("tierNum", tireNumber);
          const tierReco = response[colorIndex].recommendationLabel;
          const retreadPercentage =
            (response[colorIndex].recoPressure * 100) /
            response[colorIndex].obsPressure;

          let pressureColor;

          // if (tierReco == "All Ok") {
          //   pressureColor = "GREEN";
          // } else if (retreadPercentage > 95 && retreadPercentage < 105) {
          //   pressureColor = "GREEN";
          // } else if (
          //   (retreadPercentage < 95 && retreadPercentage > 90) ||
          //   (retreadPercentage > 105 && retreadPercentage < 110)
          // ) {
          //   pressureColor = "YELLOW";
          // } else {
          //   if (retreadPercentage > 110) {
          //     attention.push({
          //       parameters: "High Pressure",
          //       tireNumber,
          //       recommendationLabel: response[colorIndex].recommendationLabel,
          //     });
          //   } else {
          //     attention.push({
          //       parameters: "Low Pressure",
          //       tireNumber,
          //       recommendationLabel: response[colorIndex].recommendationLabel,
          //     });
          //   }
          //   pressureColor = "RED";
          // }

          array[index][i] = {
            // treadColor:
            //   response[colorIndex].recommendationLabel == "All Ok"
            //     ? "GREEN"
            //     : response[colorIndex].leastTireThicknessAllowedAnalysis,
            treadColor: response[colorIndex].leastTireThicknessAllowedAnalysis,
            obsPressure: response[colorIndex].obsPressure,
            otdMm: response[colorIndex].otdMm,
            ctMm: response[colorIndex].ctMm,
            lstMm: response[colorIndex].lstMm,
            rstMm: response[colorIndex].rstMm,
            rtd: response[colorIndex].rtd,
            tireDamageColor:
              response[colorIndex].recommendationLabel == "All Ok"
                ? "GREEN"
                : response[colorIndex].recommendationLabel
                ? "RED"
                : "GREEN",
            pressureColor: response[colorIndex].pressureColorCode,
            tireNumber,
          };
          // if (response[colorIndex].obsPressure === "RED") {
          //   attention.push({
          //     parameters: "Low Tread Depth",
          //     tireNumber,
          //     recommendationLabel: response[colorIndex].recommendationLabel,
          //   });
          // }

          dets.push({
            tireNumber,
              tireMake: response[colorIndex].tireMakeLabel,
            tireType: response[colorIndex].tireTypeLabel,
            tireSize: response[colorIndex].tireSize,
            tirePattern: response[colorIndex].tirePattern,
            tireSerialNo: response[colorIndex].tireSerialNo,
          })

          if (response[colorIndex].leastTireThicknessAllowedAnalysis == "RED") {
            attention.push({
              parameters: "Low Tread Depth",
              tireNumber,
              recommendationLabel: "Check & replace / send for retread",
            });
          }

          if (response[colorIndex].pressureColorCode == "RED") {
            attention.push({
              parameters:
                response[colorIndex].pressureAnalysis == "EXTREME OVERINFLATION"
                  ? "High Pressure"
                  : "Low Pressure",
              tireNumber,
              recommendationLabel: response[colorIndex].pressureAnalysis,
            });
          }

          if (response[colorIndex].recommendationLabel) {
            attention.push({
              parameters: response[colorIndex].observationLabel,
              tireNumber,
              recommendationLabel: response[colorIndex].recommendationLabel,
            });
          }
        }
      }
    }

    setAttention(attention);
    let att = attention.filter((item) => item.parameters !== "All Ok")
    setatt(att);
    setDets(dets);
    setArray(array);
    console.log("tilakArr", array);
    console.log("atten", attention);
    console.log("dets", dets);
  };

  const initInspectionDetails2 = async () => {
    const attention = [];
    const dets = [];
    const vehicleInspectionDetails =
      await inspectionMethods.getVehicleInspectionDetails(
        parameters.inspectionId
      );

    console.log("no of res", vehicleInspectionDetails.length);

    let valStep = 0;
    if (vehicleInspectionDetails.length > editInspectionData.noOfTires) {
      valStep = vehicleInspectionDetails.length - editInspectionData.noOfTires;
    }
    const response = vehicleInspectionDetails.slice(-valStep);

    console.log("tilakResSt", response);
    let totalTires = await editInspectionData.noOfStepneys;
    // let totalTires = response.length;
    console.log("total", valStep);
    // if (editInspectionData.noOfTires > response.length) {
    //   totalTires = response.length;
    // }
    // const array = createSubArrays(totalTires);

    const array = createStepany(valStep);

    //console.log("tilakafterSub", array);
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      console.log("tilakElement", element);
      console.log("ele len", element.length);
      for (let i = 0; i < element.length; i++) {
        let tireNumber;
        const ele = element[i];
        // console.log("tilakEle", ele);
        const colorIndex = ele + (index === 0 ? index * 2 : index * 1 + 1);
        // const colorIndex = ele;

        console.log("tilakColor", colorIndex);

        if (response[colorIndex]) {
          console.log("resp colo", response[colorIndex].recommendationLabel);
          tireNumber = response[colorIndex].tirePosition;
          //   console.log("tierNum", tireNumber);
          const tierReco = response[colorIndex].recommendationLabel;
          const retreadPercentage =
            (response[colorIndex].recoPressure * 100) /
            response[colorIndex].obsPressure;

          let pressureColor;

          // if (tierReco == "All Ok") {
          //   pressureColor = "GREEN";
          // } else if (retreadPercentage > 95 && retreadPercentage < 105) {
          //   pressureColor = "GREEN";
          // } else if (
          //   (retreadPercentage < 95 && retreadPercentage > 90) ||
          //   (retreadPercentage > 105 && retreadPercentage < 110)
          // ) {
          //   pressureColor = "YELLOW";
          // } else {
          //   if (retreadPercentage > 110) {
          //     attention.push({
          //       parameters: "High Pressure",
          //       tireNumber,
          //       recommendationLabel: response[colorIndex].recommendationLabel,
          //     });
          //   } else {
          //     attention.push({
          //       parameters: "Low Pressure",
          //       tireNumber,
          //       recommendationLabel: response[colorIndex].recommendationLabel,
          //     });
          //   }
          //   pressureColor = "RED";
          // }

          array[index][i] = {
            // treadColor:
            //   response[colorIndex].recommendationLabel == "All Ok"
            //     ? "GREEN"
            //     : response[colorIndex].leastTireThicknessAllowedAnalysis,
            treadColor: response[colorIndex].leastTireThicknessAllowedAnalysis,
            obsPressure: response[colorIndex].obsPressure,
            otdMm: response[colorIndex].otdMm,
            ctMm: response[colorIndex].ctMm,
            lstMm: response[colorIndex].lstMm,
            rstMm: response[colorIndex].rstMm,
            rtd: response[colorIndex].rtd,
            tireDamageColor:
              response[colorIndex].recommendationLabel == "All Ok"
                ? "GREEN"
                : response[colorIndex].recommendationLabel
                ? "RED"
                : "GREEN",
            pressureColor: response[colorIndex].pressureColorCode,
            tireNumber,
          

          };
          // if (response[colorIndex].obsPressure === "RED") {
          //   attention.push({
          //     parameters: "Low Tread Depth",
          //     tireNumber,
          //     recommendationLabel: response[colorIndex].recommendationLabel,
          //   });
          // }

            dets.push({
            tireNumber,
              tireMake: response[colorIndex].tireMakeLabel,
            tireType: response[colorIndex].tireTypeLabel,
            tireSize: response[colorIndex].tireSize,
              tirePattern: response[colorIndex].tirePattern,
            tireSerialNo: response[colorIndex].tireSerialNo,
          })

          if (response[colorIndex].leastTireThicknessAllowedAnalysis == "RED") {
            attention.push({
              parameters: "Low Tread Depth",
              tireNumber,
              recommendationLabel: "Check & replace / send for retread",
            });
          }

          if (response[colorIndex].pressureColorCode == "RED") {
            attention.push({
              parameters:
                response[colorIndex].pressureAnalysis == "EXTREME OVERINFLATION"
                  ? "High Pressure"
                  : "Low Pressure",
              tireNumber,
              recommendationLabel: response[colorIndex].pressureAnalysis,
            });
          }

          if (response[colorIndex].recommendationLabel) {
            attention.push({
              parameters: response[colorIndex].observationLabel,
              tireNumber,
              recommendationLabel: response[colorIndex].recommendationLabel,
            });
          }
        }
      }
    }

    const filteredArray = array.map((arr) =>
      arr.filter((item) => typeof item === "object")
    );

    console.log("filterd st", filteredArray);

    setAttention2(attention);
    let att = attention.filter((item) => item.parameters !== "All Ok")
    setatt2(att);
    setDets2(dets);
    setArray2(filteredArray);
    console.log("tilakArrSt", filteredArray);
    console.log("attenSt", attention);
    console.log("dets 2" , dets2);
  };

  const printContent = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {/* <div
        className="container-fluids pl-2 w-100 main-div-r2 special-page"
        id="printable-content"
        ref={componentRef}
        style={{ fontFamily: "Domine" }}
      >
        <div className="row"> */}
          <div className="col-12 report-analytical" id="printable-content"
        ref={componentRef}>
            <Card
              className="m-0"
              style={{ width: "100", backgroundColor: "#FFF" }}
            >
              <div className="d-md-flex justify-content-md-between align-items-md-center pt-2 px-2">
                <p className="rep-head">VEHICLE INSPECTION REPORT</p>
                <img className="mb-3" src="/assets/images/logo.png" alt="MTSS" width="200" />
              </div>
              <Card.Body className="p-0 preserve-react-bootstrap">
                <div
                  style={{ gap: "6rem" }}
                  className="d-md-flex justify-content-md-between pt-2 px-2 pb-3"
                >
                  <div className="d-flex flex-column ">
                    {/* <div className="label">
                      <span>
                        Owner Name:{" "}
                        <span className="label-value">
                          {editInspectionData.clientName}
                        </span>
                      </span>
                    </div> */}
                    <div className="label mb-2">
                      <span>
                        Company Name:{" "}
                        <span className="label-value">
                          {editInspectionData.clientInformation
                            ? editInspectionData.clientInformation
                                .split(",")[0]
                                .trim()
                            : ""}
                        </span>
                      </span>
                    </div>

                    <div className="label mb-2">
                      Inspection Date:{" "}
                      <span className="label-value">
                        {moment.utc(editInspectionData.inspectionDateTime).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </div>
                    <div className="label mb-2">
                      Odometer Reading:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleOdometerReading}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-20 mobile-margin-top">
                    <div className="label mb-2">
                      Registration No.:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleRegNumber}
                      </span>
                    </div>

                    <div className="label mb-2">
                      Vehicle Make:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleManufacturerLabel}
                      </span>
                    </div>
                    <div className="label mb-2">
                      Vehicle Model:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleModelLabel}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column mobile-margin-top">
                    <div className="label mb-2">
                      Last Inspection Date:{" "}
                      <span className="label-value">
                        {lastDate &&
                          moment.utc(lastDate?.inspectionDateTime).format(
                            "DD-MM-YYYY"
                          )
                          // lastDate?.inspectionDateTime.slice(0, 10)
                          }
                           
                      </span>
                    </div>
                    <div className="label mb-2">
                      Last Odometer Reading:{" "}
                      <span className="label-value">
                        {lastDate && lastDate?.vehicleOdometereading}
                      </span>
                    </div>
                    <div className="label mb-2">
                      Vehicle Type:{" "}
                      <span className="label-value">
                        {editInspectionData.vehicleTypeLabel}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="h-2" style={{ margin: "0.75rem 0 2.25rem 0" }}></hr>
                <div
                  style={{ backgroundColor: "#FFF" }}
                  className="pl-3 pr-3 mt-3 d-flex justify-content-between"
                >
                  <div className="text-left">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "1.35rem",
                        fontFamily: "Domine",
                        fontWeight: "800",
                      }}
                    >
                      INDICATORS
                    </p>
                    <div className="d-flex align-items-center">
                      <div className="h-64">
                        <img
                          src="/assets/images/press.png"
                          alt="pressureSymp"
                          style={{ height: "52px", width: "50px" }}
                        />
                      </div>
                      <div className="mt-0 ml-3">Pressure</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="h-64">
                        <img
                          src="/assets/images/RTD.png"
                          alt="tireSym"
                          style={{
                            marginTop: "6px",
                            height: "60px",
                            width: "4.5rem",
                            marginLeft: "-10px",
                          }}
                        />
                      </div>
                      <div className="mt-0 ml-1">RTD</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="h-64">
                        <img
                          src="/assets/images/damage.png"
                          alt="damageSym"
                          style={{
                            marginLeft: "6px",
                            marginTop: "8px",
                            height: "52px",
                            width: "32px",
                          }}
                        />
                      </div>
                      <div className="mt-0 ml-4">Tire damage</div>
                    </div>
                  </div>
                </div>

                <div className="px-3 d-md-flex justify-content-md-between">
                  <div className="mr-1 mobile-wid" style={{ width: "60%" }}>
                    <div>
                      <div className="">
                        <div style={{ marginTop: "2rem" }} className="d-flex">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "3rem",
                              position: "relative",
                            }}
                            className="d-flex flex-column"
                          >
                            {" "}
                            {array.map((item, i) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "3rem",
                                }}
                                className="d-flex flex-column"
                              >
                                {item.length === 2 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "3rem",
                                      marginLeft: "7rem",
                                      marginRight: "7rem",
                                    }}
                                    className="d-flex flex-column mobile-margin"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex justify-content-end mr-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {item[0].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[0].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            width: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire"
                                        />

                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p>
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/rod.png"
                                          alt="rod"
                                          style={{
                                            height: "1.25rem",
                                            width: "13rem",
                                          }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "1.2rem",
                                            left: "46%",
                                            zIndex: "20",
                                          }}
                                          className="mobile-left"
                                        >
                                          {tireSequenceLabel != undefined &&
                                            tireSequenceLabel != null &&
                                            tireSequenceLabel[i]}
                                        </div>
                                      </div>

                                     

                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            width: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                         
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[1].tireNumber}
                                          </p>
                                        </div>
                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-end ml-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  textAlign: "center",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[1].obsPressure}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {item[1].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {item.length === 4 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "3rem",
                                    }}
                                    className="d-flex flex-column"
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        zIndex: "100",
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <div className="d-flex justify-content-end mr-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  textAlign: "center",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[0].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[0].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            maxWidth: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                        
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p>
                                        </div>
                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <img
                                        src="/assets/images/rod.png"
                                        style={{
                                          width: "1rem",
                                          height: "2rem",
                                        }}
                                        className="mobile-rod"
                                      ></img>

                                      <div
                                        className="d-flex"
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            width: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[1].tireNumber}
                                          </p>
                                        </div>

                                        <div
                                          style={{
                                            position: "relative",
                                            zIndex: "100",
                                          }}
                                          className="d-flex justify-content-end ml-2 mt-2 mb-2"
                                        >
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div
                                                    style={{ width: "1.5rem" }}
                                                    className="d-flex flex-column justify-content-between align-items-center"
                                                  >
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/press.png"
                                                        alt="pressureSymp"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/RTD.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "27px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/damage.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                    textAlign: "center",
                                                  }}
                                                  //className="pl-1 pr-1"
                                                >
                                                  {item[1].obsPressure}
                                                </td>
                                              </tr>
                                              <tr
                                                style={{ textAlign: "center" }}
                                              >
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //className="pl-1 pr-1"
                                                >
                                                  {item[1].rtd}
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                              <tr
                                                style={{ textAlign: "center" }}
                                              >
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //  className="pl-1 pr-1"
                                                >
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/rod.png"
                                          alt="rod"
                                          style={{
                                            height: "1.25rem",
                                            width: "10rem",
                                          }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "14px",
                                            left: "37%",
                                          }}
                                          className="mobile-bot"
                                        >
                                          {tireSequenceLabel != undefined &&
                                            tireSequenceLabel != null &&
                                            tireSequenceLabel[i]}
                                        </div>
                                      </div>

                                   

                                      <div
                                        style={{
                                          position: "relative",
                                          zIndex: "100",
                                        }}
                                        className="d-flex justify-content-end mr-2 mob-maring-left"
                                      >
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  textAlign: "center",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {item[2].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[2].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            maxWidth: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[2].tireNumber}
                                          </p>
                                        </div>
                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[2].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[2].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[2].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[2].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[2].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[2].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <img
                                        src="/assets/images/rod.png"
                                        style={{
                                          width: "1rem",
                                          height: "2rem",
                                        }}
                                        className="mobile-rod"
                                      ></img>

                                      <div
                                        className="d-flex"
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            width: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                       
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[3].tireNumber}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            zIndex: "100",
                                          }}
                                          className="d-flex justify-content-end ml-2 mt-2 mb-2"
                                        >
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div
                                                    style={{ width: "1.5rem" }}
                                                    className="d-flex flex-column justify-content-between align-items-center"
                                                  >
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/press.png"
                                                        alt="pressureSymp"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/RTD.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "27px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/damage.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                    textAlign: "center",
                                                  }}
                                                  //className="pl-1 pr-1"
                                                >
                                                  {item[3].obsPressure}
                                                </td>
                                              </tr>
                                              <tr
                                                style={{ textAlign: "center" }}
                                              >
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //className="pl-1 pr-1"
                                                >
                                                  {item[3].rtd}
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                              <tr
                                                style={{ textAlign: "center" }}
                                              >
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //  className="pl-1 pr-1"
                                                >
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[3].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[3].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[3].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[3].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[3].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[3].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                            <div
                              style={{ left: "50%", bottom: "0" }}
                              className="d-flex position-absolute h-100 mob-maring-left2"
                            >
                              <img
                                style={{ width: "0.8rem" }}
                                src="/assets/images/newArrow.png"
                                alt="Arrow"
                              />
                            </div>
                          </div>
                        </div>

                        <div style={{ textAlign: "left", marginTop: "2rem" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "3rem",
                            }}
                          >
                            {array2.map((item, i) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "3rem",
                                }}
                                className="d-flex flex-column"
                              >
                                {item.length === 1 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "3rem",
                                      marginLeft: "6rem",
                                    }}
                                    className="d-flex flex-column"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex justify-content-end mr-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  textAlign: "center",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {item[0].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {item[0].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      <div style={{ position: "relative" , marginLeft:"1rem"}}>
                                        <div className="d-flex align-items-center">
                                          <img
                                            src="/assets/images/tire3.png"
                                            alt="tire"
                                            style={{
                                              width: "2.75rem",
                                              height: "auto",
                                            }}
                                            className="mobile-tire2"

                                          />
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p>
                                        </div>

                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          {/* <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p> */}
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {item.length === 2 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "3rem",
                                      marginLeft: "7rem",
                                    }}
                                    className="d-flex flex-column mobile-margin"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex justify-content-end mr-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  textAlign: "center",
                                                }}
                                                // className="pl-1 pr-1"
                                              >
                                                {item[0].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{
                                                    width: "1.5rem",
                                                    textAlign: "center",
                                                  }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                // className="pl-1 pr-1"
                                              >
                                                {item[0].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      <div style={{ position: "relative" }}>
                                        <div className="d-flex align-items-center">
                                          <img
                                            src="/assets/images/tire3.png"
                                            alt="tire"
                                            style={{
                                              width: "2.75rem",
                                              height: "auto",
                                            }}
                                            className="mobile-tire2"

                                          />
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p>
                                        </div>

                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          {/* <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p> */}
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{ position: "relative" }}>
                                        {/* <img
                                          src="/assets/images/rod.png"
                                          alt="rod"
                                          style={{
                                            height: "1.25rem",
                                            width: "13rem",
                                          }}
                                        /> */}
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "14px",
                                            left: "47%",
                                          }}
                                          className="mobile-bot"

                                        >
                                          {tireSequenceLabel != undefined &&
                                            tireSequenceLabel != null &&
                                            tireSequenceLabel[i]}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          position: "relative",
                                          marginLeft: "4rem",
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <img
                                            src="/assets/images/tire3.png"
                                            alt="tire"
                                            style={{
                                              width: "2.75rem",
                                              height: "auto",
                                            }}
                                            className="mobile-tire2"

                                          />
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {item[1].tireNumber}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          {/* <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[1].tireNumber}
                                          </p> */}
                                        </div>
                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >

                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-end ml-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  textAlign: "center",
                                                }}
                                                //className="pl-1 pr-1"
                                              >
                                                {item[1].obsPressure}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[1].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                // className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {item.length === 4 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "3rem",
                                    }}
                                    className="d-flex flex-column"
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        zIndex: "100",
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <div className="d-flex justify-content-end mr-2">
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[0].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[0].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            maxWidth: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[0].tireNumber}
                                          </p>
                                        </div>
                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[0].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[0].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <img
                                        src="/assets/images/rod.png"
                                        style={{
                                          width: "1rem",
                                          height: "2rem",
                                        }}
                                        className="mobile-rod"
                                      ></img>

                                      <div
                                        className="d-flex"
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            width: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[1].tireNumber}
                                          </p>
                                        </div>

                                        <div
                                          style={{
                                            position: "relative",
                                            zIndex: "100",
                                          }}
                                          className="d-flex justify-content-end ml-2 mt-2 mb-2"
                                        >
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div
                                                    style={{ width: "1.5rem" }}
                                                    className="d-flex flex-column justify-content-between align-items-center"
                                                  >
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/press.png"
                                                        alt="pressureSymp"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/RTD.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "27px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/damage.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //  className="pl-1 pr-1"
                                                >
                                                  {item[1].obsPressure}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //className="pl-1 pr-1"
                                                >
                                                  {item[1].rtd}
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //  className="pl-1 pr-1"
                                                >
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                              //  width: "77%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[1].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[1].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/rod.png"
                                          alt="rod"
                                          style={{
                                            height: "1.25rem",
                                            width: "100%",
                                          }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "14px",
                                            left: "37%",
                                          }}
                                          className="mobile-bot"

                                        >
                                          {tireSequenceLabel != undefined &&
                                            tireSequenceLabel != null &&
                                            tireSequenceLabel[i]}
                                        </div>
                                      </div>

                                      {/* <hr
                                  style={{
                                    height: "1.25rem",
                                    width: "15rem",
                                    backgroundColor: "#908D8D",
                                  }}
                                ></hr> */}

                                      <div
                                        style={{
                                          position: "relative",
                                          zIndex: "100",
                                        }}
                                        className="d-flex justify-content-end mr-2"
                                      >
                                        <table
                                          className="w-100"
                                          border={1}
                                          style={{
                                            borderColor: "#000000",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[2].obsPressure}
                                              </td>
                                              <td rowspan="3">
                                                <div
                                                  style={{ width: "1.5rem" }}
                                                  className="d-flex flex-column justify-content-between align-items-center"
                                                >
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/press.png"
                                                      alt="pressureSymp"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "30px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/RTD.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "27px",
                                                      marginTop: "3px",
                                                    }}
                                                  >
                                                    <img
                                                      src="/assets/images/damage.png"
                                                      alt="tireSym"
                                                      style={{
                                                        height: "28px",
                                                        width: "20px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {item[2].rtd}
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                }}
                                                //  className="pl-1 pr-1"
                                              >
                                                {/* {item[0].otdMm} */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{ position: "relative" }}>
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            maxWidth: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[2].tireNumber}
                                          </p>
                                        </div>
                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[2].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[2].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[2].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[2].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[2].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[2].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <img
                                        src="/assets/images/rod.png"
                                        style={{
                                          width: "1rem",
                                          height: "2rem",
                                        }}
                                        className="mobile-rod"
                                      ></img>

                                      <div
                                        className="d-flex"
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src="/assets/images/tire3.png"
                                          alt="tire"
                                          style={{
                                            width: "2.75rem",
                                            height: "auto",
                                          }}
                                          className="mobile-tire2"

                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-1.75rem",
                                            left: "13px",
                                          }}
                                        >
                                          {/* <img
                                      style={{
                                        width: "500px",
                                        height: "2rem",
                                        position: "relative",
                                      }}
                                      src="/assets/images/comment.png"
                                    /> */}
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "Domine",
                                            }}
                                          >
                                            {item[3].tireNumber}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            zIndex: "100",
                                          }}
                                          className="d-flex justify-content-end ml-2 mt-2 mb-2"
                                        >
                                          <table
                                            className="w-100"
                                            border={1}
                                            style={{
                                              borderColor: "#000000",
                                              backgroundColor: "#ffffff",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td rowspan="3">
                                                  <div
                                                    style={{ width: "1.5rem" }}
                                                    className="d-flex flex-column justify-content-between align-items-center"
                                                  >
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/press.png"
                                                        alt="pressureSymp"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "30px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/RTD.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "27px",
                                                        marginTop: "3px",
                                                      }}
                                                    >
                                                      <img
                                                        src="/assets/images/damage.png"
                                                        alt="tireSym"
                                                        style={{
                                                          height: "28px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //className="pl-1 pr-1"
                                                >
                                                  {item[3].obsPressure}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //  className="pl-1 pr-1"
                                                >
                                                  {item[3].rtd}
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    height: "34px",
                                                    width: "34px",
                                                  }}
                                                  //  className="pl-1 pr-1"
                                                >
                                                  {/* {item[0].otdMm} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="tire2-div-r2">
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[3].pressureColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[3].pressureColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              width: "80%",
                                              //  width: "77%",
                                            }}
                                            className="mob-bubble"
                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[3].treadColor == "GREEN"
                                                  ? "#01BF47"
                                                  : item[3].treadColor === "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}                                            className="mob-bubble"


                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                item[3].tireDamageColor ==
                                                "GREEN"
                                                  ? "#01BF47"
                                                  : item[3].tireDamageColor ===
                                                    "RED"
                                                  ? "#FF4848"
                                                  : "#FFC402",
                                              border: "none",
                                              opacity: "0.8",
                                              borderRadius: "50%",
                                              //  width: "77%",
                                              width: "80%",
                                            }}
                                            className="mob-bubble"

                                          >
                                            <img
                                              style={{ visibility: "hidden" }}
                                              src="/assets/images/circ.png"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ marginLeft: "33%" }}
                        className={
                          "pl-2  d-flex " + (array.length > 3 ? "mt-5" : "mt-5")
                        }
                      >
                        <div className="d-flex w-50 justify-content-center">
                          <div className="text-center">
                            <div
                              style={{ width: "5rem" }}
                              className="h-64 d-flex justify-content-center"
                            >
                              <img
                                className="indicator-square"
                                style={{
                                  marginTop: "14px",
                                  border: "none",
                                }}
                                src="/assets/images/green.png"
                              />
                            </div>
                            <div style={{ fontSize: "14px" }} className="mt-0">
                              All Ok
                            </div>
                          </div>
                          <div className="pl-2 text-center">
                            <div
                              style={{ width: "5rem" }}
                              className="h-64 d-flex justify-content-center"
                            >
                              <img
                                className="indicator-square"
                                style={{
                                  marginTop: "14px",
                                  border: "none",
                                }}
                                src="/assets/images/yellow.png"
                              />
                            </div>
                            <div
                              style={{ width: "5rem" }}
                              // style={{ fontSize: "14px" }}
                              className="mt-0"
                            >
                              Needs Attention
                            </div>
                          </div>
                          <div className="pl-2 text-center">
                            <div className="h-64 d-flex justify-content-center">
                              <img
                                className="indicator-square"
                                style={{
                                  marginTop: "14px",
                                  border: "none",
                                }}
                                src="/assets/images/red.png"
                              />
                            </div>
                            <div style={{ fontSize: "14px" }} className="mt-0">
                              Problem
                            </div>
                          </div>
                        </div>
                      </div>

                     
                    </div>
                  </div>

                  <div
                    style={{ width: "40%", textAlign: "center" }}
                    className="table-22-main-div mobile-wid mobile-margin-top"
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "1.35rem",
                        fontFamily: "Domine",
                      }}
                    >
                      TIRES NEEDING ATTENTION
                    </p>
                    
                    <table border={0} className="w-100 mt-2 custom-table">
                      <tbody>
                        <tr className="custom-row2">
                          <td
                            style={{
                              border: "1px solid #818181",
                              borderRadius: "8px 0 0 0",
                            }}
                          >
                            Tire Position
                          </td>
                          <td>Observation</td>
                          <td
                            style={{
                              border: "1px solid #818181",
                              borderRadius: "0 8px 0 0",
                            }}
                          >
                            Recommendation
                          </td>
                        </tr>
                        {attention
                          .filter((item) => item.parameters !== "All Ok")
                          .map((item, i) => (
                            <React.Fragment key={i}>
                              <tr
                                className={`custom-row ${
                                  i % 2 === 0 ? "even-row" : "odd-row"
                                }`}
                              >
                                <td
                                  className={` ${
                                    i == att.length - 1 &&
                                    att2.length == 0 &&
                                    "left-border"
                                  }`}
                                >
                                  {item.tireNumber}
                                </td>
                                <td>{item.parameters}</td>
                                <td
                                  className={` ${
                                    i == att.length - 1 &&
                                    att2.length == 0 &&
                                    "bot-border"
                                  }`}
                                >
                                  {item.recommendationLabel}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        {attention2
                          .filter((item) => item.parameters !== "All Ok")
                          .map((item, i) => (
                            <React.Fragment key={i}>
                              <tr
                                className={`custom-row ${
                                  i % 2 === 0 ? "even-row" : "odd-row"
                                }`}
                              >
                                <td className={`${
                                    i == att2.length - 1 && "left-border"
                                  }`}>{item.tireNumber}</td>
                                <td>
                                  {item.parameters}
                                </td>
                                <td
                                  className={`${
                                    i == att2.length - 1 && "bot-border"
                                  }`}
                                >
                                  {item.recommendationLabel}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div style={{ width: "90%", margin: "3rem auto" }}>
                  <div className="table-23-main-div">
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "1.35rem",
                        fontFamily: "Domine",
                      }}
                    >
                      TIRE DETAILS
                    </p>
                    
                    <table border={0} className="w-100 mt-2 custom-table">
                      <tbody>
                        <tr className="custom-row3">
                          <td
                            style={{
                              border: "1px solid #818181",
                              borderRadius: "8px 0 0 0",
                            }}
                            className="table-23-text-div"
                          >
                            Tire Position
                          </td>
                          <td className="table-23-text-div">Tire Make</td>
                          <td className="table-23-text-div">New / Retread</td>
                          <td className="table-23-text-div">Tire Size</td>
                          <td className="table-23-text-div">Tire Pattern</td>
                          <td
                            style={{
                              border: "1px solid #818181",
                              borderRadius: "0 8px 0 0",
                            }}
                            className="table-23-text-div"
                          >
                            Tire SRNo.
                          </td>
                        </tr>
                        {dets
                          .filter((item) => item.parameters !== "All Ok")
                          .map((item, i) => (
                            <React.Fragment key={i}>
                              <tr
                                className={`custom-row3 ${
                                  i % 2 === 0 ? "even-row" : "odd-row"
                                }`}
                              >
                                <td
                                  className={`${
                                    i == dets.length - 1 &&
                                    dets2.length == 0 &&
                                    "left-border"
                                  }`}
                                >
                                  {item.tireNumber}
                                </td>
                                <td>{item.tireMake}</td>
                                <td>{item.tireType}</td>
                                <td>{item.tireSize}</td>
                                <td>{item.tirePattern}</td>
                                <td
                                  className={`${
                                    i == dets.length - 1 &&
                                    dets2.length == 0 &&
                                    "bot-border"
                                  }`}
                                >
                                  {item.tireSerialNo}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        {dets2
                          .filter((item) => item.parameters !== "All Ok")
                          .map((item, i) => (
                            <React.Fragment key={i}>
                              <tr
                                className={`custom-row3 ${
                                  i % 2 === 0 ? "even-row" : "odd-row"
                                }`}
                              >
                                <td
                                  className={`${
                                    i == dets2.length - 1 && "left-border"
                                  }`}
                                >
                                  {item.tireNumber}
                                </td>
                                <td>{item.tireMake}</td>
                                <td>{item.tireType}</td>
                                <td>{item.tireSize}</td>
                                <td>{item.tirePattern}</td>
                                <td
                                  className={` ${
                                    i == dets2.length - 1 && "bot-border"
                                  }`}
                                >
                                  {item.tireSerialNo}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  style={{
                    width: "94%",
                    margin: "0 0 0 5%",
                    display: "flex",
                    gap: "9rem",
                    justifyContent:"space-between",
                  }}
                  className="py-3 mobile-no-flex mobile-wid"
                >
                  <div
                    className="d-flex flex-column justify-content-between"
                    style={{ gap: "6px" }}
                  >
                    <div>Driver</div>
                    <div>
                      Name:{" "}
                      <span className="label-value">
                        {editInspectionData?.driverName}
                      </span>
                    </div>
                    <div>
                      Mobile No.:{" "}
                      <span className="label-value">
                        {editInspectionData?.driverMobileNo}
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-between mobile-margin-top"
                    style={{ gap: "6px" }}
                  >
                    <div>Maintenance Manager</div>
                    <div>
                      Name:{" "}
                      <span className="label-value">
                        {editInspectionData?.maintanaceManagerName}
                      </span>
                    </div>
                    <div>
                      Mobile No. :{" "}
                      <span className="label-value">
                        {editInspectionData?.maintanaceManagerMobileNo}
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-between pr-5 mobile-margin-top"
                    style={{ gap: "6px" }}
                  >
                    <div>Milan Tire Service & Solution</div>
                    <div>
                      Name :{" "}
                      <span className="label-value">
                        {editInspectionData?.milanTireServiceAndSolutionName}
                      </span>
                    </div>
                    <div>
                      Mobile No.:{" "}
                      <span className="label-value">
                        {
                          editInspectionData?.milanTireServiceAndSolutionMobileNo
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div
              className="print-btn"
              style={{
                margin: "auto",
                width: "10%",
                marginTop: "1rem",
              }}
            >
              <button
                style={{
                  padding: "0.5rem 1rem",
                  border: "1px solid",
                  borderRadius: "5px",
                }}
                onClick={() => printContent()}
              >
                Print
              </button>
            </div>
          </div>
        {/* </div>
      </div> */}
    </>
  );
};

export default Analytical2;
