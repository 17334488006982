import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import VehicleManufactureActionButtons from '../../../../components/ActionButtons/mst_button/VehicleManufactureActionButtons';
import VehicleManufactureDataMethod from '../../../../asyncMethods/mst_action/VehicleManufactureDataMethod';
import { useSelector } from 'react-redux';
import FilterComponent from '../../../../components/FilterComponent/Index';
import { useMemo } from 'react';

const columns = [
    {
        name: "Vehicle Manufacture Name",
        selector: (row) => row?.vehicleManufacturerName,
        sortable: true
    },

    {
        name: "Status",
        selector: (row) => row?.activeStatus,
        sortable: true
    },
    {
        name: "Action",
        selector: (row) => row?.actionButtons,
        center: true
    },
];

const VehicleManufactureData = () => {
    const navigate = useNavigate();
    const vehicleManufactureMethod = VehicleManufactureDataMethod();
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        vehicleManufactureMethod.fetchAllVehicleManufactureData();
    }, []);

    const statusUpdate = (vehicleManufacturerId, status) => {
        const data = {
            id: vehicleManufacturerId,
            status: status == 1 ? 0 : 1
        }
        vehicleManufactureMethod.vehicleManufactureStatusUpdate(data);
    }

    const data = Array.from(useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList))
        .map((vehicleManufacture) => ({
            ...vehicleManufacture,
            actionButtons: (
                <VehicleManufactureActionButtons
                    onStatusUpdate={statusUpdate}
                    vehicleManufactureDetails={vehicleManufacture}
                />
            ),
        }))
        .sort((b, a) => a.vehicleManufacturerId - b.vehicleManufacturerId);


    const filteredItems = data.filter(
        (item) =>
            item.vehicleManufacturerName.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <div className="d-flex justify-content-between m-3">
                <h5></h5>
                <Button
                    onClick={() => navigate("/mst-vehicle-manufacture/create")}
                    variant="primary"
                >
                    <i className="fa-solid fa-plus"></i> New Vehicle Manufacture
                </Button>
            </div>
            <Card>
                <Card.Body>
                    <DataTable
                        title="Vehicle Manufacture List"
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default VehicleManufactureData
