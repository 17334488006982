import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const MasterTypeActionButtons = ({ masterTypeDetails, onStatusUpdate }) => {

  const navigate = useNavigate();

  const redirectToEditPage = dropDownId => {
    navigate("/master-type/edit/" + masterTypeDetails.dropDownId);
  }

return (
    <>
      <div className='d-flex align-items-center'>
        <Button variant="primary" size="sm" onClick={() => redirectToEditPage(masterTypeDetails.dropDownId)} >
          <i className="fa-solid fa-user-pen"></i>
        </Button>
        <Form.Check
          inline
          className={'mx-3 text-danger'}
          type="switch"
          value={masterTypeDetails?.activeStatus === 1 ? 1 : 0}
          checked={masterTypeDetails?.activeStatus === 1 ? true : false}
          id={'toggle-button' + masterTypeDetails?.dropDownId}
          onChange={(e) => { onStatusUpdate(masterTypeDetails?.dropDownId, e.target.value) }}
        />
      </div>
    </>
  );
}

export default MasterTypeActionButtons;