import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextInputField from "../../../components/InputField/TextInputField";
import ObservationCategoryMaster from "../../../asyncMethods/ObservationCategoryMaster";
import Select from "../../../components/DropdownField/Select";

const schema = Yup.object({
  observationCategoryType: Yup.string().required("Required Field !"),
  activeStatus: Yup.number().required("Required Field !"),
});

const CreateObservationCategoryMaster = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const observationCategoryMaster = ObservationCategoryMaster();
  const [initialValues, setInitialValues] = useState({
    observationCategoryType: "",
    activeStatus: "",
  });

  useEffect(() => {
    if (parameters.observationCategoryId) {
      observationCategoryMaster.fetchObservationCategoryMasterData(parameters.observationCategoryId);
    }
  }, []);

  const editObservationCategoryMaster = useSelector(state => state?.observationCategoryMaster?.editObservationCategoryMasterList);

  // Edit Master Type Data
  useEffect(() => {
    if (parameters.observationCategoryId && editObservationCategoryMaster.observationCategoryId == parameters.observationCategoryId) {
      setInitialValues(editObservationCategoryMaster)
      console.log("🚀 ~ file: CreateObservationCategoryMaster.js:38 ~ useEffect ~ editObservationCategoryMaster:", editObservationCategoryMaster)
    }
  }, [editObservationCategoryMaster]);

  // Form handleSubmit
  const onSubmit = (data) => {
    if (parameters.observationCategoryId) {
      observationCategoryMaster.updateObservationCategoryMasterData(parameters.observationCategoryId, data)
    }
    else {
      observationCategoryMaster.storeObservationCategoryMasterData(data);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        console.log("saveOperationalData:", values)
        onSubmit(values);
      }}
    >
      {(formik) => {
        const { errors, touched } = formik;
        return (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5>{""}</h5>
              <Button
                onClick={() => navigate("/mst-observation-category")}
                variant="primary"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </Button>
            </div>
            <Card>
              <Card.Body>
                <Form>
                  <div className="m-3">
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"Observation Category Type"}
                        name={"observationCategoryType"}
                        required={true}
                        classProps={"col"}
                      />
                    </div>
                    <div className="form-group form-row m-1">
                      <Select
                        label={"Status"}
                        name={"activeStatus"}
                        required={true}
                        classProps={"col"}
                        errors={errors}
                        touched={touched}
                      >
                        <option value={""}>select</option>
                        <option value="1">
                          Active
                        </option>
                        <option value="0">
                          InActive
                        </option>
                      </Select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center m-3">
                    <button
                      type="submit"
                      className="btn-fill btn btn-info my-3"
                    >
                      {
                        parameters?.observationCategoryId ? "Update" : "Add"
                      } Data
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateObservationCategoryMaster;
