export const FETCH_ALL_VEHICLE = 'FETCH_ALL_VEHICLE';
export const SET_EDIT_VEHICLE = "SET_EDIT_VEHICLE";
export const SET_VEHICLE_TYPE_OPTIONS = "SET_VEHICLE_TYPE_OPTIONS";
export const SET_VEHICLE_SUB_TYPE_OPTIONS = "SET_VEHICLE_SUB_TYPE_OPTIONS";
export const SET_VEHICLE_MANUFACTURE = "SET_VEHICLE_MANUFACTURE";
export const SET_VEHICLE_USAGE_OPTIONS = "SET_VEHICLE_USAGE_OPTIONS";
// export const SET_VEHICLE_MAKER_OPTIONS = "SET_VEHICLE_MAKER_OPTIONS";
// export const SET_VEHICLE_TYRE_SIZE_OPTIONS = "SET_VEHICLE_TYRE_SIZE_OPTIONS";
// export const SET_VEHICLE_TYRE_LOCATION_TYPE_OPTIONS = "SET_VEHICLE_TYRE_LOCATION_TYPE_OPTIONS";
// export const SET_VEHICLE_SPEED_INDEX_OPTIONS = "SET_VEHICLE_SPEED_INDEX_OPTIONS";
// export const SET_VEHICLE_LOAD_INDEX_OPTIONS = "SET_VEHICLE_LOAD_INDEX_OPTIONS";