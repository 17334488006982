import { FETCH_ALL_VEHICLE_TYPE, SET_EDIT_VEHICLE_TYPE } from "../../constants/mst_type/VehicleTypeTypes";

const initialState = {
    vehicleTypeList: [],
    editVehicleType: {},
};

export const VehicleTypeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_VEHICLE_TYPE:
            return {
                ...state,
                vehicleTypeList: payload?.content,
            };
        case SET_EDIT_VEHICLE_TYPE:
            return {
                ...state,
                editVehicleType: payload,
            };

        default:
            return state;
    }
}