import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


const VehicleSubTypeActionButtons = ({ vehicleSubTypeDetails, onStatusUpdate  }) => {

  const navigate = useNavigate();

  const redirectToEditPage = userId => {
      navigate("/mst-vehicle-sub-type/edit/" + vehicleSubTypeDetails?.vehicleSubTypeId);
  }

  return (
      <>
          <div className='d-flex align-items-center'>
              <Button variant="primary" size="sm" onClick={() => redirectToEditPage(vehicleSubTypeDetails?.vehicleSubTypeId)} >
                  <i className="fa-solid fa-user-pen"></i>
              </Button>{" "}
              <Form.Check
                  inline
                  className={'mx-3 text-danger'}
                  type="switch"
                  value={vehicleSubTypeDetails?.activeStatus === 1 ? 1 : 0}
                  checked={vehicleSubTypeDetails?.activeStatus === 1 ? true : false}
                  id={'toggle-button' + vehicleSubTypeDetails?.vehicleSubTypeId}
                  onChange={(e) => { onStatusUpdate(vehicleSubTypeDetails?.vehicleSubTypeId, e.target.value) }}
              />
          </div>
      </>
  );
}

export default VehicleSubTypeActionButtons;