// import React, { useEffect, useState } from "react";
// import { Formik, Form, useFormikContext } from "formik";
// import * as Yup from "yup";
// import { Button, Card } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import VehicleMethods from "../../../asyncMethods/VehicleMethods";
// import Select from "../../../components/DropdownField/Select";
// import TextInputField from "../../../components/InputField/TextInputField";
// import { useSelector } from "react-redux";
// import { components } from "react-select";
// import { default as ReactSelect } from "react-select";

// import VehicleManufactureDataMethod from "../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
// import VehicleTypeDataMethod from "../../../asyncMethods/mst_action/VehicleTypeDataMethod";
// import VehicleUsageDataMethod from "../../../asyncMethods/mst_action/VehicleUsageDataMethod";
// import VehicleSubTypeDataMethod from "../../../asyncMethods/mst_action/VehicleSubTypeDataMethod";

// const schema = Yup.object({
//   vehicleModel: Yup.string(),
//   vehicleManufacturer: Yup.string().required("Required Field !"),
//   vehicleType: Yup.string().required("Required Field !"),
//   vehicleUsage: Yup.number().required("Required Field !"),
//   noOfTires: Yup.number(),
//   noOfAxle: Yup.number(),
//   noOfStepneys: Yup.number(),
// });

// const CreateVehicles = () => {
//   const navigate = useNavigate();
//   const parameters = useParams();
//   const [typeValue, setTypeValue] = useState(false);
//   const [subTypeValue, setSubTypeValue] = useState("");
//   const [subTypeLabel, setSubTypeLabel] = useState("");
//   const [defaultSubTypeValue, setDefaultSubTypeValue] = useState([]);
//   const [priorityNumbers, setPriorityNumbers] = useState({});
//   const [usedPriorityNumbers, setUsedPriorityNumbers] = useState(new Set());
//   const [rearrangedSubTypeLabel, setRearrangedSubTypeLabel] = useState("");
//   const [vehicleSubTypeTireMapping, setVehicleSubTypeTireMapping] = useState(
//     {}
//   );
//   const [totalNoOfTires, setTotalNoOfTires] = useState(0);
//   const [showTotalNoOfTires, setShowTotalNoOfTires] = useState(false);
//   const [updatedTireSequences2, setUpdatedTireSequences2] = useState([]);
//   const [initialCallMade, setInitialCallMade] = useState(false);
//   const [newTireSequences, setNewTireSequences] = useState([]);
  
//   console.log("new render");

//   const vehicleMethods = VehicleMethods();

//   const vehicleManufactureDataMethod = VehicleManufactureDataMethod();
//   const vehicleTypeDataMethod = VehicleTypeDataMethod();
//   const vehicleUsageDataMethod = VehicleUsageDataMethod();
//   const vehicleSubTypeDataMethod = VehicleSubTypeDataMethod();

//   const editVehicleData = useSelector((state) => state?.vehicle?.editVehicle);
//   console.log("edit vehId", editVehicleData);
//   let editVehicleData1;
//   if (
//     editVehicleData !== undefined &&
//     Object.keys(editVehicleData).length !== 0
//   ) {
//     editVehicleData1 = editVehicleData.tireSequence;
//     console.log("edit vehId1", editVehicleData1);
//   } else {
//     console.log("Data not fetched yet.", editVehicleData1);
//   }

//   const vehicleTypeOptions = Array.from(
//     useSelector((state) => state?.vehicleType?.vehicleTypeList)
//   ).filter((s) => s.activeStatus == 1);
//   const vehiclesubTypeOptions = Array.from(
//     useSelector((state) => state?.vehicleSubType?.vehicleSubTypeList)
//   ).filter(
//     (s) => s.vehicleTypeId.vehicleTypeId == typeValue && s.activeStatus == 1
//   );
//   const vehicleManufactureOptions = Array.from(
//     useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList)
//   ).filter((s) => s.activeStatus == 1);
//   const vehicleUsageOptions = Array.from(
//     useSelector((state) => state?.vehicleUsage?.vehicleUsageList)
//   ).filter((s) => s.activeStatus == 1);
//   const vehicleModelOptions = Array.from(
//     useSelector((state) => state?.vehicleModel?.filterVehicleModelList)
//   ).filter((s) => s.activeStatus == 1);

//   const [initialValues, setInitialValues] = useState({
//     vehicleModel: "",
//     vehicleManufacturer: "",
//     vehicleType: "",
//     vehicleSubType: "",
//     vehicleUsage: "",
//     // noOfTires: "",
//     noOfTires: totalNoOfTires, // Initialize with the calculated totalNoOfTires
//     noOfAxle: "",
//     noOfStepneys: "",
//     activeStatus: 1,
//     tireSequence: "", // Add the tireSequence property here
//   });

//   useEffect(() => {
//     if (parameters.vehicleId) {
//       vehicleMethods.fetchVehicleData(parameters.vehicleId);
//     }
//   }, []);

//   useEffect(() => {
//     vehicleManufactureDataMethod.fetchAllVehicleManufactureData();
//     vehicleTypeDataMethod.fetchAllVehicleTypeData();
//     vehicleUsageDataMethod.fetchAllVehicleUsageData();
//   }, []);

//   const updatedVehicleData = {
//     vehicleModel: editVehicleData?.vehicleModel,
//     vehicleManufacturer: Number(editVehicleData?.vehicleManufacturer),
//     vehicleType: Number(editVehicleData?.vehicleType),
//     vehicleSubType: editVehicleData?.vehicleSubType,
//     vehicleUsage: Number(editVehicleData?.vehicleUsage),
//     noOfTires: Number(editVehicleData?.noOfTires),
//     noOfAxle: editVehicleData?.noOfAxle,
//     noOfStepneys: editVehicleData?.noOfStepneys,
//     activeStatus: editVehicleData?.activeStatus,
//     vehicleSubTypeLabel: editVehicleData?.vehicleSubTypeLabel,
//     tireSequence: editVehicleData?.tireSequence,
//     // totalNoOfTires: Number(editVehicleData?.noOfTires), // Include totalNoOfTires based on noOfTires
//   };

//   // Edit vehicle Data
//   useEffect(() => {
//     if (
//       parameters.vehicleId &&
//       editVehicleData.vehicleId == parameters.vehicleId
//     ) {
//       setInitialValues(updatedVehicleData);
//       setTypeValue(updatedVehicleData.vehicleType);
//       setSubTypeValue(updatedVehicleData.vehicleSubType);
//       setSubTypeLabel(updatedVehicleData.vehicleSubTypeLabel);
//       const vehicleSubTypeArr = (updatedVehicleData.vehicleSubType || "").split(
//         ","
//       );

//       const vehicleSubTypeLabelArr = (
//         updatedVehicleData.vehicleSubTypeLabel || ""
//       ).split(",");
//       const defaultSubType = [];
//       for (let index = 0; index < vehicleSubTypeArr.length; index++) {
//         const element = vehicleSubTypeArr[index];
//         defaultSubType.push({
//           label: vehicleSubTypeLabelArr[index],
//           value: Number(element),
//         });
//       }
//       setDefaultSubTypeValue(defaultSubType);
//       vehicleSubTypeDataMethod.fetchAllVehicleSubTypeData(
//         updatedVehicleData.vehicleType
//       );
//       vehicleManufactureDataMethod.getVehicleByFilter(
//         updatedVehicleData.vehicleManufacturer
//       );
//     }
//   }, [editVehicleData]);

//   useEffect(() => {
//     if (typeValue) {
//       //vehicleSubTypeDataMethod.fetchAllVehicleSubTypeData(values.vehicleTypeId);
//     }
//   }, [typeValue]);

//   let calculatedTotal = Object.values(vehicleSubTypeTireMapping)
//     .filter((value) => value !== null)
//     .reduce((acc, value) => acc + value, 0);

//   // Fetch the vehicle data and set the calculatedTotal
//   // useEffect(() => {
//   //   if (editVehicleData) {
//   //     const fetchedNoOfTires = editVehicleData.noOfTires;
//   //     if (fetchedNoOfTires) {
//   //       calculatedTotal = fetchedNoOfTires;
//   //     }
//   //   }
//   //   //console.log("use calculate", calculatedTotal);
//   // }, [editVehicleData]);

//   // useEffect(() => {
//   //   // Calculate the total number of tires based on vehicleSubTypeTireMapping

//   //   //console.log("Updating totalNoOfTires:", calculatedTotal);
//   //   setTotalNoOfTires(calculatedTotal);
//   //   // console.log("totalNoOfTires1:", totalNoOfTires);
//   // }, [vehicleSubTypeTireMapping]);

//   // useEffect(() => {
//   //  // console.log("totalNoOfTires after update:", totalNoOfTires);
//   //   setTotalNoOfTires(calculatedTotal);
//   // //  console.log("totalNoOfTires2:", totalNoOfTires);
//   // }, [totalNoOfTires]);

//   // Sub Type dropdown
//   const Option = (props) => {
//     return (
//       <div id="multiselectCheckbox" className="form-row">
//         <components.Option
//           {...props}
//           id="multiselectCheckbox"
//           className="col-md-12"
//         >
//           <input
//             type="checkbox"
//             checked={props.isSelected}
//             onChange={() => null}
//           />
//           <label className="pl-2 mb-0">{props.label}</label>
//         </components.Option>
//       </div>
//     );
//   };

//   const mappedOptions = vehiclesubTypeOptions?.map((item) => ({
//     value: item?.vehicleSubTypeId,
//     label: item?.vehicleSubTypeName,
//   }));

//   const handleOnChange = (event) => {
//     if (event.target.name === "vehicleType") {
//       setDefaultSubTypeValue([]);
//       const vehicleTypeId =
//         event.target.options[event.target.options.selectedIndex].getAttribute(
//           "data-dataid"
//         ) ?? 0;
//       setTypeValue(vehicleTypeId);
//       vehicleSubTypeDataMethod.fetchAllVehicleSubTypeData(vehicleTypeId);

//       // Clear the vehicleSubTypeTireMapping when changing vehicle type
//       setVehicleSubTypeTireMapping({});
//     }
//   };

//   const handleSubTypeChange = (value) => {
//     let selectedValue = value?.map((item) => item?.value);
//     let selectedLabel = value?.map((item) => item?.label);

//     let newValue = selectedValue.join(",");
//     let newLabel = selectedLabel.join(",");
//     setSubTypeValue(newValue);
//     setSubTypeLabel(newLabel);
//     setDefaultSubTypeValue(value);

//     // Initialize priority numbers for selected sub types
//     const newPriorityNumbers = {};
//     selectedValue.forEach((subType, index) => {
//       newPriorityNumbers[subType] = index + 1; // Initialize with 1, 2, 3, ...
//     });
//     setPriorityNumbers(newPriorityNumbers);

//     // Update the vehicleSubTypeTireMapping based on selected sub types
//     const newMapping = {};
//     selectedValue.forEach((subType) => {
//       const noOfTires = vehiclesubTypeOptions.find(
//         (item) => item.vehicleSubTypeId === subType
//       )?.noOfTires;
//       if (noOfTires !== undefined) {
//         newMapping[subType] = noOfTires;
//       }
//     });
//     setVehicleSubTypeTireMapping(newMapping);

//     setDefaultSubTypeValue(value);
//     setPriorityNumbers(newPriorityNumbers);

//     console.log("vehicleSubTypeTireMapping:", newMapping);
//     setShowTotalNoOfTires(true);
//   };

//   const onSelectVehicleManufacturer = async (vehicleManufacturer) => {
//     vehicleManufactureDataMethod.getVehicleByFilter(vehicleManufacturer);
//   };

//   const sortedSubTypes = defaultSubTypeValue.slice().sort((a, b) => {
//     const priorityA = priorityNumbers[a.value];
//     const priorityB = priorityNumbers[b.value];
//     return priorityA - priorityB;
//   });


//   useEffect(() => {
//     const newRearrangedSubTypeLabel = sortedSubTypes
//       .map((subType) => subType.label)
//       .join(",");
//     setRearrangedSubTypeLabel(newRearrangedSubTypeLabel);
//   }, [sortedSubTypes]);

//   const tireSequences = sortedSubTypes.map((subType) => {
//     return vehicleSubTypeTireMapping[subType.value];
//   });

//   const tireSequencesString = tireSequences.join(", ");

//   let originalPriorityNumbers = { ...priorityNumbers }; // Store the initial priority numbers

//   const hasPriorityChanged = () => {
//     return defaultSubTypeValue.some(
//       (subType) =>
//         priorityNumbers[subType.value] !==
//         originalPriorityNumbers[subType.value]
//     );
//   };

//   let newTireSequences2 = editVehicleData1;

//   useEffect(() => {
//     if (updatedTireSequences2) {
//       newTireSequences2 = updatedTireSequences2.map((priority) => {
//         return editVehicleData.tireSequence.split(", ")[priority - 1];
//       });
//     }
//     setNewTireSequences(newTireSequences2);
//     console.log("my", newTireSequences2);

//   },[updatedTireSequences2])

//   const onSubmit = (data) => {
//     const isNewSubtypeSelection = data.vehicleSubType !== subTypeValue; // Check if there's a change in subtype selection

//     let tireSequenceToSend = data.tireSequence; // Use existing value by default

//     if (newTireSequences != "") {
//       tireSequenceToSend = newTireSequences.join(",");
//     }

//     if (isNewSubtypeSelection) {
//       tireSequenceToSend = tireSequencesString; // Use calculated value if subtype has changed
//     }

//     if (isNewSubtypeSelection) {
//       const priorityValues = defaultSubTypeValue.map(
//         (subType) => priorityNumbers[subType.value]
//       );

//       const updatedTireSequences = priorityValues.map(
//         (priority) => tireSequences[priority - 1]
//       );

//       // Sort the updated tire sequences based on the priority values
//       const sortedUpdatedTireSequences = updatedTireSequences
//         .slice()
//         .sort((a, b) => {
//           const aPriority = priorityValues[updatedTireSequences.indexOf(a)];
//           const bPriority = priorityValues[updatedTireSequences.indexOf(b)];
//           return aPriority - bPriority;
//         });

//       const updatedTireSequencesString = sortedUpdatedTireSequences.join(", ");
//       tireSequenceToSend = updatedTireSequencesString;

//       console.log("Updated tireSequenceToSend:", tireSequenceToSend);
//     }

//     console.log("tireSequences:", tireSequences);
//     console.log("Calculated tireSequencesString:", tireSequencesString);

//     console.log("to sned:", tireSequenceToSend);

//     if (parameters.vehicleId) {
//       vehicleMethods.updateVehicleData(parameters.vehicleId, {
//         ...data,
//         vehicleSubTypeLabel: rearrangedSubTypeLabel,
//         tireSequence:
//           tireSequenceToSend == "" ? tireSequencesString : tireSequenceToSend, // Add tire sequence here
//       });
//     } else {
//       vehicleMethods.storeVehicleData({
//         ...data,
//         vehicleSubTypeLabel: rearrangedSubTypeLabel,
//         tireSequence:
//           tireSequenceToSend == "" ? tireSequencesString : tireSequenceToSend, // Add tire sequence here
//       });
//     }
//   };

//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={schema}
//       enableReinitialize={true}
//       onSubmit={(values) => {
//         let vehicleTypeLabel = vehicleTypeOptions?.find((item) => {
//           return values?.vehicleType == item?.vehicleTypeId;
//         });

//         const isNewSubtypeSelection = values.vehicleSubType !== subTypeValue;

//         let vehicleManufacturerLabel = vehicleManufactureOptions?.find(
//           (item) => {
//             return values?.vehicleManufacturer == item?.vehicleManufacturerId;
//           }
//         );

//         let vehicleModelLabel = vehicleModelOptions?.find((item) => {
//           return values?.vehicleModel == item?.vehicleModelId;
//         });

//         let vehicleUsageLabel = vehicleUsageOptions?.find((item) => {
//           return values?.vehicleUsage == item?.vehicleUsageId;
//         });

//         const newValue = {
//           vehicleManufacturer: values?.vehicleManufacturer,
//           vehicleManufacturerLabel:
//             vehicleManufacturerLabel?.vehicleManufacturerName,
//           vehicleModel: values?.vehicleModel,
//           vehicleModelLabel: vehicleModelLabel?.vehicleModelName,
//           vehicleType: values?.vehicleType,
//           vehicleTypeLabel: vehicleTypeLabel?.vehicleTypeName,
//           vehicleSubType: subTypeValue,
//           //vehicleSubTypeLabel: subTypeLabel,
//           vehicleSubTypeLabel: rearrangedSubTypeLabel, // Assign the rearranged subtypes directly
//           vehicleUsage: values?.vehicleUsage,
//           vehicleUsageLabel: vehicleUsageLabel?.vehicleUsageName,
//           noOfTires: values?.noOfTires,
//           //noOfTires: values?.calculatedTotal,       // -------------------noOfTires
//           noOfAxle: values?.noOfAxle,
//           noOfStepneys: values?.noOfStepneys,
//           activeStatus: values?.activeStatus,
//           tireSequence: isNewSubtypeSelection
//             ? tireSequencesString
//             : values?.tireSequence,
//         };
//         console.log("values-subtype...", values);
//         console.log("newValue:", newValue);

//         onSubmit(newValue);
//       }}
//     >
//       {(formik) => {
//         const { errors, touched, setFieldValue } = formik;

//         // Update the 'noOfTires' field value in the formik form

//         return (
//           <>
//             <div className="d-flex justify-content-between m-3">
//               <h5>{""}</h5>
//               <Button onClick={() => navigate("/vehicle")} variant="primary">
//                 <i className="fa-solid fa-arrow-left"></i> Back
//               </Button>
//             </div>
//             <Card>
//               <Card.Body>
//                 <Form onChange={handleOnChange}>
//                   <div className="m-2">
//                     <div className="form-group form-row">
//                       <Select
//                         errors={errors}
//                         touched={touched}
//                         label="Vehicle Manufacturer"
//                         name="vehicleManufacturer"
//                         classProps="col-md-12"
//                         required={true}
//                         onChange={(e) => {
//                           setFieldValue("vehicleManufacturer", e.target.value);
//                           onSelectVehicleManufacturer(e.target.value);
//                         }}
//                       >
//                         <option value="">select</option>

//                         {vehicleManufactureOptions?.map((data, index) => (
//                           <option
//                             key={index}
//                             value={data.vehicleManufacturerId}
//                           >
//                             {data.vehicleManufacturerName}
//                           </option>
//                         ))}
//                       </Select>
//                     </div>
//                     {/* <div className="form-group form-row">
//                       <TextInputField
//                         errors={errors}
//                         touched={touched}
//                         type={"text"}
//                         label={"vehicle Model"}
//                         name={"vehicleModel"}
//                         classProps={"col-md-12"}
//                       />

//                     </div> */}
//                     <div className="form-group form-row">
//                       <Select
//                         errors={errors}
//                         touched={touched}
//                         label="Vehicle Model"
//                         name="vehicleModel"
//                         classProps="col-md-12"
//                         required={true}
//                       >
//                         <option value="">select</option>
//                         {vehicleModelOptions?.map((data, index) => (
//                           <option
//                             key={index}
//                             value={data.vehicleModelId}
//                             data-dataid={data.vehicleModelId}
//                           >
//                             {data.vehicleModelName}
//                           </option>
//                         ))}
//                       </Select>
//                     </div>
//                     <div className="form-group form-row">
//                       <Select
//                         errors={errors}
//                         touched={touched}
//                         label="Vehicle Type"
//                         name="vehicleType"
//                         classProps="col-md-12"
//                         required={true}
//                       >
//                         <option value="">select</option>
//                         {vehicleTypeOptions?.map((data, index) => (
//                           <option
//                             key={index}
//                             value={data.vehicleTypeId}
//                             data-dataid={data.vehicleTypeId}
//                           >
//                             {data.vehicleTypeName}
//                           </option>
//                         ))}
//                       </Select>
//                     </div>

//                     <div className="form-group form-row">
//                       <label className="col-md-12 py-0">Vehicle Sub Type</label>
//                       <div style={{ width: "100%" }}>
//                         <ReactSelect
//                           className="col-md-12 pt-0"
//                           name="vehicleSubType"
//                           options={mappedOptions}
//                           isDisabled={
//                             typeValue || parameters.vehicleId ? false : true
//                           }
//                           isMulti
//                           closeMenuOnSelect={false}
//                           hideSelectedOptions={false}
//                           components={{
//                             Option,
//                           }}
//                           onChange={handleSubTypeChange}
//                           allowSelectAll={true}
//                           value={defaultSubTypeValue}
//                         />
//                       </div>
//                     </div>

//                     <table className="table">
//                       <thead>
//                         <tr>
//                           <th>Vehicle Sub Type</th>
//                           <th>Priority Number</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {defaultSubTypeValue.map((subType) => (
//                           <tr key={subType.value}>
//                             <td>{subType.label}</td>
//                             <td>
//                               <input
//                                 type="number"
//                                 value={priorityNumbers[subType.value]}
//                                 onChange={(e) => {
//                                   const newValue = parseInt(e.target.value);
//                                   console.log("Changing input to:", newValue);

//                                   // Handle both keyboard input and direct input
//                                   const newPriorityNumbers = {
//                                     ...priorityNumbers,
//                                     [subType.value]: isNaN(newValue)
//                                       ? ""
//                                       : newValue >= 1 &&
//                                         newValue <= defaultSubTypeValue.length
//                                       ? newValue
//                                       : priorityNumbers[subType.value],
//                                   };

//                                   console.log(
//                                     "Updated priorityNumbers:",
//                                     newPriorityNumbers
//                                   );
//                                   setPriorityNumbers(newPriorityNumbers);

//                                   // Update tireSequences and tireSequencesString based on newPriorityNumbers
//                                   const updatedTireSequences =
//                                     defaultSubTypeValue.map(
//                                       (subType) =>
//                                         newPriorityNumbers[subType.value]
//                                     );
//                                   const updatedTireSequencesString =
//                                     updatedTireSequences.join(", ");

//                                   setUpdatedTireSequences2(
//                                     updatedTireSequences
//                                   );

//                                   console.log(
//                                     "Updated tireSequences:",
//                                     updatedTireSequences
//                                   );
//                                   console.log(
//                                     "Updated tireSequencesString:",
//                                     updatedTireSequencesString
//                                   );

//                                   console.log(
//                                     "state tireSequencesString:",
//                                     updatedTireSequences2
//                                   );
//                                 }}
//                               />
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>

//                     {/* <table className="table">
//                       <thead>
//                         <tr>
//                           <th>Vehicle Sub Type</th>
//                           <th>Priority Number</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {defaultSubTypeValue.map((subType) => (
//                           <tr key={subType.value}>
//                             <td>{subType.label}</td>
//                             <td>
//                               <input
//                                 type="number"
//                                 value={priorityNumbers[subType.value]}
//                                 onChange={(e) => {
//                                   const newValue = parseInt(e.target.value);

//                                   if (
//                                     isNaN(newValue) ||
//                                     newValue < 1 ||
//                                     newValue > defaultSubTypeValue.length
//                                   ) {
//                                     // Reset priority number if not valid
//                                     updatePriorityNumber(subType.value, "");
//                                     return;
//                                   }

//                                   // If the priority number is used elsewhere, don't update
//                                   if (
//                                     isPriorityNumberUsed(
//                                       newValue,
//                                       subType.value
//                                     )
//                                   ) {
//                                     return;
//                                   }

//                                   // Update the state
//                                   updatePriorityNumber(subType.value, newValue);
//                                 }}
//                               />
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table> */}

//                     {/* <div className="form-group form-row">
//                       <label className="col-md-12 py-0">
//                         Rearranged Vehicle Sub Type
//                       </label>
//                       <div className="col-md-12">
//                         <input
//                           type="text"
//                           readOnly
//                           className="form-control"
//                           value={rearrangedSubTypeLabel}
//                         />
//                       </div>
//                     </div> */}

//                     {/* <div className="form-group form-row">
//                       <Select
//                         errors={errors}
//                         touched={touched}
//                         label="Vehicle Sub Type"
//                         name="vehicleSubType"
//                         classProps={"col-md-12"}
//                         disabled={typeValue === false ? true : false}
//                       >
//                         <option value="">select</option>

//                         {
//                           vehiclesubTypeOptions?.map((data, index) => (
//                             <option key={index} value={data.vehicleSubTypeId} >
//                               {data.vehicleSubTypeName}
//                             </option>
//                           ))
//                         }

//                       </Select>
//                     </div> */}

//                     <div className="form-group form-row">
//                       <Select
//                         errors={errors}
//                         touched={touched}
//                         label="Vehicle Usage"
//                         name="vehicleUsage"
//                         classProps="col-md-12"
//                         required={true}
//                       >
//                         <option value="">select</option>
//                         {vehicleUsageOptions?.map((data, index) => (
//                           <option key={index} value={data.vehicleUsageId}>
//                             {data.vehicleUsageName}
//                           </option>
//                         ))}
//                       </Select>
//                     </div>

//                     <div className="form-group form-row">
//                       <TextInputField
//                         errors={errors}
//                         touched={touched}
//                         // required={true}
//                         type="text"
//                         label="No. of Tiers"
//                         name="noOfTires"
//                         classProps="col-md-12"
//                       />
//                     </div>

//                     {/* {showTotalNoOfTires ? (
//                       <div className="form-group form-row">
//                         <label className="col-md-12 py-0">No. of Tires</label>
//                         <div className="col-md-12">
//                           <input
//                             type="text"
//                             // readOnly
//                             className="form-control"
//                             name="noOfTires"
//                             value={totalNoOfTires} // Make sure to define totalNoOfTires
//                           />
//                         </div>
//                       </div>
//                     ) : (
//                       <div className="form-group form-row">
//                         <TextInputField
//                           errors={errors}
//                           touched={touched}
//                           // required={true}
//                           type="text"
//                           label="No. of Tiers"
//                           name="noOfTires"
//                           classProps="col-md-12"
//                           // readOnly
//                         />
//                       </div>
//                     )} */}

//                     {/* {showTotalNoOfTires && (
//                       <div className="form-group form-row">
//                         <label className="col-md-12 py-0">Tire Sequences</label>
//                         <div className="col-md-12">
//                           <input
//                             type="text"
//                             readOnly
//                             className="form-control"
//                             name="tireSequences"
//                             value={tireSequences.join(", ")}
//                           />
//                         </div>
//                       </div>
//                     )} */}

//                     {showTotalNoOfTires ? (
//                       <div className="form-group form-row">
//                         <label className="col-md-12 py-0">Tire Sequences</label>
//                         <div className="col-md-12">
//                           <input
//                             type="text"
//                             readOnly
//                             className="form-control"
//                             name="tireSequences"
//                             value={tireSequences.join(", ")}
//                           />
//                         </div>
//                       </div>
//                     ) : (
//                       <div className="form-group form-row">
//                         <label className="col-md-12 py-0">Tire Sequences</label>
//                         <div className="col-md-12">
//                           <input
//                             type="text"
//                             readOnly
//                             className="form-control"
//                             name="tireSequence"
//                             value={editVehicleData.tireSequence}
//                           />
//                         </div>
//                       </div>
//                     )}

//                     <div className="form-group form-row">
//                       <TextInputField
//                         errors={errors}
//                         touched={touched}
//                         type={"text"}
//                         label="No Of Axle"
//                         name="noOfAxle"
//                         classProps={"col-md-12"}
//                       />
//                     </div>
//                     <div className="form-group form-row">
//                       <TextInputField
//                         errors={errors}
//                         touched={touched}
//                         type={"text"}
//                         label="No Of Stepneys"
//                         name="noOfStepneys"
//                         classProps={"col-md-12"}
//                       />
//                     </div>
//                   </div>
//                   <div className="d-flex justify-content-center m-3">
//                     <button
//                       type="submit"
//                       className="btn-fill btn btn-info my-3"
//                     >
//                       {parameters.vehicleId ? "Update" : "Add"} Vehicle
//                     </button>
//                   </div>
//                 </Form>
//               </Card.Body>
//             </Card>
//           </>
//         );
//       }}
//     </Formik>
//   );
// };

// export default CreateVehicles;

import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import VehicleMethods from "../../../asyncMethods/VehicleMethods";
import Select from "../../../components/DropdownField/Select";
import TextInputField from "../../../components/InputField/TextInputField";
import { useSelector } from "react-redux";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

import VehicleManufactureDataMethod from "../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
import VehicleTypeDataMethod from "../../../asyncMethods/mst_action/VehicleTypeDataMethod";
import VehicleUsageDataMethod from "../../../asyncMethods/mst_action/VehicleUsageDataMethod";
import VehicleSubTypeDataMethod from "../../../asyncMethods/mst_action/VehicleSubTypeDataMethod";

const schema = Yup.object({
  vehicleModel: Yup.string(),
  vehicleManufacturer: Yup.string().required("Required Field !"),
  vehicleType: Yup.string().required("Required Field !"),
  vehicleUsage: Yup.number().required("Required Field !"),
  noOfTires: Yup.number(),
  noOfAxle: Yup.number(),
  noOfStepneys: Yup.number(),
});

const CreateVehicles = () => {
  const navigate = useNavigate();
  const parameters = useParams();
  const [typeValue, setTypeValue] = useState(false);
  const [subTypeValue, setSubTypeValue] = useState("");
  const [subTypeLabel, setSubTypeLabel] = useState("");
  const [defaultSubTypeValue, setDefaultSubTypeValue] = useState([]);
  const [priorityNumbers, setPriorityNumbers] = useState({});
  const [usedPriorityNumbers, setUsedPriorityNumbers] = useState(new Set());
  const [rearrangedSubTypeLabel, setRearrangedSubTypeLabel] = useState("");
  const [vehicleSubTypeTireMapping, setVehicleSubTypeTireMapping] = useState(
    {}
  );
  const [totalNoOfTires, setTotalNoOfTires] = useState(0);
  const [showTotalNoOfTires, setShowTotalNoOfTires] = useState(false);
  const [updatedTireSequences2, setUpdatedTireSequences2] = useState([]);
  const [initialCallMade, setInitialCallMade] = useState(false);

  const vehicleMethods = VehicleMethods();

  const vehicleManufactureDataMethod = VehicleManufactureDataMethod();
  const vehicleTypeDataMethod = VehicleTypeDataMethod();
  const vehicleUsageDataMethod = VehicleUsageDataMethod();
  const vehicleSubTypeDataMethod = VehicleSubTypeDataMethod();

  const editVehicleData = useSelector((state) => state?.vehicle?.editVehicle);
  console.log("edit vehId", editVehicleData);
  const vehicleTypeOptions = Array.from(
    useSelector((state) => state?.vehicleType?.vehicleTypeList)
  ).filter((s) => s.activeStatus == 1);
  const vehiclesubTypeOptions = Array.from(
    useSelector((state) => state?.vehicleSubType?.vehicleSubTypeList)
  ).filter(
    (s) => s.vehicleTypeId.vehicleTypeId == typeValue && s.activeStatus == 1
  );
  const vehicleManufactureOptions = Array.from(
    useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleUsageOptions = Array.from(
    useSelector((state) => state?.vehicleUsage?.vehicleUsageList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleModelOptions = Array.from(
    useSelector((state) => state?.vehicleModel?.filterVehicleModelList)
  ).filter((s) => s.activeStatus == 1);

  const [initialValues, setInitialValues] = useState({
    vehicleModel: "",
    vehicleManufacturer: "",
    vehicleType: "",
    vehicleSubType: "",
    vehicleUsage: "",
    // noOfTires: "",
    noOfTires: totalNoOfTires, // Initialize with the calculated totalNoOfTires
    noOfAxle: "",
    noOfStepneys: "",
    activeStatus: 1,
    tireSequence: "", // Add the tireSequence property here
  });

  useEffect(() => {
    if (parameters.vehicleId) {
      vehicleMethods.fetchVehicleData(parameters.vehicleId);
    }
  }, []);

  useEffect(() => {
    vehicleManufactureDataMethod.fetchAllVehicleManufactureData();
    vehicleTypeDataMethod.fetchAllVehicleTypeData();
    vehicleUsageDataMethod.fetchAllVehicleUsageData();
  }, []);

  const updatedVehicleData = {
    vehicleModel: editVehicleData?.vehicleModel,
    vehicleManufacturer: Number(editVehicleData?.vehicleManufacturer),
    vehicleType: Number(editVehicleData?.vehicleType),
    vehicleSubType: editVehicleData?.vehicleSubType,
    vehicleUsage: Number(editVehicleData?.vehicleUsage),
    noOfTires: Number(editVehicleData?.noOfTires),
    noOfAxle: editVehicleData?.noOfAxle,
    noOfStepneys: editVehicleData?.noOfStepneys,
    activeStatus: editVehicleData?.activeStatus,
    vehicleSubTypeLabel: editVehicleData?.vehicleSubTypeLabel,
    tireSequence: editVehicleData?.tireSequence,
    // totalNoOfTires: Number(editVehicleData?.noOfTires), // Include totalNoOfTires based on noOfTires
  };

  // Edit vehicle Data
  useEffect(() => {
    if (
      parameters.vehicleId &&
      editVehicleData.vehicleId == parameters.vehicleId
    ) {
      setInitialValues(updatedVehicleData);
      setTypeValue(updatedVehicleData.vehicleType);
      setSubTypeValue(updatedVehicleData.vehicleSubType);
      setSubTypeLabel(updatedVehicleData.vehicleSubTypeLabel);
      const vehicleSubTypeArr = (updatedVehicleData.vehicleSubType || "").split(
        ","
      );

      const vehicleSubTypeLabelArr = (
        updatedVehicleData.vehicleSubTypeLabel || ""
      ).split(",");
      const defaultSubType = [];
      for (let index = 0; index < vehicleSubTypeArr.length; index++) {
        const element = vehicleSubTypeArr[index];
        defaultSubType.push({
          label: vehicleSubTypeLabelArr[index],
          value: Number(element),
        });
      }
      setDefaultSubTypeValue(defaultSubType);
      vehicleSubTypeDataMethod.fetchAllVehicleSubTypeData(
        updatedVehicleData.vehicleType
      );
      vehicleManufactureDataMethod.getVehicleByFilter(
        updatedVehicleData.vehicleManufacturer
      );
    }
  }, [editVehicleData]);

  useEffect(() => {
    if (typeValue) {
      //vehicleSubTypeDataMethod.fetchAllVehicleSubTypeData(values.vehicleTypeId);
    }
  }, [typeValue]);

  let calculatedTotal = Object.values(vehicleSubTypeTireMapping)
    .filter((value) => value !== null)
    .reduce((acc, value) => acc + value, 0);

  // Fetch the vehicle data and set the calculatedTotal
  // useEffect(() => {
  //   if (editVehicleData) {
  //     const fetchedNoOfTires = editVehicleData.noOfTires;
  //     if (fetchedNoOfTires) {
  //       calculatedTotal = fetchedNoOfTires;
  //     }
  //   }
  //   //console.log("use calculate", calculatedTotal);
  // }, [editVehicleData]);

  // useEffect(() => {
  //   // Calculate the total number of tires based on vehicleSubTypeTireMapping

  //   //console.log("Updating totalNoOfTires:", calculatedTotal);
  //   setTotalNoOfTires(calculatedTotal);
  //   // console.log("totalNoOfTires1:", totalNoOfTires);
  // }, [vehicleSubTypeTireMapping]);

  // useEffect(() => {
  //  // console.log("totalNoOfTires after update:", totalNoOfTires);
  //   setTotalNoOfTires(calculatedTotal);
  // //  console.log("totalNoOfTires2:", totalNoOfTires);
  // }, [totalNoOfTires]);

  // Sub Type dropdown
  const Option = (props) => {
    return (
      <div id="multiselectCheckbox" className="form-row">
        <components.Option
          {...props}
          id="multiselectCheckbox"
          className="col-md-12"
        >
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="pl-2 mb-0">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const mappedOptions = vehiclesubTypeOptions?.map((item) => ({
    value: item?.vehicleSubTypeId,
    label: item?.vehicleSubTypeName,
  }));

  const handleOnChange = (event) => {
    if (event.target.name === "vehicleType") {
      setDefaultSubTypeValue([]);
      const vehicleTypeId =
        event.target.options[event.target.options.selectedIndex].getAttribute(
          "data-dataid"
        ) ?? 0;
      setTypeValue(vehicleTypeId);
      vehicleSubTypeDataMethod.fetchAllVehicleSubTypeData(vehicleTypeId);

      // Clear the vehicleSubTypeTireMapping when changing vehicle type
      setVehicleSubTypeTireMapping({});
    }
  };

  const handleSubTypeChange = (value) => {
    let selectedValue = value?.map((item) => item?.value);
    let selectedLabel = value?.map((item) => item?.label);

    let newValue = selectedValue.join(",");
    let newLabel = selectedLabel.join(",");
    setSubTypeValue(newValue);
    setSubTypeLabel(newLabel);
    setDefaultSubTypeValue(value);

    // Initialize priority numbers for selected sub types
    const newPriorityNumbers = {};
    selectedValue.forEach((subType, index) => {
      newPriorityNumbers[subType] = index + 1; // Initialize with 1, 2, 3, ...
    });
    setPriorityNumbers(newPriorityNumbers);

    // Update the vehicleSubTypeTireMapping based on selected sub types
    const newMapping = {};
    selectedValue.forEach((subType) => {
      const noOfTires = vehiclesubTypeOptions.find(
        (item) => item.vehicleSubTypeId === subType
      )?.noOfTires;
      if (noOfTires !== undefined) {
        newMapping[subType] = noOfTires;
      }
    });
    setVehicleSubTypeTireMapping(newMapping);

    setDefaultSubTypeValue(value);
    setPriorityNumbers(newPriorityNumbers);

    console.log("vehicleSubTypeTireMapping:", newMapping);
    setShowTotalNoOfTires(true);
  };

  const onSelectVehicleManufacturer = async (vehicleManufacturer) => {
    vehicleManufactureDataMethod.getVehicleByFilter(vehicleManufacturer);
  };

  const sortedSubTypes = defaultSubTypeValue.slice().sort((a, b) => {
    const priorityA = priorityNumbers[a.value];
    const priorityB = priorityNumbers[b.value];
    return priorityA - priorityB;
  });

  useEffect(() => {
    const newRearrangedSubTypeLabel = sortedSubTypes
      .map((subType) => subType.label)
      .join(",");
    setRearrangedSubTypeLabel(newRearrangedSubTypeLabel);
  }, [sortedSubTypes]);

  const tireSequences = sortedSubTypes.map((subType) => {
    return vehicleSubTypeTireMapping[subType.value];
  });

  const filteredTireSequences = tireSequences.filter(
    (element) => element !== undefined
  );
  const tireSequencesString = filteredTireSequences.join(", ");

  console.log("tier ", tireSequencesString);

  let originalPriorityNumbers = { ...priorityNumbers }; // Store the initial priority numbers

  const hasPriorityChanged = () => {
    return defaultSubTypeValue.some(
      (subType) =>
        priorityNumbers[subType.value] !==
        originalPriorityNumbers[subType.value]
    );
  };

  useEffect(() => {
    let newTireSequences;
    if (updatedTireSequences2) {
      newTireSequences = updatedTireSequences2.map((priority) => {
        return editVehicleData.tireSequence.split(", ")[priority - 1];
      });
    }

    console.log("my", newTireSequences);
  }, [updatedTireSequences2]);

  const onSubmit = (data) => {
    const isNewSubtypeSelection = data.vehicleSubType !== subTypeValue; // Check if there's a change in subtype selection

    let tireSequenceToSend = data.tireSequence; // Use existing value by default

    if (isNewSubtypeSelection) {
      tireSequenceToSend = tireSequencesString; // Use calculated value if subtype has changed
    }

    if (isNewSubtypeSelection) {
      const priorityValues = defaultSubTypeValue.map(
        (subType) => priorityNumbers[subType.value]
      );

      const updatedTireSequences = priorityValues.map(
        (priority) => tireSequences[priority - 1]
      );

      // Sort the updated tire sequences based on the priority values
      const sortedUpdatedTireSequences = updatedTireSequences
        .slice()
        .sort((a, b) => {
          const aPriority = priorityValues[updatedTireSequences.indexOf(a)];
          const bPriority = priorityValues[updatedTireSequences.indexOf(b)];
          return aPriority - bPriority;
        });

      const updatedTireSequencesString = sortedUpdatedTireSequences.join(", ");
      tireSequenceToSend = updatedTireSequencesString;

      console.log("Updated tireSequenceToSend:", tireSequenceToSend);
    }

      console.log("tireSequences:", tireSequences);
      console.log("Calculated tireSequencesString:", tireSequencesString);

      console.log("to sned:", tireSequenceToSend);

    if (parameters.vehicleId) {
      vehicleMethods.updateVehicleData(parameters.vehicleId, {
        ...data,
        vehicleSubTypeLabel: rearrangedSubTypeLabel,
        tireSequence:
          tireSequencesString == "" ? tireSequenceToSend : tireSequencesString, // Add tire sequence here
      });
    } else {
      vehicleMethods.storeVehicleData({
        ...data,
        vehicleSubTypeLabel: rearrangedSubTypeLabel,
        tireSequence:
          tireSequencesString == "" ? tireSequenceToSend : tireSequencesString, // Add tire sequence here
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        let vehicleTypeLabel = vehicleTypeOptions?.find((item) => {
          return values?.vehicleType == item?.vehicleTypeId;
        });

        const isNewSubtypeSelection = values.vehicleSubType !== subTypeValue;

        let vehicleManufacturerLabel = vehicleManufactureOptions?.find(
          (item) => {
            return values?.vehicleManufacturer == item?.vehicleManufacturerId;
          }
        );

        let vehicleModelLabel = vehicleModelOptions?.find((item) => {
          return values?.vehicleModel == item?.vehicleModelId;
        });

        let vehicleUsageLabel = vehicleUsageOptions?.find((item) => {
          return values?.vehicleUsage == item?.vehicleUsageId;
        });

        const newValue = {
          vehicleManufacturer: values?.vehicleManufacturer,
          vehicleManufacturerLabel:
            vehicleManufacturerLabel?.vehicleManufacturerName,
          vehicleModel: values?.vehicleModel,
          vehicleModelLabel: vehicleModelLabel?.vehicleModelName,
          vehicleType: values?.vehicleType,
          vehicleTypeLabel: vehicleTypeLabel?.vehicleTypeName,
          vehicleSubType: subTypeValue,
          //vehicleSubTypeLabel: subTypeLabel,
          vehicleSubTypeLabel: rearrangedSubTypeLabel, // Assign the rearranged subtypes directly
          vehicleUsage: values?.vehicleUsage,
          vehicleUsageLabel: vehicleUsageLabel?.vehicleUsageName,
          noOfTires: values?.noOfTires,
          //noOfTires: values?.calculatedTotal,       // -------------------noOfTires
          noOfAxle: values?.noOfAxle,
          noOfStepneys: values?.noOfStepneys,
          activeStatus: values?.activeStatus,
          tireSequence: isNewSubtypeSelection
            ? tireSequencesString
            : values?.tireSequence,
        };
        console.log("values-subtype...", values);
        console.log("newValue:", newValue);

        onSubmit(newValue);
      }}
    >
      {(formik) => {
        const { errors, touched, setFieldValue } = formik;

        // Update the 'noOfTires' field value in the formik form

        return (
          <>
            <div className="d-flex justify-content-between m-3">
              <h5>{""}</h5>
              <Button onClick={() => navigate("/vehicle")} variant="primary">
                <i className="fa-solid fa-arrow-left"></i> Back
              </Button>
            </div>
            <Card>
              <Card.Body>
                <Form onChange={handleOnChange}>
                  <div className="m-2">
                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Vehicle Manufacturer"
                        name="vehicleManufacturer"
                        classProps="col-md-12"
                        required={true}
                        onChange={(e) => {
                          setFieldValue("vehicleManufacturer", e.target.value);
                          onSelectVehicleManufacturer(e.target.value);
                        }}
                      >
                        <option value="">select</option>

                        {vehicleManufactureOptions?.map((data, index) => (
                          <option
                            key={index}
                            value={data.vehicleManufacturerId}
                          >
                            {data.vehicleManufacturerName}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {/* <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label={"vehicle Model"}
                        name={"vehicleModel"}
                        classProps={"col-md-12"}
                      />

                    </div> */}
                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Vehicle Model"
                        name="vehicleModel"
                        classProps="col-md-12"
                        required={true}
                      >
                        <option value="">select</option>
                        {vehicleModelOptions?.map((data, index) => (
                          <option
                            key={index}
                            value={data.vehicleModelId}
                            data-dataid={data.vehicleModelId}
                          >
                            {data.vehicleModelName}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Vehicle Type"
                        name="vehicleType"
                        classProps="col-md-12"
                        required={true}
                      >
                        <option value="">select</option>
                        {vehicleTypeOptions?.map((data, index) => (
                          <option
                            key={index}
                            value={data.vehicleTypeId}
                            data-dataid={data.vehicleTypeId}
                          >
                            {data.vehicleTypeName}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="form-group form-row">
                      <label className="col-md-12 py-0">Vehicle Sub Type</label>
                      <div style={{ width: "100%" }}>
                        <ReactSelect
                          className="col-md-12 pt-0"
                          name="vehicleSubType"
                          options={mappedOptions}
                          isDisabled={
                            typeValue || parameters.vehicleId ? false : true
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={handleSubTypeChange}
                          allowSelectAll={true}
                          value={defaultSubTypeValue}
                        />
                      </div>
                    </div>

                    <table className="table">
                      <thead>
                        <tr>
                          <th>Vehicle Sub Type</th>
                          <th>Priority Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {defaultSubTypeValue.map((subType) => (
                          <tr key={subType.value}>
                            <td>{subType.label}</td>
                            <td>
                              <input
                                type="number"
                                value={priorityNumbers[subType.value]}
                                onChange={(e) => {
                                  const newValue = parseInt(e.target.value);
                                  console.log("Changing input to:", newValue);

                                  // Handle both keyboard input and direct input
                                  const newPriorityNumbers = {
                                    ...priorityNumbers,
                                    [subType.value]: isNaN(newValue)
                                      ? ""
                                      : newValue >= 1 &&
                                        newValue <= defaultSubTypeValue.length
                                      ? newValue
                                      : priorityNumbers[subType.value],
                                  };

                                  console.log(
                                    "Updated priorityNumbers:",
                                    newPriorityNumbers
                                  );
                                  setPriorityNumbers(newPriorityNumbers);

                                  // Update tireSequences and tireSequencesString based on newPriorityNumbers
                                  const updatedTireSequences =
                                    defaultSubTypeValue.map(
                                      (subType) =>
                                        newPriorityNumbers[subType.value]
                                    );
                                  const updatedTireSequencesString =
                                    updatedTireSequences.join(", ");

                                  setUpdatedTireSequences2(
                                    updatedTireSequences
                                  );

                                  console.log(
                                    "Updated tireSequences:",
                                    updatedTireSequences
                                  );
                                  console.log(
                                    "Updated tireSequencesString:",
                                    updatedTireSequencesString
                                  );

                                  console.log(
                                    "state tireSequencesString:",
                                    updatedTireSequences2
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* <table className="table">
                      <thead>
                        <tr>
                          <th>Vehicle Sub Type</th>
                          <th>Priority Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {defaultSubTypeValue.map((subType) => (
                          <tr key={subType.value}>
                            <td>{subType.label}</td>
                            <td>
                              <input
                                type="number"
                                value={priorityNumbers[subType.value]}
                                onChange={(e) => {
                                  const newValue = parseInt(e.target.value);

                                  if (
                                    isNaN(newValue) ||
                                    newValue < 1 ||
                                    newValue > defaultSubTypeValue.length
                                  ) {
                                    // Reset priority number if not valid
                                    updatePriorityNumber(subType.value, "");
                                    return;
                                  }

                                  // If the priority number is used elsewhere, don't update
                                  if (
                                    isPriorityNumberUsed(
                                      newValue,
                                      subType.value
                                    )
                                  ) {
                                    return;
                                  }

                                  // Update the state
                                  updatePriorityNumber(subType.value, newValue);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> */}

                    {/* <div className="form-group form-row">
                      <label className="col-md-12 py-0">
                        Rearranged Vehicle Sub Type
                      </label>
                      <div className="col-md-12">
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          value={rearrangedSubTypeLabel}
                        />
                      </div>
                    </div> */}

                    {/* <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Vehicle Sub Type"
                        name="vehicleSubType"
                        classProps={"col-md-12"}
                        disabled={typeValue === false ? true : false}
                      >
                        <option value="">select</option>

                        {
                          vehiclesubTypeOptions?.map((data, index) => (
                            <option key={index} value={data.vehicleSubTypeId} >
                              {data.vehicleSubTypeName}
                            </option>
                          ))
                        }

                      </Select>
                    </div> */}

                    <div className="form-group form-row">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Vehicle Usage"
                        name="vehicleUsage"
                        classProps="col-md-12"
                        required={true}
                      >
                        <option value="">select</option>
                        {vehicleUsageOptions?.map((data, index) => (
                          <option key={index} value={data.vehicleUsageId}>
                            {data.vehicleUsageName}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        // required={true}
                        type="text"
                        label="No. of Tiers"
                        name="noOfTires"
                        classProps="col-md-12"
                      />
                    </div>

                    {/* {showTotalNoOfTires ? (
                      <div className="form-group form-row">
                        <label className="col-md-12 py-0">No. of Tires</label>
                        <div className="col-md-12">
                          <input
                            type="text"
                            // readOnly
                            className="form-control"
                            name="noOfTires"
                            value={totalNoOfTires} // Make sure to define totalNoOfTires
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="form-group form-row">
                        <TextInputField
                          errors={errors}
                          touched={touched}
                          // required={true}
                          type="text"
                          label="No. of Tiers"
                          name="noOfTires"
                          classProps="col-md-12"
                          // readOnly
                        />
                      </div>
                    )} */}

                    {/* {showTotalNoOfTires && (
                      <div className="form-group form-row">
                        <label className="col-md-12 py-0">Tire Sequences</label>
                        <div className="col-md-12">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="tireSequences"
                            value={tireSequences.join(", ")}
                          />
                        </div>
                      </div>
                    )} */}

                    {showTotalNoOfTires ? (
                      <div className="form-group form-row">
                        <label className="col-md-12 py-0">Tire Sequences</label>
                        <div className="col-md-12">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="tireSequences"
                            value={tireSequences.join(", ")}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="form-group form-row">
                        <label className="col-md-12 py-0">Tire Sequences</label>
                        <div className="col-md-12">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="tireSequence"
                            value={editVehicleData.tireSequence}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label="No Of Axle"
                        name="noOfAxle"
                        classProps={"col-md-12"}
                      />
                    </div>
                    <div className="form-group form-row">
                      <TextInputField
                        errors={errors}
                        touched={touched}
                        type={"text"}
                        label="No Of Stepneys"
                        name="noOfStepneys"
                        classProps={"col-md-12"}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center m-3">
                    <button
                      type="submit"
                      className="btn-fill btn btn-info my-3"
                    >
                      {parameters.vehicleId ? "Update" : "Add"} Vehicle
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateVehicles;

