import { FETCH_ALL_OPERATIONAL_MASTER, SET_EDIT_OPERATIONAL_MASTER } from "../constants/OperationalMasterTypes"

const initialState = {
    operationalMasterList: [],
    editOperationalMasterList: {},
}

export const OperationalMasterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ALL_OPERATIONAL_MASTER:
            return {
                ...state,
                operationalMasterList: payload?.content
            }
        case SET_EDIT_OPERATIONAL_MASTER:
            return {
                ...state,
                editOperationalMasterList: payload
            }
        default:
            return state
    }
}