import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const VehicleTypeActionButtons = ({ vehicleTypeDetails, onStatusUpdate  }) => {

    const navigate = useNavigate();

    const redirectToEditPage = userId => {
        navigate("/mst-vehicle-type/edit/" + vehicleTypeDetails?.vehicleTypeId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(vehicleTypeDetails?.vehicleTypeId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>{" "}
                <Form.Check
                    inline
                    className={'mx-3 text-danger'}
                    type="switch"
                    value={vehicleTypeDetails?.activeStatus === 1 ? 1 : 0}
                    checked={vehicleTypeDetails?.activeStatus === 1 ? true : false}
                    id={'toggle-button' + vehicleTypeDetails?.vehicleTypeId}
                    onChange={(e) => { onStatusUpdate(vehicleTypeDetails?.vehicleTypeId, e.target.value) }}
                />
            </div>
        </>
    );
}

export default VehicleTypeActionButtons;