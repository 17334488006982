import React, { useState, useRef, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { useSelector } from "react-redux";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import TextInputField from "../../../components/InputField/TextInputField";
import Select from "../../../components/DropdownField/Select";
import VehicleManufactureDataMethod from "../../../asyncMethods/mst_action/VehicleManufactureDataMethod";
import VehicleTypeDataMethod from "../../../asyncMethods/mst_action/VehicleTypeDataMethod";
import VehicleModelDataMethod from "../../../asyncMethods/mst_action/VehicleModelDataMethod";
import ClientMethods from "../../../asyncMethods/ClientMethods";
import InspectionMethods from "../../../asyncMethods/InspectionMethods";
import CVMethods from "../../../asyncMethods/CVMethods";
import moment from "moment";
import api from "../../../api";

const InspectionForm = () => {
  const [vehicleRegNumber, setVehicleRegNumber] = useState("");
  const [clientId1, setClientId1] = useState();
  const [clientlocId1, setClientlocId1] = useState();
  const [vehs, setVehs] = useState([]);
  const [vehicleData, setVehicleData] = useState(null);
  const [err, setErr] = useState(false);
  const [nul, setNull] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedVehicleManufacturer, setSelectedVehicleManufacturer] =
    useState(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedVehicleManufacturerId, setSelectedVehicleManufacturerId] =
    useState(null);
  const [selectedVehicleModelId, setSelectedVehicleModelId] = useState(null);
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const cvMethods = CVMethods();

  const parameters = useParams();
  const formRef = useRef();
  const schema = Yup.object({
    clientId: Yup.string().required("Client Name is required !"),
    clientInformation: Yup.string().optional(),
    clientName: Yup.string().optional(),
    clientServiceLocationId: Yup.string().required(
      "Client Location is required."
    ),
    inspectionDateTime: Yup.string().required("Date And Time is required !"),
    vehicleRegNumber: Yup.string()
      .required("Vehicle Reg No is required !")
      .test(
        "no-spaces-special-chars",
        "Letter should be in capital.No spaces or special characters allowed",
        (value) => {
          // Test for spaces and special characters (only allowing capital letters and digits)
          return /^[A-Z0-9]*$/.test(value);
        }
      ),
    vehicleManufacturer: Yup.string().optional(),
    vehicleManufacturerLabel: Yup.string().optional(),
    vehicleModel: Yup.string().optional(),
    vehicleType: Yup.string().optional(),
    vehicleOdometerReading: Yup.string().required(
      "Vehicle Odometer Reading is required !"
    ),
    vehicleId: Yup.string().required("Vehicle Id is required !"),
    noOfTires: Yup.string().optional(),
    configuredVehicle: Yup.string().required(
      "vehicleConfigurationKey is required !"
    ),
    noOfStepneys: Yup.string().optional(),
    inspectionStatus: Yup.string().optional(),
    tireSequence: Yup.string().optional(),
    tireSequenceLabel: Yup.string().optional(),
    driverName: Yup.string().optional(),
    driverMobileNo: Yup.string().optional(),
    maintanaceManagerName: Yup.string().optional(),
    maintanaceManagerMobileNo: Yup.string().optional(),
    milanTireServiceAndSolutionName: Yup.string().optional(),
    milanTireServiceAndSolutionMobileNo: Yup.string().optional(),
    milanTireServiceAndSolutionName: Yup.string().optional(),
    milanTireServiceAndSolutionMobileNo: Yup.string().optional()
  });

  const [initialValues, setInitialValues] = useState({
    inspectionId: "",
    clientId: "",
    clientName: "",
    clientInformation: "",
    clientServiceLocationId: "",
    inspectionDateTime: "",
    vehicleRegNumber: "",
    vehicleOdometerReading: "",
    vehicleId: "",
    noOfTires: "",
    configuredVehicle: "",
    noOfStepneys: "",
    inspectionStatus: "1",
    activeStatus: "1",
    vehicleManufacturer: "",
    vehicleManufacturerLabel: "",
    vehicleModel: "",
    vehicleType: "",
    tireSequence: "",
    tireSequenceLabel: "",
    driverName: "",
    driverMobileNo: "",
    maintanaceManagerName: "",
    maintanaceManagerMobileNo: "",
    milanTireServiceAndSolutionName: "",
    milanTireServiceAndSolutionMobileNo: "",
    mtssName: "",
    mtssMobileNo: ""
  });

  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedVehs, setSelectedVehs] = useState("");
  const [serviceLocations, setServiceLocations] = useState([]);
  const [date, setDate] = useState(getCurrentDateTime());
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const vehicleManufactureDataMethod = VehicleManufactureDataMethod();
  const vehicleTypeDataMethod = VehicleTypeDataMethod();
  const vehicleModelDataMethod = VehicleModelDataMethod();
  const clientMethods = ClientMethods();
  const inspectionMethods = InspectionMethods();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;
  const role = user?.roleType;

  useEffect(() => {
    cvMethods.fetchAllCVInspections();
  }, []);

  console.log("sssssssssssss", selectedVehs);
  let all = useSelector((state) => state);
  //console.log("all", all);
  const vehicleManufactureOptions = Array.from(
    useSelector((state) => state?.vehicleManufacture?.vehicleManufactureList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleTypeOptions = Array.from(
    useSelector((state) => state?.vehicleType?.vehicleTypeList)
  ).filter((s) => s.activeStatus == 1);
  const vehicleModelOptions = Array.from(
    useSelector((state) => state?.vehicleModel?.filterVehicleModelList)
  ).filter((s) => s.activeStatus == 1);
  let clients = Array.from(useSelector((state) => state?.client?.clientList));
  clients = clients.filter((client) => client.clientActiveStatus == 1);

  if (role === "2" || role === 2) {
    clients = clients.filter((client) => client.userId == userId);
    //console.log("client fil", clients);
  }
  const clientLocationArr = Array.from(
    useSelector((state) => state?.client?.clientList)
  );

  let cvList = useSelector((state) => state.cvinspections.CVinspectionList);

  //console.log("cvvvv", cvList);

  //  console.log("clieeee", selectedClient);

  useEffect(() => {
    setClientId1(selectedClient[0]?.clientId);
    // setClientlocId1(selectedClient.serviceLocations);
  }, [selectedClient]);

  // console.log("cidd", clientId1);
  // console.log("ci loc dd", clientlocId1);
  const locc = clientlocId1;

  const sameClis = cvList.filter((item) => item.clientId == clientId1);
  const smaelocs2 = sameClis.filter(
    (item) => item.clientServiceLocationId == locc
  );
  console.log("same locs22", smaelocs2);
  const smaelocs = smaelocs2.filter((item) => item.activeStatus == 1);
  console.log("same locs", smaelocs);

  // console.log("same  LOCCCC", smaelocs);

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  // Handle changes in the date input
  function handleDateChange(e) {
    const selectedDate = e.target.value;
    if (selectedDate <= getCurrentDateTime()) {
      setDate(selectedDate);
    }
  }
  // const handleVehicleRegNumberChange = (e) => {
  //   // Update the vehicleRegNumber state when the input value changes
  //   setVehicleRegNumber(e.target.value);
  //  // console.log(vehicleRegNumber);
  // };

  useEffect(() => {
    vehicleManufactureDataMethod.fetchAllVehicleManufactureData();
    vehicleTypeDataMethod.fetchAllVehicleTypeData();
    vehicleModelDataMethod.fetchAllVehicleModelData();
    clientMethods.fetchAllClients();

    if (parameters.inspectionId) {
      inspectionMethods.fetchInspection(parameters.inspectionId);
    }
  }, []);

  const editInspectionData = useSelector(
    (state) => state.inspection.editInspection
  );

  useEffect(() => {
    console.log("ensssssss", editInspectionData);
    if (
      parameters.inspectionId &&
      editInspectionData.inspectionId == parameters.inspectionId &&
      clients &&
      clients.length > 0
    ) {
      if (editInspectionData?.inspectionDateTime) {
        editInspectionData.inspectionDateTime =
          editInspectionData.inspectionDateTime.toString().slice(0, 16);
      }
      const selectedClient = clients?.find(
        (c) => c.clientId == editInspectionData.clientId
      );
      if (selectedClient) {
        setSelectedClient([selectedClient]);
        setServiceLocations(selectedClient.serviceLocations);
      }
      setSelectedVehs(editInspectionData.vehicleRegNumber);
      editInspectionData.clientServiceLocationId = (
        editInspectionData.clientServiceLocationId || ""
      ).toString();
      vehicleManufactureDataMethod.getVehicleByFilter(
        editInspectionData.vehicleManufacturer
      );
      setInitialValues(editInspectionData);
    }
    localStorage.removeItem("pageRefreshedI");
  }, [editInspectionData]);

  const onSubmit = (data) => {
    if (parameters.inspectionId) {
      inspectionMethods.updateInspection(parameters.inspectionId, data);
    } else {
      inspectionMethods.storeInspection(data);
    }
  };

  const onSelectVehicle = async (data, setFieldValue, type) => {
    if (data.vehicleManufacturer && data.vehicleModel && data.vehicleType) {
      const response = await inspectionMethods.getVehicleByFilter(
        data.vehicleManufacturer,
        data.vehicleModel,
        data.vehicleType
      );
      setFieldValue("vehicleId", response ? response.vehicleId : "");
      setFieldValue(
        "configuredVehicle",
        response ? response.configuredVehicle : ""
      );
      setFieldValue("noOfTires", response ? response.noOfTires : "");
      setFieldValue("noOfStepneys", response ? response.noOfStepneys : "");
      setFieldValue("tireSequence", response ? response.tireSequence : "");
      setFieldValue(
        "tireSequenceLabel",
        response ? response.vehicleSubTypeLabel : ""
      );
    }

    if (type === "vehicleManufacturer") {
      const selectedManufacture = vehicleManufactureOptions.find(
        (v) => v.vehicleManufacturerId == data.vehicleManufacturer
      );
      setFieldValue(
        "vehicleManufacturerLabel",
        selectedManufacture.vehicleManufacturerName
      );
      vehicleManufactureDataMethod.getVehicleByFilter(data.vehicleManufacturer);
    }

    if (type === "vehicleModel") {
      const selectedModel = vehicleModelOptions.find(
        (v) => v.vehicleModelId == data.vehicleModel
      );
      setFieldValue("vehicleModelLabel", selectedModel.vehicleModelName);
    }

    if (type === "vehicleType") {
      const selectedType = vehicleTypeOptions.find(
        (v) => v.vehicleTypeId == data.vehicleType
      );
      console.log(
        "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
        selectedType
      );
      setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
    }

    if (type === "vehicleType") {
      const selectedType = vehicleTypeOptions.find(
        (v) => v.vehicleTypeId == data.vehicleType
      );
      console.log(
        "🚀 ~ file: InspectionForm.js:127 ~ onSelectVehicle ~ selectedType:",
        selectedType
      );
      setFieldValue("vehicleTypeLabel", selectedType.vehicleTypeName);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={(values) => {
          console.log("----------", values);
          onSubmit(values);
        }}
      >
        {(formik) => {
          const { errors, touched, setFieldValue, setFieldTouched, values } =
            formik;

          // const handleInputChange = (e) => {
          //   const inputValue = e.target.value;
          //   setInputValue(inputValue);

          //   // Your logic to filter and update suggestions based on inputValue
          //   const filteredSuggestions = smaelocs.filter((item) =>
          //     item.vehicleRegNumber
          //       .toLowerCase()
          //       .includes(inputValue.toLowerCase())
          //   );

          //   setSuggestions(filteredSuggestions);
          // };

          const handleApiCall = async (selected) => {
            try {
              const vehicleRegNumber2 = selected;
              // setInputValue(vehicleRegNumber2);
              // setVehicleRegNumber(vehicleRegNumber2);

              const apiUrl = `/masterservice/getvehiclebyregnumber?vehicleRegNumber=${vehicleRegNumber2}`;

              setErr(false);
              setNull(false);

              if (vehicleRegNumber2 == "" || vehicleRegNumber2 == null) {
                setErr(true);
                return;
              }

              const response = await api.get(apiUrl);
              const responseData = response.data.result;

              if (responseData.length > 0) {
                const firstItem = responseData[0];

                setFieldValue(
                  "vehicleManufacturer",
                  firstItem.vehicleManufacturer.toString() || ""
                );
                setFieldValue(
                  "vehicleModel",
                  firstItem.vehicleModel.toString() || ""
                );
                setFieldValue(
                  "vehicleType",
                  firstItem.vehicleType.toString() || ""
                );
                setFieldValue("vehicleTypeLabel", firstItem.vehicleType || "");
                setFieldValue(
                  "vehicleTypeLabel",
                  firstItem.vehicleTypeLabel || ""
                );
                setFieldValue(
                  "vehicleModelLabel",
                  firstItem.vehicleModelLabel || ""
                );
                setFieldValue(
                  "vehicleManufacturerLabel",
                  firstItem.vehicleManufacturerLabel || ""
                );
                setFieldValue(
                  "configuredVehicle",
                  firstItem.configuredVehicle || ""
                );
                setFieldValue(
                  "vehicleId",
                  firstItem.vehicleId.toString() || ""
                );
                // ... other setFieldValue assignments ...

                setSelectedVehicleManufacturer(
                  firstItem.vehicleManufacturerLabel || ""
                );
                setSelectedVehicleModel(firstItem.vehicleModelLabel || "");
                setSelectedVehicleType(firstItem.vehicleTypeLabel || "");
                setSelectedVehicleManufacturerId(
                  firstItem.vehicleManufacturer || ""
                );
                setSelectedVehicleModelId(firstItem.vehicleModel || "");
                setSelectedVehicleTypeId(firstItem.vehicleType || "");
                setSelectedConfig(firstItem.configuredVehicle || "");

                if (
                  firstItem.vehicleManufacturer &&
                  firstItem.vehicleModel &&
                  firstItem.vehicleType
                ) {
                  const res = await inspectionMethods.getVehicleByFilter(
                    firstItem.vehicleManufacturer,
                    firstItem.vehicleModel,
                    firstItem.vehicleType
                  );
                  setFieldValue(
                    "tireSequenceLabel",
                    res ? res.vehicleSubTypeLabel : ""
                  );
                }

                // Make the next API call
                const secondApiResponse = await api.get(
                  `/masterservice/getvehicledata?vehicleId=${firstItem.vehicleId}`
                );
                const secondResponseData = secondApiResponse.data.result;

                if (secondResponseData) {
                  const firstItem2 = secondResponseData;

                  setFieldValue(
                    "noOfStepneys",
                    firstItem2.noOfStepneys.toString() || ""
                  );
                  setFieldValue(
                    "noOfTires",
                    firstItem2.noOfTires.toString() || ""
                  );
                  setFieldValue("tireSequence", firstItem2.tireSequence || "");
                  setFieldValue(
                    "vehicleSubTypeLabel",
                    firstItem2.vehicleSubTypeLabel || ""
                  );
                }
              } else {
                console.warn(
                  "No data available for the given vehicle reg number."
                );
              }
            } catch (error) {
              setNull(true);
              // Set other state values to null or handle errors
              console.error("Error fetching data:", error);
              setVehicleData(null); // Clear the data in case of an error
            }
          };

          return (
            <>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Form>
                      {parameters?.inspectionId && (
                        <div className="row mb-4">
                          <div className="col-6">Inspection ID:</div>
                          <div className="col-6 text-muted h-40">
                            {values?.inspectionId ? values?.inspectionId : "-"}
                          </div>
                        </div>
                      )}

                      {/* <div className="row mb-4">
                        <div className="col-6">
                          Client Name : {selectedClient.clientId}
                        </div>
                        <div className="col-6 text-muted">
                          <Typeahead
                            clearButton
                            id="basic-typeahead-single"
                            multiple={false}
                            disabled={parameters.inspectionId}
                            onChange={(selected) => {
                              const value =
                                selected.length > 0 ? selected[0].clientId : "";
                              setFieldValue("clientId", value);
                              setFieldValue(
                                "clientName",
                                value ? selected[0].clientFullName : ""
                              );
                              setSelectedClient(selected);
                              if (value) {
                                setFieldValue(
                                  "clientInformation",
                                  `${selected[0].clientCompanyName},${selected[0].clientMobileNo}`
                                );
                                setServiceLocations(
                                  selected[0].serviceLocations
                                );
                              } else {
                                setFieldValue("clientInformation", "");
                                setServiceLocations([]);
                              }
                            }}
                            onBlur={(e) => setFieldTouched("clientId", true)}
                            labelKey="clientFullName"
                            options={clients}
                            selected={selectedClient}
                          />
                          <div className="text-danger">
                            {touched.clientId && errors.clientId}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Client Information :</div>
                        {!values.clientInformation && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {values.clientInformation && (
                          <div className="col-6 text-muted h-40">
                            {values.clientInformation}
                          </div>
                        )}
                      </div> */}

                      <div className="row mb-4">
                        <div className="col-6">
                          Client Company Name : {selectedClient.clientId}
                        </div>
                        <div className="col-6 text-muted">
                          <Typeahead
                            clearButton
                            id="basic-typeahead-single"
                            multiple={false}
                            onChange={(selected) => {
                              const value =
                                selected.length > 0
                                  ? selected[0].clientCompanyName
                                  : "";
                              setFieldValue("clientCompanyName", value);
                              setFieldValue(
                                "clientName",
                                value ? selected[0].clientFullName : ""
                              );
                              setSelectedClient(selected);
                              if (value) {
                                setFieldValue(
                                  "clientId",
                                  `${selected[0].clientId}`
                                );
                                setFieldValue(
                                  "clientInformation",
                                  `${selected[0].clientCompanyName},${selected[0].clientMobileNo}`
                                );
                                setServiceLocations(
                                  selected[0].serviceLocations
                                );
                              } else {
                                setFieldValue("clientId", "");
                                setFieldValue("clientInformation", "");
                                setServiceLocations([]);
                              }
                            }}
                            onBlur={(e) =>
                              setFieldTouched("clientCompanyName", true)
                            }
                            labelKey="clientCompanyName" // Assuming clientCompanyName is the label key in your options
                            options={clients}
                            selected={selectedClient}
                          />
                          <div className="text-danger">
                            {touched.clientCompanyName &&
                              errors.clientCompanyName}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Client Name :</div>
                        {!values.clientName && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {values.clientName && (
                          <div className="col-6 text-muted h-40">
                            {values.clientName.split(",")[0].trim()} ,
                            {values.clientInformation && values.clientInformation.split(",")[1].trim()}
                          </div>
                        )}
                      </div>

                      {values.clientName && (
                        <div className="row mb-4">
                          <div className="col-6">Client Location :</div>
                          <div className="col-6 text-muted">
                            <Select
                              errors={errors}
                              touched={touched}
                              label={false}
                              readOnly={parameters.inspectionId}
                              name="clientServiceLocationId"
                              required={false}
                              disabled={parameters.inspectionId}
                              onChange={(e) => {
                                setFieldValue(
                                  "clientServiceLocationId",
                                  e.target.value
                                );
                                setClientlocId1(e.target.value);
                                const selectedAddress = serviceLocations.find(
                                  (s) =>
                                    s.clientServiceLocationId ===
                                    Number(e.target.value)
                                );

                                if (selectedAddress) {
                                  setFieldValue(
                                    "clientServiceLocationAddress",
                                    `${selectedAddress.clientServiceAddress}, ${selectedAddress.clientServiceCity}, ${selectedAddress.clientServicePincode}`
                                  );
                                  values.clientServiceLocationAddress = `${selectedAddress.clientServiceAddress}, ${selectedAddress.clientServiceCity}, ${selectedAddress.clientServicePincode}`;
                                }
                                values.clientServiceLocationId = e.target.value;
                              }}
                            >
                              <option value="">select</option>
                              {serviceLocations?.filter(item => item.activeStatus === 1).map((data) => (
                                <option
                                  key={data.clientServiceLocationId}
                                  value={data.clientServiceLocationId}
                                >
                                  {`${data.clientServiceAddress}, ${data.clientServiceCity}, ${data.clientServicePincode}`}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <div className="col-6">Date and Time :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"datetime-local"}
                            label={false}
                            name={"inspectionDateTime"}
                            readOnly={parameters.inspectionId}
                            maxDate={getCurrentDateTime()} // Set the maxDate prop to restrict future dates
                          />
                          {/* <input
                            type="datetime-local"
                            value={date}
                            onChange={handleDateChange}
                            readOnly={parameters.inspectionId}
                          /> */}
                        </div>
                      </div>

                      {/* <div className="row mb-4">
                        <div className="col-6">Vehicle Reg No :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            name="vehicleRegNumber"
                            required={false}
                            onChange={(e) => {
                              // setVehicleRegNumber(e.target.value);
                              handleApiCall(e);
                              setFieldValue("vehicleRegNumber", e.target.value);
                              values.vehicleRegNumber = e.target.value;
                            }}
                          >
                            {parameters.inspectionId && (
                              <option>
                                {editInspectionData.vehicleRegNumber}
                              </option>
                            )}
                            <option value={""}>select</option>
                            {smaelocs?.map((item) => (
                              <option
                                key={item.vehicleRegNumber}
                                value={item.vehicleRegNumber}
                              >
                                {item.vehicleRegNumber}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div> */}


                      {parameters.inspectionId ? (
                        <>
                          <div className="row mb-4">
                            <div className="col-6">Vehicle Reg No :</div>
                            <div className="col-6 text-muted">
                              <TextInputField
                                errors={errors}
                                touched={touched}
                                readOnly={parameters.inspectionId}
                                disabled={parameters.inspectionId}
                                type={"text"}
                                label={false}
                                name={"vehicleRegNumber"}
                              />
                              <div className="text-danger">
                                {touched.vehicleRegNumber &&
                                  errors.vehicleRegNumber}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row mb-4">
                          <div className="col-6">Vehicle Reg No :</div>
                          <div className="col-6 text-muted">
                            <Typeahead
                              clearButton
                              disabled={parameters.inspectionId}
                              id="basic-typeahead-single"
                              multiple={false}
                              onChange={(selected) => {
                                const value =
                                  selected.length > 0
                                    ? selected[0].vehicleRegNumber
                                    : "";
                                setFieldValue("vehicleRegNumber", value);
                                setSelectedVehs(selected);
                                if (value) {
                                  setFieldValue(
                                    "vehicleRegNumber",
                                    `${selected[0].vehicleRegNumber}`
                                  );
                                  handleApiCall(value);
                                } else {
                                  setFieldValue("vehicleRegNumber", "");
                                }
                              }}
                              onBlur={(e) =>
                                setFieldTouched("vehicleRegNumber", true)
                              }
                              labelKey="vehicleRegNumber"
                              options={smaelocs}
                              selected={selectedVehs}
                            />
                            <div className="text-danger">
                              {touched.vehicleRegNumber &&
                                errors.vehicleRegNumber}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <div className="col-6">
                          Vehicle Manufacturer (Master) :
                        </div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            disabled={
                              parameters.inspectionId ||
                              selectedVehicleManufacturer
                            }
                            name="vehicleManufacturer"
                            value={values.vehicleManufacturer}
                            required={false}
                            onChange={(e) => {
                              setFieldValue(
                                "vehicleManufacturer",
                                e.target.value
                              );
                              values.vehicleManufacturer = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleManufacturer"
                              );
                              if (selectedVehicleManufacturer) {
                                setFieldValue(
                                  "vehicleManufacturer",
                                  selectedVehicleManufacturerId
                                );
                                values.vehicleManufacturer =
                                  selectedVehicleManufacturerId; // Update the values object
                                values.vehicleManufacturerLabel =
                                  selectedVehicleManufacturer;
                                values.configuredVehicle = selectedConfig;
                              }
                            }}
                          >
                            {!selectedVehicleManufacturer && (
                              <option value="">select</option>
                            )}

                            {selectedVehicleManufacturer ? (
                              <option
                                key={selectedVehicleManufacturerId}
                                value={selectedVehicleManufacturerId}
                              >
                                {selectedVehicleManufacturer}
                              </option>
                            ) : (
                              vehicleManufactureOptions?.map((data, index) => (
                                <>
                                  <option
                                    key={data.vehicleManufacturerId}
                                    value={data.vehicleManufacturerId}
                                  >
                                    {data.vehicleManufacturerName}
                                  </option>
                                </>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Model :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            name="vehicleModel"
                            required={false}
                            disabled={
                              parameters.inspectionId || selectedVehicleModel
                            }
                            onChange={(e) => {
                              setFieldValue("vehicleModel", e.target.value);
                              if (selectedVehicleModel) {
                                setFieldValue(
                                  "vehicleModel",
                                  selectedVehicleModelId
                                );
                                values.vehicleModel = selectedVehicleModelId; // Update the values object
                                values.vehicleModelLabel = selectedVehicleModel;
                              }
                              values.vehicleModel = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleModel"
                              );
                            }}
                          >
                            {!selectedVehicleModel && (
                              <option value="">select</option>
                            )}

                            {selectedVehicleModel ? (
                              <option
                                key={selectedVehicleModelId}
                                value={selectedVehicleModel}
                              >
                                {selectedVehicleModel}
                              </option>
                            ) : (
                              vehicleModelOptions?.map((data, index) => (
                                <option
                                  key={data.vehicleModelId}
                                  value={data.vehicleModelId}
                                  data-dataid={data.vehicleModelId}
                                >
                                  {data.vehicleModelName}
                                </option>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle type :</div>
                        <div className="col-6 text-muted">
                          <Select
                            errors={errors}
                            touched={touched}
                            label={false}
                            readOnly={parameters.inspectionId}
                            name="vehicleType"
                            disabled={
                              parameters.inspectionId || selectedVehicleType
                            }
                            required={false}
                            onChange={(e) => {
                              setFieldValue("vehicleType", e.target.value);
                              if (selectedVehicleType) {
                                setFieldValue(
                                  "vehicleType",
                                  selectedVehicleTypeId
                                );
                                values.vehicleType = selectedVehicleTypeId; // Update the values object
                                values.vehicleTypeLabel = selectedVehicleType;
                              }
                              values.vehicleType = e.target.value;
                              onSelectVehicle(
                                values,
                                setFieldValue,
                                "vehicleType"
                              );
                            }}
                          >
                            {!selectedVehicleType && (
                              <option value="">select</option>
                            )}

                            {selectedVehicleType ? (
                              <option
                                key={selectedVehicleTypeId}
                                value={selectedVehicleType}
                              >
                                {selectedVehicleType}
                              </option>
                            ) : (
                              vehicleTypeOptions?.map((data, index) => (
                                <option
                                  key={data.vehicleTypeId}
                                  value={data.vehicleTypeId}
                                  data-dataid={data.vehicleTypeId}
                                >
                                  {data.vehicleTypeName}
                                </option>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Vehicle Configuration Key :</div>

                        {!values.configuredVehicle && (
                          <div className="col-6 text-muted h-40 pl-4">
                            Auto Fill
                          </div>
                        )}
                        {selectedConfig ? (
                          <div className="col-6 text-muted h-40">
                            {selectedConfig}
                          </div>
                        ) : (
                          values.configuredVehicle && (
                            <div className="col-6 text-muted h-40">
                              {values.configuredVehicle}
                            </div>
                          )
                        )}
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Driver Name :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            type={"text"}
                            label={false}
                            name={"driverName"}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Driver Mobile No. :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            type={"text"}
                            label={false}
                            name={"driverMobileNo"}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Maintanace Manager Name :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            type={"text"}
                            label={false}
                            name={"maintanaceManagerName"}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">
                          Maintanace Manager Mobile No. :
                        </div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            type={"text"}
                            label={false}
                            name={"maintanaceManagerMobileNo"}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">MTSS Name :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            type={"text"}
                            label={false}
                            name={"milanTireServiceAndSolutionName"}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">MTSS Mobile No. :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            readOnly={parameters.inspectionId}
                            disabled={parameters.inspectionId}
                            type={"text"}
                            label={false}
                            name={"milanTireServiceAndSolutionMobileNo"}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-6">Odometer Reading :</div>
                        <div className="col-6 text-muted">
                          <TextInputField
                            errors={errors}
                            touched={touched}
                            type={"text"}
                            label={false}
                            name={"vehicleOdometerReading"}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-5">
                        <button
                          type="submit"
                          className="btn-fill btn btn-info m-3"
                        >
                          {parameters?.inspectionId ? "Update" : "Save"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default InspectionForm;
