import {
  FETCH_ALL_CLIENT,
  SET_EDIT_CLIENT,
  SET_GST_REGISTRATION_OPTIONS,
  CLIENT_LOGIN,
  CLIENT_LOGOUT,
} from "../constants/ClientActionTypes";

export const clientLogin = (data) => {
  return {
    type: CLIENT_LOGIN,
    payload: data,
  };
};

export const clientLogout = () => {
  return {
    type: CLIENT_LOGOUT,
  };
};

export const fetchAllClients = (clients) => {
    return {
        type: FETCH_ALL_CLIENT,
        payload : clients
    }
}

export const setEditClient = (clients) => {
    return {
        type: SET_EDIT_CLIENT,
        payload : clients
    }
}

export const setGstRegistrationOptions = types => {
    return {
        type : SET_GST_REGISTRATION_OPTIONS,
        payload : types
    }
}