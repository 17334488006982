import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TireMakeActionButtons = ({ vehicleTireMakeDetails, onStatusUpdate }) => {

    const navigate = useNavigate();

    const redirectToEditPage = userId => {
        navigate("/mst-tire-make/edit/" + vehicleTireMakeDetails.tireMakeId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(vehicleTireMakeDetails.tireMakeId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>{" "}
                <Form.Check
                    inline
                    className={'mx-3 text-danger'}
                    type="switch"
                    value={vehicleTireMakeDetails.activeStatus === 1 ? 1 : 0}
                    checked={vehicleTireMakeDetails.activeStatus === 1 ? true : false}
                    id={'toggle-button' + vehicleTireMakeDetails.tireMakeId}
                    onChange={(e) => { onStatusUpdate(vehicleTireMakeDetails.tireMakeId, e.target.value) }}
                />
            </div>
        </>
    );
}

export default TireMakeActionButtons;