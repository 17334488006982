import { FETCH_ALL_USERS, FETCH_ROLE_OPTIONS, FETCH_STATUS_OPTIONS, SET_EDIT_USER, USER_LOGIN, USER_LOGOUT } from "../constants/AuthActionTypes"

const initialState = {
    isLogin: false,
    user: {},
    userList : {},
    editUser : {},
    userRoleOptions : {},
    userStatusOptions : {}
}

export const UserReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case USER_LOGIN:
            return {
                isLogin : true,
                user : payload
            }
        
        case USER_LOGOUT:
            return {
              isLogin: false,
              user: {},
            };
        case FETCH_ALL_USERS: 
            return {
                ...state,
                userList : payload
            }
        case SET_EDIT_USER :
            return {
                ...state,
                editUser : payload
            }
        case FETCH_ROLE_OPTIONS : 
            return {
                ...state,
                userRoleOptions : payload?.content
            }
        case FETCH_STATUS_OPTIONS : 
            return {
                ...state,
                userStatusOptions : payload?.content
            }
        default:
            return state
    }
}