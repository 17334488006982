import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TireActionButton = ({ tireDetails, onStatusUpdate }) => {
    const navigate = useNavigate();
    const redirectToEditPage = tireConfigurationId => {
        navigate("/mst-tire-configuration/edit/" + tireDetails?.tireConfigurationId);
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Button variant="primary" size="sm" onClick={() => redirectToEditPage(tireDetails?.tireConfigurationId)} >
                    <i className="fa-solid fa-user-pen"></i>
                </Button>
                <Form.Check
                    inline
                    className={'mx-3 text-danger'}
                    type="switch"
                    value={tireDetails?.activeStatus === 1 ? 1 : 0}
                    checked={tireDetails?.activeStatus === 1 ? true : false}
                    id={'toggle-button' + tireDetails?.tireConfigurationId}
                    onChange={(e) => { onStatusUpdate(tireDetails?.tireConfigurationId, e.target.value) }}
                />
            </div>
        </>
    );
}

export default TireActionButton;